import { useFeatureFlag } from "@expert/shared-utils";
import { Flex } from "@mantine/core";
import { env } from "../../../config";
import { AgentStats, AgentStatus, Profile } from "../../header";

export function HeaderControls(): JSX.Element {
    const { data: isPostCallInsightsEnabled } = useFeatureFlag("EWP-isPostCallInsightsEnabled");
    return (
        <Flex wrap="nowrap" gap="0">
            <AgentStatus />
            {isPostCallInsightsEnabled ? <AgentStats /> : null}
            <Profile isProdMode={env.isProdMode} commitHash={env.commitHash} />
        </Flex>
    );
}
