import type { GaiaResponseBody } from "@expert/gaia";
import { gaiaWsEventBus } from "@expert/gaia";
import { useEffect } from "react";
import { usePlatformExpertAssistStore } from "../store";

export const useGaiaSubscriptionEvents = () => {
    const { setSubscriptionStatus } = usePlatformExpertAssistStore();

    useEffect(() => {
        const gaiaEventBusSubSuccess = gaiaWsEventBus.on("gaia_ws_session-subscription-success", (payload) => {
            const { sessionGroupId, sessionId } = payload.body as GaiaResponseBody;
            const subscriptionKey = sessionGroupId ?? sessionId;
            if (subscriptionKey) {
                setSubscriptionStatus(subscriptionKey, "subscribed");
            }
        });

        const gaiaEventBusUnsubSuccess = gaiaWsEventBus.on("gaia_ws_session-unsubscription-success", (payload) => {
            const body = payload.body as GaiaResponseBody;
            if (body.sessionId) {
                setSubscriptionStatus(body.sessionId, "unsubscribed");
            }
        });

        return () => {
            gaiaEventBusSubSuccess();
            gaiaEventBusUnsubSuccess();
        };
    }, [setSubscriptionStatus]);
};
