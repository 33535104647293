import { getLogger } from "@expert/logging";
import { useEffect, useState } from "react";
import { getAppConfig } from "../get-app-config";

const logger = getLogger({ module: "useAppConfig" });

interface UseAppConfigOutput<TAppConfig> {
    loading: boolean;
    error: Error | null;
    appConfig?: TAppConfig;
}

/** Can ONLY be used under the <AuthProvider> scope
 * Retrieves the app configuration (currently specific for service provider, like TwilioAppConfig)
 */
export function useAppConfig<TAppConfig>(): UseAppConfigOutput<TAppConfig> {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<Error | null>(null);
    const [appConfig, setAppConfig] = useState<TAppConfig>();

    useEffect(() => {
        const getConfig = async () => {
            const config = await getAppConfig<TAppConfig>();
            logger.debug("AppConfig received");

            setAppConfig(config);
            setLoading(false);
        };

        // TODO: More elegant error handling/reporting when we decide how we want this to bubble up to user space
        getConfig().catch((err: unknown) => {
            setError(err as Error);
            logger.error(err as Error);
            setLoading(false);
        });
    }, []);

    return { loading, error, appConfig };
}
