import { useDisclosure } from "@mantine/hooks";
import { useAnalytics, withPolymorphicClickAnalytics } from "@expert/analytics";
import { Button, Text } from "@mantine/core";
import { useMostRecentTask } from "@expert/sdk";
import { StatsModal } from "./StatsModal";
import { ChallengesIcon } from "./icons";

const AnalyticsButton = withPolymorphicClickAnalytics(Button, "Click");

export function AgentStats(): JSX.Element {
    const { dispatcher } = useAnalytics();
    const [opened, { open, close }] = useDisclosure(false, {
        onOpen: () => {
            void dispatcher.dispatchAction("DialogShown", "ChallengesModal");
        },
    });
    const mostRecentTask = useMostRecentTask();
    return (
        <>
            <StatsModal opened={opened} onClose={close} />
            <AnalyticsButton
                analytics={{
                    actionId: "AgentChallenges",
                }}
                color="gray"
                size="sm"
                variant="subtle"
                disabled={mostRecentTask?.status === "wrapping" || opened}
                onClick={open}
                leftSection={
                    <ChallengesIcon width={20} height={20} disabled={mostRecentTask?.status === "wrapping" || opened} />
                }
            >
                <Text size="xs" c={mostRecentTask?.status === "wrapping" ? "dark.4" : "white"}>
                    Challenges
                </Text>
            </AnalyticsButton>
        </>
    );
}
