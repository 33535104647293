export const PendingIcon = (
    <svg width="66" height="52" fill="none">
        <path
            stroke="#6E767D"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={3}
            d="M63.667 45a5.333 5.333 0 0 1-5.334 5.334H7.667A5.333 5.333 0 0 1 2.333 45V7.667a5.333 5.333 0 0 1 5.334-5.333h50.666a5.333 5.333 0 0 1 5.334 5.333V45Z"
            clipRule="evenodd"
        />
        <path
            stroke="#6E767D"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2.667}
            d="M2.333 15.662h61.334M12.333 7.667a.667.667 0 1 0 0 1.333.667.667 0 0 0 0-1.333M20.333 7.667a.667.667 0 1 0 0 1.333.667.667 0 0 0 0-1.333M28.333 7.667a.667.667 0 1 0 0 1.333.667.667 0 0 0 0-1.333"
        />
        <path
            stroke="#6E767D"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2.667}
            d="M10.333 33A6.667 6.667 0 0 1 17 26.334h32a6.667 6.667 0 0 1 0 13.333H17A6.667 6.667 0 0 1 10.333 33Z"
            clipRule="evenodd"
        />
        <path
            stroke="#6E767D"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2.667}
            d="m12.416 37.827 11.499-11.499M18.584 39.661l13.33-13.333M26.584 39.661l13.33-13.333"
        />
    </svg>
);
