export function SuccessIcon() {
    return (
        <svg fill="none" height="16" viewBox="0 0 17 16" width="17" xmlns="http://www.w3.org/2000/svg">
            <path
                clipRule="evenodd"
                d="M13.6667 8C13.6667 10.9455 11.2789 13.3333 8.33335 13.3333C5.38784 13.3333 3.00002 10.9455 3.00002 8C3.00002 5.05448 5.38784 2.66667 8.33335 2.66667C11.2789 2.66667 13.6667 5.05448 13.6667 8ZM15 8C15 11.6819 12.0153 14.6667 8.33335 14.6667C4.65146 14.6667 1.66669 11.6819 1.66669 8C1.66669 4.3181 4.65146 1.33333 8.33335 1.33333C12.0153 1.33333 15 4.3181 15 8ZM7.4759 11.107L12.1673 5.77365L11.1661 4.89302L6.93988 9.69758L5.46708 8.25156L4.53296 9.20298L6.50827 11.1424L7.01078 11.6357L7.4759 11.107Z"
                fill="white"
                fillRule="evenodd"
            />
        </svg>
    );
}
