import styled from "@emotion/styled";

export const ErrorWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    width: 100%;
`;

export const ErrorAsset = styled.div({
    display: "flex",
    width: "100%",
    maxWidth: "12rem",
    minWidth: "8rem",
    alignSelf: "center",
    justifyContent: "center",
    alignItems: "center",
});

export const ErrorContent = styled.div({
    display: "flex",
    width: "100%",
    marginTop: "1rem",
    alignSelf: "center",
    justifyContent: "center",
    alignItems: "center",
});
