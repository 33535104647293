import { useAnalytics } from "@expert/analytics";
import { useFeatureFlag } from "@expert/shared-utils";
import { Flex, Text } from "@mantine/core";
import { useEffect } from "react";
import { useHomeProductSessionStore } from "../stores";
import { EligibilityStatus, SaleProgress } from "../types";
import { SaleView } from "./StepSpecific/SaleView";
import classes from "./HomeProductFlow.module.css";
import { GuidedFlow } from "./GuidedFlow";
import { UnguidedFlow } from "./UnguidedFlow";
import { EnrollFailureScreen } from "./StepSpecific/EnrollFailure";

export function HomeProductFlow(): JSX.Element {
    const { data: isGuidedFlowEnabled, isLoading } = useFeatureFlag("EWP-isGuidedFlowEnabled");
    const { dispatcher } = useAnalytics();
    const products = useHomeProductSessionStore((state) => state.products);
    useEffect(() => {
        void dispatcher.dispatchAction("PageShown", "sale-tool");
    }, [dispatcher]);

    if (!products || products.length === 0) {
        return <Text data-testid="home-flow-error">Something went wrong</Text>;
    }
    const homeProduct = products[0];

    // eslint-disable-next-line react/jsx-no-useless-fragment
    if (isLoading) return <></>;

    return isGuidedFlowEnabled &&
        homeProduct.status === EligibilityStatus.Eligible &&
        homeProduct.saleProgress === SaleProgress.InProgress ? (
        <Flex className={classes.flexContainer}>
            <GuidedFlow />
        </Flex>
    ) : (
        <Flex className={classes.flexContainer} data-testid="home-product-flow-container">
            {/* Customer Eligible */}
            {homeProduct.status === EligibilityStatus.Eligible &&
            homeProduct.saleProgress === SaleProgress.InProgress ? (
                <UnguidedFlow homeProduct={homeProduct} />
            ) : null}

            {/* Customer Not eligible */}
            {homeProduct.status !== EligibilityStatus.Eligible &&
            homeProduct.saleProgress === SaleProgress.InProgress ? (
                <SaleView status={homeProduct.status} />
            ) : null}

            {/* Endgame */}
            {homeProduct.saleProgress === SaleProgress.Success || homeProduct.saleProgress === SaleProgress.Declined ? (
                <SaleView status={homeProduct.saleProgress} />
            ) : null}

            {/* Alternate Endgame */}
            {homeProduct.saleProgress === SaleProgress.Failure ? <EnrollFailureScreen /> : null}
        </Flex>
    );
}
