import type { ElementProps, InputProps } from "@mantine/core";
import { Input } from "@mantine/core";
import { IMaskInput } from "react-imask";

interface MaskedInputProps extends InputProps, ElementProps<"input", keyof InputProps> {
    mask: string;
}

export function MaskedInput({ mask, ...props }: MaskedInputProps) {
    const _value: string | undefined = props.value?.toString();
    return <Input {...props} component={IMaskInput} mask={mask} value={_value} />;
}
