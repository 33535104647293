import { sdkEventBus } from "@expert/sdk";
import { type Contact, TFN_LENGTH, US } from "@expert/shared-types";
import { extractNumbers, isInternalTfn } from "@expert/shared-utils/src/transfer/transferUtils";
import { parsePhoneNumber } from "awesome-phonenumber";
import { create } from "zustand";
import { devtools } from "zustand/middleware";
import type { TransferType } from "./types";

// Ensure we reset our state after the session ends
sdkEventBus.on("session_changed", () => {
    useTransfersStateStore.getState().resetState();
});

/** TODO: there isn't much value in storing tfn and vdns as seperate state values when we could have a boolean to indicate if it's sip.
 * or even a small helper function that checks if it is a sip transfer or not.
 * Consider refactoring this to be more clear and concise during the transfers panel refactor.
 */

interface TransfersState {
    /** currently only REDIRECT is supported*/
    transferType: TransferType;
    /** Transfer Toll-Free-Number */
    transferTfn: string | null;
    /** Transfer Avaya Vector Directory Number */
    transferVdn: Contact | null;
}
interface TransfersActions {
    resetState: () => void;
    setTransferType: (transferType: TransferType) => void;
    setTransferTfn: (tollFreeNumber: string | null) => void;
    setTransferVdn: (vdn: Contact | null) => void;
}

type TransfersStateStore = TransfersState & TransfersActions;

const defaultState: TransfersState = {
    transferType: "REDIRECT",
    transferTfn: null,
    transferVdn: null,
};

/** Only used for managing the transfer UI state */
export const useTransfersStateStore = create<TransfersStateStore>()(
    devtools(
        (set) => ({
            ...defaultState,

            setTransferType: (type: TransferType) => set({ transferType: type }, false, "setTransferType"),

            setTransferTfn: (tfn: string | null) =>
                set(
                    () => {
                        if (!tfn) {
                            return { transferTfn: null };
                        }
                        const digitsOnly = extractNumbers(tfn).slice(-TFN_LENGTH); // Prevent adding any numbers from the name
                        const { valid } = parsePhoneNumber(`${US}${digitsOnly}`);

                        if (!valid && !isInternalTfn(tfn)) {
                            //TODO: should we reset state and handle this error more gracefully?
                            throw new Error("Invalid phone number");
                        }
                        return { transferTfn: digitsOnly, transferVdn: null }; // Clear inverse
                    },
                    false,
                    "setTransferTfn",
                ),

            setTransferVdn: (vdn: Contact | null) =>
                set(
                    () => {
                        if (!vdn) {
                            return { transferVdn: null };
                        }
                        return { transferVdn: vdn, transferTfn: null }; // Clear inverse
                    },
                    false,
                    "setTransferVdn",
                ),

            resetState: () => set(defaultState, false, "resetState"),
        }),
        { enabled: import.meta.env.MODE !== "production", store: "transfers", name: "workspace/call-controls" },
    ),
);
