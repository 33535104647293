import { Flex } from "@mantine/core";
import classes from "./TimelineList.module.css";

export function TimelineListWrapper({
    children,
    scrollRef,
}: {
    children: React.ReactNode;
    scrollRef: React.RefObject<HTMLDivElement>;
}) {
    return (
        <Flex className={classes.wrapper} justify="center" ref={scrollRef}>
            {children}
        </Flex>
    );
}

export function List({ children }: { children: React.ReactNode }) {
    return (
        <Flex className={classes.list} direction="column">
            <ul>{children}</ul>
        </Flex>
    );
}
