import { PhoneNumber, ProperName } from "@expert/common-ui";
import { useVerifiedCustomerStore } from "@expert/customer-info";
import { Button, Flex, Image, Text } from "@mantine/core";
import { useCustomerAvatar } from "@expert/contentful";
import { useSessionId } from "@expert/sdk";
import { useAnalytics } from "@expert/analytics";
import { useFeatureFlag } from "@expert/shared-utils";
import { useCurrentStepStore, useHomeProductSessionStore, useOtherProductSessionStore } from "@expert/sales-tools";
import { SaleProgress } from "@expert/sales-tools/src/home-product/types";
import { useVerificationFlowStore } from "../customer-verification";
import classes from "./CustomerProfile.module.css";
import { CustomerPlanInfo } from "./CustomerPlanInfo";
import { EditIcon } from "./EditIcon";

export function CustomerProfile(): JSX.Element {
    const [customer] = useVerifiedCustomerStore((state) => [state.customer]);
    const stepHistoryUpdated = useVerificationFlowStore((state) => state.stepHistory);
    const sessionId = useSessionId();
    const customerAvatarUrl = useCustomerAvatar(sessionId);
    const { dispatcher } = useAnalytics();
    const { data: isMdnEditEnabled } = useFeatureFlag("EWP-isMdnEditEnabled");
    const otherProducts = useOtherProductSessionStore((state) => state.products);
    const homeProducts = useHomeProductSessionStore((state) => state.products);

    const hasDispositionedAnyProduct =
        otherProducts.some((product) => !!product.saleProgress && product.saleProgress !== SaleProgress.InProgress) ||
        homeProducts?.some((product) => !!product.saleProgress && product.saleProgress !== SaleProgress.InProgress);

    const isProfileTransition = stepHistoryUpdated[stepHistoryUpdated.length - 1] === "VerificationComplete";
    return (
        <Flex className={classes.root}>
            <Flex data-testid="profile-top" className={classes.topSection}>
                <Flex
                    data-testid="empty-cell"
                    className={isProfileTransition ? classes.removeEmptyCell : classes.emptyLeftCell}
                />
                {customerAvatarUrl !== undefined && (
                    <Image className={classes.profilePicture} data-testid="customer-avatar" src={customerAvatarUrl} />
                )}
                <Flex
                    data-testid="customer-detail"
                    className={isProfileTransition ? classes.customerDetailsPost : classes.customerDetailsPre}
                >
                    {isProfileTransition ? (
                        <>
                            <Text className={`${classes.name} fs-mask`} data-testid="customer-name" size="lg">
                                <ProperName name={customer?.fullName} />
                            </Text>
                            <Flex h="75%" w="100%" align="center">
                                <Text w="90%" className={`${classes.phoneNumber} fs-mask data-testid="customer-mdn"`}>
                                    {customer?.mdn ? <PhoneNumber mdn={customer.mdn} /> : null}
                                </Text>
                                {isMdnEditEnabled && !hasDispositionedAnyProduct ? (
                                    <Button
                                        id="mdn-edit-button"
                                        onClick={() => {
                                            void dispatcher.dispatchBusinessEvent("EditMDN");
                                            useVerificationFlowStore.getState().pushStep("CustomerPhoneInput");
                                            useHomeProductSessionStore.getState().resetSaleSession();
                                            useOtherProductSessionStore.getState().resetSaleSession();
                                            useCurrentStepStore.getState().resetCurrentStepStore();
                                        }}
                                        w="45%"
                                        leftSection={<EditIcon />}
                                        color="dark.7"
                                        size="sm"
                                        p={0}
                                        variant="filled"
                                    >
                                        Edit
                                    </Button>
                                ) : null}
                            </Flex>
                        </>
                    ) : null}
                </Flex>
            </Flex>
            {isProfileTransition && customer?.purchaseDate ? <CustomerPlanInfo /> : null}
        </Flex>
    );
}
