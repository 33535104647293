import { SimpleGrid } from "@mantine/core";
import { DialButton } from "./DialButton";

const dialOptions = [
    ["1", ""],
    ["2", "ABC"],
    ["3", "DEF"],
    ["4", "GHI"],
    ["5", "JKL"],
    ["6", "MNO"],
    ["7", "PQRS"],
    ["8", "TUV"],
    ["9", "WXYZ"],
    ["*", ""],
    ["0", ""],
    ["#", ""],
] as const;

export function DialButtons() {
    return (
        <SimpleGrid cols={3} p="0.75rem" spacing={0}>
            {dialOptions.map(([key, value]) => (
                <DialButton key={key} subtitle={value} title={key} />
            ))}
        </SimpleGrid>
    );
}
