import { withPolymorphicClickAnalytics } from "@expert/analytics";
import { ActivityBubble, CaretDown } from "@expert/common-ui";
import type { AgentActivity } from "@expert/sdk";
import { AGENT_ACTIVITIES, isUnselectableActivity, useAgentActivity, useAgentStore } from "@expert/sdk";
import { useStopwatch } from "@expert/shared-utils";
import { Button, Flex, Group, Menu, Space, Stack, Text } from "@mantine/core";
import classes from "./AgentStatus.module.css";

const AnalyticMenuItem = withPolymorphicClickAnalytics(Menu.Item, "Click");
const AnalyticsButton = withPolymorphicClickAnalytics(Button, "Click");

function ActivityClock({ activityUpdatedAt }: { activityUpdatedAt: number }) {
    const activityClockString = useStopwatch(activityUpdatedAt);
    return (
        <Text ff="mono" size="xs" className={classes.activityClock}>
            ({activityClockString})
        </Text>
    );
}

export function AgentStatus(): JSX.Element {
    const { activity, pendingActivity, activityUpdatedAt } = useAgentStore();
    const { loadingSetAgentActivity, setNewAgentActivity } = useAgentActivity();
    const shouldShowClock = !!activityUpdatedAt;

    /** Allow agents to set pending status, anything but Available is valid when in unselectable activity */
    function isDisabled(currentActivity: AgentActivity, activityLabel: string) {
        return (
            isUnselectableActivity(currentActivity) && activityLabel === "Available" && currentActivity !== "Default"
        );
    }

    const menuActivities = AGENT_ACTIVITIES.filter((activityName) => !isUnselectableActivity(activityName));

    const menuItems = menuActivities.map((activityName) => (
        <AnalyticMenuItem
            analytics={{
                actionId: "agent-activity-item",
                eventData: {
                    activity: activityName,
                },
            }}
            data-testid={`agent-activity-item-${activityName}`}
            disabled={activityName === activity || isDisabled(activity, activityName)}
            key={activityName}
            leftSection={<ActivityBubble available={activityName === "Available"} />}
            onClick={() => setNewAgentActivity(activityName)}
        >
            <Text size="sm">{activityName}</Text>
        </AnalyticMenuItem>
    ));

    return (
        <Menu shadow="sm" zIndex="var(--mantine-priority-highest)">
            <Menu.Target>
                <AnalyticsButton
                    analytics={{
                        actionId: "AgentStatus_ActivityDropdown",
                    }}
                    color="gray"
                    data-testid="agent-activity"
                    disabled={loadingSetAgentActivity || activity === "Offline"}
                    ml="lg"
                    mr="md"
                    size="sm"
                    variant="subtle"
                >
                    <Stack gap="2px">
                        <Group justify="center" gap="0" wrap="nowrap">
                            <ActivityBubble available={activity === "Available"} />
                            <Space w="xs" />
                            <Text size="xs">{activity}</Text>
                            <Space w="4px" />
                            {shouldShowClock ? <ActivityClock activityUpdatedAt={activityUpdatedAt} /> : null}
                            <Space w="xs" />
                            <CaretDown />
                        </Group>
                        {pendingActivity ? (
                            <Flex justify="center">
                                <Text c="gray" size="xxs">
                                    Pending:
                                </Text>
                                <ActivityBubble available={activity === "Available"} size={10} />
                                <Text size="xxs">{pendingActivity}</Text>
                            </Flex>
                        ) : null}
                    </Stack>
                </AnalyticsButton>
            </Menu.Target>
            <Menu.Dropdown>{menuItems}</Menu.Dropdown>
        </Menu>
    );
}
