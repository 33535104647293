import { withPolymorphicClickAnalytics } from "@expert/analytics";
import type { ButtonProps, ElementProps } from "@mantine/core";
import { Button } from "@mantine/core";

interface CancelCallbackProps extends ButtonProps, ElementProps<"button", keyof ButtonProps> {
    loading: boolean;
}

const AnalyticsButton = withPolymorphicClickAnalytics(Button, "Click");

export function CancelCallbackButton(props: CancelCallbackProps): JSX.Element {
    return (
        <AnalyticsButton
            analytics={{
                actionId: "CancelCallbackButton",
            }}
            aria-label="Cancel Callback"
            color="error.9"
            data-testid="call-controls-callback-cancel"
            size="md"
            {...props}
        >
            Cancel
        </AnalyticsButton>
    );
}
