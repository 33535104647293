import { bool, customCleanEnv, url } from "envalid";

interface Env {
    acssBaseUrl: string;
    isDevMode: boolean;
}

export const env = customCleanEnv(
    import.meta.env,
    {
        VITE_ACSS_URL: url(),
        DEV: bool(),
    },
    (inputEnv): Env => ({
        acssBaseUrl: inputEnv.VITE_ACSS_URL,
        isDevMode: inputEnv.DEV,
    }),
);
