import type { VoiceTask } from "@expert/sdk";
import { clearCallbackState, setCallbackState } from "@expert/sdk";
import { getLogger } from "@expert/logging";
import { useCallbacksFormStore } from "./ScheduleCallback/callbacks.store";
import { getCallbackStatus } from "./api";

const logger = getLogger({ module: "callback-status" });

//TODO: This needs to move to the SDK, our UI doe snot need to be responsible for this logic, it's getting fragile

/** Updates the task state with an existing customer callbacks upon call accept */
export async function updateExistingCallback(task: VoiceTask) {
    try {
        const result = await getCallbackStatus({ phoneNumber: task.mdn });

        if (!result?.length) {
            logger.debug({ task, result }, "Callback-status api did not return an existing callback");
            return;
        }

        const existingCallback = result.find((callback) => {
            return callback.status === "Pending";
        });

        if (!existingCallback) return;

        setCallbackState(task.id, {
            callbackType: "CallbackLater",
            scheduledAt: existingCallback.createdAt,
            scheduledFor: existingCallback.estimatedFor,
            callbackDelay: null,
            callbackMDN: task.mdn,
        });

        useCallbacksFormStore.getState().setCallbackScheduled(true);
    } catch (error) {
        // TODO: refactor to be able to display a toast here.
        logger.error({ task, error }, "Unable to receive callback information from Mindful");
    }
}

/** Clears the customer callback state when a call is completed */
export function clearCallbacksState() {
    clearCallbackState();
    useCallbacksFormStore.getState().setCallbackScheduled(false);
}
