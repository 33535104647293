import { getRootDispatcher } from "@expert/analytics";
import { getLogger } from "@expert/logging";
import type { AgentActivity, CallbackState, TaskCompletedReason, UpdateParticipantOptions } from "@expert/sdk";
import {
    AgentSdkBase,
    OUTBOUND_CALL_KINDS,
    canSetActivity,
    getActiveSession,
    getSessionId,
    isTrainingTask,
    isVoiceTask,
    sdkEventBus,
    useAgentStore,
} from "@expert/sdk";
import { useSessionStore } from "@expert/sdk/src/sessions/session.store";
import { environment, tfnToMaskedName, userCache } from "@expert/shared-utils";
import { getFeature } from "@soluto-private/expert-workspace-feature-flagging";
import type { OutgoingTransfer, Reservation, Task } from "twilio-taskrouter";
import { Worker } from "twilio-taskrouter";
import {
    addSipParticipant,
    addTfnParticipant,
    endCall,
    endConference,
    endConferenceByTaskId,
    redirectCallToCCTS,
    redirectCallToSip,
    redirectCallToTfn,
    removeParticipant,
    updateParticipant,
} from "./client";
import type { TwilioSync } from "./sync";
import { TwilioTask, getTaskConfig } from "./twilioTask";
import { TwilioWorkspaceSdk } from "./twilioWorkspaceSdk";
import { isTwilioTask } from "./typeGuards";
import type { TwilioAppConfig, TwilioVoiceEvent } from "./types";
import type { TwilioVoiceSDK, TwilioVoiceTask } from "./voice";
import { buildSipParameters, calculateToAndFrom, createUcid } from "./sipUtils";
import { TwilioTrainingTask } from "./twilioTrainingTask";

export interface TwilioAgentConfig {
    workerSid: string;
}

export interface TwilioAgentSdkConfig {
    workerActivities: Record<AgentActivity, string>;
    workspaceSid: string;
}

const logger = getLogger({ module: "twilioAgentSdk" });

export class TwilioAgentSDK extends AgentSdkBase<TwilioTask> {
    private readonly worker: Worker;
    private readonly workspace: TwilioWorkspaceSdk;
    private readonly voiceSdkPromise?: Promise<TwilioVoiceSDK>;
    private readonly syncSdkPromise?: Promise<TwilioSync>;

    // Readiness components
    private isTaskRouterSdkReady = false;
    private isVoiceReady = false;
    private readonly _mode: "evl" | "hdx";

    constructor(
        private readonly token: string,
        private readonly refreshToken: () => Promise<string>,
        private readonly employeeNum: string | null,
        hdxFeatureEnabled: boolean,
        private readonly appConfig: TwilioAppConfig,
        onReady: (isReady: boolean) => void,
    ) {
        super(onReady);
        this.worker = new Worker(this.token, { logLevel: "warn" });
        this.workspace = new TwilioWorkspaceSdk(token);

        /**  Initialize voice:
         * Due to Twilio limitations agents have only one voice sdk initialized with their token.
         * It's either local voice-sdk inside the app or EVL (expert-voice-link)
         * EVL is open in separate tab (mostly when agents use Citrix it's open outside Citrix)
         * The communication with EVL is done via Twilio Sync.
         */
        if (employeeNum !== null && !hdxFeatureEnabled) {
            this.syncSdkPromise = import("./sync").then(
                ({ TwilioSync }) =>
                    new TwilioSync(token, employeeNum, this.onVoiceIsReady, this.refreshAccessToken, this.onVoiceEvent),
            );
            this._mode = "evl";
        } else {
            this.voiceSdkPromise = import("./voice").then(
                ({ TwilioVoiceSDK }) =>
                    new TwilioVoiceSDK(this.token, this.onVoiceIsReady, this.refreshAccessToken, this.onVoiceEvent),
            );
            this._mode = "hdx";
        }

        this.subscribeToTwilioWorkerEvents();
    }

    /** Inits an outbound call to the current customer and completes the related task */
    protected async createCustomerCallbackForTask(task: TwilioVoiceTask, callbackState: CallbackState) {
        const taskLogger = logger.child({
            action: "createCustomerCallbackForTask",
            taskId: task.id, //TODO: toLog method needs to be separated from class for use in this method that may just be an object
            taskName: task.name,
            sessionId: task.sessionId,
            taskStatus: task.status,
            callbackState,
        });
        taskLogger.debug("Creating customer callback for task");
        //TODO: Create constants/env init for these values
        //TODO: Do we want to move this to a BE service?

        const callBackTaskId = await this.createOutboundCallTask(
            callbackState.callbackMDN,
            task.config.reservation.task.workflowSid,
            task.config.reservation.task.queueSid,
            {
                previousTaskSid: task.config.reservation.task.sid,
                previous_call_start: task.config.reservation.dateCreated,
                previous_call_sid: task.agentCallId,
                sessionId: getSessionId(), // We can only include this sessionId for a CallbackNow callback, not for an AdHoc Outbound call
                outboundKind: OUTBOUND_CALL_KINDS.CallbackNow,
                Client: task.client || "verizon", // Cased this way intentionally to match IVR attributes
                Client_Friendly_Name: task.clientFriendlyName || "verizon", // Cased this way intentionally to match IVR attributes
            },
        );

        taskLogger.debug({ callBackTaskId }, "Created customer callback for task");
        return callBackTaskId;
    }

    // TODO: Setup support for multiple workflows & queues for AdHoc callbacks EWP-348
    protected async createOutboundCall(callbackMdn: string) {
        const localLogger = logger.child({ action: "createOutboundCall" });
        localLogger.debug("Creating task for outbound call");

        const taskId = await this.createOutboundCallTask(
            callbackMdn,
            this.appConfig.taskRouterWorkflowSidVerizon,
            this.workspace.outboundCallTaskQueueId ?? this.appConfig.taskRouterTaskQueueSidVznMtsBundleEn2233,
            {
                outboundKind: OUTBOUND_CALL_KINDS.AdHocOutboundCall,
                Client: "verizon", // Cased this way intentionally to match IVR attributes. Hardcoding to verizon until partner enablement
                Client_Friendly_Name: "verizon", // Cased this way intentionally to match IVR attributes. Hardcoding to verizon until partner enablement
            },
        );
        localLogger.debug({ taskId }, "Task for outbound call created");
        return taskId;
    }

    // ==============================
    // General

    public async setAgentActivity(activity: AgentActivity): Promise<void> {
        const localLogger = logger.child({ action: "setAgentActivity" });
        localLogger.trace("Set Agent Activity | Execution started");
        if (!canSetActivity(activity, this.worker.activity.name as AgentActivity)) {
            localLogger.error(
                {
                    fromActivity: this.worker.activity.name,
                    toActivity: activity,
                },
                `Cannot change activity from ${this.worker.activity.name} to ${activity}`,
            );
            return;
        }

        if (this.worker.activity.name === activity) {
            localLogger.error({ activity }, `Activity is already set to ${activity}`);
            return;
        }

        const cachedActivity = this.worker.activity.name;

        const activityLogger = localLogger.child({
            fromActivity: this.worker.activity.name,
            toActivity: activity,
        });

        activityLogger.debug(`Setting agent activity to "${activity}"`);

        // TODO: Separate calculation and execution
        const newActivity = await Array.from(this.worker.activities.values())
            .find((a) => a.name === activity)
            ?.setAsCurrent();

        if (useAgentStore.getState().pendingActivity === newActivity?.name) {
            useAgentStore.getState().setPendingActivity(undefined);
        }

        // TODO: Should we move this check above updating store ???
        if (!newActivity) {
            activityLogger.error({ newActivity }, "Failed to set agent activity");
            return;
        }

        this.emitAgentActivityChangedAnalytics(activity, cachedActivity as AgentActivity);
        activityLogger.trace("Set Agent Activity | Execution ended");
    }

    public getAgentActivity(): AgentActivity {
        return this.worker.activity.name as AgentActivity;
    }

    // TODO: This shouldn't be a method. Either inline or external function
    private emitAgentActivityChangedAnalytics(to: AgentActivity, from: AgentActivity) {
        let dispatcher = getRootDispatcher();

        const currentTask = getActiveSession().currentTask;

        // TODO: We don't use task in setting agent activity. Why do we have to add it to analytics?
        // If we still do have. We should dispatch analytics from the place we calling `setAgentActivity` method and have task there
        if (currentTask) {
            dispatcher = dispatcher.withIdentities({
                TaskSid: currentTask.id,
            });
        }

        void dispatcher.dispatchBusinessEvent("AgentActivityChanged", {
            activityFrom: from,
            activityTo: to,
        });
    }

    // ==============================
    // Task

    public canHandleTaskType(task: TwilioTask): boolean {
        return task instanceof TwilioTask;
    }

    public async acceptTask(task: TwilioTask): Promise<void> {
        const reservation = task.config.reservation;
        const taskLogger = logger.child({ action: "acceptTask", ...task.toLog(), reservation });
        taskLogger.trace("Accept Task | Execution started");

        if (isVoiceTask(task)) {
            await (task as TwilioVoiceTask).accept(this.worker, userCache, this.employeeNum, taskLogger);
        } else if (isTrainingTask(task)) {
            (task as TwilioTrainingTask).accept(taskLogger);
        }

        if (reservation.status !== "accepted") {
            taskLogger.debug("Accept Task | Accepting Twilio reservation...");
            await reservation.accept();
            taskLogger.info("Accept Task | Twilio reservation accepted");
        }

        taskLogger.trace("Accept Task | Execution ended");
    }

    public async rejectTask(task: TwilioTask): Promise<void> {
        const reservation = task.config.reservation;
        const taskLogger = logger.child({ action: "rejectTask", ...task.toLog(), reservation });
        taskLogger.trace("Reject Task | Execution started");
        taskLogger.debug("Reject Task | Rejecting Twilio reservation...");
        await reservation.reject();
        taskLogger.info("Reject Task | Twilio reservation rejected");
        taskLogger.trace("Reject Task | Execution ended");
    }

    public async wrapupTask(task: TwilioTask, reason: string): Promise<void> {
        const reservation = task.config.reservation;
        const taskLogger = logger.child({ action: "wrapupTask", ...task.toLog(), reservation, reason });
        taskLogger.trace("Wrapup Task | Execution started");
        if (reservation.status === "pending") {
            taskLogger.error("Wrapup Task | Cannot wrap up pending task");
            return;
        }

        try {
            taskLogger.debug("Wrapup Task | Wrapping Twilio reservation up...");
            await reservation.task.wrapUp({ reason });
            taskLogger.info("Wrapup Task | Twilio reservation wrapped up");
        } catch (err: unknown) {
            const error = err as Error;
            // TODO: Move to separate file with Twilio errors. Twilio task router SDK doesn't return error codes
            if (error.message.includes("it is not currently assigned")) {
                taskLogger.warn({ err }, `Wrapup Task | Wrapping task failed: ${error.message}`);
                return;
            }
            taskLogger.error({ err }, "Wrapup Task | Wrapping task failed");
            throw err;
        }
        taskLogger.trace("Wrapup Task | Execution ended");
    }

    public async completeTask(task: TwilioTask, reason: string): Promise<void> {
        const reservation = task.config.reservation;
        const taskLogger = logger.child({ action: "completeTask", ...task.toLog(), reservation, reason });
        taskLogger.trace("Complete Task | Execution started");
        // TODO: Check if we need to add this for the rest of the methods
        await reservation.task.fetchLatestVersion();
        if (reservation.status === "wrapping" && reservation.task.status !== "wrapping") {
            taskLogger.debug("Complete Task | Completing Twilio reservation...");
            await reservation.complete();
            taskLogger.info("Complete Task | Twilio reservation completed");
        } else {
            taskLogger.debug("Complete Task | Completing Twilio task...");
            await reservation.task.complete(reason);
            taskLogger.info("Complete Task | Twilio task completed");
        }
        taskLogger.trace("Complete Task | Execution ended");
    }

    public cancelTask(_task: TwilioTask): Promise<void> {
        throw new Error("Method not implemented.");
    }

    // ==============================
    // Voice
    public async sendDtmfDigits(digits: string): Promise<void> {
        const syncSdk = await this.syncSdkPromise;
        await syncSdk?.sendDtmfDigits(digits);
        const voiceSdk = await this.voiceSdkPromise;
        voiceSdk?.sendDtmfDigits(digits);
    }

    // TODO: Split to leaveConference and endConference
    public async hangupCall(task: TwilioVoiceTask, reason: string): Promise<void> {
        const taskLogger = logger.child({ action: "hangupCall", ...task.toLog(), reason });
        taskLogger.trace("Hangup Call | Execution started");
        // TODO: Do we need this assertion here? Should be type safe
        if (!isVoiceTask(task)) {
            throw new Error("Only voice tasks can be hang up");
        }

        if (task.customerCallId) {
            taskLogger.debug("Hangup Call | Ending call...");
            await endCall(task.id, task.customerCallId);
            taskLogger.info("Hangup Call | Call ended");
        } else {
            if (task.callDirection === "outbound") {
                // Customer never answered the call
                taskLogger.debug("Hangup Call | Ending conference...");
                await endConferenceByTaskId(task.id);
                taskLogger.info("Hangup Call | Conference ended");
            } else {
                taskLogger.warn(
                    "Hangup Call | Cannot hangup a call that was never answered. Just wrapping up the task.",
                );
            }

            taskLogger.debug("Hangup Call | Wrapping task up...");
            await this.wrapupTask(task, reason);
            taskLogger.info("Hangup Call | Task wrapped up");
        }

        this.onHangupCall(task);
        taskLogger.trace("Hangup Call | Execution ended");
    }

    // TODO: Change to TwilioVoiceTask
    public async holdCall(task: TwilioTask): Promise<void> {
        const taskLogger = logger.child({ action: "holdCall", ...task.toLog() });
        taskLogger.trace("Hold Call | Execution started");
        if (!isVoiceTask(task)) {
            throw new Error("Only voice tasks can be put on hold");
        }

        if (task.holdState.onHold) return;

        const reservation = task.config.reservation;
        taskLogger.debug({ reservation }, "Hold Call | Putting customer on hold...");
        await reservation.task.updateParticipant({
            hold: true,
            holdUrl: this.appConfig.holdMusicUrl,
            holdMethod: "GET",
        });
        taskLogger.info({ reservation }, "Hold Call | Customer put on hold");
        this.onHoldCall(task);
        taskLogger.trace("Hold Call | Execution ended");
    }

    // TODO: Change to TwilioVoiceTask
    public async resumeCall(task: TwilioTask): Promise<void> {
        const taskLogger = logger.child({ action: "resumeCall", ...task.toLog() });
        taskLogger.trace("Resume Call | Execution started");
        if (!isVoiceTask(task)) {
            throw new Error("Only voice tasks can be resumed");
        }

        if (!task.holdState.onHold) return;

        const reservation = task.config.reservation;
        taskLogger.debug({ reservation }, "Resume Call | Resuming customer...");
        await reservation.task.updateParticipant({
            hold: false,
        });
        taskLogger.info({ reservation }, "Resume Call | Customer resumed");
        this.onResumeCall(task);
        taskLogger.trace("Resume Call | Execution ended");
    }

    public async muteCall(task: TwilioVoiceTask, shouldMute: boolean): Promise<void> {
        const isRecordedAudioDisclosureEnabled = await getFeature<boolean>("EWP-isRecordedAudioDisclosureEnabled", {
            environment,
            employeeId: userCache.employeeId,
        });

        if (isRecordedAudioDisclosureEnabled) {
            if (this._mode === "evl") {
                const syncSdk = await this.syncSdkPromise;
                await syncSdk?.toggleMute(!shouldMute);
            } else {
                const voiceSdk = await this.voiceSdkPromise;
                voiceSdk?.toggleMute(!shouldMute);
            }
        } else {
            const reservation = task.config.reservation;
            await reservation.updateParticipant({
                mute: shouldMute,
            });
        }
        this.onMuteCall(task, shouldMute);
    }

    // Call Redirect & Transfer
    public async redirectCallToCCTS(task: TwilioVoiceTask): Promise<void> {
        if (!task.conferenceId || !task.customerCallId) return; // Unreachable state. Redirecting before the conference created
        await redirectCallToCCTS(
            task.conferenceId,
            task.customerCallId,
            task.config.reservation.task.attributes as unknown as Record<string, unknown>,
        );
    }

    public async redirectCallToTFN(task: TwilioVoiceTask, redirectTo: string): Promise<void> {
        if (!task.conferenceId || !task.customerCallId) return; // Unreachable state. Redirecting before the conference created
        await redirectCallToTfn(task.conferenceId, task.customerCallId, redirectTo);
    }

    public async redirectCallToSIP(
        task: TwilioVoiceTask,
        sip: string,
        sipParams?: Record<string, string>,
    ): Promise<void> {
        if (!task.conferenceId || !task.customerCallId) return; // Unreachable state. Redirecting before the conference created

        await redirectCallToSip(task.conferenceId, task.customerCallId, sip, buildSipParameters(task, sipParams));
    }

    public async transferCall(task: TwilioTask, _to: string): Promise<void> {
        const reservation = task.config.reservation;
        await reservation.updateParticipant({
            endConferenceOnExit: false,
        });
        const to = this.temporaryCalculateToForTransfer(task);
        await reservation.task.transfer(to, {
            mode: "COLD",
            attributes: {
                previousSessionId: getSessionId(),
            },
        });
        // TODO: Maybe should be something else
        await reservation.complete();
        await this.onTaskComplete(task, "Transferred");
    }

    public async beginWarmTransferCall(task: TwilioTask, _to: string): Promise<void> {
        const reservation = task.config.reservation;
        await reservation.updateParticipant({
            endConferenceOnExit: false,
        });
        const to = this.temporaryCalculateToForTransfer(task);
        await reservation.task.transfer(to, {
            mode: "WARM",
        });
    }

    public async completeWarmTransferCall(task: TwilioTask): Promise<void> {
        const reservation = task.config.reservation;
        const twilioTask = await reservation.task.fetchLatestVersion();

        // Bad Twilio typing. Incoming transfer is nullable - PR: https://github.com/twilio/twilio-taskrouter.js/pull/104

        if (twilioTask.transfers.incoming) {
            const fromAgent = twilioTask.transfers.incoming.workerSid;
            await reservation.task.kick(fromAgent);
        } else if (twilioTask.transfers.outgoing) {
            // TODO: Doesn't work. The reservation goes to wrapping but it doesn't do anything to the call
            await reservation.wrap();
        } else {
            throw new Error("Cannot complete an ongoing warm transfer. There are not transfers");
        }
    }

    public async cancelWarmTransferCall(task: TwilioTask): Promise<void> {
        const reservation = task.config.reservation;
        const twilioTask = await reservation.task.fetchLatestVersion();

        // Bad Twilio typing. Outgoing transfer is nullable - PR: https://github.com/twilio/twilio-taskrouter.js/pull/104

        if (twilioTask.transfers.outgoing) {
            const toAgent = twilioTask.transfers.outgoing.to;
            // TODO: Doesn't work. For worker Sid it ends the call to everybody. How can I get the sid if to is a task
            await reservation.task.kick(toAgent);
        } else if (twilioTask.transfers.incoming) {
            // TODO: Doesn't work. The reservation goes to wrapping but it doesn't do anything to the call
            await reservation.wrap();
        } else {
            throw new Error("Cannot cancel an ongoing warm transfer. There are not transfers");
        }
    }

    // Conference
    public async addConferenceParticipant(
        task: TwilioVoiceTask,
        name: string,
        to: string,
        isSip = false,
        sipParams: Record<string, string> = {},
    ): Promise<void> {
        const taskLogger = logger.child({
            action: "addConferenceParticipant",
            ...task.toLog(),
            participantName: tfnToMaskedName(name),
            isSip,
        });
        taskLogger.trace("Add Conference Participant | Execution started");
        if (!task.customerCallId || !task.conferenceId) {
            const errMsg = "Conference has not started";
            taskLogger.error(`Add Conference Participant | ${errMsg}`);
            throw new Error(errMsg);
        }

        // TODO: SessionId shouldn't even be undefined when calling this
        if (!task.sessionId) {
            const errMsg = "SessionId is undefined";
            taskLogger.error(`Add Conference Participant | ${errMsg}`);
            throw new Error(errMsg);
        }

        //Maybe we should have a better check than this. A selector instead of a set flag
        if (!task.customerLeftTheConference) {
            taskLogger.debug("Add Conference Participant | Updating ‘endConferenceOnExit’ flag for customer...");
            await updateParticipant(task.conferenceId, task.customerCallId, { endConferenceOnExit: false });
            taskLogger.info("Add Conference Participant | Customer’s ‘endConferenceOnExit’ flag updated");
            await this.holdCall(task);
        } else {
            taskLogger.debug(
                "Add Conference Participant | Customer has left the conference. Not updating endConferenceOnExit flag or mute state",
            );
        }

        taskLogger.debug("Add Conference Participant | Adding new participant...");
        if (isSip) {
            await addSipParticipant(
                task.partner,
                task.sessionId,
                task.id,
                task.conferenceId,
                name,
                to,
                buildSipParameters(task, sipParams),
            );
        } else {
            await addTfnParticipant(task.partner, task.sessionId, task.id, task.conferenceId, name, to);
        }
        taskLogger.info("Add Conference Participant | New participant added");

        // Fires a hold event when customer is automatically placed on hold
        await getRootDispatcher()
            .withExtra({
                partner: task.partner,
            })
            .withIdentities({
                TaskSid: task.id,
                PreviousTaskSid: task.previousTaskId,
            })
            .dispatchBusinessEvent("CallPutOnHold", {
                participant: task.mdn,
            })
            .then(() => {
                taskLogger.info(
                    { analyticsEventName: "CallPutOnHold" },
                    "Adding participant | CallPutOnHold analytics dispatched",
                );
            });

        taskLogger.trace("Add Conference Participant | Execution ended");
    }

    public async updateParticipant(
        task: TwilioVoiceTask,
        name: string,
        options: UpdateParticipantOptions,
    ): Promise<void> {
        const taskLogger = logger.child({
            action: "updateParticipant",
            ...task.toLog(),
            participantName: tfnToMaskedName(name), //TODO: this assumes the name is a tfn when it may be an actual name
        });
        taskLogger.trace("Update Participant | Execution started");
        if (!task.conferenceId) {
            const errMsg = "Conference has not started";
            taskLogger.error(`Update Participant | ${errMsg}`);
            throw new Error(errMsg);
        }

        const participant = task.conferenceParticipants.find((p) => p.participantName === name);
        if (!participant) {
            const errMsg = `Participant with name ${name} not found in conference`;
            taskLogger.error(`Update Participant | ${errMsg}`);
            throw new Error(errMsg);
        }
        taskLogger.debug("Update Participant | Updating participant...");
        await updateParticipant(task.conferenceId, participant.participantCallId, options);
        taskLogger.info("Update Participant | Participant updated");
        taskLogger.trace("Update Participant | Execution ended");
    }

    public async removeParticipant(task: TwilioVoiceTask, callId: string): Promise<void> {
        const taskLogger = logger.child({
            action: "removeParticipant",
            ...task.toLog(),
            participantCallId: callId,
        });
        taskLogger.trace("Remove Participant | Execution started");
        if (!task.conferenceId) {
            const errMsg = "Conference has not started";
            taskLogger.error(`Remove Participant | ${errMsg}`);
            throw new Error(errMsg);
        }
        const participant = task.conferenceParticipants.find((p) => p.participantCallId === callId);
        if (!participant) {
            const errMsg = `Participant with call ID ${callId} not found in conference`;
            taskLogger.error(`Remove Participant | ${errMsg}`);
            throw new Error(errMsg);
        }
        taskLogger.debug("Remove Participant | Removing participant...");
        await removeParticipant(task.conferenceId, participant.participantCallId);
        taskLogger.info("Remove Participant | Participant removed");
        taskLogger.trace("Remove Participant | Execution ended");
    }

    public async removePendingParticipant(task: TwilioVoiceTask, callId: string): Promise<void> {
        const taskLogger = logger.child({
            action: "removePendingParticipant",
            ...task.toLog(),
            participantCallId: callId,
        });
        taskLogger.trace("Remove Pending Participant | Execution started");
        if (!task.conferenceId) {
            const errMsg = "Conference has not started";
            taskLogger.error(`Remove Pending Participant | ${errMsg}`);
            throw new Error(errMsg);
        }
        const participant = task.pendingConferenceParticipants.find((p) => p.participantCallId === callId);
        if (!participant) {
            const errMsg = `Pending participant with call ID ${callId} not found in conference`;
            taskLogger.error(`Remove Pending Participant | ${errMsg}`);
            throw new Error(errMsg);
        }
        taskLogger.debug("Remove Pending Participant | Ending participant’s call...");
        await endCall(task.id, participant.participantCallId);
        taskLogger.info("Remove Pending Participant | Participant’s call ended");
        taskLogger.trace("Remove Pending Participant | Execution ended");
    }

    public async leaveConference(task: TwilioVoiceTask): Promise<void> {
        const taskLogger = logger.child({ action: "leaveConference", ...task.toLog() });
        taskLogger.trace("Leave conference | Execution started");
        if (!task.conferenceId || !task.agentCallId) {
            const errMsg = "Conference has not started";
            taskLogger.error(`Leave conference | ${errMsg}`);
            throw new Error(errMsg);
        }

        taskLogger.debug("Leave conference | Removing expert participant from the conference...");
        await removeParticipant(task.conferenceId, task.agentCallId);
        taskLogger.info("Leave conference | Expert participant removed from the conference");
        taskLogger.trace("Leave conference | Execution ended");
    }

    public async endConference(task: TwilioVoiceTask): Promise<void> {
        const taskLogger = logger.child({ action: "leaveConference", ...task.toLog() });
        taskLogger.trace("End conference | Execution started");
        if (!task.conferenceId) {
            const errMsg = "Conference has not started";
            taskLogger.error(`End conference | ${errMsg}`);
            throw new Error(errMsg);
        }

        taskLogger.debug("End conference | Ending conference...");
        await endConference(task.conferenceId);
        taskLogger.info("End conference | Conference ended");
        taskLogger.trace("End conference | Execution ended");
    }

    // ==============================
    // Private

    private async createTask(reservation: Reservation): Promise<TwilioTask> {
        const taskConfig = getTaskConfig(reservation);
        const taskName = TwilioTask.getTaskName(taskConfig);

        // NOTE: Eslint ensures that we have exhaustive coverage here which means we should always return a task
        //       or throw an error before returning from the function
        switch (taskConfig.taskType) {
            case "voice": {
                const { createTwilioVoiceTask } = await import("./voice");
                return await createTwilioVoiceTask(taskName, taskConfig);
            }
            case "training": {
                return new TwilioTrainingTask(taskName, taskConfig);
            }
            case "chat": {
                // NOTE: This is dead code, it's not used anywhere, and if this somehow does happen then we
                //       have a bug.
                throw new Error("This feature is not supported. If this happens, this is a bug.");
            }
            case "sms": {
                throw new Error("This feature is not supported. If this happens, this is a bug.");
            }
        }
    }

    private subscribeToTwilioWorkerEvents = () => {
        this.worker.on("ready", (worker: Worker) => {
            void Promise.all([
                (async () => {
                    logger.debug({ worker }, "==== GOT worker ready EVENT =====");

                    const { setAgentTools } = useAgentStore.getState();
                    setAgentTools((this.worker.attributes.realtime_tools as string[] | undefined) ?? []);

                    const reservations = Array.from(this.worker.reservations.values());
                    const tasks = await Promise.all(reservations.map((r) => this.createTask(r)));
                    tasks.forEach((t) => {
                        this.subscribeToTwilioReservationEvents(t);
                    });

                    this.isTaskRouterSdkReady = true;
                    this.onReady(this.isVoiceReady);
                    // worker only switches to Default when the page is loaded or refreshed while the worker is offline
                    const initialActivity =
                        (worker.activity.name as AgentActivity) !== "Offline" ? worker.activity.name : "Default";

                    this.onInitialized(
                        worker.workerSid,
                        worker.name,
                        initialActivity as AgentActivity,
                        worker.dateStatusChanged.valueOf(),
                        tasks,
                    );
                })(),
                this.workspace.init(this.worker.sid),
            ]);
        });

        this.worker.on("activityUpdated", (event: Worker) => {
            const twilioActivity = event.activity.name;
            // dateStatusChanged is the time when the worker's activity name was changed, dateActivityUpdated is for availability changes only
            const timestampActivityChanged = new Date(event.dateStatusChanged).valueOf();
            this.onActivityChanged(twilioActivity as AgentActivity, timestampActivityChanged);
        });

        this.worker.on("reservationCreated", (event: Reservation) => {
            void (async () => {
                logger.debug(event, "==== GOT reservation created EVENT =====");

                // TODO: Nasty way to have session from the very beginning
                const twilioTaskWithSessionId = await event.task.setAttributes({
                    ...event.task.attributes,
                    sessionIdFallback: (event.task.attributes.sessionId as string | undefined) ?? crypto.randomUUID(),
                });

                const task = await this.createTask(event);
                this.subscribeToTwilioReservationEvents(task);

                // Ensure we set a new sessionId to the task attributes as soon as it's available
                //TODO: Consider design EWP-351
                sdkEventBus.once(
                    "session_changed",
                    ({ newSession }) => {
                        if (event.task.attributes.sessionId) return;

                        void event.task.setAttributes({
                            ...twilioTaskWithSessionId.attributes,
                            sessionId: newSession.id,
                        });
                    },
                    ({ reason: changeReason }) =>
                        changeReason === "InboundCall" || changeReason === "AdHocOutboundCall",
                );

                // TODO: We need to check if we can move this inside `createTask` function after migration to sessions
                await this.onTaskCreated(task);
            })();
        });

        this.worker.on("reservationFailed", (event: Reservation) => {
            logger.debug(event, "==== GOT reservationFailed EVENT =====");
            logger.error("Reservation Failed!");
        });

        this.worker.on("tokenExpired", () => {
            void this.refreshAccessToken();
        });
    };

    private subscribeToTwilioReservationEvents = (task: TwilioTask) => {
        const reservation = task.config.reservation;
        this.subscribeToTaskEvents(task);
        // Adding only task ID because the other props can be different at the time of event
        // We also cannot get a task inside event handler, unless we take it from the stop
        const taskLogger = logger.child({ taskId: task.id, action: "subscribeToTwilioReservationEvents" });

        reservation.on("accepted", () => {
            const upToDateTask = this.getTaskFromStore(task.id);
            if (!upToDateTask) {
                const errMsg = `Task ${task.id} is not found in the store`;
                taskLogger.warn({ eventName: "accepted" }, `On Twilio Reservation accepted | ${errMsg}`);
                throw new Error(errMsg);
            }
            void this.onTaskAccepted(upToDateTask);
        });

        reservation.on("rejected", () => {
            const upToDateTask = this.getTaskFromStore(task.id);
            if (!upToDateTask) {
                const errMsg = `Task ${task.id} is not found in the store`;
                taskLogger.warn({ eventName: "rejected" }, `On Twilio Reservation rejected | ${errMsg}`);
                throw new Error(errMsg);
            }
            this.onTaskRejected(upToDateTask);
        });

        reservation.on("wrapup", () => {
            const upToDateTask = this.getTaskFromStore(task.id);
            if (!upToDateTask) {
                const errMsg = `Task ${task.id} is not found in the store`;
                taskLogger.warn({ eventName: "wrapup" }, `On Twilio Reservation wrapup | ${errMsg}`);
                throw new Error(errMsg);
            }
            void this.onTaskWrapping(upToDateTask);
        });

        reservation.on("completed", () => {
            const upToDateTask = this.getTaskFromStore(task.id);
            if (!upToDateTask) {
                const errMsg = `Task ${task.id} is not found in the store`;
                taskLogger.warn({ eventName: "completed" }, `On Twilio Reservation completed | ${errMsg}`);
                throw new Error(errMsg);
            }
            void this.onTaskComplete(upToDateTask, task.config.reservation.task.reason as TaskCompletedReason);
        });

        reservation.on("canceled", () => {
            const upToDateTask = this.getTaskFromStore(task.id);
            if (!upToDateTask) {
                const errMsg = `Task ${task.id} is not found in the store`;
                taskLogger.warn({ eventName: "canceled" }, `On Twilio Reservation canceled | ${errMsg}`);
                throw new Error(errMsg);
            }
            this.onTaskCancelled(upToDateTask);
        });

        reservation.on("timeout", (event: Reservation) => {
            const upToDateTask = this.getTaskFromStore(task.id);
            if (!upToDateTask) {
                const errMsg = `Task ${task.id} is not found in the store`;
                taskLogger.warn({ eventName: "timeout" }, `On Twilio Reservation timeout | ${errMsg}`);
                // throw new Error(errMsg);
            }
            taskLogger.debug({ event }, "==== GOT reservation timeout EVENT =====");
        });

        reservation.on("rescinded", (event: Reservation) => {
            const upToDateTask = this.getTaskFromStore(task.id);
            if (!upToDateTask) {
                const errMsg = `Task ${task.id} is not found in the store`;
                taskLogger.warn({ eventName: "rescinded" }, `On Twilio Reservation rescinded | ${errMsg}`);
                // throw new Error(errMsg);
            }
            taskLogger.debug({ event }, "==== GOT reservation rescinded EVENT =====");
        });
    };

    private subscribeToTaskEvents = (task: TwilioTask) => {
        // Adding only task ID because the other props can be different at the time of event
        // We also cannot get a task inside event handler, unless we take it from the stop
        const taskLogger = logger.child({ taskId: task.id, action: "subscribeToTaskEvents" });

        const twilioOriginalTask = task.config.reservation.task;
        twilioOriginalTask.on("transferInitiated", (event: OutgoingTransfer) => {
            this.subscribeToTransferEvents(event);
            taskLogger.debug({ event }, "==== TASK transferInitiated EVENT ====");
        });

        twilioOriginalTask.on("canceled", (event: Task) => {
            taskLogger.debug({ event }, "==== TASK canceled EVENT ====");
        });

        twilioOriginalTask.on("completed", (event: Task) => {
            taskLogger.debug({ event }, "==== TASK completed EVENT ====");
        });

        twilioOriginalTask.on("updated", (event: Task) => {
            taskLogger.debug({ event }, "==== TASK updated EVENT ====");
            const upToDateTask = this.getTaskFromStore(task.id);
            if (!upToDateTask) {
                logger.warn(
                    { eventName: "updated" },
                    `Task is not found in the store. Possibly it has already removed from the store`,
                );
                return;
            }

            if (!isTwilioTask(upToDateTask)) throw new Error(`Task ${upToDateTask.id} is not a Twilio task`);

            // No need to check for conference started and trigger call_accepted if we are already wrapping or completed
            if (event.status === "wrapping" || event.status === "completed") return;

            void upToDateTask.config.reservation.task.fetchLatestVersion().then(() => {
                // Race condition between call accepted and task updated with conference attributes
                if (isVoiceTask(upToDateTask) && !upToDateTask.conferenceStarted && upToDateTask.agentCallId) {
                    taskLogger.info(upToDateTask.toLog(), "Marking conference started");
                    this.markConferenceStarted(upToDateTask);
                    this.onCallAccepted(upToDateTask);
                }
            });
        });

        twilioOriginalTask.on("wrapup", (event: Task) => {
            taskLogger.debug({ event }, "==== TASK wrapup EVENT ====");
        });
    };

    private subscribeToTransferEvents = (transfer: OutgoingTransfer) => {
        transfer.on("attemptFailed", (event: OutgoingTransfer) => {
            logger.debug(event, "==== TRANSFER attemptFailed EVENT ====");
        });

        transfer.on("canceled", (event: OutgoingTransfer) => {
            logger.debug(event, "==== TRANSFER canceled EVENT ====");
        });

        transfer.on("completed", (event: OutgoingTransfer) => {
            logger.debug(event, "==== TRANSFER completed EVENT ====");
        });

        transfer.on("failed", (event: OutgoingTransfer) => {
            logger.debug(event, "==== TRANSFER failed EVENT ====");
        });
    };

    private onVoiceIsReady = async (isReady: boolean) => {
        this.isVoiceReady = isReady;
        if (!isReady) {
            await this.setAgentActivity("Offline");
        }
        this.onReady(this.isTaskRouterSdkReady && this.isVoiceReady);
        logger.debug({ isReady }, `===== VOICE SDK IS READY: ${isReady} =====`);
    };

    private onVoiceEvent = (callId: string, eventName: TwilioVoiceEvent, eventPayload?: unknown) => {
        const localLogger = logger.child({ action: "onVoiceEvent", callId, eventName, eventPayload });
        localLogger.trace("on Voice Event | Execution started");
        localLogger.debug(`on Voice Event | Call event received: "${eventName}"`);

        const task = useSessionStore.getState().findTask((t) => isVoiceTask(t) && t.agentCallId === callId);

        if (!task) {
            // TODO: It might be because the EVL event comes before the task router event.
            localLogger.warn(`on Voice Event | The task wasn’t found for callId: ${callId} & event ${eventName}`);
            return;
        }

        if (!isVoiceTask(task)) {
            localLogger.warn(
                task.toLog(),
                `on Voice Event | The task found for callId: ${callId} & event ${eventName} wasn’t a voice task`,
            );
            return;
        }

        switch (eventName) {
            case "accept":
                // Race condition between call accepted and task updated with conference attributes
                if (!task.conferenceStarted && task.agentCallId) {
                    this.markConferenceStarted(task);
                    this.onCallAccepted(task);
                }
                break;
            case "reject":
                this.onCallRejected(task);
                break;
            case "disconnect":
                this.onCallDisconnected(task);
                break;
            default:
                break;
        }
        localLogger.trace("on Voice Event | Execution ended");
    };

    private temporaryCalculateToForTransfer = (task: TwilioTask) => {
        // TODO: E2E tests for now
        let to = task.config.reservation.task.queueSid;
        if (this.worker.friendlyName === "svc.US-d-RE-E2E" || this.worker.friendlyName === "Alexander.Fux") {
            to = "WK31a3bc1b398ee042053556b4f6a7f530"; // svc.US-d-RE-E2E-2
        } else if (this.worker.friendlyName === "svc.US-d-RE-E2E-2") {
            to = "WKf3a61255e9968184289a757a710e62c9"; // svc.US-d-RE-E2E
        }
        return to;
    };

    public refreshAccessToken = async () => {
        const token = await this.refreshToken();
        this.worker.updateToken(token);
        const voiceSdk = await this.voiceSdkPromise;
        voiceSdk?.updateAccessToken(token);
        const syncSdk = await this.syncSdkPromise;
        await syncSdk?.updateAccessToken(token);
        this.workspace.updateToken(token);
    };

    protected getTaskFromStore(taskId: string) {
        const task = useSessionStore.getState().getTask(taskId);
        return task;
    }

    private async createOutboundCallTask(
        toPhoneNumber: string,
        twilioTaskRouterWorkflowSid: string,
        twilioTaskRouterQueueSid: string,
        attributes: Record<string, unknown>,
    ) {
        const { to, from } = await calculateToAndFrom(toPhoneNumber, this.appConfig);

        const ucid = createUcid();
        const twilioTaskSid = await this.worker.createTask(
            to,
            from,
            twilioTaskRouterWorkflowSid,
            twilioTaskRouterQueueSid,
            {
                taskChannelUniqueName: "voice",
                attributes: {
                    direction: "outbound",
                    name: toPhoneNumber,
                    UCID: ucid.hexUcid,
                    AsurionCallId: ucid.ucid,
                    ...attributes,
                },
            },
        );
        return twilioTaskSid;
    }
}
