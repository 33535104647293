import { AnalyticsProvider } from "@soluto-private/eventualize-react";
import { Button, Container, Divider, Flex } from "@mantine/core";
import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { getCustomerDetails } from "@expert/customer-info/src/api";
import { LoadingAnimation } from "@expert/sales-tools/src/home-product/components/Icons";
import { transformDispatcher, useAnalytics, withPolymorphicClickAnalytics } from "@expert/analytics";
import { useVerifiedCustomerStore } from "@expert/customer-info";
import { SalesFlow, useEligibilityV2 } from "@expert/sales-tools";
import { stripCountryCode, useFeatureFlag, validatePhoneNumber } from "@expert/shared-utils";
import { getSessionId, isVoiceTask, useActiveTask, useMostRecentTask, type VoiceTask } from "@expert/sdk";
import { useCallDetailsStore } from "../../../call-details";
import { AutoConfirmCountDown } from "./AutoConfirm";
import { determineCustomerVerificationStatus } from "./utils/determineCustomerVerificationStatus";
import { env } from "./utils/environment";
import { CustomerNamesSection } from "./CustomerNamesSection";
import classes from "./CustomerVerificationUpdated.module.css";
import { MdnSection } from "./MdnSection";
import { useVerificationFlowStore } from "./store";
import { SimplifiedMdnSection } from "./SimplifiedMdnSection";

export function CustomerVerificationFlowV3({ mdnFromTask }: { mdnFromTask: string }): JSX.Element {
    const ButtonWithAnalytics = withPolymorphicClickAnalytics(Button, "Click");
    const { dispatcher } = useAnalytics();
    const mostRecentTask = useMostRecentTask();
    const [stepHistory] = useVerificationFlowStore((state) => [state.stepHistory]);
    const currentStep = stepHistory.at(stepHistory.length - 1);
    const [mdn, setMdn] = useState<string>(mdnFromTask);
    const [customerName, setCustomerName] = useState<string | undefined>(undefined);
    const { data: isUpdatedConfirmationFlow } = useFeatureFlag("EWP-isUpdatedConfirmationFlow");
    const { data: isEligibilityPrecheckEnabled, isLoading: isLoadingEligibilityPrecheckEnabled } = useFeatureFlag(
        "EWP-isEligibilityPrecheckEnabled",
    );

    if (!isVoiceTask(mostRecentTask)) {
        throw new Error("Active task is not voice task");
    }
    const { data: isMdnEditEnabled } = useFeatureFlag("EWP-isMdnEditEnabled");
    const { data: customerData, isLoading } = useQuery({
        queryKey: [`customer-details-${mdn}`, mdn],
        queryFn: async () => getCustomerDetails(mdn),
        enabled: validatePhoneNumber(mdn).valid,
        refetchOnMount: true,
    });
    useEffect(() => {
        // if expert is coming back to change mdn/name, reset all the fields.
        // once there is a singular flow, we need to make this file less complex
        if (isMdnEditEnabled && stepHistory.includes("VerificationComplete")) {
            setIsEditingSimplifiedMdn(true);
            setIsEditMdn(true);
            setCustomerName(undefined);
        }
    }, [isMdnEditEnabled, stepHistory]);

    const isEditingCustomerInformation = stepHistory.includes("VerificationComplete");
    useEligibilityV2(
        {
            asurionCallId: mostRecentTask.asurionCallId,
            partner: "verizon",
            reservationSid: (mostRecentTask as VoiceTask & { config?: { reservation?: { sid: string } } }).config
                ?.reservation?.sid,
            sessionId: getSessionId(),

            customerMdn: mdn.slice(2),
        },
        !!mdn && isEligibilityPrecheckEnabled === true && !isLoadingEligibilityPrecheckEnabled,
    );

    const [isEditMdn, setIsEditMdn] = useState(false);
    const [isEditingSimplifiedMdn, setIsEditingSimplifiedMdn] = useState(true);
    const onConfirmClick = () => {
        if (!customerName) return;
        const { setCustomer } = useVerifiedCustomerStore.getState();
        const { pushStep } = useVerificationFlowStore.getState();
        setCustomer({ ...customerData?.primaryAccountHolder, fullName: customerName, mdn });
        const callDetails = useCallDetailsStore.getState().callDetails;
        const strippedMdn = stripCountryCode(mdn);
        void dispatcher
            .withMeta({ Client: "Verizon" })
            .withExtra({
                planId: customerData?.primaryAccountHolder?.planId,
                RetailName: customerData?.primaryAccountHolder?.productName,
                AccountType: callDetails.ACCOUNT_TYPE,
                AccountCategory: callDetails.ACCOUNT_CATEGORY,
                EnrollmentStatus: callDetails.VALIDATION_FLAG,
            })
            .withIdentities({
                MDN: strippedMdn,
                AccountNumber: customerData?.primaryAccountHolder?.accountNumber,
            })
            .dispatchBusinessEvent("SupportedProfileDisplayed");
        void dispatcher
            .withMeta({ SkipMixpanel: true, SkipEDP: true, isTest: true })
            .withExtra({
                confirmedMdn: mdn,
                mdnFromTask,
            })
            .dispatchBusinessEvent("MdnConfirmedByExpert");
        if (!env.isDevMode) {
            const authorizedUsers = Array.from(customerData?.authorizedUsers ?? []);
            if (customerData?.primaryAccountHolder) authorizedUsers.push(customerData.primaryAccountHolder);

            window.open(
                `${env.acssBaseUrl}${determineCustomerVerificationStatus(
                    customerName,
                    strippedMdn,
                    authorizedUsers,
                )}&mdnSearched=${strippedMdn}`,
                "_blank",
                "noopener,noreferrer",
            );
        }
        pushStep("VerificationComplete");
    };
    const compareName = (): string => {
        if (customerData?.primaryAccountHolder?.fullName === undefined) {
            return "NoCustomerFound";
        } else if (
            customerData.primaryAccountHolder.fullName === customerName ||
            customerData.authorizedUsers?.find((user) => user.fullName === customerName)
        ) {
            return "ExistingCustomerNameSelected";
        }
        return "NewCustomerNameAdded";
    };
    const activeVoiceTask = useActiveTask();
    return (
        <AnalyticsProvider
            dispatcher={(dispatch) =>
                dispatch.withExtra({
                    newFlow: true,
                    page: currentStep,
                })
            }
        >
            <Divider size="md" />
            {currentStep === "CustomerPhoneInput" && (
                <Flex id="customer-confirmation-flexbox" className={classes.root}>
                    {isUpdatedConfirmationFlow ? (
                        <SimplifiedMdnSection
                            mdn={
                                isEditingCustomerInformation
                                    ? useVerifiedCustomerStore.getState().customer?.mdn ?? mdnFromTask
                                    : mdnFromTask
                            }
                            onComplete={(input) => {
                                if (input !== mdn) {
                                    setCustomerName(undefined);
                                }
                                setMdn(input);
                            }}
                            onSave={(input) => {
                                setIsEditMdn(input);
                                setIsEditingSimplifiedMdn(input);
                            }}
                        />
                    ) : (
                        <MdnSection
                            mdn={
                                isEditingCustomerInformation
                                    ? useVerifiedCustomerStore.getState().customer?.mdn ?? mdnFromTask
                                    : mdnFromTask
                            }
                            onComplete={(input) => {
                                if (input !== mdn) {
                                    setCustomerName(undefined);
                                }
                                setMdn(input);
                            }}
                            onSave={(input) => {
                                setIsEditMdn(input);
                            }}
                        />
                    )}

                    {!isLoading ? (
                        <CustomerNamesSection
                            key={mdn}
                            customer={customerData ?? undefined}
                            onOptionSelect={(input) => {
                                setCustomerName(input);
                                transformDispatcher((newDispatcher) => {
                                    return newDispatcher.withExtra({ eligibilityCheckType: "ManualCheck" });
                                });
                            }}
                            onMdnSave={isEditMdn || mdn === ""}
                        />
                    ) : (
                        <Flex className={classes.animationRoot}>
                            <Container maw="160px">
                                <LoadingAnimation />
                            </Container>
                        </Flex>
                    )}

                    <ButtonWithAnalytics
                        size="md"
                        mt="24px"
                        analytics={{ actionId: "Confirm", extraContext: { customerNameSelected: compareName() } }}
                        onClick={onConfirmClick}
                        disabled={
                            !customerName ||
                            isEditMdn ||
                            !validatePhoneNumber(mdn).valid ||
                            (isUpdatedConfirmationFlow && isEditingSimplifiedMdn)
                        }
                        data-testid="change-phone-number-button"
                    >
                        Confirm and open ACSS
                    </ButtonWithAnalytics>
                </Flex>
            )}
            {currentStep === "VerificationComplete" && <SalesFlow />}
            {currentStep !== "VerificationComplete" &&
            activeVoiceTask &&
            !isEligibilityPrecheckEnabled &&
            !isLoadingEligibilityPrecheckEnabled &&
            !isEditingCustomerInformation &&
            !isMdnEditEnabled ? (
                <AutoConfirmCountDown mdnFromTask={mdnFromTask} customerData={customerData} />
            ) : null}
        </AnalyticsProvider>
    );
}
