import { customCleanEnv, url } from "envalid";

interface Env {
    webSocketUrl: string;
}

export const env = customCleanEnv(
    import.meta.env,
    {
        VITE_WEBSOCKET_URL: url(),
    },
    (inputEnv): Env => ({
        webSocketUrl: inputEnv.VITE_WEBSOCKET_URL,
    }),
);
