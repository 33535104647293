import { getLogger } from "@expert/logging";
import type { Contact } from "@expert/shared-types";
import { formatPhoneNumber, validatePhoneNumber } from "@expert/shared-utils";

const logger = getLogger({
    module: "PhoneNumber",
});

interface PhoneNumberProps {
    mdn: string;
}

interface VdnNameProps {
    vdn: Contact;
}

function validateAndFormatMdn(mdn: string) {
    if (!validatePhoneNumber(mdn).valid) {
        logger.error(`Unexpected MDN received: ${mdn}`);
        return <span data-testid="unexpected-phone-number">Unknown</span>;
    }

    return <span data-testid="phone-number">{formatPhoneNumber(mdn)}</span>;
}

export function PhoneNumber({ mdn }: PhoneNumberProps): JSX.Element {
    return validateAndFormatMdn(mdn);
}

export function VdnName({ vdn }: VdnNameProps): JSX.Element {
    return <span data-testid="vdn-name">{vdn.name}</span>;
}
