import type { Draft } from "immer";
import { current } from "immer";
import { getLogger } from "@expert/logging";
import { useAgentStore } from "../agent/store";
import { type AgentSdkBase, type Task, type TaskCompletedReason } from "../agent";
import { isTrainingTask } from "../agent/trainingTask";
import { isOutboundVoiceTask, isVoiceTask } from "../agent/voice";
import { sdkEventBus } from "../agent/eventBus";
import { subTaskCompletedCallbackNow } from "../agent/callbacks";
import { SessionInstance } from "./session";
import type { CustomerSessionEndedReason, CustomerSessionStartedReason, Session } from "./types";
import type { SessionStore } from "./session.store";
import { updateTaskStatusInternal, useSessionStore } from "./session.store";

type TriggerStart = typeof triggerCxSessionStart;
type TriggerEnd = typeof triggerCxSessionEnd;
interface SessionControls {
    startSession: TriggerStart;
    endSession: TriggerEnd;
}

type DeferredHandlerFunction<TArgs extends unknown[]> = (sessionControls: SessionControls, ...args: TArgs) => void;
type HandlerFunction<TArgs extends unknown[]> = (...args: TArgs) => void;

const logger = getLogger({
    module: "sessionsOrchestrator",
});

let agentSdkInstance: AgentSdkBase<never> | null = null;

function safeAction<TArgs extends unknown[]>(action: HandlerFunction<TArgs>) {
    const enhanced = (...args: TArgs) => {
        try {
            action(...args);
        } catch (err: unknown) {
            logger.error({ err }, `Error in session orchestrator: ${(err as Error).message}`);
        }
    };

    return enhanced as HandlerFunction<TArgs>;
}

/** Mixin to provide deferred session start/end event behavior for session changes on complex state mutations.
 * This is used to ensure that the session start/end events are emitted after the store has been updated, and not during update.
 *
 * @description We use a mixin instead of returning interpretable state to avoid WET state management in favor of composability
 */
function withDeferredSessionChange<TArgs extends unknown[]>(action: DeferredHandlerFunction<TArgs>) {
    let startSessionArgs: Parameters<TriggerStart> | null = null;
    let endSessionArgs: Parameters<TriggerEnd> | null = null;

    // We capture the call args and use them after the action has completed to trigger the session start/end
    const sessionControls: SessionControls = {
        startSession: (...args: Parameters<TriggerStart>) => {
            startSessionArgs = args;
            logger.debug(
                {
                    startSessionArgs,
                },
                "Deferring session start2",
            );
        },
        endSession: (...args: Parameters<TriggerEnd>) => {
            endSessionArgs = args;
            logger.debug(
                {
                    endSessionArgs,
                },
                "Deferring session end",
            );
        },
    };

    const newAction = (...args: TArgs) => {
        try {
            action(sessionControls, ...args);

            if (startSessionArgs) {
                triggerCxSessionStart(...startSessionArgs);
            } else if (endSessionArgs) {
                triggerCxSessionEnd(...endSessionArgs);
            }
        } finally {
            startSessionArgs = null;
            endSessionArgs = null;
        }
    };

    return newAction as HandlerFunction<TArgs>;
}

const initSessions = safeAction(
    withDeferredSessionChange((sessionControls: SessionControls, agentSdk: AgentSdkBase<never>, tasks: Task[]) => {
        agentSdkInstance = agentSdk;
        useSessionStore.getState().mutateStore((state) => {
            return initStore(sessionControls, state, tasks);
        });
    }),
);

const addNewAgentTask = safeAction(
    withDeferredSessionChange((sessionControls, task: Task) => {
        if (isVoiceTask(task)) {
            const currentSession = useSessionStore.getState().computed.currentSession();

            // Ad-hoc outbound call, new session
            if (task.callDirection === "outbound" && !task.previousTaskId) {
                sessionControls.startSession(currentSession.id, "AdHocOutboundCall", task);
            } else if (task.callDirection === "inbound") {
                // New incoming call, new session
                sessionControls.startSession(currentSession.id, "InboundCall", task);
            } else {
                useSessionStore.getState().addTaskToSession(currentSession.id, task);
            }
            return;
        }

        if (isTrainingTask(task)) {
            const currentSession = useSessionStore.getState().computed.currentSession();
            // New training, new session
            sessionControls.startSession(currentSession.id, "IncomingTraining", task);
            return;
        }

        throw new Error("Unsupported task type");
    }),
);

const onTaskRejected = safeAction((task: Task) => {
    const currentSession = useSessionStore.getState().computed.currentSession();

    triggerCxSessionEnd(currentSession.id, "CallRejected", task);
});

const onTaskCancelled = safeAction((task: Task) => {
    const currentSession = useSessionStore.getState().computed.currentSession();

    triggerCxSessionEnd(currentSession.id, "CallCancelled", task);
});

const onTaskCompleted = safeAction(
    withDeferredSessionChange((sessionControls: SessionControls, task: Task, reason: TaskCompletedReason) => {
        useSessionStore.getState().mutateStore((state) => {
            const currentSession = state.sessions[state.sessions.length - 1];

            const storeTask = state.getTask(task.id);
            if (!storeTask) throw new Error(`Task ${task.id} not found in store`);

            updateTaskStatusInternal(state, task.id, "completed");

            if (reason !== "CallbackInitiated") {
                sessionControls.endSession(currentSession.id, "CallCompletedWithoutCallback", storeTask);
            }

            return state;
        });
    }),
);

/** Manually end an active session in cases where there will be no task actions to trigger from */
const completeSession = safeAction(() => {
    const currentSession = useSessionStore.getState().computed.currentSession();

    if (currentSession.currentTask) throw new Error("Cannot manually complete a session that has active task(s)");
    if (currentSession.callbackState?.callbackType === "CallbackNow")
        throw new Error("Cannot manually complete a session that is waiting callback");

    triggerCxSessionEnd(currentSession.id, "CallCompletedWithoutCallback", undefined);
});

function initStore(sessionControls: SessionControls, state: Draft<SessionStore>, tasks: Task[] | undefined | null) {
    if (state.initialized) throw new Error("SessionStore already initialized");

    function handleExistingCallbackNow(session: Draft<SessionInstance>) {
        logger.debug({ session: current(session) }, "Handling existing callback now");
        // If we refresh after scheduling a callback now, event listeners are lost, re-listen
        if (session.currentTask && session.callbackState?.callbackType === "CallbackNow") {
            subTaskCompletedCallbackNow(session.currentTask.id);
        }
    }

    // To achieve the requirement that we do not expose tasks till post SDK/Twilio init we hide tasks till SDK init
    state.sessions.forEach((session) => {
        session.restoreHydratedTasks();
    });

    let currentSession = state.sessions[state.sessions.length - 1];
    const agentHasTasks = !!tasks?.length;

    logger.debug(
        {
            agentHasTasks,
            agentTasks: tasks,
            currentSession: current(currentSession),
        },
        "Initializing Cx session store",
    );

    // Session's active task is the same as agent active task, replace the non-class instance in the session with our instance Task
    if (agentHasTasks && currentSession.currentTask?.id === tasks[0].id) {
        currentSession.replaceTask(tasks[0]);
        handleExistingCallbackNow(currentSession);
        state.initialized = true;
        return state;
    }

    switch (agentHasTasks) {
        case true:
            // Satisfies the type checker
            if (!tasks) throw new Error("Unexpected undefined tasks");

            // We have tasks assigned, but current session has no task or has a different task association than the current task
            if (currentSession.currentTask?.id !== tasks[0].id) {
                // New task was created from an outbound call from this session, just add task to session and break
                if (isOutboundVoiceTask(tasks[0]) && tasks[0].sessionId === currentSession.id) {
                    currentSession.addTask(tasks[0]);
                    break;
                }
                sessionControls.startSession(currentSession.id, "CallAlreadyExists", tasks[0]);
            }
            break;
        case false:
            // We are waiting on an outbound call for this session, don't end the session
            if (currentSession.callbackState?.callbackType === "CallbackNow") {
                break;
            }

            // We have existing wrapping state, defer to handlers of wrapping state to wrap or end session
            if (currentSession.wrappingState) {
                break;
            }

            if (currentSession.kind === "with-customer") {
                // Session is with a customer (on task), Agent has no tasks, and we are not waiting for a callback now, therefore we must end the session
                // Regardless of if the session has or does not have an active task association
                sessionControls.endSession(currentSession.id, "CallUnexpectedlyEnded", undefined);
            }

            break;
    }

    // Current session may be different after the above checks
    currentSession = state.sessions[state.sessions.length - 1];

    // Cleanup all other sessions that are not the current session
    state.sessions.map((session) => {
        if (session.id === currentSession.id || session.metadata.status === "ended") return;

        session.endSession("ForcedSessionEnd", undefined);

        return null;
    });

    handleExistingCallbackNow(currentSession);

    // Regression can land us in an untenable state where we have no active tasks, a pending callback, but no set scheduled time
    // This will cause the UI to skip past rendering anything callback-state related, and the agent will be semi-stuck in this situation
    // After a reload, we fix this state directly to ensure a refresh clears the problem
    if (
        !currentSession.currentTask &&
        currentSession.callbackState?.callbackType === "CallbackNow" &&
        !currentSession.callbackState.scheduledFor
    ) {
        const delay = currentSession.callbackState.callbackDelay ?? 0;
        currentSession.callbackState.scheduledFor = Date.now() + delay * 1000;
    }

    state.initialized = true;

    return state;
}

function triggerCxSessionStart(currentSessionId: string, reason: CustomerSessionStartedReason, task: Task) {
    logger.debug({ reason, currentSessionId, task }, "Starting Cx session");

    if (!isVoiceTask(task) && !isTrainingTask(task)) {
        throw new Error("Unsupported task type");
    }

    const newSession = new SessionInstance({
        /* sessionId is set in Twilio task attributes after the task (ExWo's one) is created.
           Due to the fact that we have auto-accept there is a race condition between updating task attributes and accepting it.
           We need to have sessionId available at earlier stages to avoid this race condition.
           So, we add task attribute sessionIdFallback in reservationCreated event handler before creating ExWo task.
           And then, we use it as a fallback in certain places when there is a chance that sessionId hasn't been set yet.
        */
        id: task.sessionIdFallback,
        previousId: currentSessionId,
        startedTimestamp: Date.now(),
        kind: task.hasCustomer ? "with-customer" : "without-customer",
        tasks: [task],
        callbackState: null,
        wrappingState: null,
        metadata: {
            startReason: reason,
            status: "active",
        },
    });

    useSessionStore.getState().endSession(currentSessionId, reason, newSession);

    const previousSession = useSessionStore.getState().getSession(currentSessionId);
    if (!previousSession) throw new Error(`Session ${currentSessionId} not found`);

    sdkEventBus.emit("session_changed", {
        newSession: newSession as Session,
        previousSession,
        reason,
    });
}

function triggerCxSessionEnd(
    currentSessionId: string,
    reason: CustomerSessionEndedReason,
    triggeringTask: Task | undefined,
) {
    logger.debug({ reason, currentSessionId, triggeringTask }, "Ending Cx session");

    const newSession = new SessionInstance({
        id: crypto.randomUUID(),
        previousId: currentSessionId,
        startedTimestamp: Date.now(),
        kind: "without-customer",
        tasks: [],
        callbackState: null,
        wrappingState: null,
        metadata: {
            startReason: reason,
            status: "active",
        },
    });

    useSessionStore.getState().endSession(currentSessionId, reason, newSession);

    const previousSession = useSessionStore.getState().getSession(currentSessionId);
    if (!previousSession) throw new Error(`Session ${currentSessionId} not found`);

    if (reason === "CallCancelled" || reason === "CallCompletedWithoutCallback" || reason === "CallRejected") {
        void handleEndSessionSetAgentActivity();
    }

    sdkEventBus.emit("session_changed", {
        newSession: newSession as Session,
        previousSession,
        reason,
    });
}

async function handleEndSessionSetAgentActivity() {
    if (!agentSdkInstance) throw new Error("Sessions not initialized with agent SDK instance");

    const pendingActivity = useAgentStore.getState().pendingActivity;
    logger.debug("==== TROUBLESHOOT: session ended pending activity ====", pendingActivity);

    // NOTE: We should never get any string other than the ones defined in the AgentActivity type, but it looks
    //       like somehow we're sometimes getting the string '"undefined"' (the quotes included). The following
    //       if check is there to confirm whether this is happening and report it when it does happen.

    // @ts-expect-error Checking for invalid value
    // eslint-disable-next-line prefer-smart-quotes/prefer
    if (pendingActivity === '"undefined"') {
        logger.warn(
            `==== TROUBLESHOOT: pending activity wrong status ====: Somehow pendingActivity was the string undefined: '${pendingActivity}`,
        );
    }

    const activeTasks = useSessionStore.getState().computed.activeTasks();

    if (pendingActivity) {
        await agentSdkInstance.setAgentActivity(pendingActivity);
    } else if (!activeTasks.length) {
        // If we have an active task, we do NOT want to go available, such as during a callback
        await agentSdkInstance.setAgentActivity("Available");
    }
}

export const sessionsOrchestrator = {
    initSessions,
    addNewAgentTask,
    onTaskRejected,
    onTaskCancelled,
    onTaskCompleted,
    completeSession,
};
