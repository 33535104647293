import { ActionIcon, Button, Flex, Text } from "@mantine/core";
import { useAnalytics, withPolymorphicClickAnalytics } from "@expert/analytics";
import { useState } from "react";
import { getSessionId } from "@expert/sdk";
import type { EligibleProduct } from "../../stores";
import { useCurrentStepStore, useHomeProductSessionStore } from "../../stores";
import { SaleProgress } from "../../types";
import { InfoIcon, RightArrowIcon } from "../Icons";
import { postDeclination } from "../../api";
import styles from "./StepTwoFooter.module.css";
import { PlanInfoGuidedFlow } from "./PlanInfoGuidedFlow";

interface EnrollSectionProps {
    product: EligibleProduct;
}

export function StepTwoFooter({ product }: EnrollSectionProps) {
    const AnalyticsButton = withPolymorphicClickAnalytics(Button, "ButtonClick");
    const [declining, setDeclining] = useState(false);
    const [updateProduct] = useHomeProductSessionStore((state) => [state.updateProduct]);
    const [step, updateStep] = useCurrentStepStore((state) => [state.step, state.updateStep]);
    const [opened, setOpened] = useState(false);
    const { dispatcher } = useAnalytics();
    const openDrawer = () => {
        setOpened(true);
        void dispatcher.withExtra({ step }).dispatchBusinessEvent("ButtonClick_PlanInfo");
    };
    const closeDrawer = () => {
        setOpened(false);
    };

    const decline = async () => {
        try {
            const sessionId = getSessionId();
            await postDeclination({
                callId: sessionId,
                sessionId,
                partner: "verizon",
                productOfferId: product.id,
            });
            updateProduct({ ...product, ...{ saleProgress: SaleProgress.Declined } });
            setDeclining(false);
        } catch (e) {
            setDeclining(false);
        }
    };

    return (
        <Flex className={styles.footerBox}>
            <PlanInfoGuidedFlow productSku={product.productSku} opened={opened} closeDrawer={closeDrawer} />
            <ActionIcon className={styles.infoIcon} variant="transparent" onClick={openDrawer}>
                <InfoIcon />
            </ActionIcon>
            <AnalyticsButton
                analytics={{
                    actionId: "EnrollmentDeclineButton",
                    eventData: {
                        step,
                    },
                }}
                color="white"
                data-testid="enrollment-decline-button"
                loading={declining}
                onClick={() => {
                    setDeclining(true);
                    void decline();
                }}
                size="md"
                variant="transparent"
            >
                <Text td="underline" size="sm">
                    Decline
                </Text>
            </AnalyticsButton>
            <Button
                disabled={declining}
                className={styles.actionText}
                color="white"
                variant="outline"
                rightSection={<RightArrowIcon />}
                onClick={() => {
                    void dispatcher.withExtra({ step }).dispatchBusinessEvent("ButtonClicked_ContinueToDisclosures");
                    updateStep("LegalDisclosureStep");
                }}
            >
                Continue to disclosures
            </Button>
        </Flex>
    );
}
