export const pendingConferenceParticipantStatuses = ["queued", "initiated", "ringing"] as const;
export const activeConferenceParticipantStatuses = ["in-progress"];
export const inactiveConferenceParticipantStatuses = ["completed", "canceled", "failed", "busy", "no-answer"];

// this magic incantation will create a union from that array
export type PendingConferenceParticipantStatus = (typeof pendingConferenceParticipantStatuses)[number];
export type ActiveConferenceParticipantStatus = (typeof activeConferenceParticipantStatuses)[number];
export type InactiveConferenceParticipantStatus = (typeof inactiveConferenceParticipantStatuses)[number];

export interface ConferenceParticipantBase {
    participantCallId: string;
    participantName: string;
    muted?: boolean;
    hold?: boolean;
    onHoldSince?: number;
    status?: string;
}

export interface PendingConferenceParticipant extends ConferenceParticipantBase {
    status: PendingConferenceParticipantStatus;
}

export interface InactiveConferenceParticipant extends ConferenceParticipantBase {
    status: InactiveConferenceParticipantStatus;
}

export interface ConferenceParticipant extends ConferenceParticipantBase {
    status?: ActiveConferenceParticipantStatus;
    hold: boolean;
}

export interface Conference {
    id: string;
    name: string;
    participants: ConferenceParticipant[];
    pendingParticipants?: PendingConferenceParticipant[];
}

export interface UpdateParticipantOptions {
    hold?: boolean;
    endConferenceOnExit?: boolean;
}

export function isPendingConferenceParticipant(participant: ConferenceParticipantBase) {
    return (
        participant.status !== undefined &&
        (pendingConferenceParticipantStatuses as readonly string[]).includes(participant.status)
    );
}
