import { getLogger } from "@expert/logging";
import { useStopwatch } from "@expert/shared-utils";
import { Text } from "@mantine/core";
import { useEffect } from "react";
import type { TaskProps } from "../../types";
import classes from "./CallDuration.module.css";

const logger = getLogger({ module: "CallDuration" });

export function CallDuration({ task }: TaskProps): JSX.Element {
    const taskAssignedAt = task.assignedAt;
    const formattedTime = useStopwatch(taskAssignedAt?.getTime());

    useEffect(() => {
        if (task.status !== "assigned") {
            return;
        }
        if (!taskAssignedAt) {
            logger.warn(
                {
                    task,
                    callTime: formattedTime,
                },
                "UNDEFINED TASK ASSIGNMENT DATE",
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps -- we don't want formattedTime to trigger this effect every second
    }, [task.status, taskAssignedAt]);

    return (
        <Text className={classes.timerText} component="span" fw={300} size="sm">
            {formattedTime}
        </Text>
    );
}
