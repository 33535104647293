import { TinyEmitter } from "@expert/event-bus";
import type { ExpertWorkspaceWebSocketMessageType } from "./types";

export const WS_EMITTER_NAME = "ws";
export type ExpertWorkspaceWebSocketEvents = Record<
    `${typeof WS_EMITTER_NAME}_${ExpertWorkspaceWebSocketMessageType}`,
    // fixme: move conference event types somewhere where they can be imported without causing cyclical dependencies
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    any
>;
export type ExpertWorkspaceWebSocketEventType = keyof ExpertWorkspaceWebSocketEvents;

export const expertWorkspaceWebSocketEventBus = new TinyEmitter<ExpertWorkspaceWebSocketEvents>(WS_EMITTER_NAME);
