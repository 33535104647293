import { useAnalytics } from "@expert/analytics";
import { Checkbox, Flex, Mark, Text } from "@mantine/core";
import { usePlanInfo } from "@expert/contentful";
import classes from "./LegalDisclosure.module.css";

interface LegalDisclosureProps {
    productSku: string;
    onDisclosureCheckedUpdated: (newStatus: boolean) => void;
    isDisclosureChecked: boolean;
}
export function LegalDisclosure({
    productSku,
    onDisclosureCheckedUpdated,
    isDisclosureChecked,
}: LegalDisclosureProps): JSX.Element {
    const { dispatcher } = useAnalytics();
    const { planInfo, error } = usePlanInfo(productSku);

    if (error) {
        return (
            <Text ta="center" m="3rem">
                We ran into an issue. Verizon Home Device Protect legal disclosures are not available right now.
            </Text>
        );
    }

    return (
        <>
            <Flex direction="column" align="center" data-testid="home-flow-product-name">
                <Text fw="400" size="md" mt="sm">
                    Verizon Home Device Protect
                </Text>
                <Text c="var(--mantine-color-success-5)" size="md" fw="700">
                    legal disclosures
                </Text>
            </Flex>
            <Flex className={classes.legalDisclosureContainer}>
                <Text className={classes.legalDisclosureText}>
                    {planInfo?.legalDisclosure}
                    <Mark className={classes.highlightedText}>May I complete your enrollment today?</Mark>
                </Text>
                <Checkbox
                    checked={isDisclosureChecked}
                    className={classes.checkbox}
                    data-testid="disclosure-checkbox"
                    label="I’ve read the disclosures verbatim"
                    onChange={(e) => {
                        void dispatcher.dispatchAction("ButtonClick", "DisclosureCheckbox");
                        onDisclosureCheckedUpdated(e.target.checked);
                    }}
                />
            </Flex>
        </>
    );
}
