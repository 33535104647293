import type { SVGProps } from "react";

export function CloseX(props: SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.41083 4.41075C4.73626 4.08531 5.2639 4.08531 5.58934 4.41075L10.0001 8.82149L14.4108 4.41075C14.7363 4.08531 15.2639 4.08531 15.5893 4.41075C15.9148 4.73619 15.9148 5.26382 15.5893 5.58926L11.1786 10L15.5893 14.4107C15.9148 14.7362 15.9148 15.2638 15.5893 15.5893C15.2639 15.9147 14.7363 15.9147 14.4108 15.5893L10.0001 11.1785L5.58934 15.5893C5.2639 15.9147 4.73626 15.9147 4.41083 15.5893C4.08539 15.2638 4.08539 14.7362 4.41083 14.4107L8.82157 10L4.41083 5.58926C4.08539 5.26382 4.08539 4.73619 4.41083 4.41075Z"
                fill="#A5AAAF"
            />
        </svg>
    );
}
