import { BADGE_BREAKPOINT, type BadgeType } from "../../types";
import { BronzeBadge, GoldBadge, SilverBadge } from "../icons";
import bronzeBadge from "../lotties/bronze-badge.json";
import silverBadge from "../lotties/silver-badge.json";
import goldBadge from "../lotties/gold-badge.json";

interface BadgeProps {
    upperLimit: number;
    lowerLimit: number;
    icon?: JSX.Element;
    animationData: object;
}
export const badgeMap: Record<BadgeType, BadgeProps> = {
    bronze: {
        lowerLimit: 0,
        upperLimit: BADGE_BREAKPOINT,
        icon: <BronzeBadge height={24} width={24} />,
        animationData: bronzeBadge,
    },
    silver: {
        lowerLimit: BADGE_BREAKPOINT,
        upperLimit: BADGE_BREAKPOINT * 2,
        icon: <SilverBadge height={24} width={24} />,
        animationData: silverBadge,
    },
    gold: {
        lowerLimit: BADGE_BREAKPOINT * 2,
        upperLimit: BADGE_BREAKPOINT * 3,
        icon: <GoldBadge height={24} width={24} />,
        animationData: goldBadge,
    },
};
