export function Megaphone() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="18" viewBox="0 0 22 18" fill="none">
            <path
                d="M7.25 3.75V10.9625C7.24995 13.2408 8.5417 15.3222 10.5833 16.3333"
                stroke="var(--mantine-color-dark-3)"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.9141 1.7366C18.914 1.45211 18.7689 1.18728 18.5291 1.03422C18.2893 0.88115 17.988 0.860999 17.7299 0.980772C14.5341 2.46327 11.3774 3.80327 4.7474 3.83327H3.91406C2.53335 3.83327 1.41406 4.95256 1.41406 6.33327V7.99994C1.41406 9.38065 2.53335 10.4999 3.91406 10.4999H4.7474C11.3774 10.5299 14.5341 11.8699 17.7299 13.3524C17.988 13.4722 18.2893 13.4521 18.5291 13.299C18.7689 13.1459 18.914 12.8811 18.9141 12.5966V1.7366Z"
                stroke="var(--mantine-color-dark-3)"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M18.9141 5.5H19.3307C20.0211 5.5 20.5807 6.05964 20.5807 6.75V7.58333C20.5807 8.27369 20.0211 8.83333 19.3307 8.83333H18.9141"
                stroke="var(--mantine-color-dark-3)"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
