export function EditIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none">
            <path
                fill="#E6E6EB"
                fillRule="evenodd"
                d="M9.195 3.195a2.552 2.552 0 1 1 3.61 3.61l-7 7a.667.667 0 0 1-.472.195H2.667A.667.667 0 0 1 2 13.333v-2.666c0-.177.07-.347.195-.472l6.33-6.329a.587.587 0 0 1 .008-.008l.662-.663ZM9 5.276l-5.667 5.667v1.724h1.724L10.724 7 9 5.276Zm2.667.781L9.943 4.333l.195-.195a1.219 1.219 0 1 1 1.724 1.724l-.195.195Z"
                clipRule="evenodd"
            />
        </svg>
    );
}
