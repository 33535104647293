import { Card, Flex, Text } from "@mantine/core";
import type { BadgeType } from "../../types";
import { capitalize } from "../../utils";
import { badgeMap } from "./badgeMap";
import classes from "./filledBar.module.css";

interface FilledBarProps {
    badgeType: BadgeType;
}

export function FilledBar({ badgeType }: FilledBarProps) {
    const { upperLimit, icon } = badgeMap[badgeType];
    return (
        <Card className={classes.filledCard}>
            <Flex className={classes.leftText}>
                {icon}
                <Text className={classes.filledText} size="sm">
                    {`${capitalize(badgeType)} unlocked`}
                </Text>
            </Flex>
            <Text className={classes.filledText} size="sm">{`${upperLimit} offers`}</Text>
        </Card>
    );
}
