import { GlobalError, GlobalLoader } from "@expert/common-ui";
import { createContext } from "react";
import type { AgentSdk } from "./agentSdk";
import { useAgentSdkInternal } from "./internal";

export const AgentSdkContext = createContext<AgentSdk | null>(null);

export type AgentSdkProviderCreator<TAppConfig> = (
    token: string,
    employeeNum: string | null,
    hdxEnabled: boolean,
    appConfig: TAppConfig,
    onReady: (isReady: boolean) => void,
) => AgentSdk;

interface AgentSdkProviderProps<TAppConfig> {
    token: string | null;
    employeeNum: string | null;
    hdxEnabled: boolean;
    appConfig: TAppConfig;
    agentSdkCreator: AgentSdkProviderCreator<TAppConfig>;
    children?: React.ReactNode;
}

export function AgentSdkProvider<TAppConfig>({
    token,
    employeeNum,
    hdxEnabled,
    appConfig,
    agentSdkCreator,
    children,
}: AgentSdkProviderProps<TAppConfig>) {
    const { agentSdk, isReady } = useAgentSdkInternal(token, employeeNum, hdxEnabled, appConfig, agentSdkCreator);

    if (!agentSdk) {
        return <GlobalError msg="Failed to create Agent SDK" />;
    }
    if (!isReady) {
        const message = hdxEnabled
            ? "Getting things ready... Checking whether you are in a Citrix environment"
            : "Getting things ready... make sure you’re connected to EVL.";
        return <GlobalLoader msg={message} />;
    }

    return <AgentSdkContext.Provider value={agentSdk}>{children}</AgentSdkContext.Provider>;
}
