import { Group, Stack, Text } from "@mantine/core";
import React from "react";
import { AsurionLogo } from "../assets";
import { WorkspaceLoading } from "./WorkspaceLoading";
import styles from "./styles.module.css";

interface InitProps {
    msg: string;
    children?: React.ReactNode;
}
export function GlobalLoader({ msg, children }: InitProps) {
    return (
        <Stack justify="center" align="center" classNames={{ root: styles.globalLoaderRoot }} gap={0}>
            <WorkspaceLoading />
            <Group mb="xs">
                <AsurionLogo />
            </Group>
            <Text c="dark.3" size="md" pl="xl" pr="xl">
                {msg}
            </Text>
            {children}
        </Stack>
    );
}
