import type { ConferenceParticipantBase } from "@expert/sdk";
import { useActiveVoiceTask } from "@expert/sdk";
import { Popover, Stack } from "@mantine/core";
import { useMemo } from "react";
import styles from "./ConferencePanel.module.css";
import { ConferenceParticipantControls, PrimaryParticipantControls } from "./Participant";

export function ConferencePanel() {
    const task = useActiveVoiceTask();

    const participantMatchesCustomer = (participant: ConferenceParticipantBase) => {
        return participant.participantName === task.mdn;
    };

    const participants = [...task.conferenceParticipants, ...task.pendingConferenceParticipants];

    const primaryParticipantRejoined = useMemo(() => {
        if (task.customerLeftTheConference) {
            return participants.find((participant) => participantMatchesCustomer(participant));
        }
        return undefined;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [task.conferenceParticipants, task.pendingConferenceParticipants, task.customerLeftTheConference]);

    const filteredParticipants = primaryParticipantRejoined
        ? participants.filter((participant) => !participantMatchesCustomer(participant))
        : participants;

    return (
        <Popover zIndex="var(--mantine-priority-high)">
            <Stack data-testid="conference-panel" gap="xs" bg="dark.9" classNames={{ root: styles.containerRoot }}>
                {primaryParticipantRejoined ? (
                    <ConferenceParticipantControls
                        key={primaryParticipantRejoined.participantCallId}
                        participant={primaryParticipantRejoined}
                        isPrimary
                    />
                ) : (
                    <PrimaryParticipantControls />
                )}
                {filteredParticipants.map((participant) => (
                    <ConferenceParticipantControls
                        key={participant.participantCallId}
                        participant={participant}
                        isPrimary={participant.participantCallId === primaryParticipantRejoined?.participantCallId}
                    />
                ))}
            </Stack>
        </Popover>
    );
}
