export function CheckMarkIcon() {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M26.6665 15.9998C26.6665 21.8909 21.8909 26.6665 15.9998 26.6665C10.1088 26.6665 5.33317 21.8909 5.33317 15.9998C5.33317 10.1088 10.1088 5.33317 15.9998 5.33317C21.8909 5.33317 26.6665 10.1088 26.6665 15.9998ZM29.3332 15.9998C29.3332 23.3636 23.3636 29.3332 15.9998 29.3332C8.63604 29.3332 2.6665 23.3636 2.6665 15.9998C2.6665 8.63604 8.63604 2.6665 15.9998 2.6665C23.3636 2.6665 29.3332 8.63604 29.3332 15.9998ZM14.2849 22.2138L23.6676 11.5471L21.6654 9.78588L13.2129 19.395L10.2673 16.503L8.39905 18.4058L12.3497 22.2846L13.3547 23.2713L14.2849 22.2138Z"
                fill="#0F1113"
            />
        </svg>
    );
}
