import { useEffect, useState } from "react";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { getLogger } from "@expert/logging";
import { GAIA_WS_EMITTER_NAME, gaiaWsEventBus } from "../eventBus";
import type {
    GaiaResponseBody,
    GaiaSubscription,
    GaiaWebSocketResponse,
    GaiaWebsocketContext,
    SendJsonMessage,
} from "../types";
import { GaiaWebSocketEvent } from "../types";

const logger = getLogger({
    module: "useGaiaWebsocket",
    tag: "solve",
});

const heartbeatInterval = 60000;

export function useGaiaWebsocketInternal(baseUrl: string, token: string, client: string, identity: string) {
    const [loading, setLoading] = useState(true);
    const url = `${baseUrl}?application=expert-workspace&client=${client}`;
    const { sendJsonMessage, readyState } = useWebSocket(
        url,
        {
            heartbeat: {
                interval: heartbeatInterval,
                message: JSON.stringify({
                    action: "heartbeat",
                    correlationId: identity,
                }),
            },
            shouldReconnect: (_closeEvent) => true,
            reconnectAttempts: 10,
            reconnectInterval: 2000,
            onMessage: createOnMessageEventHandler(setLoading),
            onError,
        },
        true,
    );

    useEffect(() => {
        if (!loading && readyState === ReadyState.CONNECTING) {
            logger.debug("GAIA | websocket connecting");
            setLoading(true);
        }
    }, [loading, readyState]);

    useEffect(() => {
        if (loading && readyState === ReadyState.OPEN) {
            logger.info("GAIA | websocket open");
            const template = {
                action: "authorize",
                token,
                correlationId: identity,
            };
            sendJsonMessage(template);
            logger.debug("GAIA | authorize message sent");
        }
    }, [loading, readyState, identity, token, sendJsonMessage]);

    useEffect(() => {
        if (!loading && readyState === ReadyState.CLOSED) {
            logger.info("GAIA | websocket closed");
            setLoading(false);
        }
    }, [loading, readyState]);

    const result: GaiaWebsocketContext = {
        sendJsonMessage,
        loading,
        subscribeSessionToGaia: subscribeSessionToGaia(identity),
        unsubscribeSessionFromGaia: unsubscribeSessionFromGaia(identity),
    };
    return result;
}

const createOnMessageEventHandler = (onAuthenticated: (isLoading: boolean) => void) => (event: MessageEvent) => {
    try {
        const data = JSON.parse(event.data as string) as GaiaWebSocketResponse;
        const { body } = data;

        getLoggerWithContext(data).debug({ body }, `GAIA | message received: ${GAIA_WS_EMITTER_NAME}_${data.name}`);
        gaiaWsEventBus.emit(`${GAIA_WS_EMITTER_NAME}_${data.name}`, data);
        if (data.name === GaiaWebSocketEvent.AuthorizationSuccess) {
            onAuthenticated(false);
        }
    } catch (err: unknown) {
        logger.error({ err, ...event }, "GAIA | message handler error");
    }
};

const onError = (event: Event) => {
    logger.error(event, "GAIA | websocket error");
};

export const subscribeSessionToGaia =
    (identity: string) =>
    ({ sessionId, sendJsonMessage, callSid, timeout = 5000 }: GaiaSubscription) => {
        const loggerWithContext = logger.child({ sessionId, callSid });
        return new Promise<void>((resolve, reject) => {
            const template = {
                action: "subscribe",
                sessionGroupId: sessionId,
                sessionId: callSid ?? sessionId,
                correlationId: identity,
            };
            sendJsonMessage(template);
            loggerWithContext.debug("GAIA | session subscribing");

            const timer = setTimeout(() => {
                cleanupEventListener();
                loggerWithContext.warn("GAIA | session subscription timeout");
                reject(Error("GAIA session subscription timed out"));
            }, timeout);

            const cleanupEventListener = gaiaWsEventBus.once("gaia_ws_session-subscription-success", () => {
                clearTimeout(timer);
                resolve();
            });
        });
    };

const unsubscribeSessionFromGaia = (identity: string) => (sessionId: string, sendJsonMessage: SendJsonMessage) => {
    const template = {
        action: "unsubscribe",
        sessionId,
        correlationId: identity,
    };
    sendJsonMessage(template);
    logger.child({ sessionId }).debug("GAIA | session unsubscribing");
};

const getLoggerWithContext = ({ body }: GaiaWebSocketResponse) => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
    const { sessionGroupId, sessionId } = body as unknown as GaiaResponseBody;

    return sessionId ? logger.child({ sessionId: sessionGroupId ?? sessionId }) : logger;
};
