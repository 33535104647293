import { immer } from "zustand/middleware/immer";
import { shallow } from "zustand/shallow";
import { createWithEqualityFn } from "zustand/traditional";
import { persist } from "zustand/middleware";
import { type CustomerVerificationStep } from "../types";

interface VerifiedCustomerState {
    stepHistory: CustomerVerificationStep[];
    pushStep: (step: CustomerVerificationStep) => void;
    popStep: VoidFunction;
    resetSteps: VoidFunction;
}

export const useVerificationFlowStore = createWithEqualityFn<VerifiedCustomerState>()(
    persist(
        immer((set) => ({
            stepHistory: ["CustomerPhoneInput"],
            pushStep: (step: CustomerVerificationStep) => {
                set((state) => {
                    state.stepHistory.push(step);
                });
            },
            popStep: () => {
                set((state) => {
                    state.stepHistory.pop();
                });
            },
            resetSteps: () => {
                set((state) => {
                    state.stepHistory = ["CustomerPhoneInput"];
                });
            },
        })),
        { name: "verification-flow-store", skipHydration: true },
    ),
    shallow,
);
