import { immer } from "zustand/middleware/immer";
import { shallow } from "zustand/shallow";
import { createWithEqualityFn } from "zustand/traditional";
import { persist } from "zustand/middleware";
import { type EligibilityStatus, type SaleProgress } from "../types";

export interface EligibleProduct {
    id: string;
    productSku: string;
    status: EligibilityStatus;
    saleProgress?: SaleProgress;
    isConfirmationCodeRequired?: boolean;
}

interface HomeProductSessionStore {
    products?: EligibleProduct[];
    updateProducts: (products: EligibleProduct[]) => void;
    updateProduct: (product: EligibleProduct) => void;
    resetSaleSession: () => void;
}

export const useHomeProductSessionStore = createWithEqualityFn<HomeProductSessionStore>()(
    persist(
        immer((set) => ({
            products: undefined,
            updateProducts: (products) => {
                set((state) => {
                    state.products = products;
                });
            },
            updateProduct: (product) => {
                set((state) => {
                    const index = state.products?.findIndex((p) => p.productSku === product.productSku);
                    if (state.products && index !== undefined && index !== -1) {
                        state.products[index] = product;
                    }
                });
            },
            resetSaleSession: () => {
                set((state) => {
                    state.products = [];
                });
            },
        })),
        { name: "home-product-store", skipHydration: true },
    ),
    shallow,
);
