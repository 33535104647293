export const NoVerifiedMdnIcon = (
    <svg width="64" height="66" viewBox="0 0 64 66" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M32 37V18.3333" stroke="#FF8787" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
        <path
            d="M31.9766 42.3333C32.3461 42.3273 32.6528 42.6174 32.6673 42.9867C32.6737 43.1625 32.6093 43.3335 32.4885 43.4614C32.3676 43.5893 32.2005 43.6632 32.0246 43.6667C31.6551 43.6728 31.3484 43.3826 31.3339 43.0133C31.3275 42.8375 31.3919 42.6665 31.5127 42.5386C31.6336 42.4108 31.8007 42.3368 31.9766 42.3333"
            stroke="#FF8787"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4 11.0667V31.432C4.1605 45.2164 12.9155 57.4322 25.9173 62.0133L28.9067 63.1173C30.9037 63.8507 33.0963 63.8507 35.0933 63.1173L38.0827 62.0133C51.0845 57.4322 59.8395 45.2164 60 31.432V11.0667C59.9945 9.43119 59.0148 7.95637 57.5093 7.31733C49.4315 3.94574 40.7529 2.25013 32 2.33333C23.2471 2.25013 14.5686 3.94574 6.49067 7.31733C4.98519 7.95637 4.00548 9.43119 4 11.0667Z"
            stroke="#FF8787"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
