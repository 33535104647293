import { useState } from "react";
import { useAgentStore, useCallbackState, useSessionActiveMdn } from "@expert/sdk";
import { useShallow } from "zustand/react/shallow";
import { Group, Text } from "@mantine/core";
import { Caller, PhoneNumber } from "@expert/common-ui";
import { NoActiveCallControl } from "./NoActiveCallControl";
import { NewCallCallControl } from "./NewCallCallControl";

export function IdleNoCallControls() {
    const [isAddingCall, setIsAddingCall] = useState(false);
    const callbackState = useCallbackState();
    const agentActivity = useAgentStore(useShallow((state) => state.activity));
    const sessionMdn = useSessionActiveMdn();

    function handleAddCallClick() {
        setIsAddingCall(true);
    }

    function handleCancelAddCallClick() {
        setIsAddingCall(false);
    }

    if (callbackState ?? agentActivity === "Wrapping") {
        return (
            <Group gap="xs">
                <Group h="35" p="0">
                    <Caller fill="var(--mantine-color-gray-8)" />
                </Group>
                {sessionMdn ? (
                    <Text className="fs-mask" fw={700} mr="sm" size="md">
                        <PhoneNumber mdn={sessionMdn} />
                    </Text>
                ) : null}
            </Group>
        );
    }

    if (isAddingCall) {
        return <NewCallCallControl onAddCall={handleAddCallClick} onCancel={handleCancelAddCallClick} />;
    }

    return <NoActiveCallControl onAddCall={handleAddCallClick} />;
}
