import { immer } from "zustand/middleware/immer";
import { shallow } from "zustand/shallow";
import { createWithEqualityFn } from "zustand/traditional";
import { persist } from "zustand/middleware";
import type { EligibleProduct } from "./HomeProductSessionStore";

interface OtherProductSessionStore {
    products: EligibleProduct[];
    updateProducts: (products: EligibleProduct[]) => void;
    updateProduct: (product: EligibleProduct) => void;
    resetSaleSession: () => void;
}

export const useOtherProductSessionStore = createWithEqualityFn<OtherProductSessionStore>()(
    persist(
        immer((set) => ({
            products: [],
            updateProducts: (products) => {
                set((state) => {
                    state.products = products;
                });
            },
            updateProduct: (product) => {
                set((state) => {
                    const index = state.products.findIndex((p) => p.productSku === product.productSku);
                    state.products[index] = product;
                });
            },
            resetSaleSession: () => {
                set((state) => {
                    state.products = [];
                });
            },
        })),
        { name: "other-product-store", skipHydration: true },
    ),
    shallow,
);
