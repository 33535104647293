import type { Contact } from "@expert/shared-types";
import { TFN_LENGTH, TRANSFER_OPTIONS, VDN_LENGTH, VDN_PREFIX, VDN_PREFIX_LENGTH } from "@expert/shared-types";
//TODO: clean up the functions in this folder. It seems some of them are doing similar things or may not be needed

/** check the transfer list and return a transfer option if a match is found for given number */
export function tryGetByVdnNumber(number: string) {
    // Don't include 555 vdn prefix
    if (number.startsWith(VDN_PREFIX)) {
        return TRANSFER_OPTIONS.find((option) => option.number === number.slice(VDN_PREFIX_LENGTH));
    }
    return TRANSFER_OPTIONS.find((option) => option.number === number);
}

export function getVdn(num: string): Contact {
    let key = extractNumbers(num);
    if (key.length >= VDN_LENGTH) {
        key = key.slice(-VDN_LENGTH); // VDN's are 7 digits appended to name
    }
    return tryGetByVdnNumber(key) as Contact;
}

export function isInternalTfn(num: string) {
    let key = extractNumbers(num);
    if (key.length >= TFN_LENGTH) {
        key = key.slice(-TFN_LENGTH); // VDN's are 7 digits appended to name
    }
    return tryGetByVdnNumber(key)?.isInternal;
}

export function extractNumbers(value: string): string {
    return value.replace(/\D/g, "");
}
export function tryGetByVdnName(rawInput: string): Contact | undefined {
    return TRANSFER_OPTIONS.find((option) => option.name.toLowerCase() === rawInput.toLowerCase());
}

export function isValidVdn(vdn: string) {
    return /(?:\d{3})-(?:\d{4})/.test(vdn) || /^\d{7}$/.test(vdn) || /^\d{10}$/.test(vdn);
}

/** Returns a masked version of a TFN to use as a name
 * this utility could use work to not be dependant on a good string being passed in
 */
export const tfnToMaskedName = (tfn: string | null | undefined) => {
    return `+1(XXX) XXX-X${(tfn ?? "XXX").slice(-3)}`;
};
