import { useQuery } from "@tanstack/react-query";
import { getDeviceList } from "../api";

export const useDevices = (defaultButtons: string[], listName?: string) => {
    const { data } = useQuery({
        queryKey: [`device-list`],
        queryFn: async () => await getDeviceList(listName),
    });

    const devices = data ? (data.device.map((device) => device.name) as string[]) : defaultButtons;

    return devices;
};
