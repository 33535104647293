export function CheckMarkCircle() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={25} height={25} fill="none">
            <path
                fill="#0F1113"
                fillRule="evenodd"
                d="M10 2a8 8 0 1 0 0 16 8 8 0 0 0 0-16ZM0 10C0 4.477 4.477 0 10 0s10 4.477 10 10-4.477 10-10 10S0 15.523 0 10Zm13.707-2.707a1 1 0 0 1 0 1.414l-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 0Z"
                clipRule="evenodd"
            />
        </svg>
    );
}
