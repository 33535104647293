import { getFeature } from "@soluto-private/expert-workspace-feature-flagging";
import { retryAsync } from "ts-retry";
import { PlatformFeatures } from "./types";

let isInitCalled = false;
const platformFeatures: PlatformFeatures = new PlatformFeatures();

export const initPlatformFeature = async (context: { environment: string; employeeId?: string }) => {
    try {
        const featureFlagResults = await Promise.all(
            Object.keys(platformFeatures).map(async (featureFlag) => ({
                key: featureFlag,
                value: await retryAsync<unknown>(async () => await getFeature(featureFlag, context), {
                    maxTry: 3,
                    delay: 200,
                }),
            })),
        );
        featureFlagResults.forEach(({ key, value }) => {
            // @ts-expect-error this is not a type-safe assignment
            platformFeatures[key] = value;
        });
    } finally {
        isInitCalled = true;
    }
};

export const getFeaturesCache = () => {
    if (!isInitCalled) throw new Error("Tried to get platform features cache without calling init.");

    return platformFeatures;
};
