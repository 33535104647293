import type { SVGProps } from "react";

export function Caller(props: SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg fill="none" height="100%" viewBox="0 0 45 45" width="100%" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g clipPath="url(#clip0_5114_8425)">
                <rect
                    fill={props.fill ?? "var(--mantine-color-asurion-5)"}
                    height="44.3117"
                    rx="22.1558"
                    width="44.3117"
                    x="0.159973"
                    y="0.344147"
                />
                <rect fill="white" height="6.1082" rx="0.381762" width="0.763525" x="17.4484" y="19.482" />
                <rect fill="white" height="4.58115" rx="0.381762" width="0.763525" x="15.5396" y="20.2455" />
                <rect fill="white" height="6.1082" rx="0.381762" width="0.763525" x="13.6307" y="19.482" />
                <rect fill="white" height="4.58115" rx="0.381762" width="0.763525" x="11.7219" y="20.2455" />
                <rect fill="white" height="3.0541" rx="0.381762" width="0.763525" x="9.81317" y="21.0091" />
                <rect fill="white" height="4.96291" rx="0.381762" width="0.763525" x="7.90436" y="20.2455" />
                <rect fill="white" height="2.29057" rx="0.381762" width="0.763525" x="5.99554" y="21.3908" />
                <rect fill="white" height="2.29057" rx="0.381762" width="0.763525" x="4.08673" y="21.3908" />
                <rect fill="white" height="2.29057" rx="0.381762" width="0.763525" x="2.17792" y="21.3908" />
                <rect fill="white" height="4.58115" rx="0.381762" width="0.763525" x="19.3572" y="20.2455" />
                <rect fill="white" height="6.48996" rx="0.381762" width="0.763525" x="21.266" y="19.482" />
                <rect fill="white" height="4.96291" rx="0.381762" width="0.763525" x="23.1748" y="20.2455" />
                <rect fill="white" height="6.48996" rx="0.381762" width="0.763525" x="25.0836" y="19.482" />
                <rect fill="white" height="4.96291" rx="0.381762" width="0.763525" x="26.9924" y="20.2455" />
                <rect fill="white" height="6.48996" rx="0.381762" width="0.763525" x="28.9012" y="19.482" />
                <rect fill="white" height="4.58115" rx="0.381762" width="0.763525" x="30.8101" y="20.2455" />
                <rect fill="white" height="6.1082" rx="0.381762" width="0.763525" x="32.7189" y="19.482" />
                <rect fill="white" height="4.58115" rx="0.381762" width="0.763525" x="34.6277" y="20.2455" />
                <rect fill="white" height="6.1082" rx="0.381762" width="0.763525" x="36.5365" y="19.482" />
                <rect fill="white" height="4.58115" rx="0.381762" width="0.763525" x="38.4453" y="20.2455" />
                <rect fill="white" height="2.29057" rx="0.381762" width="0.763525" x="40.3541" y="21.3908" />
                <rect fill="white" height="3.0541" rx="0.381762" width="0.763525" x="42.2629" y="21.0091" />
            </g>
            <defs>
                <clipPath id="clip0_5114_8425">
                    <rect fill="white" height="44.3117" rx="22.1558" width="44.3117" x="0.159973" y="0.344147" />
                </clipPath>
            </defs>
        </svg>
    );
}
