import type useWebSocket from "react-use-websocket";

interface GaiaSuggestion {
    message: string;
    messageId: string;
}
export interface GaiaResponseBody {
    sessionGroupId?: string;
    sessionId: string;
    suggestion?: GaiaSuggestion;
}

export interface GaiaWebSocketResponse {
    body: unknown;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any;
    correlationId: string;
    name: GaiaWebSocketEventType;
    timestamp: number;
}

export const GaiaWebSocketEvent = {
    AuthorizationSuccess: "authorization-success",
    UnauthorizedConnection: "unauthorized-connection",
    SessionSubscriptionSuccess: "session-subscription-success",
    MessageSuggestion: "message-suggestion",
    OrchestratorInvoke: "orchestrator-invoke",
    OrchestratorInvokeSuccess: "orchestrator-invoke-success",
    OrchestratorInvokeError: "orchestrator-invoke-error",
    SessionUnsubscriptionSuccess: "session-unsubscription-success",
    Subscribe: "subscribe",
    Unsubscribe: "unsubscribe",
    Authorize: "authorize",
} as const;

export type GaiaWebSocketEventType = (typeof GaiaWebSocketEvent)[keyof typeof GaiaWebSocketEvent];

export type SendJsonMessage = ReturnType<typeof useWebSocket>["sendJsonMessage"];

export interface GaiaSubscription {
    sessionId: string;
    sendJsonMessage: SendJsonMessage;
    callSid?: string;
    timeout?: number;
}

export interface GaiaWebsocketContext {
    sendJsonMessage: SendJsonMessage;
    loading: boolean;
    subscribeSessionToGaia: ({ sessionId, sendJsonMessage, callSid, timeout }: GaiaSubscription) => Promise<void>;
    unsubscribeSessionFromGaia: (sessionId: string, sendJsonMessage: SendJsonMessage) => void;
}

export type NullableGaiaWebsocketContext = GaiaWebsocketContext | null;
