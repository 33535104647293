import React, { useState } from "react";
import { Button, Container, Flex, Table, Text, TextInput, Title } from "@mantine/core";
import { useQueryClient } from "@tanstack/react-query";
import { useSetupSsoToken } from "../auth";
import { useTrafficControl } from "../hooks";
import { PercentageInput } from "../components/admin";

export default function Admin(): React.JSX.Element {
    useSetupSsoToken();
    const { data, isFetching } = useTrafficControl();
    const [filterValue, setFilterValue] = useState<string>("");
    const queryClient = useQueryClient();

    return (
        <Flex h="100%" direction="column" ta="center">
            <Title mt="2rem">WFM Traffic Management</Title>
            <Flex justify="center" mt="3rem">
                <TextInput
                    mr="3rem"
                    mt="0.25rem"
                    value={filterValue}
                    onChange={(event) => setFilterValue(event.currentTarget.value)}
                    placeholder="Filter"
                />
                <Button mr="3rem" onClick={() => setFilterValue("")}>
                    Clear
                </Button>
                <Button
                    loading={isFetching}
                    onClick={async () =>
                        await queryClient.invalidateQueries({ queryKey: ["traffic-control-all-queues"] })
                    }
                >
                    Refresh Data
                </Button>
            </Flex>
            <Container h="100%" mt="6rem">
                <Table striped highlightOnHover withTableBorder withColumnBorders highlightOnHoverColor="asurion.5">
                    <Table.Thead>
                        <Table.Tr>
                            <Table.Th ta="center">
                                <Text size="lg" fw={700}>
                                    Queue Name
                                </Text>
                            </Table.Th>
                            <Table.Th ta="center">
                                <Text size="lg" fw={700}>
                                    Queue SID
                                </Text>
                            </Table.Th>
                            <Table.Th ta="center">
                                <Text size="lg" fw={700}>
                                    Percent of Volume going to ExWo
                                </Text>
                            </Table.Th>
                        </Table.Tr>
                    </Table.Thead>
                    <Table.Tbody>
                        {data
                            ?.filter((q) => q.queueName.includes(filterValue) || q.queueSid.includes(filterValue))
                            .map((queue) => (
                                <Table.Tr key={queue.queueSid}>
                                    <Table.Td>{queue.queueName}</Table.Td>
                                    <Table.Td>{queue.queueSid}</Table.Td>
                                    <Table.Td>
                                        <PercentageInput queueInfo={queue} />
                                    </Table.Td>
                                </Table.Tr>
                            ))}
                    </Table.Tbody>
                </Table>
            </Container>
        </Flex>
    );
}
