import { getLogger } from "@expert/logging";
import { immerable } from "immer";
import type { Task } from "../agent/task";
import type { CallbackState, WrappingState } from "../agent/types";
import type { Session, SessionChangeReason, SessionKind, SessionMetadata } from "./types";

const logger = getLogger({
    module: "SessionInstance",
});

export class SessionInstance implements Pick<Session, keyof Session> {
    [immerable] = true;

    id: string;
    previousId: string | null;

    kind: SessionKind;
    startedTimestamp: number;
    endedTimestamp?: number;

    // Chronological history of tasks that have been active during this session
    tasks: Task[];
    private rehydratedTasks: Task[] = [];

    metadata: SessionMetadata;

    callbackState: CallbackState | null;
    wrappingState: WrappingState | null;

    /** The current active task for a session that is not completed. Otherwise undefined */
    public get currentTask() {
        const task = this.tasks.at(-1);
        // A  completed task is no longer active, and there is effectively no "current" task
        // This logic used to be downstream, but to keep it DRY, we're moving it here
        if (task && task.status === "completed") {
            return undefined;
        }

        return task;
    }

    constructor(sessionData: Omit<Session, "currentTask" | "toLog">) {
        this.id = sessionData.id;
        this.previousId = sessionData.previousId;
        this.kind = sessionData.kind;
        this.startedTimestamp = sessionData.startedTimestamp;
        this.endedTimestamp = sessionData.endedTimestamp;
        this.tasks = sessionData.tasks;

        this.metadata = sessionData.metadata;

        this.callbackState = sessionData.callbackState ?? null;
        this.wrappingState = sessionData.wrappingState ?? null;
    }

    // We hide the rehydrated tasks after rehydration and then restore them once the SDK is initialized
    // This was a design ask so we avoid exposing tasks to session consumers before we have synced state from the vendor
    hideRehydratedTasks() {
        this.rehydratedTasks = [...this.tasks];
        this.tasks.length = 0;
    }

    restoreHydratedTasks() {
        this.tasks = [...this.rehydratedTasks];
        this.rehydratedTasks.length = 0;
    }

    getTaskById(id: string) {
        return this.tasks.find((task) => task.id === id);
    }

    addTask(task: Task) {
        if (this.getTaskById(task.id)) throw new Error("Task already exists in session");

        this.tasks.push(task);
    }

    replaceTask(task: Task) {
        const index = this.tasks.findIndex((t) => t.id === task.id);
        if (index === -1) {
            throw new Error("Task not found in session");
        }

        this.tasks[index] = task;
    }

    endSession(reason: SessionChangeReason, finalTaskState?: Task) {
        if (finalTaskState && this.currentTask?.id && this.currentTask.id !== finalTaskState.id) {
            throw new Error("Task provided does not match active task");
        }

        // This _shouldn't_ happen, and is likely an error. However, all potential cases are not yet enumerated, so we don't throw here.
        if (reason === this.metadata.startReason) {
            logger.error({ sessionId: this.id, reason }, "Session end reason is the same as the start reason");
        }

        this.metadata.endReason = reason;
        this.endedTimestamp = Date.now();
        this.metadata.status = "ended";
        //TODO: THis may no longer be necessary if tasks in sessions are mutated directly?
        if (finalTaskState) {
            this.tasks[this.tasks.length - 1] = finalTaskState;
        }
    }

    /**
     * This function extracts from session simple details for logging.
     * It uses explicit properties name so we can destructure the object in the root level of the log record
     * @returns Session details for logging
     */
    public toLog(): Record<string, unknown> {
        return {
            sessionId: this.id,
            sessionKind: this.kind,
            sessionStartTime: this.startedTimestamp,
            sessionEndTime: this.endedTimestamp,
            sessionCallbackState: this.callbackState,
            sessionStartReason: this.metadata.startReason,
            sessionEndReason: this.metadata.endReason,
            sessionStatus: this.metadata.status,
        };
    }
}
