import dayjs from "dayjs";

export function getDurationText(from: dayjs.ConfigType, to: dayjs.ConfigType): string {
    const fromDateJs = dayjs(from);
    const toDateJs = dayjs(to);
    const months = toDateJs.diff(fromDateJs, "months");
    let monthString = ``;
    if (months < 12) {
        monthString = pluralize(months, "month");
    } else {
        monthString = pluralize(Math.floor(months / 12), "year");
    }
    return monthString;
}

function pluralize(value: number, str: string) {
    let responseString = str;
    if (value !== 1) {
        responseString = `${str}s`;
    }
    return `${value}+ ${responseString}`;
}
