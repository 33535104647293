import { type SVGProps } from "react";

export function Schedule(props: SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg fill="none" height="100%" viewBox="0 0 24 23" width="100%" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                clipRule="evenodd"
                d="M7.81166 9.66683C8.53243 8.94585 8.53243 7.77713 7.81166 7.05614L5.85363 5.09719C5.50736 4.75078 5.03763 4.55615 4.54782 4.55615C4.05802 4.55615 3.58829 4.75078 3.24201 5.09719L2.16838 6.17175C1.24053 7.10262 1.09474 8.55743 1.81942 9.65391C4.98192 14.4125 9.06188 18.4925 13.8205 21.655C14.9176 22.3805 16.3736 22.2342 17.3045 21.3051L18.3791 20.2315C18.7255 19.8852 18.9201 19.4155 18.9201 18.9257C18.9201 18.4359 18.7255 17.9661 18.3791 17.6199L16.4201 15.6637C16.0739 15.3173 15.6041 15.1226 15.1143 15.1226C14.6245 15.1226 14.1548 15.3173 13.8085 15.6637L13.1558 16.3164C10.9663 14.515 8.95666 12.5054 7.15529 10.3158L7.81166 9.66683Z"
                fillRule="evenodd"
                stroke={props.stroke ?? "white"}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
            />
            <path
                clipRule="evenodd"
                d="M17.0487 11.9617C20.1078 11.9617 22.5877 9.48183 22.5877 6.42275C22.5877 3.36367 20.1078 0.883789 17.0487 0.883789C13.9896 0.883789 11.5098 3.36367 11.5098 6.42275C11.5098 9.48183 13.9896 11.9617 17.0487 11.9617Z"
                fillRule="evenodd"
                stroke={props.stroke ?? "white"}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
            />
            <path
                d="M18.8951 6.4228H17.0488V3.65332"
                stroke={props.stroke ?? "white"}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
            />
        </svg>
    );
}
