import type { SVGProps } from "react";

export function ArrowRectangleDown(props: SVGProps<SVGSVGElement>) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" {...props}>
            <path
                stroke="var(--mantine-color-gray-1)"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M12 6.155 8.236 9.92a.333.333 0 0 1-.471 0L4 6.155"
            />
        </svg>
    );
}
