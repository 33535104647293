import { useAnalytics } from "@expert/analytics";
import { GlobalError, GlobalLoader, useToast } from "@expert/common-ui";
import { ExpertWorkspaceWebSocketProvider } from "@expert/exwo-websocket";
import { GaiaProvider } from "@expert/gaia";
import { getLogger } from "@expert/logging";
import { PlatformFeaturesInitializer } from "@expert/platform-features";
import { AgentSdkProvider } from "@expert/sdk";
import { RenderError, environment, errorEventBus, userCache } from "@expert/shared-utils";
import { Flex } from "@mantine/core";
import { getFeature } from "@soluto-private/expert-workspace-feature-flagging";
import React, { useEffect, useState } from "react";
import { withErrorBoundary } from "react-error-boundary";
import { useAccessToken } from "../auth";
import { env } from "../../config";
import { Workspace } from "../components";
import { createAgentSDK, useSdkAppConfig } from "../utils";
import { setupSessionLifecycleManager } from "../utils/setupSessionLifecycleManager";

setupSessionLifecycleManager();
const logger = getLogger({ module: "Home" });

const WorkspaceHandled = withErrorBoundary(Workspace, { FallbackComponent: RenderError });
export default function Home(): React.JSX.Element {
    const toast = useToast();

    const [isCheckingHdxEnablement, setIsCheckingHdxEnablement] = useState<boolean>(true);
    const [hdxEnabled, setHdxEnabled] = useState<boolean>(false);

    useEffect(() => {
        const errorUnsub = errorEventBus.on("error-occurred", (error) => {
            logger.error({ err: error }, "Error running loading action:");

            if (error instanceof Error) {
                toast.error(`An unexpected error occurred: ${error.message}`);
            } else {
                toast.error("An unexpected error occurred");
            }
        });

        const errorBoundaryUnsub = errorEventBus.on("error-boundary-hit", (error) => {
            logger.error({ err: error }, "Error Boundary caught an error:");
        });

        return () => {
            errorUnsub();
            errorBoundaryUnsub();
        };
    }, [toast]);

    const { dispatcher } = useAnalytics();
    const [tokenLoading, tokenError, token] = useAccessToken();

    useEffect(() => {
        if (tokenLoading) {
            return;
        }

        const fetchHdxFeature = async () => {
            const isHDXEnabled = await getFeature<boolean>("EWP-hdxEnabled", {
                environment,
                employeeId: userCache.employeeId,
            });

            setHdxEnabled(isHDXEnabled?.valueOf() ?? false);
            setIsCheckingHdxEnablement(false);
            logger.info(`(HDX): Feature flag enablement: ${isHDXEnabled?.valueOf()}`);
        };

        void fetchHdxFeature();
    }, [tokenLoading]);

    // TODO: Remove Twilio from here
    const { loading: appConfigLoading, error: appConfigError, appConfig } = useSdkAppConfig();

    if (!tokenError && tokenLoading) {
        void dispatcher.dispatchBusinessEvent("Authenticating");
        return <GlobalLoader msg="Acquiring access token..." />;
    }

    if (isCheckingHdxEnablement) {
        return <GlobalLoader msg="Checking if experimental features should be enabled..." />;
    }

    if (tokenError) {
        logger.error({ err: tokenError }, "Error acquiring access token");
        return (
            <GlobalError msg="Error creating Agent SDK:">
                <>
                    <p>{tokenError.message}</p>
                    <p>{tokenError.stack}</p>
                </>
            </GlobalError>
        );
    }

    if (!appConfigError && appConfigLoading) {
        void dispatcher.dispatchAction("LoadingConfiguration", "LoadingConfiguration");
        return <GlobalLoader msg="Loading configuration..." />;
    }

    if (appConfigError) {
        logger.error({ err: appConfigError }, "Error loading configuration");
        return (
            <GlobalError msg="Error loading configuration">
                <>
                    <p>{appConfigError.message}</p>
                    <p>{appConfigError.stack}</p>
                </>
            </GlobalError>
        );
    }

    const { ssoAccessToken, identity } = userCache;
    if (!ssoAccessToken) return <h1>DEBUG: NO token</h1>;
    if (!identity) return <h1>DEBUG: NO identity</h1>;

    const { employeeId, userPrincipalName } = userCache;

    return (
        <Flex flex={1} direction="column" h="100%" w="100%">
            <PlatformFeaturesInitializer employeeId={employeeId ?? userPrincipalName} mode={env.mode}>
                {/* TODO: Move relevant providers up once we have several pages */}
                <AgentSdkProvider
                    // @ts-expect-error using interfaces rather than classes as types might help fix
                    agentSdkCreator={createAgentSDK}
                    employeeNum={employeeId}
                    token={token}
                    hdxEnabled={hdxEnabled}
                    appConfig={appConfig}
                >
                    {/* TODO: Get client from the worker attributes maybe? */}
                    <ExpertWorkspaceWebSocketProvider identity={identity} token={ssoAccessToken}>
                        <GaiaProvider
                            client="verizon"
                            identity={identity}
                            token={ssoAccessToken}
                            url={env.gaiaWebsocketUrl}
                        >
                            <WorkspaceHandled />
                        </GaiaProvider>
                    </ExpertWorkspaceWebSocketProvider>
                </AgentSdkProvider>
            </PlatformFeaturesInitializer>
        </Flex>
    );
}
