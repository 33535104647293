import { useShallow } from "zustand/react/shallow";
import type { SessionStore } from "./session.store";
import { useSessionStore } from "./session.store";
import type { Session } from "./types";

export function getActiveSession() {
    return useSessionStore.getState().computed.currentSession() as unknown as Session;
}

export function getSessionId() {
    return useSessionStore.getState().computed.currentSession().id;
}

export function getPreviousSessionId() {
    return useSessionStore.getState().computed.previousSession()?.id;
}

export function getSessionIdHistory(n?: number) {
    const history = getSessionHistory().map((item) => {
        return {
            sessionId: item.id,
            reason: item.metadata.startReason,
            timestamp: item.startedTimestamp,
        };
    });

    if (n && n < history.length) {
        const trimmedHistory = history.slice(Math.max(history.length - n, 0));
        return [...trimmedHistory];
    }

    return [...history];
}

export function getSessionHistory() {
    return useSessionStore.getState().sessions as unknown as Session[];
}

// TODO: This exists just to supply the UI with the direct access to what it should not be accessing directly.
// The UI logic that uses this should be moved to the SDK
export function useSetCallbackState() {
    return useSessionStore(useShallow((state) => state.setCallbackState));
}

/** @deprecated this doesn't seem to be called anywhere and may not work as intended */
export function useClearCallbackState() {
    return useSessionStore(useShallow((state) => state.clearCallbackState));
}

// TODO: This exists just to supply the UI with the direct access to what it should not be accessing directly.
// The UI logic that uses this should be moved to the SDK
export const setCallbackState = (...args: Parameters<SessionStore["setCallbackState"]>) => {
    useSessionStore.getState().setCallbackState(...args);
};

export const clearCallbackState = () => {
    useSessionStore.getState().clearCallbackState();
};
