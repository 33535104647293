import * as React from "react";
import { type SVGProps } from "react";

export function Exit(props: SVGProps<SVGSVGElement>) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" fill="none" {...props}>
            <path
                fill={props.fill ?? "#6E767D"}
                fillRule="evenodd"
                d="M1.399 1.232A2.5 2.5 0 0 1 3.167.5H9A2.5 2.5 0 0 1 11.5 3v1.667a.833.833 0 0 1-1.667 0V3A.833.833 0 0 0 9 2.167H3.167A.833.833 0 0 0 2.333 3v10a.833.833 0 0 0 .834.833H9A.834.834 0 0 0 9.833 13v-1.667a.833.833 0 0 1 1.667 0V13A2.5 2.5 0 0 1 9 15.5H3.167a2.5 2.5 0 0 1-2.5-2.5V3a2.5 2.5 0 0 1 .732-1.768ZM13.41 4.911a.833.833 0 0 1 1.178 0l2.5 2.5a.833.833 0 0 1 0 1.178l-2.5 2.5a.833.833 0 0 1-1.178-1.178l1.077-1.078H4.833a.833.833 0 1 1 0-1.666h9.655l-1.077-1.078a.833.833 0 0 1 0-1.178Z"
                clipRule="evenodd"
            />
        </svg>
    );
}
