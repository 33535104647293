import type { GaiaWebSocketResponse } from "@expert/gaia";
import { gaiaWsEventBus } from "@expert/gaia";
import { getLogger } from "@expert/logging";
import { useEffect } from "react";
import { type ReactiveMessagePayload } from "@expert/messages";
import { addErrorMessage, handleIncomingMessage } from "../messaging";
import type { GaiaMessageSuggestion, OrchestratorErrorBody, OrchestratorErrorMessage } from "../types";
import { isProactiveErrorMessage, isProactiveMessage } from "./validators";

const logger = getLogger({ module: "useGaiaMessageEvents", tag: "solve" });

export const useGaiaMessageEvents = () => {
    useEffect(() => {
        const gaiaEventBusMessageSuccess = gaiaWsEventBus.on(
            "gaia_ws_orchestrator-invoke-success",
            ({ body }: GaiaWebSocketResponse) => {
                handleIncomingMessage(body as ReactiveMessagePayload);
            },
        );

        const gaiaEventBusProactiveMessageReceived = gaiaWsEventBus.on(
            "gaia_ws_message-suggestion",
            ({ body }: GaiaWebSocketResponse) => {
                //eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
                const gaiaSuggestion = body as unknown as GaiaMessageSuggestion;

                // All messages are coming through this event (reactive messages + proactive messages)
                if (isProactiveMessage(gaiaSuggestion)) {
                    if (isProactiveErrorMessage(gaiaSuggestion)) {
                        logger
                            .child({
                                sessionId: gaiaSuggestion.sessionGroupId,
                            })
                            .error(
                                {
                                    originalError: gaiaSuggestion.suggestion.message,
                                },
                                "GAIA websocket proactive error message response",
                            );
                    } else handleIncomingMessage(gaiaSuggestion);
                }
            },
        );

        const gaiaEventBusOrchestratorFailure = gaiaWsEventBus.on("gaia_ws_orchestrator-invoke-error", (response) => {
            addErrorMessage("botFailure");
            const { message, body } = response as unknown as OrchestratorErrorMessage;
            const { sessionGroupId } = (JSON.parse(body) ?? {}) as unknown as OrchestratorErrorBody;
            logger
                .child({
                    sessionId: sessionGroupId,
                })
                .error(
                    {
                        originalError: message,
                    },
                    "GAIA websocket error message response",
                );
        });

        return () => {
            gaiaEventBusMessageSuccess();
            gaiaEventBusOrchestratorFailure();
            gaiaEventBusProactiveMessageReceived();
        };
    }, []);
};
