/*
 * Codes should be between 6 and 9 characters (inclusive)
 * CS asked to explicitly ban 123456789
 * CS also asked to explicitly ban all 0s, 1s, 2s, etc
 */
export const isValidConfirmationCode = (code: string): boolean => {
    return !(
        code.length < 6 ||
        code.length > 9 ||
        code === "123456789" ||
        code.split("").every((c) => c === code.at(0))
    );
};
