export function InfoIcon() {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.9998 5.33317C10.1088 5.33317 5.33317 10.1088 5.33317 15.9998C5.33317 21.8909 10.1088 26.6665 15.9998 26.6665C21.8909 26.6665 26.6665 21.8909 26.6665 15.9998C26.6665 10.1088 21.8909 5.33317 15.9998 5.33317ZM2.6665 15.9998C2.6665 8.63604 8.63604 2.6665 15.9998 2.6665C23.3636 2.6665 29.3332 8.63604 29.3332 15.9998C29.3332 23.3636 23.3636 29.3332 15.9998 29.3332C8.63604 29.3332 2.6665 23.3636 2.6665 15.9998ZM14.6665 10.6665C14.6665 9.93012 15.2635 9.33317 15.9998 9.33317H16.0132C16.7496 9.33317 17.3465 9.93012 17.3465 10.6665C17.3465 11.4029 16.7496 11.9998 16.0132 11.9998H15.9998C15.2635 11.9998 14.6665 11.4029 14.6665 10.6665ZM13.3332 15.9998C13.3332 15.2635 13.9301 14.6665 14.6665 14.6665H15.9998C16.7362 14.6665 17.3332 15.2635 17.3332 15.9998V19.9998C18.0696 19.9998 18.6665 20.5968 18.6665 21.3332C18.6665 22.0696 18.0696 22.6665 17.3332 22.6665H15.9998C15.2635 22.6665 14.6665 22.0696 14.6665 21.3332V17.3332C13.9301 17.3332 13.3332 16.7362 13.3332 15.9998Z"
                fill="#E6E6EB"
            />
        </svg>
    );
}
