import { Card, ScrollArea, Stack, Text } from "@mantine/core";
import { errorEventBus } from "./hooks";

interface RenderErrorProps {
    error?: Error;
}
export function RenderError({ error }: RenderErrorProps) {
    if (error) {
        errorEventBus.emit("error-boundary-hit", error);
        return (
            <Stack align="center">
                <Card maw="60vw" padding="md">
                    <Card.Section inheritPadding py="xs" withBorder>
                        <h1>Something went wrong</h1>
                        <h3>{error.message}</h3>
                    </Card.Section>
                    <Card.Section inheritPadding mt="sm">
                        <ScrollArea>
                            <Text c="blue.4" component="pre">
                                {error.stack}
                            </Text>
                        </ScrollArea>
                    </Card.Section>
                </Card>
            </Stack>
        );
    }
}
