import { Container, Flex, Stack, Text } from "@mantine/core";
import Player from "react-lottie-player";
import { useEffect, useState } from "react";
import { useAnalytics } from "@expert/analytics";
import { useCurrentStepStore, useGuidedFlowStreamhubStore, useHomeProductSessionStore } from "../../stores";
import { CheckMarkIcon, IneligibleIcon } from "../Icons";
import waiting from "../lotties/waiting.json";
import classes from "../../../SalesFlow.module.css";
import { StepOneFooter } from "./StepOneFooter";
import styles from "./StepOneContent.module.css";

interface ProductData {
    productType: string;
    detected: boolean;
}

export function StepOneContent() {
    const initialData: ProductData[] = [
        { productType: "iPad", detected: false },
        { productType: "Laptops", detected: false },
        { productType: "TVs", detected: false },
        { productType: "Gaming consoles", detected: false },
    ];
    const products = useHomeProductSessionStore((state) => state.products);
    const [receivedData, setReceivedData] = useState<ProductData[]>(initialData);
    const deviceEvents = useGuidedFlowStreamhubStore((state) => state.salesAssistDevicesEvents);
    const updateStep = useCurrentStepStore((state) => state.updateStep);
    const { dispatcher } = useAnalytics();

    useEffect(() => {
        if (deviceEvents.length <= 0) return;
        const updatedData: ProductData[] = [];
        const latestEvent = deviceEvents[0];
        for (let i = 0; i < 4; i++) {
            const message = latestEvent.body[i]?.message;
            updatedData[i] = message ? { productType: message, detected: true } : initialData[i];
        }
        //TODO: Add analytics for devices updated
        setReceivedData(updatedData);
        if (latestEvent.body.length >= 2) {
            void dispatcher.dispatchBusinessEvent("DeviceDetectionAutoAdvance_GuidedFlow");
            const timer1 = setTimeout(() => {
                updateStep("SuggestedOfferStep");
            }, 2000);
            return () => {
                clearTimeout(timer1);
            };
        }
        return undefined;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deviceEvents]);

    if (!products) {
        return (
            <Flex className={classes.loadingContainer} data-testid="failure">
                <Container>{IneligibleIcon}</Container>
                <Text className={classes.statusBodyHeader}>We had an issue checking eligibility.</Text>
                <Text className={classes.statusBodyText} data-testid="sale-view-status-body">
                    Try refreshing the page.
                </Text>
            </Flex>
        );
    }
    const homeProduct = products[0];

    return (
        <Stack gap={0} className={styles.devicesSection}>
            <Flex className={styles.devicesList}>
                <Text className={styles.listTitle} size="lg" c="white">
                    Find out what kind of{" "}
                    <Text className={styles.boldGreenText} span>
                        home electronics
                    </Text>{" "}
                    they have
                </Text>

                {receivedData.map((entry) => (
                    <Flex
                        mb="0.75rem"
                        className={`${styles.labelBase} ${
                            entry.detected ? styles.labelDetected : styles.labelUndetected
                        }`}
                        key={entry.productType}
                    >
                        {entry.detected ? (
                            <CheckMarkIcon />
                        ) : (
                            <div className={styles.playerBox}>
                                <Player play speed={1} loop animationData={waiting} />
                            </div>
                        )}{" "}
                        {entry.productType}
                        {entry.detected ? "" : "?"}
                    </Flex>
                ))}
            </Flex>
            <StepOneFooter productSku={homeProduct.productSku} />
        </Stack>
    );
}
