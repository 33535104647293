import type { SVGProps } from "react";

export function ArrowRectangleUp(props: SVGProps<SVGSVGElement>) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" {...props}>
            <path
                stroke="var(--mantine-color-gray-1)"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="m4 10.155 3.764-3.764c.13-.13.341-.13.471 0L12 10.155"
            />
        </svg>
    );
}
