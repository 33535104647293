import { useContext } from "react";
import { AgentSdkContext } from "../AgentSdkProvider";

export const useAgentSdk = () => {
    const agentSdk = useContext(AgentSdkContext);
    if (!agentSdk) {
        throw new Error("useAgentSdkContext has to be used within AgentSdkProvider");
    }
    return agentSdk;
};
