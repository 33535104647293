import { Flex, Text } from "@mantine/core";
import Player from "react-lottie-player";
import type { BadgeType } from "../../types";
import { capitalize } from "../../utils";
import { badgeMap } from "../badgeBar";
import classes from "./revealScreen.module.css";

interface RevealScreenProps {
    badgeType: BadgeType;
}

export function RevealScreen({ badgeType }: RevealScreenProps): JSX.Element {
    const { upperLimit, animationData } = badgeMap[badgeType];
    return (
        <Flex className={classes.revealWrapper}>
            <Player play speed={1 / 2} loop={false} animationData={animationData} className={classes.player} />
            <Text fw={700}>{`That’s ${upperLimit} offers today.`}</Text>
            <Text>{`${capitalize(badgeType)} unlocked!`}</Text>
        </Flex>
    );
}
