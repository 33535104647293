import { reactAnalytics } from "@soluto-private/eventualize-react";
import type { FeedbackPayload } from "./types";
import { env } from "./environment";

export const sendFeedback = async ({ ssoAccessToken, ...payload }: Omit<FeedbackPayload, "caller">) => {
    await reactAnalytics.dispatcher.dispatchBusinessEvent("SubmitFeedback", {
        feedbackCategory: payload.category,
        feedbackText: payload.text,
    });
    await fetch(env.expertFeedbackUrl, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${ssoAccessToken}`,
        },
        body: JSON.stringify(payload),
    });
};
