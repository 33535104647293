import type { EligibilityStatus, SaleProgress } from "@expert/sales-tools/src/home-product/types";

export interface OfferRateMessage {
    offered: boolean;
}

export interface OfferRateEventPayload {
    body: { message: string | OfferRateMessage; format?: string }[];
    context: "offer-rate";
    heading?: string;
    template?: string;
}

export const BADGE_TYPES = ["bronze", "silver", "gold"] as const;
export type BadgeType = (typeof BADGE_TYPES)[number];
export interface StatisticsPayload {
    dailyChallenge: {
        offerCount: number;
    };
    badges: {
        bronze: number;
        silver: number;
        gold: number;
    };
}

export interface ConfirmedProduct {
    id: string;
    productSku: string;
    status: EligibilityStatus;
    saleProgress: SaleProgress;
    isConfirmationCodeRequired: boolean;
}

export const BADGE_BREAKPOINT = 3;
