import type { ExpertAssistMessage } from "../shared-types";
import { useExpertAssistStore } from "./timelineStore";
import { type FailureReason, failureMessages } from "./failureMessages";

/**
 * This is intended to add regular message to the timeline
 * Please use `addFailureMessage` for specific failures
 */
export const addMessage = (message: ExpertAssistMessage) => {
    useExpertAssistStore.getState().addMessage(message);
};

export const addMessages = (messages: ExpertAssistMessage[]) => {
    useExpertAssistStore.getState().addMessages(messages);
};

/**
 * This is intended to add failure message to the timeline
 * Return the ExpertAssistMessage for the added message
 */
export const addFailureMessage = (failure: FailureReason): ExpertAssistMessage => {
    const message = {
        ...failureMessages[failure],
        id: `botFailureMessage_${crypto.randomUUID()}`,
    };
    useExpertAssistStore.getState().addMessage(message);
    return message;
};

export const getLastMessage = () => {
    const messages = useExpertAssistStore.getState().messages;
    return messages.length > 0 ? messages[messages.length - 1] : undefined;
};
