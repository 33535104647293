import { hashId } from "@expert/shared-utils";
import { getOrCreateContentfulClient } from "../getOrCreateContentfulClient";

/*
 * Takes in a sessionID to deterministically fetch an avatar.
 * As long as the sessionID persists on refresh, the same avatar will be tied to the same customer
 */
export const getAvatar = async (sessionId: string): Promise<string> => {
    const contentfulClient = getOrCreateContentfulClient();
    const assets = await contentfulClient.getAssets({ "metadata.tags.sys.id[in]": ["exWoAvatar"] });
    const hashed = await hashId(sessionId, assets.total);
    return assets.items[hashed - 1].fields.file?.url ?? "/assets/avatar-1.webp";
};
