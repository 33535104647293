import { getLogger } from "@expert/logging";
import type { StatisticsPayload } from "../types";
import { achievementsApi } from "./api";

const logger = getLogger({ module: "PostCallInsights" });
export const getStatistics = async (): Promise<StatisticsPayload> => {
    try {
        return await achievementsApi.get("v1/statistics").json<StatisticsPayload>();
    } catch (e) {
        logger.error(e, "Error fetching stats from db");
        return {} as StatisticsPayload;
    }
};
