import { Badge, Flex, Text } from "@mantine/core";
import { useVerifiedCustomerStore } from "@expert/customer-info";
import classes from "./CustomerPlanInfo.module.css";
import { getDurationText } from "./utils";

export function CustomerPlanInfo(): JSX.Element {
    const [customer] = useVerifiedCustomerStore((state) => [state.customer]);

    let lengthOfTime;
    if (customer?.purchaseDate) {
        lengthOfTime = getDurationText(customer.purchaseDate, new Date());
    }
    return (
        <Flex className={classes.section}>
            <Text c="dark.1" size="sm" pl="sm">
                Mobile protection customer
            </Text>
            <Badge autoContrast color="var(--mantine-color-dark-3)" size="sm" radius={2} className={classes.badge}>
                <Text size="xs" className={classes.text}>
                    {lengthOfTime}
                </Text>
            </Badge>
        </Flex>
    );
}
