import { NumberInput } from "@mantine/core";
import { useEffect } from "react";
import { useCallbacksFormStore } from "../../../callbacks.store";
import {
    DEFAULT_CALLBACK_NOW_DELAY,
    MAX_CALLBACK_NOW_DELAY,
    MIN_CALLBACK_NOW_DELAY,
    MIN_CALLBACK_STEP,
} from "../../../types";
import classes from "./inputs.module.css";

export function SecondsInput({ disabled }: { disabled: boolean }) {
    const { selectedCallbackNowDelay, setSelectedCallbackNowDelay } = useCallbacksFormStore();

    function handleChange(value: string | number) {
        if (Number.isNaN(value)) {
            return;
        }
        const newValue = Number(value);
        // this if is a workaround for the step value going to 6-11-16 instead of 5-10-15 when you hit the lower bound and go back up
        if (
            (selectedCallbackNowDelay === MIN_CALLBACK_NOW_DELAY &&
                newValue === MIN_CALLBACK_STEP + MIN_CALLBACK_NOW_DELAY) ||
            (selectedCallbackNowDelay === MAX_CALLBACK_NOW_DELAY &&
                newValue === MAX_CALLBACK_NOW_DELAY - MIN_CALLBACK_STEP)
        ) {
            // using modulus here supports changing the min, max and step values.
            setSelectedCallbackNowDelay(newValue - (newValue % MIN_CALLBACK_STEP));
        } else {
            setSelectedCallbackNowDelay(newValue);
        }
    }

    function setDefaultWhenEmpty() {
        if (!selectedCallbackNowDelay) {
            setSelectedCallbackNowDelay(DEFAULT_CALLBACK_NOW_DELAY);
        }
    }

    useEffect(
        setDefaultWhenEmpty,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    return (
        <NumberInput
            clampBehavior="blur"
            data-testid="call-controls-callback-delay"
            disabled={disabled}
            onBlur={setDefaultWhenEmpty}
            stepHoldDelay={100}
            step={MIN_CALLBACK_STEP}
            max={MAX_CALLBACK_NOW_DELAY}
            min={MIN_CALLBACK_NOW_DELAY}
            onChange={handleChange}
            size="sm"
            value={selectedCallbackNowDelay || undefined}
            className={classes.seconds}
        />
    );
}
