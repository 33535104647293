import { useQuery } from "@tanstack/react-query";
import type { EligibilityRequestV2 } from "../types";
import { getEligibilityV2 } from "./eligibilityV2";

export const useEligibilityV2 = (request: EligibilityRequestV2, isEnabled = true) => {
    const { data, isLoading, isError } = useQuery({
        queryKey: [`sales-eligibility-v2`, request.customerMdn, request.sessionId],
        queryFn: async () =>
            getEligibilityV2({
                ...request,
            }),
        staleTime: Infinity,
        gcTime: Infinity,
        retry: 1,
        enabled: isEnabled,
    });
    return { data, isLoading, isError };
};
