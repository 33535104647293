import { GlobalLoader } from "@expert/common-ui";
import { createContext, useEffect, useState } from "react";
import { getLogger } from "@expert/logging";
import { initPlatformFeature } from "./platformFeaturesCache";
import type { PlatformFeatures } from "./types";

export const PlatformFeaturesContext = createContext<PlatformFeatures | null>(null);

interface PlatformFeaturesProviderProps {
    employeeId: string | null;
    mode: string;
}

export function PlatformFeaturesInitializer({
    employeeId,
    mode,
    children,
}: React.PropsWithChildren<PlatformFeaturesProviderProps>) {
    const [loading, setLoading] = useState(true);
    const logger = getLogger({
        module: "PlatformFeaturesProvider",
    });
    useEffect(() => {
        void (async () => {
            if (!employeeId) return;
            try {
                await initPlatformFeature({
                    environment: mode,
                    employeeId,
                });
            } catch (err: unknown) {
                logger.error({ err }, "Unable to fetch platform features, using default");
            } finally {
                setLoading(false);
            }
        })();
    }, [employeeId, mode, logger]);

    if (loading) {
        return <GlobalLoader msg="Fetching platform feature flags" />;
    }

    return <>{children}</>;
}
