import { Text } from "@mantine/core";

type ProperNameProps = {
    name?: string;
} & Partial<React.HTMLAttributes<HTMLSpanElement>>;

export function ProperName({ name, className, style }: ProperNameProps): JSX.Element {
    const formattedName = name?.toLowerCase();

    return (
        <Text
            className={className}
            data-testid="test-proper-name"
            span
            style={{ textTransform: "capitalize", ...style }}
        >
            {formattedName}
        </Text>
    );
}
