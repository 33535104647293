import { getSessionId } from "@expert/sdk";
import { api } from "@expert/shared-utils";
import type { QueueTrafficInfo } from "../models";

export const updateQueueVolume = async (updatedQueues: QueueTrafficInfo[]): Promise<unknown> => {
    return api.post(`api/traffic-control/v1/queues`, {
        body: JSON.stringify(updatedQueues),
        headers: {
            "x-ewp-correlation-id": getSessionId(),
        },
    });
};
