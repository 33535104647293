import { create } from "zustand";
import { devtools, persist, subscribeWithSelector } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import type { Logger } from "@expert/logging";
import type { ExpertAssistMessage, Features, SendMessageType } from "../shared-types";
import { defaultMessages } from "./defaultMessages";

export interface ExpertAssistStore {
    callSid?: string;
    setCallSid: (callSid?: string, logger?: Logger) => void;

    expertId: string;
    setExpertId: (expertId: string, logger: Logger) => void;

    messages: ExpertAssistMessage[];
    addMessage: (message: ExpertAssistMessage) => void;
    addMessages: (messages: ExpertAssistMessage[]) => void;
    resetMessages: (messages: ExpertAssistMessage[]) => void;

    partner: string;
    setPartner: (partner: string, logger: Logger) => void;

    sessionId: string;
    setSessionId: (sessionId: string, logger: Logger) => void;

    sendMessage: SendMessageType;
    setSendMessage: (sendMessage: SendMessageType) => void;

    features: Features;
    setFeatures: (features: Features) => void;

    shouldAutoOpenToolbox: boolean;
    setShouldAutoOpenToolbox: (val: boolean) => void;
}

export const useExpertAssistStore = create<ExpertAssistStore>()(
    subscribeWithSelector(
        immer(
            devtools(
                persist(
                    (set) => ({
                        messages: [defaultMessages.firstOpen],
                        addMessage: (message: ExpertAssistMessage) => {
                            set(
                                (state) => {
                                    state.messages = [...state.messages, message];
                                },
                                false,
                                "addMessage",
                            );
                        },
                        addMessages: (messages: ExpertAssistMessage[]) => {
                            set(
                                (state) => {
                                    state.messages = [...state.messages, ...messages];
                                },
                                false,
                                "addMessages",
                            );
                        },
                        resetMessages: (messages: ExpertAssistMessage[]) => {
                            set(
                                (state) => {
                                    state.messages = [...messages];
                                },
                                false,
                                "resetMessages",
                            );
                        },
                        expertId: "",
                        setExpertId: (expertId: string, logger?: Logger) => {
                            set(
                                (state) => {
                                    logger?.info({ expertId }, "Timeline Setup | expertId");
                                    state.expertId = expertId;
                                },
                                false,
                                "setExpertId",
                            );
                        },
                        callSid: undefined,
                        setCallSid: (callSid?: string, logger?: Logger) => {
                            set(
                                (state) => {
                                    logger?.info({ callSid }, "Expert Assist Timeline Setup | callSid");
                                    state.callSid = callSid;
                                },
                                false,
                                "setCallSid",
                            );
                        },
                        partner: "",
                        setPartner: (partner: string, logger?: Logger) => {
                            set(
                                (state) => {
                                    logger?.info({ partner }, "Expert Assist Timeline Setup | partner");
                                    state.partner = partner;
                                },
                                false,
                                "setPartner",
                            );
                        },
                        sessionId: "",
                        setSessionId: (sessionId: string, logger?: Logger) => {
                            set(
                                (state) => {
                                    logger?.info({ sessionId }, "Expert Assist Timeline Setup | sessionId");
                                    state.sessionId = sessionId;
                                },
                                false,
                                "setSessionId",
                            );
                        },
                        sendMessage: () => {
                            console.warn("No implementation of send logger");
                        },
                        setSendMessage: (sendMessage: SendMessageType) => {
                            set(
                                (state) => {
                                    state.sendMessage = sendMessage;
                                },
                                false,
                                "setSendMessage",
                            );
                        },
                        features: {
                            isProactiveEnabled: false,
                            isFormattingEnabled: false,
                            isOzmoToolEnabled: false,
                            isOzmoMessagingEnabled: false,
                            isMultiModalEnabled: false,
                        },
                        setFeatures: (features: Features) => {
                            set(
                                (state) => {
                                    state.features = features;
                                },
                                false,
                                "setFeatures",
                            );
                        },
                        shouldAutoOpenToolbox: true,
                        setShouldAutoOpenToolbox: (val: boolean) => {
                            set((state) => {
                                state.shouldAutoOpenToolbox = val;
                            });
                        },
                    }),
                    {
                        name: "timelineStore",
                        partialize: (state: ExpertAssistStore) => ({
                            shouldAutoOpenToolbox: state.shouldAutoOpenToolbox,
                        }),
                    },
                ),
                { enabled: import.meta.env.MODE !== "production", store: "expert-assist", name: "timeline" },
            ),
        ),
    ),
);
