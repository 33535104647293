import { immer } from "zustand/middleware/immer";
import { createWithEqualityFn } from "zustand/traditional";
import { persist } from "zustand/middleware";
import type { Customer } from "../api";

export interface CustomerState {
    customer?: Customer;
    isIvrVerified: boolean;
    setCustomer: (customer?: Partial<Customer & { mdn: string }>) => void;
    authorizedUsers?: Customer[];
    setAuthorizedUsers: (value: Customer[] | undefined) => void;
    resetAuthorizedUsers: () => void;
    resetCustomer: () => void;
    reset: () => void;
    setIsIvrVerified: (value: boolean) => void;
}

export const useVerifiedCustomerStore = createWithEqualityFn<CustomerState>()(
    persist(
        immer((set) => ({
            customer: undefined,
            isIvrVerified: true,
            authorizedUsers: undefined,
            setCustomer: (customer?: Partial<Customer & { mdn: string }>) => {
                set(() => ({ customer }));
            },
            setAuthorizedUsers: (authorizedUsers: Customer[] | undefined) => {
                set(() => ({ authorizedUsers }));
            },
            resetAuthorizedUsers: () => {
                set(() => ({ authorizedUsers: [] }));
            },
            resetCustomer: () => {
                set(() => ({ customer: undefined }));
            },
            reset: () => {
                set(() => ({ authorizedUsers: [] }));
                set(() => ({ customer: undefined }));
                set(() => ({ isIvrVerified: true }));
            },
            setIsIvrVerified: (isIvrVerified: boolean) => {
                set((state) => {
                    state.isIvrVerified = isIvrVerified;
                });
            },
        })),
        { name: "verified-customer-store", skipHydration: true },
    ),
);
