import { create } from "zustand";
import { persist } from "zustand/middleware";
import type { SalesSteps } from "../components/types";

export interface CurrentStepStore {
    step: SalesSteps;
    updateStep: (step: SalesSteps) => void;
    resetCurrentStepStore: () => void;
}

export const useCurrentStepStore = create<CurrentStepStore>()(
    persist(
        (set) => ({
            step: "DeviceCollectionStep",
            updateStep: (step: SalesSteps) => {
                set(() => ({ step }));
            },
            resetCurrentStepStore: () => {
                set(() => ({ step: "DeviceCollectionStep" }));
            },
        }),
        { name: "guided-flow-step-store", skipHydration: true },
    ),
);
