import type { TaskStatus } from "@expert/sdk";
import { Task } from "@expert/sdk";
import type { CaseDetails } from "@expert/shared-types";
import { immerable } from "immer";
import type { Reservation } from "twilio-taskrouter";
import type { TwilioTaskAttributes } from "./models";
import type { ReservationStatus, TaskChannelType } from "./types";

export function getTaskConfig(reservation: Reservation): TwilioTaskConfig {
    const taskConfig: TwilioTaskConfig = {
        taskType: reservation.task.taskChannelUniqueName as TaskChannelType,
        isOnHold: !!reservation.task.transfers.incoming, // TODO: We need more complex treatment here.
        reservation,
    };
    return taskConfig;
}

const TASK_STATUS_MAP: Record<ReservationStatus, TaskStatus> = {
    pending: "pending",
    accepted: "assigned",
    rejected: "cancelled",
    timeout: "cancelled",
    canceled: "cancelled",
    rescinded: "cancelled",
    completed: "completed",
    wrapping: "wrapping",
} as const;

export interface TwilioTaskConfig {
    taskType: TaskChannelType;
    isOnHold: boolean;
    reservation: Reservation;
}

export abstract class TwilioTask extends Task {
    [immerable] = true;

    constructor(
        name: string,
        public readonly config: TwilioTaskConfig,
    ) {
        super(config.taskType, config.reservation.task.sid, name, config.reservation.dateCreated);

        this.setStatus(config);
    }

    // --- NOTE: These methods must be implemented by a sub-class ---

    public abstract get partner(): string;

    public abstract get hasCustomer(): boolean;

    public abstract get sessionId(): string | undefined;

    public abstract get previousTaskId(): string | undefined;

    // --- ------------------------------------------------------- ---

    // TODO: Nasty way to have session from the very beginning
    public get sessionIdFallback(): string {
        return (this.config.reservation.task.attributes as TwilioTaskAttributes).sessionIdFallback;
    }

    public static getTaskName(config: TwilioTaskConfig): string {
        return `${config.taskType}-${config.reservation.task.sid}`;
    }

    public get assignedAt(): Date | undefined {
        return this.config.reservation.status === "accepted" ? this.config.reservation.dateUpdated : undefined;
    }

    public get caseDetails(): CaseDetails | undefined {
        const attributes = this.config.reservation.task.attributes as TwilioTaskAttributes;
        return attributes.case_details;
    }

    public async updateCaseDetails(caseDetailsUpdates: Partial<CaseDetails>): Promise<void> {
        const latestTask = await this.config.reservation.task.fetchLatestVersion();
        const attributes = latestTask.attributes as TwilioTaskAttributes;
        await this.config.reservation.task.setAttributes({
            ...attributes,
            case_details: {
                ...attributes.case_details,
                ...caseDetailsUpdates,
            },
        });
    }

    private setStatus(config: TwilioTaskConfig) {
        let status = TASK_STATUS_MAP[this.config.reservation.status];

        if (config.reservation.task.status === "wrapping" || config.reservation.task.status === "completed") {
            status = config.reservation.task.status;
        }
        this.status = status;
    }
}
