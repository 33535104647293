import { SERVICE_PROVIDER } from "@expert/shared-types";
import { customCleanEnv, str, url } from "envalid";

interface Env {
    accessTokenGeneratorPath: string;
    oidcSsoUrl: string;
    oidcSsoClientId: string;
    oidcSsoReturnUri: string;
}

export const env = customCleanEnv(
    import.meta.env,
    {
        VITE_OIDC_SSO_URL: url(),
        VITE_OIDC_SSO_CLIENT_ID: str(),
        VITE_OIDC_SSO_RETURN_URI: url(),
    },
    (inputEnv): Env => ({
        accessTokenGeneratorPath: `api/service-provider/generate-access-token/${SERVICE_PROVIDER}`.toLowerCase(),
        oidcSsoUrl: inputEnv.VITE_OIDC_SSO_URL,
        oidcSsoClientId: inputEnv.VITE_OIDC_SSO_CLIENT_ID,
        oidcSsoReturnUri: inputEnv.VITE_OIDC_SSO_RETURN_URI,
    }),
);
