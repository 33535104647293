export const GLOBAL_CODE_LENGTH = 1;
export const PREFIX_LENGTH = 3;
export const TFN_LENGTH = 10;
export const US = "+1";
export const VDN_LENGTH = 7;
export const VDN_PREFIX = "555"; //All VDN's are prefixed by 555
export const VDN_PREFIX_LENGTH = 3;

export const MDN_MASK = "+{1} (000) 000-0000";
export const PHONE_MAX_LENGTH = MDN_MASK.length - 2;

export interface Contact {
    name: string;
    number: string;
    isInternal: boolean;
    /* Internal numbers are SIP's that can only
    be accessed through the asurion network */
}

export const TRANSFER_OPTIONS = [
    { group: "VHDP", number: "1820158", name: "VHDP Support English", isInternal: true },
    { group: "VHDP", number: "1820159", name: "VHDP Support Spanish", isInternal: true },
    { group: "VHDP", number: "1820481", name: "VHDP Saves Desk English", isInternal: true },
    { group: "VHDP", number: "1820482", name: "VHDP Saves Desk Spanish", isInternal: true },
    { group: "VHDP", number: "1820144", name: "VHDP Spanish", isInternal: true },
    { group: "VHDP", number: "1820150", name: "Verizon Home Device Protect English", isInternal: true },
    { group: "VHDP", number: "1820144", name: "Connect Home+ Support", isInternal: true },
    { group: "VHDP", number: "1820153", name: "Connected Home+ Support", isInternal: true },

    { group: "5G", number: "1820147", name: "5G Support English", isInternal: true },
    { group: "5G", number: "1820148", name: "5G Support Spanish", isInternal: true },
    { group: "5G", number: "1820426", name: "Verizon 5G Home Internet Support", isInternal: true },

    { group: "Claims / Warranty", number: "1820169", name: "Verizon Handset Claims English", isInternal: true },
    { group: "Claims / Warranty", number: "1820171", name: "Verizon Handset Claims Spanish", isInternal: true },
    {
        group: "Claims / Warranty",
        number: "1820269",
        name: "Device Replacement Warranty English (DRW)",
        isInternal: true,
    },
    {
        group: "Claims / Warranty",
        number: "1820273",
        name: "Device Replacement Warranty Spanish (DRW)",
        isInternal: true,
    },

    { group: "Tech Support", number: "8662717730", name: "Tier 2", isInternal: false },
    { group: "Tech Support", number: "8662991974", name: "Asurion Internal IT", isInternal: false },
    { group: "Tech Support", number: "1820126", name: "Tech Coach Spanish", isInternal: true },
    { group: "Tech Support", number: "1820124", name: "Verizon Tech Coach English", isInternal: true },
    { group: "Tech Support", number: "1825368", name: "Tech Lead", isInternal: true },
    { group: "Tech Support", number: "1825369", name: "CST", isInternal: true },

    { group: "Customer Service", number: "8009220204", name: "Verizon Customer Service", isInternal: false },
];

export function formatTransferNumber(number: string): string {
    // Check the length of the number to determine the formatting
    if (number.length === 10) {
        // Format 10-digit TFN numbers as (XXX) XXX-XXXX
        return `(${number.slice(0, 3)}) ${number.slice(3, 6)}-${number.slice(6)}`;
    } else if (number.length === 7) {
        // Format 7-digit VDN numbers as (XXX) XXXX
        return `(${number.slice(0, 3)}) ${number.slice(3)}`;
    }
    // Return number if it does not meet expected length
    return number;
}

export const GROUPED_TRANSFER_OPTIONS_STRINGIFIED = Array.from(
    TRANSFER_OPTIONS.reduce((index, { group, name }) => {
        const item = name;
        // Group contacts with matching categories
        if (!index.has(group)) {
            index.set(group, []);
        }
        index.get(group)?.push(item);
        return index;
    }, new Map<string, string[]>()),
    ([group, items]) => ({ group, items: items.sort() }), // Alphabetize items
);

export const PhoneNumberFormattedMask = "(###) ###-####";
