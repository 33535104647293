import { getLogger } from "@expert/logging";
import { Notification, Stack, Text } from "@mantine/core";
import { createContext, useContext } from "react";
import { useIcon } from "../toast/hooks";
import type { ToastType } from "../toast/types";
import classes from "./GlobalNotification.module.css";
import { useGlobalNotificationStore } from "./store";
import type { GlobalNotification } from "./types";

export interface ToastContext {
    id: string;
}

interface GlobalNotificationContextValue {
    info: (message: string, opts?: Partial<Omit<GlobalNotification, "id" | "done" | "fadeOutAnim">>) => ToastContext;
    success: (message: string, opts?: Partial<Omit<GlobalNotification, "id" | "done" | "fadeOutAnim">>) => ToastContext;
    error: (message: string, opts?: Partial<Omit<GlobalNotification, "id" | "done" | "fadeOutAnim">>) => ToastContext;
    show: (props: Omit<GlobalNotification, "id">) => ToastContext;
    clearNotification: (context: ToastContext) => void;
}

const logger = getLogger({
    module: "GlobalToastProvider",
});

export const useGlobalToastCss = (type: ToastType, fadeOut: boolean) => {
    if (fadeOut) {
        switch (type) {
            case "success":
                return classes.successFadeOut;
            case "error":
                return classes.errorFadeOut;
            case "info":
                return classes.infoFadeOut;
            default:
                return classes.infoFadeOut;
        }
    }

    switch (type) {
        case "success":
            return classes.success;
        case "error":
            return classes.error;
        case "info":
            return classes.info;
        default:
            return classes.info;
    }
};

function GlobalToastElement({ config }: { config: GlobalNotification }): JSX.Element {
    const Icon = useIcon(config.type);
    const css = useGlobalToastCss(config.type, config.fadeOutAnim);

    // auto close toast after delay if it's not persistent.
    // If it is persistent, then the caller will have to manually clear the notification.
    if (!config.persistent) {
        setTimeout(config.done, config.delay);
    }

    return (
        <Notification
            classNames={{
                root: css,
                icon: classes.icon,
                description: classes.description,
                title: classes.title,
                closeButton: classes.closeButton,
            }}
            icon={<Icon />}
            onClose={config.done}
            title={config.title}
            withCloseButton={!config.persistent}
        >
            <Text style={{ marginTop: "5px" }} size="sm">
                {config.message}
            </Text>
        </Notification>
    );
}

function GlobalNotificationContainer({ notifications }: { notifications: GlobalNotification[] }): JSX.Element {
    if (notifications.length > 0) {
        return (
            <Stack classNames={{ root: classes.globalToastContainer }}>
                <GlobalToastElement config={notifications[0]} key={notifications[0].id} />
            </Stack>
        );
    }

    return <Stack classNames={{ root: classes.globalToastContainer }} />;
}

function notImplemented() {
    logger.warn("GlobalToast cannot be used outside of GlobalToastProvider!");
    return { id: "" };
}

function notImplementedNoReturn() {
    logger.warn("GlobalToast cannot be used outside of GlobalToastProvider!");
}

const GlobalNotificationContext = createContext<GlobalNotificationContextValue>({
    show: notImplemented,
    info: notImplemented,
    success: notImplemented,
    error: notImplemented,
    clearNotification: notImplementedNoReturn,
});

export function GlobalNotificationProvider({ children }: { children: React.ReactNode }): JSX.Element {
    const { notifications, addNotification, removeNotification } = useGlobalNotificationStore();

    const context = {
        show: addNotification,
        info: (message: string, opts?: Partial<Omit<GlobalNotification, "id" | "done" | "fadeOutAnim">>) => {
            return addNotification({
                message,
                type: "info",
                persistent: false,
                fadeOutAnim: false,
                ...opts,
            });
        },
        success: (message: string, opts?: Partial<Omit<GlobalNotification, "id" | "done" | "fadeOutAnim">>) => {
            return addNotification({
                message,
                type: "success",
                persistent: false,
                fadeOutAnim: false,
                ...opts,
            });
        },
        error: (message: string, opts?: Partial<Omit<GlobalNotification, "id" | "done" | "fadeOutAnim">>) => {
            return addNotification({
                message,
                type: "error",
                persistent: false,
                fadeOutAnim: false,
                ...opts,
            });
        },
        clearNotification: (toastContext: ToastContext) => {
            removeNotification(toastContext.id);
        },
    };

    return (
        <GlobalNotificationContext.Provider value={context}>
            {children}
            <GlobalNotificationContainer notifications={notifications} />
        </GlobalNotificationContext.Provider>
    );
}

export const useGlobalNotification = () => useContext(GlobalNotificationContext);
