import { CloseX } from "@expert/common-ui";
import {
    GROUPED_TRANSFER_OPTIONS_STRINGIFIED,
    MDN_MASK,
    PHONE_MAX_LENGTH,
    PhoneNumberFormattedMask,
    US,
} from "@expert/shared-types";
import { extractNumbers, stripCountryCode, tryGetByVdnName } from "@expert/shared-utils";
import { Autocomplete, Text } from "@mantine/core";
import type { ClipboardEvent } from "react";
import { useEffect, useState } from "react";
import { parsePhoneNumber } from "awesome-phonenumber";
import IMaskInput from "react-imask/input";
import { useTransfersStateStore } from "../../transfers.store";
import classes from "./PhoneNumberInput.module.css";

export function PhoneNumberInput() {
    const { setTransferTfn, transferTfn, transferVdn, setTransferVdn } = useTransfersStateStore();

    const [displayValue, setDisplayValue] = useState<string>("");
    const [validTransferInput, setValidTransferInput] = useState<boolean>(false);
    const [enforceMaxLength, setEnforceMaxLength] = useState<boolean>(false);

    useEffect(() => {
        if (!validTransferInput) {
            // Make sure vdn & tfn are null until a valid entry
            if (transferVdn) {
                setTransferVdn(null);
            }
            if (transferTfn) {
                setTransferTfn(null);
            }
        }
        // eslint-disable-next-line
    }, [validTransferInput, displayValue]);

    function isTfn(value: string): boolean {
        const { valid } = parsePhoneNumber(`${US}${value}`);
        return valid;
    }

    function isVdn(value: string): boolean {
        return !!tryGetByVdnName(value);
    }

    function handleChange(value: string): void {
        if (!value) {
            setValidTransferInput(false);
            setDisplayValue(value);
            return;
        }

        // Check for any non-phone number characters
        const isNotPhoneNumber = /[^0-9()+\- .]/.test(value);

        // Handle user entering a name (VDN)
        if (isNotPhoneNumber) {
            setEnforceMaxLength(false);
            if (isVdn(value)) {
                setValidTransferInput(true);
                setTransferVdn(tryGetByVdnName(value) ?? null);
            } else {
                setValidTransferInput(false);
            }
            setDisplayValue(value);
            return;
        }

        // Applies max length for number input
        setEnforceMaxLength(true);
        // Extract only up to 10 digits to handle phone number with +1 country code
        const digitsOnly = extractNumbers(stripCountryCode(value)).substring(0, 10);

        // Handle user entering a number (TFN)
        if (isTfn(digitsOnly)) {
            setValidTransferInput(true);
            setTransferTfn(digitsOnly);
        } else {
            setValidTransferInput(false);
        }
        setDisplayValue(digitsOnly);
    }
    function handleSubmit(_: string): void {
        setDisplayValue("");
        setValidTransferInput(false);
    }

    function handlePaste(event: ClipboardEvent<HTMLInputElement>): void {
        event.preventDefault();

        const pastedData: string = event.clipboardData.getData("text");

        handleChange(pastedData);
    }

    const clearInput = () => handleChange("");

    //TODO: use a label instead of a text component
    return (
        <>
            <Text fw={700} size="sm">
                Enter a phone number
            </Text>
            <Autocomplete
                renderRoot={({ ref, ...props }) => (
                    <IMaskInput
                        mask={[
                            {
                                mask: MDN_MASK,
                            },
                            {
                                mask: /^.*$/,
                            },
                        ]}
                        inputRef={ref as React.Ref<HTMLInputElement>}
                        maxLength={enforceMaxLength ? PHONE_MAX_LENGTH : undefined}
                        {...props}
                    />
                )}
                value={displayValue}
                data={GROUPED_TRANSFER_OPTIONS_STRINGIFIED}
                placeholder={PhoneNumberFormattedMask}
                type="tel"
                classNames={{
                    input: classes.autocompleteInput,
                    option: classes.autocompleteOption,
                    section: classes.autocompleteSection,
                }}
                size="md"
                maw="30rem"
                mb="xs"
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus
                onChange={handleChange}
                onOptionSubmit={handleSubmit}
                onPaste={handlePaste}
                rightSection={displayValue.length ? <CloseX /> : null}
                rightSectionPointerEvents="auto"
                rightSectionProps={{ onClick: clearInput, title: "Clear", className: classes.clearButton }}
                data-testid="autocomplete"
            />
        </>
    );
}
