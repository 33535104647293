import { PhoneNumber, VdnName } from "@expert/common-ui";
import { extractNumbers, stripCountryCode, tryGetByVdnNumber } from "@expert/shared-utils";

interface ParticipantInfoProps {
    mdn: string;
}

export function ParticipantLabel({ mdn }: ParticipantInfoProps): JSX.Element {
    const vdn = tryGetByVdnNumber(extractNumbers(stripCountryCode(mdn)));
    return vdn ? <VdnName vdn={vdn} /> : <PhoneNumber mdn={mdn} />;
}
