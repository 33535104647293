import { reactAnalytics } from "@soluto-private/eventualize-react";
import { trackFullStoryEvent } from "@expert/monitoring";
import { defaultMessages, getLastMessage, useExpertAssistStore } from "../state";

export interface SolveSessionProps {
    sessionId: string;
    callSid?: string;
    withCustomer: boolean;
}

export const solveSessionStarted = ({ sessionId, callSid, withCustomer }: SolveSessionProps) => {
    const { expertId, partner, features, addMessage } = useExpertAssistStore.getState();
    const lastMessage = getLastMessage();
    if (withCustomer && lastMessage?.type !== "DeviceInfo") {
        addMessage({ ...defaultMessages.inCallDeviceInfo, id: `deviceInfo_${crypto.randomUUID()}` });
    }

    const expertAssistType = features.isProactiveEnabled ? "Proactive" : "Reactive";
    void reactAnalytics.dispatcher
        .withIdentities({
            SessionId: sessionId,
            ExpertAssistSessionId: sessionId,
            ExpertId: expertId,
            ...(callSid && { CallSid: callSid }),
        })
        .withExtra({ tool: "expert-assist", partner, expertAssistType, withCustomer })
        .dispatchBusinessEvent("ExpertAssistSessionStarted");
    trackFullStoryEvent("ExpertAssistSessionStarted", { sessionId, callSid, partner, expertAssistType, withCustomer });
};

export const solveSessionClosed = ({ sessionId, callSid }: Omit<SolveSessionProps, "withCustomer">) => {
    const { expertId, partner, features } = useExpertAssistStore.getState();
    const expertAssistType = features.isProactiveEnabled ? "Proactive" : "Reactive";
    void reactAnalytics.dispatcher
        .withIdentities({
            SessionId: sessionId,
            ExpertAssistSessionId: sessionId,
            ExpertId: expertId,
            ...(callSid && { CallSid: callSid }),
        })
        .withExtra({ tool: "expert-assist", partner, expertAssistType })
        .dispatchBusinessEvent("ExpertAssistSessionClosed");
    trackFullStoryEvent("ExpertAssistSessionClosed", { sessionId, callSid, partner, expertAssistType });
};
