import dayjs, { extend as dayJsExtend } from "dayjs";
import duration from "dayjs/plugin/duration";
import { MS_IN_SECOND, SECONDS_IN_HOUR, SECONDS_IN_MIN } from "../constants";

dayJsExtend(duration);

/** Formats seconds into a human readable time string
 * @param appendUnit - Whether to append the unit to the formatted string (ie. sec or min)
 * @param format - The format to use (mm:ss default) see: https://day.js.org/docs/en/display/format
 */
export function formatTime(totalSeconds: number, appendUnit = true, format = "mm:ss"): string {
    let durationFormat = format;

    //! at 24 hours, the hours roll back over to 0 so the incorrect format is used unless we add "days, months and years" or "custom logic"
    if (totalSeconds >= SECONDS_IN_HOUR && !format.includes("H")) {
        durationFormat = "H:mm:ss";
    }

    let formatted = dayjs.duration(totalSeconds * MS_IN_SECOND).format(durationFormat);

    if (appendUnit) {
        let unitString = "sec";
        if (totalSeconds >= SECONDS_IN_HOUR) {
            unitString = "hr";
        } else if (totalSeconds >= SECONDS_IN_MIN) {
            unitString = "min";
        }
        formatted = `${formatted} ${unitString}`;
    }
    return formatted;
}
