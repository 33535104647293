import { useGaiaWebsocket } from "@expert/gaia";
import { getLogger } from "@expert/logging";
import { useReactAnalytics } from "@soluto-private/eventualize-react";
import type { ExpertAssistMessage, SendMessageProps } from "@soluto-private/expert-workspace-timeline";
import { addMessage } from "@soluto-private/expert-workspace-timeline";
import { type VoiceTask, fallbackPartner, useActiveTask, usePartner, useSessionId } from "@expert/sdk";
import { userCache } from "@expert/shared-utils";
import { trackFullStoryEvent } from "@expert/monitoring";
import { usePlatformExpertAssistStore } from "../store";
import type { OrchestratorExpertMessagePayload } from "../types";
import { addErrorMessage } from "./messaging";

const logger = getLogger({ module: "sendMessage", tag: "solve" });

export const useSendMessage = () => {
    const websocketObj = useGaiaWebsocket();

    const { subscriptionStatus } = usePlatformExpertAssistStore();
    const { dispatcher } = useReactAnalytics();
    const task = useActiveTask();
    // TODO(afd): Revisit this to properly handle nonvoice tasks and not unsafely cast this
    // eslint-disable-next-line
    const callSid = (task as VoiceTask)?.callSid;
    const sdkPartner = usePartner();
    const partner = sdkPartner && sdkPartner !== "unknown" ? sdkPartner : fallbackPartner;
    const expertId = userCache.employeeId ?? "";
    const sessionId = useSessionId();

    const loggerWithContext = logger.child({
        sessionId,
        callSid,
    });

    const sendMessage = ({ text, type }: SendMessageProps) => {
        const messageId = crypto.randomUUID();
        const correlationId = crypto.randomUUID();

        const payload: OrchestratorExpertMessagePayload = {
            action: "orchestrator-invoke",
            message: text,
            messageId,
            partner,
            sessionId: callSid ?? sessionId,
            sessionGroupId: sessionId,
            userId: expertId,
            userType: "expert",
            correlationId,
            appName: "expert-assist",
        };

        const message: ExpertAssistMessage = { id: messageId, text, type };
        addMessage(message);

        if (websocketObj) {
            if (subscriptionStatus[sessionId] === "subscribed") {
                websocketObj.sendJsonMessage(payload);
                void dispatcher.dispatchBusinessEvent("MessageSent", {
                    senderType: "expert",
                    messageType: message.type,
                    messageId: message.id,
                });
                loggerWithContext.info({ payload }, "Message sent to gaia");
                trackFullStoryEvent("ExpertAssistMessageSent", { sessionId, callSid });
            } else {
                void websocketObj.subscribeSessionToGaia({
                    sessionId,
                    sendJsonMessage: websocketObj.sendJsonMessage,
                    callSid,
                });
                addErrorMessage("connectionFailure");
                loggerWithContext.warn({ sessionId, callSid }, "Expert session not subscribed, subscribing now");
            }
        } else {
            addErrorMessage("connectionFailure");
            loggerWithContext.error({ payload, websocketObj }, "There is no websocket obj");
        }

        return messageId;
    };
    return sendMessage;
};
