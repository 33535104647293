import { Flex, Text } from "@mantine/core";
import { Badge } from "./Badge";

interface BadgeProps {
    gold: number;
    silver: number;
    bronze: number;
}
export function Badges({ gold, silver, bronze }: BadgeProps): JSX.Element {
    return (
        <Flex direction="column" pb="md" mt="md">
            <Text mb="sm" size="md" fw={700}>
                Badge history
            </Text>
            <Flex direction="row" justify="space-between" mr="md">
                <Badge badgeType="gold" badgeCount={gold} />
                <Badge badgeType="silver" badgeCount={silver} />
                <Badge badgeType="bronze" badgeCount={bronze} />
            </Flex>
        </Flex>
    );
}

export default Badges;
