import type { ExpertAssistMessage, Features } from "../../shared-types";
import { isFromBot } from "../../utils";
import { TimelineItem } from "../timeline-item";
import { generateTimelineItemFromType } from "./generateTimelineItemForType";

interface GenerateTimelineItemProps extends Features {
    messages: ExpertAssistMessage[];
    scrollRef: React.RefObject<HTMLDivElement>;
}

export function generateTimelineItems({ messages = [], scrollRef, ...rest }: GenerateTimelineItemProps) {
    const items: JSX.Element[] = [];

    const lastProactiveMessage =
        messages
            .slice()
            .reverse()
            .find((message) => ["Proactive", "DeviceInfo"].includes(message.type))?.id ?? -1;

    items.push(
        ...messages.map((msg, i) => (
            <TimelineItem
                isLastMessage={i === messages.length - 1}
                isLastProactiveMessage={msg.id === lastProactiveMessage}
                isLoading={false}
                key={`timeline-item-${msg.id}-${msg.type}`}
                messageId={msg.id}
                scrollRef={scrollRef}
                metaData={msg.metaData}
                type={msg.type}
            >
                {generateTimelineItemFromType(msg, rest)}
            </TimelineItem>
        )),
    );

    if (messages.length > 0) {
        const mostRecentMessage = messages[messages.length - 1];
        const isLoadingBotMessage = !isFromBot(mostRecentMessage.type);
        if (isLoadingBotMessage) {
            items.push(
                <TimelineItem
                    isLastMessage
                    isLastProactiveMessage
                    isLoading
                    key="LoadingBotMessage"
                    type="Proactive"
                />,
            );
        }
    }
    return items;
}
