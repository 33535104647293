import { Container, Flex, Space, Text } from "@mantine/core";
import { NotesStarIcon } from "../../../assets/NotesStarIcon";
import type { SessionSummaryMetadata } from "../../../shared-types";
import { MarkdownViewer } from "../../formatted-content";
import classes from "./SessionSummary.module.css";

export function generateSessionSummaryItem(metaData: SessionSummaryMetadata, isFormattingEnabled: boolean) {
    return (
        <Flex direction="row">
            <Container flex="2">
                <Text className={classes.sessionSummaryColumnHeader}>
                    <b>Summary</b>
                </Text>
                {isFormattingEnabled ? <MarkdownViewer content={metaData.summary} /> : metaData.summary}
            </Container>
            <Container flex="1">
                <Text className={classes.sessionSummaryColumnHeader}>
                    <b>Sell</b>
                </Text>
                {isFormattingEnabled ? <MarkdownViewer content={metaData.sell} /> : metaData.sell}
            </Container>
        </Flex>
    );
}

export function SessionSummaryHeader({ metaData }: { metaData?: SessionSummaryMetadata }) {
    return (
        <Flex direction="row" className={classes.timelineItemSessionSummaryHeader}>
            <NotesStarIcon />
            <Space pr="8px" />
            <Text className={classes.sessionSummaryHeader}>
                <b>
                    {metaData?.sessionDate.toLocaleDateString("en-us", {
                        month: "short",
                        day: "numeric",
                    })}
                </b>{" "}
                session notes
            </Text>
        </Flex>
    );
}
