import { getLogger } from "@expert/logging";
import { useSessionStore } from "../../sessions/session.store";
import type { ConferenceParticipant } from "../voice/conferenceTypes";
import { isVoiceTask } from "../voice/typeGuards";
import type { VoiceTask } from "../voice/voiceTask";
import type { ConferenceEvent, ConferenceParticipantEventBase } from "./conferenceEventTypes";

const logger = getLogger({ module: "conferenceEventHandler" });

const isAgentEvent = (event: ConferenceParticipantEventBase, task: VoiceTask) =>
    event.participantCallId === task.agentCallId;
const isCustomerEvent = (event: ConferenceParticipantEventBase, task: VoiceTask) =>
    event.participantCallId === task.customerCallId;
const isConferenceParticipantEvent = (event: ConferenceParticipantEventBase, task: VoiceTask) =>
    !isAgentEvent(event, task) && !isCustomerEvent(event, task);

export function conferenceEventHandler(event: ConferenceEvent) {
    const localLogger = logger.child({
        action: "conferenceEventHandler",
        taskId: event.taskId,
        conferenceId: event.conferenceId,
        eventType: event.eventType,
    });
    localLogger.trace("Conference Events Handler | Execution started");
    const task = useSessionStore.getState().getTask(event.taskId);

    // We might receive conference events after the task was completed
    if (!task || !isVoiceTask(task)) {
        localLogger.debug(
            {
                taskId: event.taskId,
                conferenceId: event.conferenceId,
                eventType: event.eventType,
            },
            "Conference Events Handler | Receive conference event for the task that is not in the store or is not a voice task",
        );
        return;
    }
    const taskLogger = localLogger.child(task.toLog());

    // Conference participants joining before conference is started. We ignore this events
    if (!task.conferenceStarted) {
        taskLogger.debug("Conference Events Handler | Receive conference event before conferenceStarted flag is true");
        return;
    }

    if (event.eventType === "conference-start" || event.eventType === "conference-end") {
        taskLogger.debug("Conference Events Handler | Receive conference start/end event. Skipping...");
        return;
    }

    const taskParticipantLogger = taskLogger.child({ participantCallId: event.participantCallId });

    switch (event.eventType) {
        case "participant-join":
            if (isConferenceParticipantEvent(event, task)) {
                taskParticipantLogger.debug("Conference Events Handler | Adding new conference participant");
                useSessionStore.getState().addConferenceParticipant(event.taskId, event as ConferenceParticipant);
            }
            break;
        case "participant-leave":
            if (isCustomerEvent(event, task)) {
                taskParticipantLogger.debug("Conference Events Handler | Customer left the call");
                useSessionStore.getState().setCustomerLeftTheCall(event.taskId);
            } else if (isAgentEvent(event, task)) {
                // TODO: Clean agent state (mute). We need to move to use taskId as a parameter to store function before that
                // TODO: potentially support an expertLeft flag on task attributes for edge cases where the agent leaves the call
            } else {
                // Additional participant left
                taskParticipantLogger.debug("Conference Events Handler | Removing conference participant");
                useSessionStore.getState().removeConferenceParticipant(event.taskId, event.participantCallId);
            }

            break;
        case "participant-hold":
            if (isConferenceParticipantEvent(event, task)) {
                taskParticipantLogger.debug("Conference Events Handler | Updating conference participant - Hold");
                useSessionStore.getState().updateConferenceParticipant(event.taskId, event.participantCallId, event);
            }
            break;
        case "participant-unhold":
            if (isConferenceParticipantEvent(event, task)) {
                taskParticipantLogger.debug("Conference Events Handler | Updating conference participant - Resume");
                useSessionStore.getState().updateConferenceParticipant(event.taskId, event.participantCallId, event);
            }
            break;
        default:
            taskParticipantLogger.info(event, "===== OTHER CONFERENCE EVENT ====");
            break;
    }
    taskParticipantLogger.trace("Conference Events Handler | Execution ended");
}
