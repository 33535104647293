import { customCleanEnv, str } from "envalid";

interface Env {
    space: string;
    accessToken: string;
}

export const env = customCleanEnv(
    import.meta.env,
    {
        VITE_CONTENTFUL_SPACE: str(),
        VITE_CONTENTFUL_ACCESS_TOKEN: str(),
    },
    (inputEnv): Env => ({
        space: inputEnv.VITE_CONTENTFUL_SPACE,
        accessToken: inputEnv.VITE_CONTENTFUL_ACCESS_TOKEN,
    }),
);
