import type { Task, VoiceTask } from "@expert/sdk";

export interface TaskProps<TTask extends Task = Task> {
    task: TTask;
}

export type VoiceTaskProps = TaskProps<VoiceTask>;

/** The maximum number of participants that can be in a conference. not including the expert/agent */
export const MAX_CONFERENCE_PARTICIPANTS = 5;
