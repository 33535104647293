import { getSessionId } from "@expert/sdk";
import { getRootDispatcher } from "@expert/analytics";
import { getLogger } from "@expert/logging";
import { getFeature } from "@soluto-private/expert-workspace-feature-flagging";
import { environment, userCache } from "@expert/shared-utils";
import { useStatisticsStore } from "../stores";
import { determineNewBadge } from "../utils/badges";
import type { StatisticsPayload } from "../types";
import { achievementsApi } from "./api";

const logger = getLogger({ module: "PostCallInsights" });

let _isPostCallInsightsEnabled: boolean | undefined;

// TODO: This is temporary while we pilot/expand the offer challenge/post call insights feature
const checkIfPostCallInsightsEnabled = async () => {
    const result =
        _isPostCallInsightsEnabled ??
        (await getFeature("EWP-isPostCallInsightsEnabled", {
            environment,
            employeeId: userCache.employeeId,
        }));

    _isPostCallInsightsEnabled = result;

    return result;
};

export const saveOfferBehavior = async (offered: boolean) => {
    try {
        const isPostCallInsightsEnabled = await checkIfPostCallInsightsEnabled();

        // TODO: see note above, delete when pilot is complete
        if (!isPostCallInsightsEnabled) {
            logger.debug(`Post call insights feature is not enabled for this user ${userCache.employeeId}, skipping`);
            return;
        }

        void getRootDispatcher().dispatchAction("ResponseReceived", "OfferRate", { offered });

        if (!offered) {
            logger.debug("Offer not made, skipping");

            useStatisticsStore.getState().setNewOffer(false);
            useStatisticsStore.getState().setOfferStatusReceived(true);

            return;
        }

        const response = await achievementsApi.post("v1/behaviors", {
            json: { sessionId: getSessionId(), behaviorType: "offer" },
        });

        logger.debug(response, "saveOffer response");

        // Now that the server has responded successfully, increment the offer count and set the new
        // offer state to reflect that a new offer has been made + any badge changes

        // Get a deep copy of the current statistics state
        const statisticsStateCopy = JSON.parse(
            JSON.stringify(useStatisticsStore.getState().statisticState),
        ) as StatisticsPayload;

        const updatedOfferCount = statisticsStateCopy.dailyChallenge.offerCount + 1;
        const newBadge = determineNewBadge(updatedOfferCount);

        if (newBadge) {
            statisticsStateCopy.badges[newBadge] += 1;
        }

        statisticsStateCopy.dailyChallenge.offerCount = updatedOfferCount;

        useStatisticsStore.getState().setState(statisticsStateCopy);
        useStatisticsStore.getState().setNewOffer(true);
        useStatisticsStore.getState().setOfferStatusReceived(true);

        logger.debug(statisticsStateCopy, "updated statisticsState");
    } catch (e) {
        logger.error(e, "Error saving offer to behavior table");
    }
};
