import { gaiaWsEventBus } from "@expert/gaia";
import { getLogger } from "@expert/logging";
import { useEffect } from "react";
import { usePlatformExpertAssistStore } from "../store";

const logger = getLogger({ module: "useGaiaAuthEvents", tag: "solve" });

export const useGaiaAuthEvents = (sessionId: string, callSid?: string) => {
    const { setIsWsAuthenticated, isWsAuthenticated } = usePlatformExpertAssistStore();
    const loggerWithContext = logger.child({
        sessionId,
        callSid,
    });

    useEffect(() => {
        const gaiaEventBusAuthSuccess = gaiaWsEventBus.on("gaia_ws_authorization-success", () => {
            if (!isWsAuthenticated) {
                setIsWsAuthenticated(true);
                loggerWithContext.info("GAIA websocket authorized");
            }
        });
        const gaiaEventBusAuthFailure = gaiaWsEventBus.on("gaia_ws_unauthorized-connection", ({ data, timestamp }) => {
            setIsWsAuthenticated(false);
            loggerWithContext.error(
                { ...(data as unknown as object), responseTimestamp: timestamp },
                "GAIA websocket unauthorized",
            );
        });

        return () => {
            gaiaEventBusAuthSuccess();
            gaiaEventBusAuthFailure();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setIsWsAuthenticated, isWsAuthenticated]);
};
