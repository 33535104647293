import { useEffect, useMemo } from "react";
import { useFeatureFlag } from "@expert/shared-utils";
import { Container, Flex } from "@mantine/core";
import { PostCallInsights, useStatisticsStore } from "@expert/post-call-insights";
import { isVoiceTask, useMostRecentTask } from "@expert/sdk";
import { LoadingAnimation } from "@expert/sales-tools/src/home-product/components/Icons";
import { trackAppcuesEvent } from "@expert/monitoring";
import { CustomerVerificationFlowV3 } from "../customer-verification";
import classes from "./CustomerPanel.module.css";
import { CustomerProfile } from "./CustomerProfile";

export function CustomerPanel(): JSX.Element {
    const mostRecentTask = useMostRecentTask();
    const mostRecentVoiceTask = useMemo(() => {
        return isVoiceTask(mostRecentTask) ? mostRecentTask : null;
    }, [mostRecentTask]);
    const { data: isPostCallInsightsEnabled } = useFeatureFlag("EWP-isPostCallInsightsEnabled");
    const { data: blacklistedNumbers, isLoading: isLoadingBlacklistedNumbers } =
        useFeatureFlag<string[]>("EWP-isBlacklistedNumber");
    const [offerStatusReceived, setOfferStatusReceived, setNewOffer] = useStatisticsStore((state) => [
        state.offerStatusReceived,
        state.setOfferStatusReceived,
        state.setNewOffer,
    ]);

    // Reset the offer status received flag when the component mounts
    useEffect(() => {
        setNewOffer(false);
        setOfferStatusReceived(false);
        trackAppcuesEvent("CustomerPanelVisible", {});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (isLoadingBlacklistedNumbers) {
        return (
            <Container maw="160px">
                <LoadingAnimation />
            </Container>
        );
    }

    return (
        <Flex className={classes.customerPanel}>
            {isPostCallInsightsEnabled && offerStatusReceived && mostRecentTask?.status === "wrapping" ? (
                <PostCallInsights />
            ) : (
                <Flex className={classes.responsivePanel}>
                    <Flex className={classes.topSection}>
                        <CustomerProfile />
                    </Flex>
                    {mostRecentVoiceTask ? (
                        <CustomerVerificationFlowV3
                            mdnFromTask={
                                blacklistedNumbers?.includes(mostRecentVoiceTask.mdn) ? "" : mostRecentVoiceTask.mdn
                            }
                        />
                    ) : null}
                </Flex>
            )}
        </Flex>
    );
}
