import { getLogger } from "@expert/logging";
import { type VoiceTask, fallbackPartner, useActiveTask, usePartner, useSessionId } from "@expert/sdk";
import { useFeatureFlag, userCache } from "@expert/shared-utils";
import { AnalyticsProvider, useReactAnalytics } from "@soluto-private/eventualize-react";
import { Timeline } from "@soluto-private/expert-workspace-timeline";
import { useSendMessage, useSetupSubscriptions } from "./state";
import { features } from "./utils";

const logger = getLogger({
    rootModule: "PlatformTimeline",
    tag: "solve",
});

export function PlatformTimeline(): JSX.Element {
    useSetupSubscriptions();

    const task = useActiveTask();
    // TODO(afd): Revisit this to properly handle nonvoice tasks and not unsafely cast this
    // eslint-disable-next-line
    const callSid = (task as VoiceTask)?.callSid;
    const expertId = userCache.employeeId ?? "";
    const sessionId = useSessionId();
    const sdkPartner = usePartner();
    const partner = sdkPartner && sdkPartner !== "unknown" ? sdkPartner : fallbackPartner;

    const { data: isProactiveEnabled } = useFeatureFlag("isProactiveEnabled");
    const { data: isOzmoToolEnabled } = useFeatureFlag("isOzmoToolEnabled", { partner });
    const { data: isOzmoMessagingEnabled } = useFeatureFlag("isOzmoMessagingEnabled", { partner });
    const { data: isFormattingEnabled } = useFeatureFlag("isFormattingEnabled");

    const updatedFeatures = {
        ...features,
        isOzmoToolEnabled: isOzmoToolEnabled ?? features.isOzmoToolEnabled,
        isProactiveEnabled: isProactiveEnabled ?? features.isProactiveEnabled,
        isOzmoMessagingEnabled: isOzmoMessagingEnabled ?? features.isOzmoMessagingEnabled,
        isFormattingEnabled: isFormattingEnabled ?? features.isFormattingEnabled,
    };

    const sendMessage = useSendMessage();

    const { dispatcher } = useReactAnalytics();
    const enhancedDispatcher = dispatcher
        .withIdentities({
            ...(expertId && { ExpertId: expertId }),
            ...(callSid && { CallSid: callSid }),
        })
        .withExtra({ tool: "expert-assist" });

    return (
        <AnalyticsProvider dispatcher={enhancedDispatcher}>
            <Timeline
                agentId={expertId}
                callSid={callSid}
                features={updatedFeatures}
                sendMessage={sendMessage}
                sessionId={sessionId}
                partner={partner}
                logger={logger.child({
                    sessionId,
                    callSid,
                })}
            />
        </AnalyticsProvider>
    );
}
