import { GlobalStyles, GlobalThemeProvider } from "@expert/theme";
import { transformDispatcher } from "@expert/analytics";
import { GlobalNotificationProvider, ToastProvider } from "@expert/common-ui";
import { initAppcues, initDynatrace, initFullStory } from "@expert/monitoring";
import { RootAnalyticsProvider } from "@soluto-private/eventualize-react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { useEffect } from "react";
import { RouterProvider } from "react-router-dom";
import { env } from "../config";
import { router } from "./router";
import { enhanceAnalytics } from "./utils";

const queryClient = new QueryClient();

export default function App(): JSX.Element {
    useEffect(() => {
        transformDispatcher((dispatcher) => {
            return dispatcher.withEnhancer(enhanceAnalytics);
        });
        initFullStory({
            orgId: env.fullstoryOrgId,
            isDevMode: !env.isProdMode,
        });
        initDynatrace({
            isEnabled: env.dynatraceEnabled,
            appMonitoringUrl: env.dynatraceAppMonitoringUrl,
        });
        initAppcues({
            isEnabled: env.appcuesEnabled,
            url: env.appcuesUrl,
        });
    }, []);

    return (
        <GlobalThemeProvider>
            <GlobalStyles />
            <RootAnalyticsProvider>
                <QueryClientProvider client={queryClient}>
                    <GlobalNotificationProvider>
                        <ToastProvider>
                            <RouterProvider router={router} />
                        </ToastProvider>
                    </GlobalNotificationProvider>
                </QueryClientProvider>
            </RootAnalyticsProvider>
        </GlobalThemeProvider>
    );
}
