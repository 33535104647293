import type { SVGProps } from "react";

export function DeclinedIcon(props: SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg fill="none" height={26} width={26} xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                clipRule="evenodd"
                d="M13 24.5c6.351 0 11.5-5.149 11.5-11.5S19.351 1.5 13 1.5 1.5 6.649 1.5 13 6.649 24.5 13 24.5Z"
                stroke="var(--mantine-color-red-5)"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
            />
            <path
                d="m8 8 10 10M18 8 8 18"
                stroke="var(--mantine-color-red-5)"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
            />
        </svg>
    );
}
