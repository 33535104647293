import { useQuery } from "@tanstack/react-query";
import { getPlanInfo } from "../api";

export const usePlanInfo = (productSku: string) => {
    const { data, error } = useQuery({
        queryKey: [`plan-info-${productSku}`],
        queryFn: async () => await getPlanInfo(productSku),
    });
    return { planInfo: data, error };
};
