import { getLogger } from "@expert/logging";
import { useActiveVoiceTask, useAgentSdk } from "@expert/sdk";
import { useAsyncLoading } from "@expert/shared-utils";
import { Button } from "@mantine/core";
import { withClickAnalytics } from "@soluto-private/eventualize-react";
import { useEffect } from "react";
import { useControlsStore, withControlsLock } from "../controls.store";

const logger = getLogger({ module: "LeaveButton" });

export function LeaveCallButton(): JSX.Element {
    const agentSdk = useAgentSdk();
    const task = useActiveVoiceTask();
    const { controlLock, toggleConferencePanelOpen } = useControlsStore();
    const conferenceInProgress = task.conferenceStarted && task.conferenceParticipants.length === 0;

    const [isLoadingLeaveCall, leaveCall] = useAsyncLoading(
        withControlsLock(async () => {
            toggleConferencePanelOpen();
            // Ensure that everyone is off of hold before disengaging

            // Primary customer
            if (task.holdState.onHold) {
                await agentSdk.resumeCall(task);
            }

            if (!task.conferenceParticipants.length || !task.conferenceParticipants[0]) {
                logger.error(task.toLog(), "No conference participants found when leaving call");
                return;
            }

            // Additional participants
            const conferenceParticipant = task.conferenceParticipants[0]; // TODO: it works now, but we need to find participant by name or call id
            if (conferenceParticipant.participantName) {
                await agentSdk.updateParticipant(task, conferenceParticipant.participantName, {
                    endConferenceOnExit: false,
                    hold: false,
                });
            }
            await agentSdk.leaveConference(task);
        }),
    );

    const AnalyticButton = withClickAnalytics(Button, "Click");

    useEffect(() => {
        logger.info(
            { ...task.toLog(), isLoadingLeaveCall, controlLock, conferenceInProgress },
            "Leave button is disabled",
        );
    }, [conferenceInProgress, controlLock, isLoadingLeaveCall, task]);

    return (
        <AnalyticButton
            analytics={{
                actionId: "LeaveCallButton",
            }}
            aria-label="Leave Call"
            data-testid="call-controls-leave"
            disabled={isLoadingLeaveCall || task.status !== "assigned" || controlLock || conferenceInProgress}
            loading={isLoadingLeaveCall}
            onClick={leaveCall}
            radius="xl"
            size="sm"
            color="red.9"
        >
            Leave
        </AnalyticButton>
    );
}
