import { validatePhoneNumber } from "./validatePhoneNumber";

export function formatPhoneNumber(value: string): string {
    const { number, valid } = validatePhoneNumber(value);

    if (!valid || !number) {
        throw new Error(`Invalid phone number format, ${value}`);
    }

    // eslint-disable-next-line prefer-named-capture-group
    const match = /^\+(\d{1})(\d{3})(\d{3})(\d{4})$/.exec(number);

    if (!match) {
        throw new Error(`Invalid phone number format, no matches found, ${value}`);
    }

    const areaCode = match[2];
    const centralOfficeCode = match[3];
    const lineNumber = match[4];

    return `(${areaCode}) ${centralOfficeCode}-${lineNumber}`;
}

export function stripCountryCode(number: string, prefix = "+1"): string {
    if (!number.startsWith(prefix)) {
        return number;
    }

    return number.slice(prefix.length);
}

export const formattedPhoneWithoutSipCountryCode = (mdn: string) => {
    const strippedSip = formatSip(mdn);
    const strippedCountryCode = stripCountryCode(strippedSip);
    return formatPhoneNumber(strippedCountryCode);
};

export function formatSip(mdn: string) {
    const index = mdn.indexOf(":");
    if (index === -1) {
        return mdn;
    }
    const noSip = mdn.substring(index + 1);
    return noSip.substring(0, 12);
}
