import { getRootDispatcher } from "@expert/analytics";
import { getLogger } from "@expert/logging";
import { HTTPError } from "ky";
import type { Declination } from "../types";
import { salesApi } from "./api";

const logger = getLogger({
    module: "declination",
});

export const postDeclination = async (declination: Declination) => {
    try {
        const result = await salesApi.post("v1/declination", { json: declination }).json<boolean>();

        void getRootDispatcher().dispatchBusinessEvent("PostDeclination", {
            result,
            StatusOrigin: "ASP",
        });
        return result;
    } catch (err: unknown) {
        if (err instanceof HTTPError) {
            void getRootDispatcher().dispatchBusinessEvent("DeclinationError", {
                Status: "LAMBDA_ERROR",
                Error: err,
            });
        }
        logger.error({ err });
        return false;
    }
};
