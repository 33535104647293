import { type ToastContext } from "@expert/common-ui";
import { getLogger } from "@expert/logging";
import { create } from "zustand";
import { devtools } from "zustand/middleware";

interface ControlsState {
    // panel state
    dialpadOpen: boolean;
    scheduleCallbackPanelOpen: boolean;
    conferencePanelOpen: boolean;
    transferCallActive: boolean;

    // input state
    dialpadInput: string;
    isMuted: boolean;
    isConference: boolean;
    controlLock: boolean;

    //loading state
    loadingExistingCallback: boolean;

    // error states
    dialpadNotification: ToastContext | undefined;
}

interface ControlsActions {
    toggleDialpadActive: () => void;
    setDialpadNotification: (notification: ToastContext | undefined) => void;
    setDialpadInput: (input: string) => void;
    openCallbackPanel: () => void;
    closeCallbackPanel: () => void;
    toggleTransferActive: () => void;
    setLoadingExistingCallback: (loading: boolean) => void;
    toggleMute: (muted: boolean) => void;
    setIsConference: (isConference: boolean) => void;
    toggleConferencePanelOpen: () => void;
    setControlLock: (isLocked: boolean) => void;
    clearPanelsState: () => void;
    resetState: () => void;
}

interface ControlsStore extends ControlsState, ControlsActions {}

const defaultState: ControlsState = {
    dialpadOpen: false,
    dialpadInput: "",
    dialpadNotification: undefined,
    scheduleCallbackPanelOpen: false,
    transferCallActive: false,
    loadingExistingCallback: false,
    isMuted: false,
    isConference: false,
    conferencePanelOpen: false,
    controlLock: false,
};

const clearedControlState: Partial<ControlsState> = {
    scheduleCallbackPanelOpen: false,
    transferCallActive: false,
    conferencePanelOpen: false,
    dialpadOpen: false,
    dialpadInput: "",
};

export const useControlsStore = create<ControlsStore>()(
    devtools(
        (set) => ({
            ...defaultState,
            toggleDialpadActive: () => {
                set(
                    (state) => ({
                        transferCallActive: false,
                        scheduleCallbackPanelOpen: false,
                        conferencePanelOpen: false,
                        dialpadOpen: !state.dialpadOpen,
                    }),
                    false,
                    "toggleDialpadActive",
                );
            },
            setDialpadInput: (input: string) => {
                set({ dialpadInput: input }, false, "setDialpadInput");
            },
            setDialpadNotification: (notification: ToastContext | undefined) => {
                set({ dialpadNotification: notification }, false, "setDialpadNotification");
            },
            openCallbackPanel: () => {
                set(
                    {
                        ...clearedControlState,
                        scheduleCallbackPanelOpen: true,
                    },
                    false,
                    "openCallbackPanel",
                );
            },
            closeCallbackPanel: () => {
                set(
                    {
                        dialpadOpen: false,
                        transferCallActive: false,
                        scheduleCallbackPanelOpen: false,
                    },
                    false,
                    "closeCallbackPanel",
                );
            },
            toggleTransferActive: () => {
                set(
                    (state) => ({
                        dialpadOpen: false,
                        scheduleCallbackPanelOpen: false,
                        transferCallActive: !state.transferCallActive,
                    }),
                    false,
                    "toggleTransferActive",
                );
            },
            setLoadingExistingCallback: (loading: boolean) => {
                set(
                    {
                        loadingExistingCallback: loading,
                    },
                    false,
                    "setLoadingExistingCallback",
                );
            },
            toggleMute: () => {
                set(
                    (state) => ({
                        isMuted: !state.isMuted,
                    }),
                    false,
                    "toggleMute",
                );
            },
            setIsConference: (isConference: boolean) => {
                set({ isConference, conferencePanelOpen: isConference }, false, "setIsConference");
            },
            toggleConferencePanelOpen: () => {
                set(
                    (state) => ({
                        ...clearedControlState,
                        conferencePanelOpen: !state.conferencePanelOpen,
                    }),
                    false,
                    "toggleConferencePanelOpen",
                );
            },
            setControlLock: (isLocked: boolean) => {
                set({ controlLock: isLocked }, false, "setControlLock");
            },
            clearPanelsState: () => {
                set(clearedControlState, false, "clearPanelsState");
            },
            resetState: () => {
                set(defaultState, false, "resetState");
            },
        }),
        { enabled: import.meta.env.MODE !== "production", store: "call-controls", name: "workspace/call-controls" },
    ),
);

type HandlerFunctionVariadic = (...args: never[]) => Promise<void>;

const logger = getLogger({
    module: "controlsStore",
});

/** Control lock mixin to augment any calls with a control locking mechanism.
 * Think of this as the functional equivalent of an HOC. */
export function withControlsLock(action: HandlerFunctionVariadic) {
    return async () => {
        try {
            useControlsStore.getState().setControlLock(true);
            await action();
        } catch (err: unknown) {
            logger.error({ err }, "Error in call controls lock");
        } finally {
            useControlsStore.getState().setControlLock(false);
        }
    };
}
