import { Card, Flex, Progress, Text } from "@mantine/core";
import { useEffect, useState } from "react";
import { BADGE_BREAKPOINT, type BadgeType } from "../../types";
import classes from "./activeBar.module.css";
import { badgeMap } from "./badgeMap";

interface ActiveBarProps {
    offerCount: number;
    badgeType: BadgeType;
    newOffer?: boolean;
}

export function ActiveBar({ offerCount, badgeType, newOffer }: ActiveBarProps) {
    const [fill, setFill] = useState(0);
    const [transitionDuration, setTransitionDuration] = useState(0);
    const FILL_INCREMENT = 100 / BADGE_BREAKPOINT;

    const { upperLimit } = badgeMap[badgeType];

    useEffect(() => {
        const fillTo = offerCount === upperLimit ? 100 : (offerCount % BADGE_BREAKPOINT) * FILL_INCREMENT;

        if (newOffer) {
            setTransitionDuration(0);
            setFill(fillTo - FILL_INCREMENT);
            setTimeout(() => {
                setTransitionDuration(1900);
                setFill(fillTo);
            }, 100);
        } else {
            setTransitionDuration(0);
            setFill(fillTo);
        }
    }, [offerCount, upperLimit, newOffer, FILL_INCREMENT]);

    return (
        <Card className={classes.activeCard} withBorder>
            <Flex direction="row" justify="space-between">
                <Text size="sm" fw={700}>{`Unlock ${badgeType}`}</Text>
                <Text size="sm" fw={700}>{`${offerCount} of ${upperLimit} offers`}</Text>
            </Flex>
            <Progress
                classNames={{ root: classes.progressBarRoot, section: classes.progressBarFilledSection }}
                size="lg"
                radius="xl"
                value={fill}
                transitionDuration={transitionDuration}
            />
        </Card>
    );
}
