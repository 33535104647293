import type { SVGProps } from "react";

export function DialpadIcon(props: SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g id="Group">
                <g id="dial-pad">
                    <path
                        clipRule="evenodd"
                        d="M4.5 5.5C5.60457 5.5 6.5 4.60457 6.5 3.5C6.5 2.39543 5.60457 1.5 4.5 1.5C3.39543 1.5 2.5 2.39543 2.5 3.5C2.5 4.60457 3.39543 5.5 4.5 5.5Z"
                        fillRule="evenodd"
                        id="Oval"
                        stroke={props.stroke}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        clipRule="evenodd"
                        d="M11.969 5.5C13.0736 5.5 13.969 4.60457 13.969 3.5C13.969 2.39543 13.0736 1.5 11.969 1.5C10.8644 1.5 9.969 2.39543 9.969 3.5C9.969 4.60457 10.8644 5.5 11.969 5.5Z"
                        fillRule="evenodd"
                        id="Oval_2"
                        stroke={props.stroke}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        clipRule="evenodd"
                        d="M19.5 5.5C20.6046 5.5 21.5 4.60457 21.5 3.5C21.5 2.39543 20.6046 1.5 19.5 1.5C18.3954 1.5 17.5 2.39543 17.5 3.5C17.5 4.60457 18.3954 5.5 19.5 5.5Z"
                        fillRule="evenodd"
                        id="Oval_3"
                        stroke={props.stroke}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        clipRule="evenodd"
                        d="M4.5 11.5C5.60457 11.5 6.5 10.6046 6.5 9.5C6.5 8.39543 5.60457 7.5 4.5 7.5C3.39543 7.5 2.5 8.39543 2.5 9.5C2.5 10.6046 3.39543 11.5 4.5 11.5Z"
                        fillRule="evenodd"
                        id="Oval_4"
                        stroke={props.stroke}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        clipRule="evenodd"
                        d="M11.969 11.5C13.0736 11.5 13.969 10.6046 13.969 9.5C13.969 8.39543 13.0736 7.5 11.969 7.5C10.8644 7.5 9.969 8.39543 9.969 9.5C9.969 10.6046 10.8644 11.5 11.969 11.5Z"
                        fillRule="evenodd"
                        id="Oval_5"
                        stroke={props.stroke}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        clipRule="evenodd"
                        d="M19.5 11.5C20.6046 11.5 21.5 10.6046 21.5 9.5C21.5 8.39543 20.6046 7.5 19.5 7.5C18.3954 7.5 17.5 8.39543 17.5 9.5C17.5 10.6046 18.3954 11.5 19.5 11.5Z"
                        fillRule="evenodd"
                        id="Oval_6"
                        stroke={props.stroke}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        clipRule="evenodd"
                        d="M4.5 17.5C5.60457 17.5 6.5 16.6046 6.5 15.5C6.5 14.3954 5.60457 13.5 4.5 13.5C3.39543 13.5 2.5 14.3954 2.5 15.5C2.5 16.6046 3.39543 17.5 4.5 17.5Z"
                        fillRule="evenodd"
                        id="Oval_7"
                        stroke={props.stroke}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        clipRule="evenodd"
                        d="M11.969 17.5C13.0736 17.5 13.969 16.6046 13.969 15.5C13.969 14.3954 13.0736 13.5 11.969 13.5C10.8644 13.5 9.969 14.3954 9.969 15.5C9.969 16.6046 10.8644 17.5 11.969 17.5Z"
                        fillRule="evenodd"
                        id="Oval_8"
                        stroke={props.stroke}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        clipRule="evenodd"
                        d="M11.969 23.5C13.0736 23.5 13.969 22.6046 13.969 21.5C13.969 20.3954 13.0736 19.5 11.969 19.5C10.8644 19.5 9.969 20.3954 9.969 21.5C9.969 22.6046 10.8644 23.5 11.969 23.5Z"
                        fillRule="evenodd"
                        id="Oval_9"
                        stroke={props.stroke}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        clipRule="evenodd"
                        d="M19.5 17.5C20.6046 17.5 21.5 16.6046 21.5 15.5C21.5 14.3954 20.6046 13.5 19.5 13.5C18.3954 13.5 17.5 14.3954 17.5 15.5C17.5 16.6046 18.3954 17.5 19.5 17.5Z"
                        fillRule="evenodd"
                        id="Oval_10"
                        stroke={props.stroke}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </g>
            </g>
        </svg>
    );
}
