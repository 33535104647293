import styled from "@emotion/styled";
import type { IconButtonProps } from "@expert/common-ui";
import { Hangup, IconButton } from "@expert/common-ui";
import type { VoiceTask } from "@expert/sdk";
import { TASK_WRAPUP_REASON, useAgentSdk } from "@expert/sdk";
import { useAsyncLoading } from "@expert/shared-utils";
import { ThemeIcon } from "@mantine/core";
import { useMemo } from "react";
import type { TaskProps } from "../../types";
import { useControlsStore, withControlsLock } from "../controls.store";

//FIXME: convert to css modules and classnames
export const StyledIconBtn = styled(IconButton)<IconButtonProps>`
    border: none;
    background-color: ${(props) => (props.active ? "var(--mantine-color-gray-3)" : "var(--mantine-color-error-9)")};
    &:hover {
        background-color: ${(props) => (props.active ? "white" : "var(--mantine-color-red-6)")};
    }
    &:disabled {
        color: color-mix(in srgb, var(--mantine-color-dark-1), transparent 62%);
        background-color: color-mix(in srgb, var(--mantine-color-dark-1), transparent 90%);
    }
`;
export function HangupButton({ task }: TaskProps<VoiceTask>): JSX.Element {
    const agentSdk = useAgentSdk();
    const { controlLock } = useControlsStore();

    const anyParticipantOnHold = useMemo(() => {
        const conferenceParticipantIsOnHold =
            task.conferenceParticipants.find((participant) => participant.hold) !== undefined;
        const primaryParticipantIsOnHold = task.holdState.onHold;

        return conferenceParticipantIsOnHold || primaryParticipantIsOnHold;
    }, [task.conferenceParticipants, task.holdState]);

    const [isLoading, hangupCall] = useAsyncLoading(
        withControlsLock(async () => {
            if (task.status === "pending") {
                await agentSdk.wrapupTask(task, TASK_WRAPUP_REASON);
                return;
            }

            if (task.status === "assigned") {
                await agentSdk.hangupCall(task, TASK_WRAPUP_REASON);
                return;
            }

            throw new Error("Cannot hangup a call associated with a task not in pending or assigned status.");
        }),
    );

    return (
        <StyledIconBtn
            analytics={{
                actionId: "HangupButton",
            }}
            aria-label="End call"
            data-testid="call-controls-hangup"
            disabled={isLoading || task.status !== "assigned" || controlLock || anyParticipantOnHold}
            loading={isLoading || controlLock}
            onClick={hangupCall}
            radius="xl"
            size="35"
            tooltipLabel="End call"
        >
            <ThemeIcon bottom={2} left={0} variant="transparent">
                <Hangup height={16} />
            </ThemeIcon>
        </StyledIconBtn>
    );
}
