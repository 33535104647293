export const zoomPhoneNumbers = Object.freeze([
    "+1 (646) 558-8656",
    "+1 (646) 931-3860",
    "+1 (301) 715-8592",
    "+1 (305) 224-1968",
    "+1 (309) 205-3325",
    "+1 (312) 626-6799",
    "+1 (564) 217-2000",
    "+1 (669) 444-9171",
    "+1 (669) 900-6833",
    "+1 (689) 278-1000",
    "+1 (719) 359-4580",
    "+1 (253) 205-0468",
    "+1 (253) 215-8782",
    "+1 (346) 248-7799",
    "+1 (360) 209-5623",
    "+1 (386) 347-5053",
    "+1 (507) 473-4847",
]);

function secureRandom() {
    return crypto.getRandomValues(new Uint32Array(1))[0] * 2 ** -32;
}

export function randomZoomPhoneNumber() {
    return zoomPhoneNumbers[Math.floor(secureRandom() * zoomPhoneNumbers.length)];
}
