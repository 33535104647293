import { Combobox, InputBase, useCombobox } from "@mantine/core";
import { useCallbacksFormStore } from "../../../callbacks.store";
import type { TimeZoneValue } from "../../../types";
import { TIME_ZONES } from "../../../types";
import classes from "./inputs.module.css";

export function ZoneInput() {
    const { setScheduledTimeZone, scheduledTimeZone } = useCallbacksFormStore();

    const options = TIME_ZONES.map((item) => (
        <Combobox.Option key={item.name} value={item.value}>
            {item.name}
        </Combobox.Option>
    ));

    const combobox = useCombobox({
        onDropdownClose: () => {
            combobox.resetSelectedOption();
        },
    });

    function handleSubmit(timeZone: TimeZoneValue) {
        setScheduledTimeZone(timeZone);
        combobox.closeDropdown();
    }

    return (
        <Combobox
            classNames={{ dropdown: classes.dropdown }}
            onOptionSubmit={(value) => handleSubmit(value as TimeZoneValue)}
            store={combobox}
            zIndex="var(--mantine-priority-higher)"
        >
            <Combobox.Target>
                <InputBase
                    component="button"
                    onClick={() => combobox.toggleDropdown()}
                    pointer
                    rightSection={<Combobox.Chevron />}
                    rightSectionPointerEvents="none"
                    size="lg"
                >
                    {TIME_ZONES.find((item) => item.value === scheduledTimeZone)?.name}
                </InputBase>
            </Combobox.Target>

            <Combobox.Dropdown>
                <Combobox.Options mah={280}>{options}</Combobox.Options>
            </Combobox.Dropdown>
        </Combobox>
    );
}
