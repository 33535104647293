import { Container, Flex, Text } from "@mantine/core";
import { GreenQuote, TextInfoPage } from "../Icons";
import classes from "./EnrollFailure.module.css";

export function EnrollFailureScreen(): JSX.Element {
    return (
        <Flex className={classes.statusContainer} data-testid="sale-finished-view">
            <Container className={classes.statusIcon}>
                <TextInfoPage />
            </Container>
            <Text className={classes.statusHeader} data-testid="sale-view-status-header">
                We hit a snag with enrollment
            </Text>
            <Text className={classes.statusBodyText} data-testid="sale-view-status-body">
                Don’t worry-you’ll still get credit!
            </Text>
            <Flex className={classes.lowerBox}>
                <Text size="xs">Tell them:</Text>
                <Flex className={classes.lowerSection}>
                    <GreenQuote />
                    <Flex className={classes.lowerText} direction="column">
                        <Text className={classes.subtext} size="sm">
                            Sorry for the trouble—looks like something went wrong on our end.
                        </Text>
                        <Text className={classes.subtext} size="sm">
                            Don’t worry—we’ll keep trying, but there’s a chance it won’t go through.
                        </Text>
                        <Text className={classes.subtext} size="sm">
                            If you don’t get an email or see it on your account within a couple weeks, please reach back
                            out.
                        </Text>
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    );
}
