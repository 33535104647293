//probably move these in to contentful
import { EligibilityStatus, SaleProgress } from "@expert/sales-tools/src/home-product/types";
import { BADGE_BREAKPOINT, type ConfirmedProduct } from "../../types";

interface TextMapFields {
    title: string[];
    subtitle: string[];
}

const scenarioTextMap: Record<string, TextMapFields> = {
    declined: {
        title: ["Nice offer!", "You got this!", "Keep your chin up.", "Maybe next time!"],
        subtitle: [
            "Maybe next time.",
            "You tried your best - don’t give up!",
            "It’s all good - you’ll land the next one.",
            "You tried - keep up the good work.",
        ],
    },
    noOffer: {
        title: ["Make the next one count.", "Don’t forget to offer."],
        subtitle: ["Offer on the next one - you can do it!", "Let’s aim to hit our daily goals."],
    },
    sale: {
        title: ["That’s how it’s done!", "You did it!", "Nice sale!", "Keep ’em coming!"],
        subtitle: ["Keep it up.", "Nice sale - keep it up!", "You’re doing great", "Excellent work."],
    },
    ineligible: {
        title: ["The customer wasn’t eligible."],
        subtitle: ["Good job helping them solve their problem."],
    },
    unlock: { title: ["Great Offer!"], subtitle: ["New reward unlocked!"] },
};

function getRandomText(scenarioTexts: TextMapFields): TextPair {
    const randomIndex = Math.floor(Math.random() * scenarioTexts.title.length);
    return { title: scenarioTexts.title[randomIndex], subtitle: scenarioTexts.subtitle[randomIndex] };
}

interface TextPair {
    title: string;
    subtitle: string;
}

export function getTextByScenario(homeProduct: ConfirmedProduct, offered: boolean, offerCount: number): TextPair {
    if (offerCount % BADGE_BREAKPOINT === 0 && offered && offerCount <= BADGE_BREAKPOINT * 3) {
        return getRandomText(scenarioTextMap.unlock);
    }
    if (homeProduct.status !== EligibilityStatus.Eligible) {
        return getRandomText(scenarioTextMap.ineligible);
    }
    if (!offered) {
        return getRandomText(scenarioTextMap.noOffer);
    }
    if ([SaleProgress.Declined, SaleProgress.InProgress].includes(homeProduct.saleProgress)) {
        return getRandomText(scenarioTextMap.declined);
    }
    if ([SaleProgress.Success, SaleProgress.Failure].includes(homeProduct.saleProgress)) {
        return getRandomText(scenarioTextMap.sale);
    }
    return { title: "Something has gone wrong", subtitle: "please submit feedback" };
}
