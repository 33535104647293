import * as React from "react";
import type { SVGProps } from "react";

export function GoldBadge(props: SVGProps<SVGSVGElement>) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" height="100%" viewBox="0 0 25 25" width="100%" {...props}>
            <path
                fill="#F6BB21"
                d="M12.194 24c2.485 0 6.176-2.787 7.506-3.713 1.377-.96 2.89-2.192 2.89-3.957V3.906A3.906 3.906 0 0 0 18.686 0H5.905A3.906 3.906 0 0 0 2 3.906V16.33c0 1.56 1.035 2.694 2.234 3.53C5.5 20.743 9.607 24 12.194 24Z"
            />
            <path
                fill="url(#a)"
                d="M12.194 24c2.485 0 6.176-2.787 7.506-3.713 1.377-.96 2.89-2.192 2.89-3.957V3.906A3.906 3.906 0 0 0 18.686 0H5.905A3.906 3.906 0 0 0 2 3.906V16.33c0 1.56 1.035 2.694 2.234 3.53C5.5 20.743 9.607 24 12.194 24Z"
            />
            <path fill="#F66262" d="m2.77 1.577 5.722 8.11h7.747L9.938 0H5.906A3.9 3.9 0 0 0 2.77 1.577Z" />
            <path
                fill="url(#b)"
                fillOpacity={0.15}
                d="m2.77 1.577 5.722 8.11h7.747L9.938 0H5.906A3.9 3.9 0 0 0 2.77 1.577Z"
            />
            <path fill="#F74949" d="M21.999 1.839A3.902 3.902 0 0 0 18.685 0h-3.53L8.783 9.687l7.57.253 5.646-8.101Z" />
            <path
                fill="url(#c)"
                fillOpacity={0.15}
                d="M21.999 1.839A3.902 3.902 0 0 0 18.685 0h-3.53L8.783 9.687l7.57.253 5.646-8.101Z"
            />
            <path fill="#F9D664" d="M12.296 19.576a5.68 5.68 0 1 0 0-11.36 5.68 5.68 0 0 0 0 11.36Z" />
            <path
                fill="url(#d)"
                fillOpacity={0.5}
                d="M12.296 19.576a5.68 5.68 0 1 0 0-11.36 5.68 5.68 0 0 0 0 11.36Z"
            />
            <path
                stroke="#C49C4A"
                strokeWidth={0.25}
                d="M12.296 19.576a5.68 5.68 0 1 0 0-11.36 5.68 5.68 0 0 0 0 11.36Z"
            />
            <path
                fill="#FFFCF6"
                stroke="#C49C4A"
                strokeWidth={0.25}
                d="M11.912 10.586a.406.406 0 0 1 .767 0l.561 1.616c.056.16.205.269.375.272l1.71.035a.406.406 0 0 1 .237.729l-1.363 1.033a.406.406 0 0 0-.143.44l.495 1.638a.406.406 0 0 1-.62.45l-1.404-.976a.405.405 0 0 0-.463 0l-1.404.976a.406.406 0 0 1-.62-.45l.495-1.637a.406.406 0 0 0-.143-.44l-1.363-1.034a.406.406 0 0 1 .237-.729l1.71-.035a.406.406 0 0 0 .375-.272l.561-1.616Z"
            />
            <defs>
                <linearGradient id="a" x1={2} x2={20.917} y1={1.319} y2={21.149} gradientUnits="userSpaceOnUse">
                    <stop stopColor="#fff" stopOpacity={0.3} />
                    <stop offset={1} stopColor="#fff" stopOpacity={0} />
                </linearGradient>
                <linearGradient id="b" x1={10.672} x2={6.083} y1={3.575} y2={7.531} gradientUnits="userSpaceOnUse">
                    <stop stopOpacity={0} />
                    <stop offset={1} />
                </linearGradient>
                <linearGradient id="c" x1={13.284} x2={18.863} y1={0.735} y2={6.466} gradientUnits="userSpaceOnUse">
                    <stop offset={0.01} stopOpacity={0} />
                    <stop offset={1} />
                </linearGradient>
                <radialGradient
                    id="d"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientTransform="rotate(58.401 -3.28 12.935) scale(11.7601)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#fff" stopOpacity={0.7} />
                    <stop offset={1} stopColor="#fff" stopOpacity={0} />
                </radialGradient>
            </defs>
        </svg>
    );
}
