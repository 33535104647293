import { useAnalytics } from "@expert/analytics";
import type { DefaultMantineColor } from "@mantine/core";
import { Button, Radio } from "@mantine/core";
import { useMemo } from "react";
import styles from "./RadioCard.module.css";
import { type RadioCardProps } from "./types";

function hoverOverride(color: DefaultMantineColor | undefined) {
    return () => {
        return {
            root: {
                "--button-hover": color,
            },
        };
    };
}

export function RadioCard(props: RadioCardProps) {
    const { dispatcher } = useAnalytics();
    const { actionId, extraContext, eventData } = props.analytics;
    const enhancedDispatcher = extraContext ? dispatcher.withExtra(extraContext) : dispatcher;

    const overrideHoverVarsResolver = useMemo(() => {
        return hoverOverride(props.color);
    }, [props.color]);

    function onClick(event: React.MouseEvent<HTMLButtonElement>) {
        void enhancedDispatcher.dispatchAction("ButtonClick", actionId, { ...eventData });
        props.onClick?.(event);
    }

    const handleWrapperEnter = () => {
        void enhancedDispatcher.dispatchAction("KeypressEnter", actionId, eventData);
    };

    return (
        <Button
            {...props}
            classNames={{ root: styles.RadioCardRoot }}
            onClick={onClick}
            onKeyUp={(e) => e.key === "Enter" && handleWrapperEnter}
            data-selected={props.selected}
            vars={overrideHoverVarsResolver}
        >
            <Radio
                disabled={props.disabled}
                checked={props.selected}
                readOnly
                tabIndex={-1}
                value={props.value ?? props.label}
                label={props.label}
            />
        </Button>
    );
}
