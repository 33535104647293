import { type SVGProps } from "react";

export function Unmute(props: SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg fill="none" height="100%" viewBox="0 0 15 24" width="100%" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M7.15625 18.2384V22.8542"
                stroke="black"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.38474"
            />
            <path
                clipRule="evenodd"
                d="M7.15651 1.62152C5.11712 1.62152 3.46387 3.27477 3.46387 5.31416V11.7763C3.46387 13.8157 5.11712 15.4689 7.15651 15.4689C9.1959 15.4689 10.8491 13.8157 10.8491 11.7763V5.31416C10.8491 3.27477 9.1959 1.62152 7.15651 1.62152Z"
                fillRule="evenodd"
                stroke="black"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.38474"
            />
            <path
                d="M13.6186 9.00681V11.7763C13.6186 15.3452 10.7254 18.2384 7.15646 18.2384C3.58753 18.2384 0.694336 15.3452 0.694336 11.7763V9.00681"
                stroke="black"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.38474"
            />
            <line stroke="black" strokeWidth="2" x1="-0.707107" x2="16.6777" y1="1.29289" y2="17.2635" />
        </svg>
    );
}
