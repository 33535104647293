import { Menu, Tooltip, UnstyledButton } from "@mantine/core";
import { useDisclosure, useHover } from "@mantine/hooks";
import { NoteDialog } from "@soluto-private/expert-workspace-notepad";
import { useReactAnalytics } from "@soluto-private/eventualize-react";
import type { Logger } from "@expert/logging";
import { trackAppcuesEvent } from "@expert/monitoring";
import { ToolboxHoverIcon, ToolboxIcon, ToolboxMenuCloseIcon } from "../../assets";
import { defaultMessages, useExpertAssistStore } from "../../state";
import type { MenuItemUI } from "./types";
import { toolboxUIMapping } from "./types";
import { ToolboxMenuItem } from "./elements";

interface ToolboxProps {
    input?: string;
    logger: Logger;
    opened: boolean;
    setOpened: React.Dispatch<React.SetStateAction<boolean>>;
    onClose: () => void;
    trapFocus: boolean;
}

export function ToolboxButton({ opened, setOpened, input, trapFocus, onClose, logger: loggerProp }: ToolboxProps) {
    const { dispatcher } = useReactAnalytics();
    const { ref, hovered } = useHover<HTMLButtonElement>();
    const logger = loggerProp.child({ module: "ToolboxButton" });
    const [isNoteDialogOpened, { open: openNotepad, close: closeNotepad }] = useDisclosure(false, {
        onOpen: () => {
            logger.info("Notepad opened");
        },
        onClose: () => {
            logger.info("Notepad closed");
        },
    });
    const { features, resetMessages } = useExpertAssistStore();

    const handleButtonClick = () => {
        logger.info(`Toolbox ${!opened ? "opened" : "closed"}`);
        const actionId = opened ? "CloseToolbox" : "OpenToolbox";
        void dispatcher.dispatchAction("Click", actionId, {});
    };

    const handleMenuButtonClick = (item: MenuItemUI) => {
        // NOTE: using business events for toolbox clicks for now to enable event usage in EDP for reporting.
        // We should use UserAction events for button clicks in the future once the piloting table is setup in EDP.
        void dispatcher.dispatchBusinessEvent(`Click_Toolbox_${item.actionId}`, {
            button: item.actionId,
        });
        trackAppcuesEvent(`Click_Toolbox_${item.actionId}`, { actionId: item.actionId, actionType: "Toolbox" });
        switch (item.actionId) {
            case "OpenNotepad":
                openNotepad();
                break;
            case "LaunchOzmo":
                logger.info("Ozmo home page opened");
                void dispatcher.dispatchBusinessEvent("Ozmo_OpenHomepage", {
                    source: "Toolbox",
                    ozmoUrl: item.additionalProps?.href,
                    ozmoTitle: item.label,
                });
                break;
            case "ResetChat":
                logger.info("Chat was reset");
                resetMessages([defaultMessages.manualReset]);
                break;
            default:
                logger.info(`${item.label} tool onClick not implemented`);
        }
    };

    const Toolbox = hovered ? <ToolboxHoverIcon /> : <ToolboxIcon />;

    let focusedMenuItem = "";
    const menuItems = toolboxUIMapping(features).map((item) => {
        const id = `toolbox-menu-item-${item.quickActionLabel ?? item.label}`;
        // if input matches a quick action, the menu item should autofocus
        if (input && input === item.quickActionLabel) {
            focusedMenuItem = id;
            void dispatcher.dispatchAction("Shortcut_Toolbox", item.actionId, {});
        }

        return (
            <Tooltip
                disabled={!item.tooltip}
                key={item.label}
                label={item.tooltip}
                openDelay={1000}
                position="top-start"
            >
                <ToolboxMenuItem
                    id={id}
                    item={item}
                    focusedMenuItem={focusedMenuItem}
                    onClick={handleMenuButtonClick}
                />
            </Tooltip>
        );
    });

    // if menu is opened and shortcut input doesn't match a shortcut - close the menu
    const shouldCloseMenu = opened && !focusedMenuItem && input && input.length > 1;
    if (focusedMenuItem) {
        document.getElementById(focusedMenuItem)?.focus();
    } else if (shouldCloseMenu) {
        setOpened(false);
    }

    return (
        <>
            <Menu
                arrowOffset={20}
                onChange={setOpened}
                onClose={onClose}
                opened={opened && !shouldCloseMenu}
                position="top-start"
                shadow="sm"
                transitionProps={{ duration: 200, timingFunction: "ease", transition: "rotate-left" }}
                width={250}
                withArrow
                id="toolbox-menu"
                trapFocus={trapFocus}
                clickOutsideEvents={["click", "Escape"]}
            >
                <Tooltip disabled={opened} label="Toolbox" openDelay={1000}>
                    <Menu.Target>
                        <UnstyledButton
                            color="gray"
                            data-testid="toolbox-button"
                            pt=".25rem"
                            ref={ref}
                            size="sm"
                            type="submit"
                            variant="subtle"
                            onClick={handleButtonClick}
                        >
                            {opened ? <ToolboxMenuCloseIcon /> : Toolbox}
                        </UnstyledButton>
                    </Menu.Target>
                </Tooltip>
                <Menu.Dropdown>
                    <Menu.Item rightSection="/" disabled fz="xs">
                        Toolbox
                    </Menu.Item>
                    {menuItems}
                </Menu.Dropdown>
            </Menu>
            <NoteDialog isOpen={isNoteDialogOpened} onClose={closeNotepad} logger={logger} />
        </>
    );
}
