import type { EntryFieldTypes, EntrySkeletonType } from "contentful";
import { getOrCreateContentfulClient } from "../getOrCreateContentfulClient";

interface DeviceList {
    name: string;
    device: { name: string | undefined }[];
}

interface DeviceFields {
    name: EntryFieldTypes.Text;
}

interface DeviceListFields {
    name: EntryFieldTypes.Text;
    device: EntryFieldTypes.Array<EntryFieldTypes.EntryLink<EntrySkeletonType<DeviceFields, "device">>>;
}

export const getDeviceList = async (listName = "default"): Promise<DeviceList> => {
    const contentfulClient = getOrCreateContentfulClient();
    const entries = await contentfulClient.withoutUnresolvableLinks.getEntries<EntrySkeletonType<DeviceListFields>>({
        content_type: "deviceList",
        "fields.name": listName,
    });
    const fields = entries.items[0].fields;
    return {
        name: fields.name,
        device: fields.device.map((device) => {
            return { name: device?.fields.name };
        }),
    };
};
