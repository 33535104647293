export const UnknownIcon = (
    <svg width="66" height="65" viewBox="0 0 66 65" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M26.3334 54.6675H5.00004C3.52728 54.6675 2.33337 53.4736 2.33337 52.0008V9.33415C2.33337 7.86139 3.52728 6.66748 5.00004 6.66748H42.3334C43.8061 6.66748 45 7.86139 45 9.33415V22.6675"
            stroke="#6E767D"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M13 12.0007V1.33398"
            stroke="#6E767D"
            strokeWidth="2.66667"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M23.6667 12.0007V1.33398"
            stroke="#6E767D"
            strokeWidth="2.66667"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M34.3334 12.0007V1.33398"
            stroke="#6E767D"
            strokeWidth="2.66667"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M47.6667 62.6675C56.5033 62.6675 63.6667 55.504 63.6667 46.6675C63.6667 37.8309 56.5033 30.6675 47.6667 30.6675C38.8302 30.6675 31.6667 37.8309 31.6667 46.6675C31.6667 55.504 38.8302 62.6675 47.6667 62.6675Z"
            stroke="#6E767D"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M42.6667 42.7793C42.6667 40.0179 44.9053 37.7793 47.6667 37.7793C50.4282 37.7793 52.6667 40.0179 52.6667 42.7793C52.6667 45.5407 50.4282 47.7793 47.6667 47.7793V50.446"
            stroke="#6E767D"
            strokeWidth="2.66667"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M47.6667 55.7793C48.0349 55.7793 48.3333 56.0778 48.3333 56.446C48.3333 56.8142 48.0349 57.1126 47.6667 57.1126C47.2985 57.1126 47 56.8142 47 56.446C47 56.0778 47.2985 55.7793 47.6667 55.7793"
            stroke="#6E767D"
            strokeWidth="2.66667"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
