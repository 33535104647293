import { Card, Flex, Text } from "@mantine/core";
import { type BadgeType } from "../../types";
import { LockIcon } from "../icons";
import classes from "./lockedBar.module.css";
import { badgeMap } from "./badgeMap";

interface LockedBarProps {
    badgeType: BadgeType;
}

export function LockedBar({ badgeType }: LockedBarProps) {
    const { upperLimit } = badgeMap[badgeType];
    return (
        <Card className={classes.lockedCard}>
            <Flex className={classes.leftText}>
                <LockIcon />
                <Text className={classes.lockedText} size="sm">{`Unlock ${badgeType}`}</Text>
            </Flex>
            <Text className={classes.lockedText} size="sm">{`${upperLimit} offers`}</Text>
        </Card>
    );
}
