import classes from "./Toast.module.css";
import { ErrorIcon, InfoIcon, SuccessIcon } from "./icons";
import { type ToastType } from "./types";

export const useIcon = (type: ToastType) => {
    switch (type) {
        case "success":
            return SuccessIcon;
        case "error":
            return ErrorIcon;
        case "info":
            return InfoIcon;
        default:
            return InfoIcon;
    }
};

export const useCss = (type: ToastType) => {
    switch (type) {
        case "success":
            return classes.success;
        case "error":
            return classes.error;
        case "info":
            return classes.info;
        default:
            return classes.info;
    }
};
