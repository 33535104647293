import { getLogger } from "@expert/logging";
import { immer } from "zustand/middleware/immer";
import { shallow } from "zustand/shallow";
import { createWithEqualityFn } from "zustand/traditional";

const logger = getLogger({
    module: "useSessionStore",
});

export type SessionSummary = string;

export type SummaryResolution = true | false | null;

export interface SessionSummaryState {
    summary: SessionSummary;
    autogeneratedSummary: SessionSummary;
    wasIssueResolved: SummaryResolution;
    submitted: boolean;
    canAppend: boolean;
    setCanAppend: (value: boolean) => void;
    setSessionSummary: (value: SessionSummary) => void;
    appendSessionSummary: (fragment: SessionSummary) => void;
    resetSessionSummary: () => void;
    setResolved: (value: SummaryResolution) => void;
    setSubmitted: (value: boolean) => void;
}

export const useSessionSummaryStore = createWithEqualityFn<SessionSummaryState>()(
    immer((set) => ({
        summary: "",
        autogeneratedSummary: "",
        wasIssueResolved: null,
        submitted: false,
        canAppend: true,
        setCanAppend: (canAppend: boolean) => {
            set((state) => {
                state.canAppend = canAppend;
            });
        },
        setSessionSummary: (summary: SessionSummary) => {
            set((state) => {
                state.summary = summary;
            });
        },
        appendSessionSummary: (fragment: SessionSummary) => {
            set((state) => {
                if (!state.canAppend) {
                    logger.warn("Cannot append to session summary, canAppend is false");
                    return;
                }

                const formatted = [state.summary, fragment].join(" ").trim();

                state.summary = formatted;
                state.autogeneratedSummary = formatted;
            });
        },
        resetSessionSummary: () => {
            set((state) => {
                state.summary = "";
                state.autogeneratedSummary = "";
                state.wasIssueResolved = null;
                state.canAppend = true;
                state.submitted = false;
            });
        },
        setResolved: (wasIssueResolved: SummaryResolution) => {
            set((state) => {
                state.wasIssueResolved = wasIssueResolved;
            });
        },
        setSubmitted: (submitted: boolean) => {
            set((state) => {
                state.submitted = submitted;
            });
        },
    })),
    shallow,
);
