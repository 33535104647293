import { CallControls, NoCallControls } from "@expert/call-controls";
import { useVerifiedCustomerStore } from "@expert/customer-info";
import { getLogger } from "@expert/logging";
import { setAppcuesIdentifier, setFullStoryIdentifier, trackAppcuesEvent } from "@expert/monitoring";
import {
    useCurrentStepStore,
    useGuidedFlowStreamhubStore,
    useHomeProductSessionStore,
    useOtherProductSessionStore,
} from "@expert/sales-tools";
import {
    DevDebugTools,
    fallbackPartner,
    getSessionIdHistory,
    isTrainingTask,
    isVoiceTask,
    sdkEventBus,
    useActiveTask,
    useAgentStore,
    useSession,
} from "@expert/sdk";
import { RenderError, userCache } from "@expert/shared-utils";
import { Flex } from "@mantine/core";
import { AnalyticsProvider } from "@soluto-private/eventualize-react";
import { FeedbackPortal } from "@soluto-private/expert-workspace-feedback";
import { useSetDraggableBounds } from "@soluto-private/expert-workspace-notepad";
import { useEffect, useMemo, useRef } from "react";
import { ErrorBoundary, withErrorBoundary } from "react-error-boundary";
import { env } from "../../../config";
import { useCallDetailsStore } from "../../call-details";
import { CustomerPanel, useVerificationFlowStore } from "../../customer-panel";
import { useFeedbackNotification } from "../../feedback";
import { SessionSummary } from "../../session-summary";
import { useStreamhubConnection } from "../../streamhub-client";
import { PlatformTimeline, useGaiaAuthEvents } from "../../timeline";
import { storeStreamhubMessages } from "../../utils";
import { WorkspaceHeader } from "../header";
import styles from "./workspace.module.css";

const logger = getLogger({ module: "Workspace" });

const SessionSummaryHandled = withErrorBoundary(SessionSummary, { FallbackComponent: RenderError });
const PlatformTimelineHandled = withErrorBoundary(PlatformTimeline, { FallbackComponent: RenderError });
const NoCallControlsHandled = withErrorBoundary(NoCallControls, { FallbackComponent: RenderError });
const CallControlsHandled = withErrorBoundary(CallControls, { FallbackComponent: RenderError });

export function Workspace(): JSX.Element {
    const activeTask = useActiveTask();
    const { agentId, agentName } = useAgentStore((state) => state);
    const activeVoiceTask = useMemo(() => {
        return isVoiceTask(activeTask) ? activeTask : null;
    }, [activeTask]);
    const activeTrainingTask = useMemo(() => {
        return isTrainingTask(activeTask) ? activeTask : null;
    }, [activeTask]);
    const { employeeId, userEmail, identity, givenName, familyName } = userCache;
    const session = useSession();
    const sessionId = session.id;
    const isCustomerSession = session.kind === "with-customer";
    const callSid = activeVoiceTask?.callSid;
    const partner = activeTask?.partner ?? fallbackPartner;
    const activeTaskIdentities = activeTask ? { TaskId: activeTask.id } : {};
    const activeTaskExtra = activeTask
        ? {
              taskType: activeTask.type,
              partner: activeTask.partner,
          }
        : {};
    const ssoAccessToken = userCache.ssoAccessToken ?? "";
    const showFeedbackNotification = useFeedbackNotification();

    useStreamhubConnection({
        activeVoiceTask,
        onMessage: storeStreamhubMessages,
    });

    useGaiaAuthEvents(sessionId, callSid);

    useEffect(() => {
        const agentOfflineEvent = sdkEventBus.on("agent_activity_changed", (activity) => {
            if (activity === "Offline") {
                localStorage.clear();
                sessionStorage.clear();
                window.location.href = "/logged-out";
            }
        });

        return () => {
            agentOfflineEvent();
        };
    }, []);

    useEffect(() => {
        const warnOnClose = (ev: BeforeUnloadEvent) => {
            // skip browser warning if there is no customer session
            if (!isCustomerSession) {
                return;
            }
            ev.preventDefault();
            ev.returnValue = "";
            return "";
        };

        window.addEventListener("beforeunload", warnOnClose);

        return () => {
            // remove the old listener every time the task id changes or becomes undefined
            window.removeEventListener("beforeunload", warnOnClose);
        };
    }, [isCustomerSession]);

    useEffect(() => {
        setFullStoryIdentifier({ identity: agentName, displayName: agentName, properties: { agentId, employeeId } });
        setAppcuesIdentifier({
            identity: employeeId ?? agentId,
            name: givenName && familyName ? `${givenName} ${familyName}` : agentName,
            email: userEmail ?? identity,
        });
        trackAppcuesEvent("Workspace Loaded", {});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // rehydrate stores if current session is with a customer and it has not ended
        if (session.metadata.status !== "ended" && session.kind === "with-customer") {
            void useVerificationFlowStore.persist.rehydrate();
            void useCallDetailsStore.persist.rehydrate();
            void useHomeProductSessionStore.persist.rehydrate();
            void useOtherProductSessionStore.persist.rehydrate();
            void useVerifiedCustomerStore.persist.rehydrate();
            void useGuidedFlowStreamhubStore.persist.rehydrate();
            void useCurrentStepStore.persist.rehydrate();
        }
    }, [session]);

    const containerRef = useRef(null);
    useSetDraggableBounds(containerRef);

    return (
        <Flex ref={containerRef} className={styles.container}>
            {env.isProdMode ? null : <DevDebugTools />}
            <AnalyticsProvider
                dispatcher={(dispatcher) =>
                    dispatcher
                        .withIdentities({
                            WorkerId: agentId,
                            SessionId: sessionId,
                            ...activeTaskIdentities,
                        })
                        .withExtra({ partner, ...activeTaskExtra })
                }
            >
                <WorkspaceHeader />

                {activeTrainingTask ? (
                    <Flex className={styles.workspaceBody}>
                        <p>Active Training Task</p>
                    </Flex>
                ) : (
                    <Flex className={styles.workspaceBody}>
                        {activeVoiceTask ? <CallControlsHandled /> : <NoCallControlsHandled />}
                        {isCustomerSession ? <SessionSummaryHandled /> : null}
                        <PlatformTimelineHandled />
                    </Flex>
                )}
                {/* This error boundary is swallowing up errors. We need to have a better way to show customer panel errors while still the panel */}
                {isCustomerSession ? (
                    <ErrorBoundary fallback={<RenderError />}>
                        <CustomerPanel />
                    </ErrorBoundary>
                ) : null}

                <FeedbackPortal
                    payload={{
                        appName: "expert-workspace",
                        caller: "PlatformTimeline",
                        sessionId,
                        callSid,
                        ssoAccessToken,
                        extraData: { "Previous Sessions": JSON.stringify(getSessionIdHistory(5)) },
                    }}
                    showNotification={showFeedbackNotification}
                    taskStatus={activeTask?.status}
                    logger={logger.child({
                        tag: "solve",
                        sessionId,
                        callSid,
                    })}
                    source="Sidebar"
                />
            </AnalyticsProvider>
        </Flex>
    );
}
