import { useEffect, useRef, useState } from "react";
import { Container } from "@mantine/core";
import { useExpertAssistStore } from "../../state";
import { isFromBot } from "../../utils";
import { BotIcon } from "../bot-icon";
import { MessageBubble } from "../message-bubble";
import { SessionSummaryHeader } from "../message-bubble/custom-messages/SessionSummary";
import { QuickActionGroup, useQuickActionsManager } from "../quick-actions";
import { MessageBubbleWrapper, MessageTag, ProactiveTextMask, TimelineItemWrapper } from "./elements";
import type { TimelineItemProps } from "./types";
import timelineClasses from "./TimelineItem.module.css";

export function TimelineItem({
    type,
    isLoading,
    isLastMessage,
    isLastProactiveMessage,
    scrollRef,
    metaData,
    children,
}: TimelineItemProps) {
    const overflowRef = useRef<HTMLDivElement>(null);
    const [isOverflowing, setIsOverflowing] = useState(true);
    const [isExpanded, setIsExpanded] = useState(false);

    const { callSid, features } = useExpertAssistStore();
    const isFromAI = isFromBot(type);
    const isLastAIMessage = isFromAI && isLastMessage;

    const isProactiveMessage = features.isProactiveEnabled && type === "Proactive";
    const shouldShowProactive = isProactiveMessage && isLastProactiveMessage;
    const isProactiveExpanded = isProactiveMessage ? isExpanded || !isLastProactiveMessage || !isOverflowing : true;
    const isBotListening = features.isProactiveEnabled && !isLoading && isLastAIMessage && !!callSid;

    const showDeviceInfoTag = type === "DeviceInfo" && isLastProactiveMessage;
    const actions = useQuickActionsManager({
        currentMessageType: type,
        isOverflowing,
        isExpanded,
        isLastMessage,
        isLastProactiveMessage,
        setIsExpanded,
    });
    const [isQuickActionsVisible, setIsQuickActionsVisible] = useState<boolean>(actions.length > 0);

    // check if the proactive bot message is overflowing in the bubble
    useEffect(() => {
        setIsOverflowing(
            overflowRef.current?.scrollHeight
                ? overflowRef.current.scrollHeight > overflowRef.current.clientHeight
                : false,
        );
    }, [overflowRef.current?.scrollHeight, overflowRef.current?.clientHeight]);

    useEffect(() => {
        if (isProactiveExpanded && isOverflowing && isLastAIMessage) {
            const div = scrollRef?.current;
            div?.scrollTo({ top: div.scrollHeight, behavior: "smooth" });
        }
    }, [isProactiveExpanded, isOverflowing, isLastAIMessage, scrollRef]);

    // TODO: Clean up as part of TimelineItem refactor
    const messageBubbleWrapperContainer = timelineClasses.messageBubbleAndHeaderWrapper;
    const containerClassName =
        type === "SessionSummary"
            ? `${timelineClasses.sessionSummaryContainer} ${messageBubbleWrapperContainer}`
            : messageBubbleWrapperContainer;

    return (
        <TimelineItemWrapper data-testid="timeline-item" isFromAi={isFromAI}>
            <BotIcon isBotListening={isBotListening} isHidden={!isLastAIMessage && !isLoading} isLoading={isLoading} />
            {!isLoading && (
                <Container className={containerClassName}>
                    {type === "SessionSummary" && <SessionSummaryHeader metaData={metaData?.sessionSummary} />}
                    <MessageBubbleWrapper isBotListening={isBotListening} isExpanded={isProactiveExpanded} type={type}>
                        <MessageBubble
                            isExpanded={isExpanded}
                            shouldShowProactive={shouldShowProactive}
                            isLastProactiveMessage={isLastProactiveMessage}
                            overflowRef={overflowRef}
                            type={type}
                        >
                            <ProactiveTextMask isExpanded={isProactiveExpanded}>
                                {shouldShowProactive && <MessageTag tagName="Solve suggestion" />}
                                {showDeviceInfoTag && <MessageTag tagName="Device info" />}
                                {children}
                            </ProactiveTextMask>
                        </MessageBubble>
                        {isQuickActionsVisible && (
                            <QuickActionGroup actions={actions} setIsQuickActionsVisible={setIsQuickActionsVisible} />
                        )}
                    </MessageBubbleWrapper>
                </Container>
            )}
        </TimelineItemWrapper>
    );
}
