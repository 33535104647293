import { Group, Text } from "@mantine/core";
import "@mantine/dates/styles.css";
import { useEffect } from "react";
import { useCallbacksFormStore } from "../../../callbacks.store";
import { DateInput } from "./DateInput";
import { TimeInput } from "./TimeInput";
import { ZoneInput } from "./ZoneInput";

export function DateTimeSelection() {
    const { clearDateTimeState } = useCallbacksFormStore();

    useEffect(() => {
        return () => clearDateTimeState(); //clearing on unmount only
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Group gap="sm">
            <DateInput />
            <Text fw={300}>at</Text>
            <TimeInput />
            <ZoneInput />
        </Group>
    );
}
