import { getLogger } from "@expert/logging";
import type { Task } from "../agent";
import type { Session } from "./types";

const logger = getLogger({
    module: "sessionAssertions",
});

/** Asserts that the provided task is the current task in the provided session.
 * This exists as precondition verification to catch bugs & regressions where the current task is not being set correctly or out of order.
 */
export function assertCurrentTask(session: Session, task?: Task) {
    let message = "";
    if (session.currentTask && !task) {
        message = `Session assertion failed: Task is falsy but session has a current task`;
    } else if (!session.currentTask && task) {
        message = `Session assertion failed: Task is truthy but session has no current task`;
    } else if (session.currentTask?.id !== task?.id) {
        message = `Session assertion failed: Task ID mismatch between session and task`;
    }

    if (message) {
        logger.error(message, {
            session,
            task,
        });
        throw new Error(message);
    }
}

//TODO: Rename to assertSessionTaskExists
export function assertTaskExists(session: Session, task?: Task) {
    let message = "";
    if (!session.tasks.some((t) => t.id === task?.id)) {
        message = `Session assertion failed: Task ${task?.id} does not exist in session`;
        logger.error(message, {
            session,
            task,
        });
        throw new Error(message);
    }
}
