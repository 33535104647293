import type { Partner } from "@expert/shared-types";
import type { EligibleProduct } from "../home-product";
import type { SalesConfiguration } from "./SalesConfiguration";
import { verizonSalesConfig, verizonSkuOrder } from "./verizon";

const PartnerConfig: Record<string, SalesConfiguration> = {
    verizon: verizonSalesConfig,
};

export const getDisplayName = (partner: string, sku: string): string | undefined => {
    return PartnerConfig[partner].plans.find((p) => p.sku === sku)?.name;
};

export const getIcon = (partner: string, sku: string): React.ReactNode | undefined => {
    return PartnerConfig[partner].plans.find((p) => p.sku === sku)?.icon;
};

export const getSortedProductSKUs = (partner: Partner, eligibleProducts: EligibleProduct[]): EligibleProduct[] => {
    if (partner !== "verizon") {
        return eligibleProducts;
    }
    const sortedProducts = [...eligibleProducts];
    const skuOrder: Record<string, number> = verizonSkuOrder;
    sortedProducts.sort((a, b) => {
        return skuOrder[a.productSku] - skuOrder[b.productSku];
    });

    return sortedProducts;
};
