import type { ScheduleCallbackType } from "@expert/shared-types";

export type TaskCompletedReason = "SessionEnded" | "CallbackInitiated" | "Transferred";

export type ChannelKind = "voice" | "chat" | "sms" | "training";
export type TaskStatus = "pending" | "assigned" | "wrapping" | "completed" | "cancelled";
export const AGENT_ACTIVITIES = [
    "Offline",
    "Default",
    "Available",
    "Busy",
    "Wrapping",
    "Callback Pending",
    "Lunch",
    "Coaching",
    "Break",
    "Meeting",
    "Special Project",
    "Training",
    "Academy Support",
    "Alt Claims",
    "Technical Issues",
    "Orbit Training",
] as const;
export type AgentActivity = (typeof AGENT_ACTIVITIES)[number];
export const UNSELECTABLE_AGENT_ACTIVITIES: ReadonlySet<AgentActivity> = new Set([
    "Callback Pending",
    "Busy",
    "Wrapping",
    "Default",
    "Offline",
    "Orbit Training",
]);

export function isUnselectableActivity(activityName: AgentActivity) {
    return UNSELECTABLE_AGENT_ACTIVITIES.has(activityName);
}

export type CallDirection = "inbound" | "outbound";

export interface HoldState {
    onHold: boolean;
    onHoldSince: number | null;
}

/** State that covers a new or existing callback for a voice task */
export interface CallbackState {
    callbackType: ScheduleCallbackType;
    scheduledAt: number;
    scheduledFor: number;
    callbackDelay: number | null;
    callbackMDN: string;

    /** The task we are calling back from */
    originTaskId: string;
}

//TODO: These can be more specific for tracking, such as tying them back to whether this was scheduled or was semi-ad hoc
export const OUTBOUND_CALL_KINDS = {
    CallbackNow: "CallbackNow", // Outbound call initiated during a session with a customer
    AdHocOutboundCall: "AdHocOutboundCall", // Outbound call not initiated during a session with a customer
} as const;

export type OutboundCallKind = (typeof OUTBOUND_CALL_KINDS)[keyof typeof OUTBOUND_CALL_KINDS];

export type WrappingStartReason = "TaskWrapping" | "CallbackCancelled" | "UnexpectedWrappingTask";
export type WrappingEndReason = "AgentTerminatedWrapping" | "WrappingTimeExpired" | "CallbackScheduled";
export interface WrappingState {
    startReason: WrappingStartReason;
    expirationTimestamp: number;
}
