export function InfoIcon() {
    return (
        <svg fill="none" height="16" viewBox="0 0 17 16" width="17" xmlns="http://www.w3.org/2000/svg">
            <path
                clipRule="evenodd"
                d="M4.87282 5.40445C4.63392 5.16611 4.24703 5.16628 4.00834 5.40497L1.84583 7.56748C1.60697 7.80634 1.60697 8.19362 1.84583 8.43248L4.00834 10.595C4.24721 10.8339 4.63448 10.8339 4.87334 10.595L7.03585 8.43248C7.27471 8.19362 7.27471 7.80634 7.03585 7.56748L4.87282 5.40445ZM9.63086 8.43248L11.7934 10.595C12.0322 10.8339 12.4195 10.8339 12.6584 10.595L14.8209 8.43248C15.0597 8.19362 15.0597 7.80634 14.8209 7.56748L12.6584 5.40497C12.4195 5.16611 12.0322 5.16611 11.7934 5.40497L9.63086 7.56748C9.39199 7.80634 9.39199 8.19362 9.63086 8.43248ZM5.73835 4.53997L7.90085 6.70248C8.13972 6.94134 8.52699 6.94134 8.76586 6.70248L10.9284 4.53997C11.1672 4.30111 11.1672 3.91383 10.9284 3.67497L8.76586 1.51246C8.52699 1.2736 8.13972 1.2736 7.90085 1.51246L5.73835 3.67497C5.49948 3.91383 5.49948 4.30111 5.73835 4.53997ZM10.9284 11.46L8.76586 9.29748C8.52699 9.05862 8.13972 9.05862 7.90085 9.29748L5.73835 11.46C5.49948 11.6989 5.49948 12.0861 5.73835 12.325L7.90085 14.4875C8.13972 14.7264 8.52699 14.7264 8.76586 14.4875L10.9284 12.325C11.1672 12.0861 11.1672 11.6989 10.9284 11.46ZM8.33335 2.80996L7.03585 4.10747L8.33335 5.40497L9.63086 4.10747L8.33335 2.80996ZM3.14334 7.99998L4.44084 9.29748L5.73835 7.99998L4.44084 6.70248L3.14334 7.99998ZM7.03585 11.8925L8.33335 13.19L9.63086 11.8925L8.33335 10.595L7.03585 11.8925ZM13.5234 7.99998L12.2259 6.70248L10.9284 7.99998L12.2259 9.29748L13.5234 7.99998Z"
                fill="white"
                fillRule="evenodd"
            />
        </svg>
    );
}
