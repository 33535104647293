import { EligibilityStatus, SaleProgress, type SaleStatusStyle, StatusStyles } from "../../types";
import { DeclinedIcon, EnrolledIcon, IneligibleIcon, PendingIcon, UnknownIcon } from "../Icons";
import KungFuKat from "../Icons/KungFuKat.svg";

interface SalesView {
    badgeText: string | ((partner: string) => string);
    badgeStyle: SaleStatusStyle | undefined;
    statusHeader: string;
    statusBody: string;
    icon?: JSX.Element;
}

const statusMap: Record<string, SalesView | undefined> = {
    [EligibilityStatus.Ineligible]: {
        badgeText: "Not eligible",
        badgeStyle: StatusStyles.Neutral,
        statusHeader: "Not eligible",
        statusBody: "This customer isn’t eligible for any additional protection plans at this time.",
        icon: IneligibleIcon,
    },
    [EligibilityStatus.Unknown]: {
        badgeText: "Unknown",
        badgeStyle: StatusStyles.Neutral,
        statusHeader: "Eligibility unknown",
        statusBody: "We couldn’t find the customer’s eligibility.",
        icon: UnknownIcon,
    },
    [EligibilityStatus.Pending]: {
        badgeText: "Pending",
        badgeStyle: StatusStyles.Neutral,
        statusHeader: "Pending enrollment",
        statusBody:
            "This customer has already been enrolled in Verizon Home Device Protect and should receive a welcome email within 24-48 hours.",
        icon: PendingIcon,
    },
    [EligibilityStatus.Enrolled]: {
        badgeText: "Enrolled",
        badgeStyle: StatusStyles.success,
        statusHeader: "Already enrolled",
        statusBody: "This customer is already enrolled in an available protection plan.",
        icon: EnrolledIcon,
    },
    [SaleProgress.Declined]: {
        badgeText: "Eligible",
        badgeStyle: StatusStyles.success,
        statusHeader: "Customer declined",
        statusBody: "This customer has declined",
        icon: DeclinedIcon,
    },
    [SaleProgress.Failure]: {
        badgeText: "Eligible",
        badgeStyle: StatusStyles.success,
        statusHeader: "Enrollment failed",
        statusBody: "Let the customer know that we couldn’t sign them up at this time",
        icon: IneligibleIcon,
    },
    [SaleProgress.Success]: {
        badgeText: "Eligible",
        badgeStyle: StatusStyles.success,
        statusHeader: "Nice sale!",
        statusBody: "Let the customer know they’ll get a welcome email within 24 hours.",
        icon: <img data-testid="kungfu-kat-animation" src={KungFuKat} alt="" />,
    },
};

export const getSalesViewConfig = (status: EligibilityStatus | SaleProgress) => {
    return (
        statusMap[status] ?? {
            badgeText: "Unknown",
            badgeStyle: StatusStyles.Neutral,
            statusHeader: "Unknown",
            statusBody: "Something went wrong.",
            icon: IneligibleIcon,
        }
    );
};
