import { getRootDispatcher } from "@expert/analytics";
import type { EligibleProduct } from "../stores";
import { type EligibilityRequestV2, EligibilityStatus } from "../types";
import { salesApi } from "./api";

export const getEligibilityV2 = async (eligibility: EligibilityRequestV2): Promise<EligibleProduct[]> => {
    const eligibleProducts = await salesApi
        .post("v2/eligibility", {
            json: eligibility,
            retry: 0, // retries already done via @tanstack/react-query
        })
        .json<EligibleProduct[]>();

    void getRootDispatcher().dispatchAction("QueryCompleted", "EligibilityV2", {
        eligibleProductSkus: eligibleProducts.map((p) =>
            p.status === EligibilityStatus.Eligible ? p.productSku : undefined,
        ),
        inEligibleProductSkus: eligibleProducts.map((p) =>
            p.status === EligibilityStatus.Ineligible ? p.productSku : undefined,
        ),
    });

    eligibleProducts.forEach((result) => {
        void getRootDispatcher()
            .withExtra({
                productSku: result.productSku,
                productOfferId: result.id,
                eligibilityStatus: result.status,
            })
            .dispatchAction("ApiResult", "EligibilityStatus");
    });

    return eligibleProducts;
};
