import { type VoiceTask } from "@expert/sdk";
import { HangupButton } from "../Buttons";

interface PendingCallControlsProps {
    task: VoiceTask;
}

export function PendingCallControls({ task }: PendingCallControlsProps): JSX.Element {
    return <HangupButton task={task} />;
}
