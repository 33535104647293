import { type ReactiveMessagePayload } from "../types";
import { generateOzmoEmulatorUrl, generateOzmoTutorialUrl } from "./generateOzmoUrl";

export function processMessage({
    response,
    externalDevices,
    tutorials,
    multiModal,
    sessionGroupId,
    sessionId,
}: ReactiveMessagePayload): {
    response: string;
    analytics?: Record<string, unknown>;
} {
    if (tutorials && tutorials.length > 0) {
        const latestTutorial = tutorials[tutorials.length - 1];
        const url = generateOzmoTutorialUrl(latestTutorial, sessionGroupId, sessionId);
        const ozmoMarkdown = `<a href="${url}" ozmo actionId="OpenTutorial">${latestTutorial.name}</a>`;
        return {
            response: `${response}\n\nView in Ozmo: ${ozmoMarkdown}`,
            analytics: { includesOzmoTutorialLink: true },
        };
    } else if (externalDevices && externalDevices.length > 0) {
        /*
        If there's 1 device, show in a single line like:
            View in Ozmo: Device 1
        If there's more than 1 device, show the emulator links as a list like:
            View in Ozmo:
            - Device 1
            - Device 2
        */
        const shouldShowAsList = externalDevices.length > 1;
        let updatedResponse = `${response}\n\nView in Ozmo: ${shouldShowAsList ? "\n" : ""}`;
        externalDevices.forEach((device) => {
            const url = generateOzmoEmulatorUrl(device.model, sessionGroupId, sessionId);
            if (shouldShowAsList) updatedResponse += "* ";
            updatedResponse += `<a href="${url}" ozmo actionId="OpenEmulator">${device.name}</a>\n`;
        });
        return { response: updatedResponse, analytics: { includesOzmoEmulatorLink: true } };
    } else if (multiModal) {
        const imageMarkdown = `<img src="${multiModal.collage}" title="${multiModal.title}" />`;
        return {
            response: `${response}\n\n${imageMarkdown}`,
            analytics: { includesMultiModalImage: true },
        };
    }
    return { response };
}
