import { ActionIcon, Button, Stack } from "@mantine/core";
import { useState } from "react";
import { useAnalytics } from "@expert/analytics";
import { InfoIcon, RightArrowIcon } from "../Icons";
import { useCurrentStepStore } from "../../stores";
import { PlanInfoGuidedFlow } from "./PlanInfoGuidedFlow";
import styles from "./StepOneFooter.module.css";

export function StepOneFooter({ productSku }: { productSku: string }) {
    const [step, updateStep] = useCurrentStepStore((state) => [state.step, state.updateStep]);
    const [opened, setOpened] = useState(false);
    const { dispatcher } = useAnalytics();
    const openDrawer = () => {
        setOpened(true);
    };
    const closeDrawer = () => {
        setOpened(false);
    };

    return (
        <Stack className={styles.footerBox}>
            <PlanInfoGuidedFlow productSku={productSku} opened={opened} closeDrawer={closeDrawer} />
            <ActionIcon
                className={styles.infoIcon}
                variant="transparent"
                onClick={() => {
                    openDrawer();
                    void dispatcher.withExtra({ step }).dispatchBusinessEvent("ButtonClick_PlanInfo");
                }}
            >
                <InfoIcon />
            </ActionIcon>
            <Button
                className={styles.stepAdvance}
                variant="subtle"
                color="white"
                rightSection={<RightArrowIcon />}
                onClick={() => {
                    updateStep("SuggestedOfferStep");
                    void dispatcher.withExtra({ step }).dispatchBusinessEvent("ButtonClick_DevicesAsked");
                }}
            >
                I asked about devices
            </Button>
        </Stack>
    );
}
