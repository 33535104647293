import type { VoiceTask } from "@expert/sdk";
import { HangupButton } from "./Buttons";
import { AssignedCallControls, CancelledCallControls, CompletedCallControls, PendingCallControls } from "./Status";

export function CallControlsSwitch({ task }: { task: VoiceTask }) {
    const renderControls = () => {
        switch (task.status) {
            case "pending":
                return <PendingCallControls task={task} />;
            case "assigned":
                if (!task.conferenceId || !task.conferenceStarted) {
                    return <HangupButton task={task} />;
                }
                return <AssignedCallControls task={task} />;
            case "wrapping":
                // We don't have any controls during wrapping, we have the session summary modal
                return null;
            case "completed":
                return <CompletedCallControls />;
            case "cancelled":
                return <CancelledCallControls />;
            default:
                return null;
        }
    };
    return <>{renderControls()}</>;
}
