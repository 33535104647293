import * as React from "react";
import type { SVGProps } from "react";

export function BronzeBadge(props: SVGProps<SVGSVGElement>) {
    return (
        <svg viewBox="0 0 42 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M21.0547 48C26.0249 48 33.4071 42.4263 36.0667 40.5731C38.8206 38.654 41.8484 36.1902 41.8484 32.6603V7.81134C41.8484 3.4974 38.351 0 34.037 0H8.47802C4.16409 0 0.666687 3.4974 0.666687 7.81134V32.6603C0.666687 35.7784 2.73693 38.0484 5.1348 39.7211C7.66555 41.486 15.8816 48 21.0547 48Z"
                fill="#CA8E56"
            />
            <path
                d="M21.0547 48C26.0249 48 33.4071 42.4263 36.0667 40.5731C38.8206 38.654 41.8484 36.1902 41.8484 32.6603V7.81134C41.8484 3.4974 38.351 0 34.037 0H8.47802C4.16409 0 0.666687 3.4974 0.666687 7.81134V32.6603C0.666687 35.7784 2.73693 38.0484 5.1348 39.7211C7.66555 41.486 15.8816 48 21.0547 48Z"
                fill="url(#paint0_linear_3616_6126)"
                fillOpacity="0.3"
            />
            <path
                d="M2.20648 3.15456L13.6501 19.3737H29.144L16.542 0H8.47807C5.90878 0 3.6306 1.24052 2.20648 3.15456Z"
                fill="#8E332D"
            />
            <path
                d="M2.20648 3.15456L13.6501 19.3737H29.144L16.542 0H8.47807C5.90878 0 3.6306 1.24052 2.20648 3.15456Z"
                fill="url(#paint1_linear_3616_6126)"
                fillOpacity="0.15"
            />
            <path
                d="M40.6647 3.67795C39.2853 1.46976 36.8336 0 34.0371 0H26.9764L14.2334 19.3737L29.3722 19.8808L40.6647 3.67795Z"
                fill="#802A1E"
            />
            <path
                d="M40.6647 3.67795C39.2853 1.46976 36.8336 0 34.0371 0H26.9764L14.2334 19.3737L29.3722 19.8808L40.6647 3.67795Z"
                fill="url(#paint2_linear_3616_6126)"
                fillOpacity="0.15"
            />
            <path
                d="M21.2575 39.1531C27.5317 39.1531 32.618 34.0668 32.618 27.7926C32.618 21.5184 27.5317 16.4321 21.2575 16.4321C14.9833 16.4321 9.89703 21.5184 9.89703 27.7926C9.89703 34.0668 14.9833 39.1531 21.2575 39.1531Z"
                fill="#EEB776"
            />
            <path
                d="M21.2575 39.1531C27.5317 39.1531 32.618 34.0668 32.618 27.7926C32.618 21.5184 27.5317 16.4321 21.2575 16.4321C14.9833 16.4321 9.89703 21.5184 9.89703 27.7926C9.89703 34.0668 14.9833 39.1531 21.2575 39.1531Z"
                fill="url(#paint3_radial_3616_6126)"
                fillOpacity="0.6"
            />
            <path
                d="M21.2575 39.1531C27.5317 39.1531 32.618 34.0668 32.618 27.7926C32.618 21.5184 27.5317 16.4321 21.2575 16.4321C14.9833 16.4321 9.89703 21.5184 9.89703 27.7926C9.89703 34.0668 14.9833 39.1531 21.2575 39.1531Z"
                stroke="#A47A53"
                strokeWidth="0.5"
            />
            <path
                d="M20.491 21.173C20.7436 20.4462 21.7713 20.4462 22.0239 21.173L23.1469 24.4035C23.2583 24.7241 23.5575 24.9414 23.8968 24.9483L27.3162 25.018C28.0854 25.0337 28.403 26.0111 27.7899 26.4759L25.0645 28.5422C24.7941 28.7472 24.6798 29.0989 24.7781 29.4238L25.7684 32.6974C25.9912 33.4338 25.1598 34.0379 24.5283 33.5984L21.7209 31.6449C21.4424 31.4511 21.0725 31.4511 20.794 31.6449L17.9866 33.5984C17.3551 34.0379 16.5237 33.4338 16.7465 32.6974L17.7368 29.4238C17.8351 29.0989 17.7208 28.7472 17.4504 28.5422L14.725 26.4759C14.1119 26.0111 14.4295 25.0337 15.1987 25.018L18.6181 24.9483C18.9574 24.9414 19.2566 24.7241 19.368 24.4035L20.491 21.173Z"
                fill="#FFFCF6"
                stroke="#A47A53"
                strokeWidth="0.5"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_3616_6126"
                    x1="0.666687"
                    y1="4.25"
                    x2="36.9167"
                    y2="40.25"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#693C11" />
                    <stop offset="1" stopColor="#B06E2D" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_3616_6126"
                    x1="18.0117"
                    y1="7.15101"
                    x2="8.83206"
                    y2="15.0628"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopOpacity="0" />
                    <stop offset="1" />
                </linearGradient>
                <linearGradient
                    id="paint2_linear_3616_6126"
                    x1="23.2356"
                    y1="1.47078"
                    x2="34.3932"
                    y2="12.9327"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.00968827" stopOpacity="0" />
                    <stop offset="1" />
                </linearGradient>
                <radialGradient
                    id="paint3_radial_3616_6126"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(15.5773 17.9029) rotate(58.4006) scale(23.5203)"
                >
                    <stop stopColor="white" stopOpacity="0.7" />
                    <stop offset="1" stopColor="white" stopOpacity="0" />
                </radialGradient>
            </defs>
        </svg>
    );
}
