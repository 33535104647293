import type { CachedUser } from "@expert/shared-utils";
import { userCache } from "@expert/shared-utils";
import { useAuth } from "react-oidc-context";

/** Can ONLY be used under the <AuthProvider> scope
 * Retrieves the sso token only
 */
export function useSetupSsoToken() {
    const auth = useAuth();
    if (!auth.user) {
        throw new Error("Unable to initialize SDK, no SSO User Available");
    }
    userCache.setUser(auth.user as unknown as CachedUser);
}
