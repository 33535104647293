import { useMemo, useState } from "react";
import type { AgentSdk } from "../agentSdk";
import type { AgentSdkProviderCreator } from "../AgentSdkProvider";

interface UseAgentSdkHookReturnValue {
    agentSdk: AgentSdk | null;
    isReady: boolean;
}

export function useAgentSdkInternal<TAppConfig>(
    token: string | null,
    employeeNum: string | null,
    hdxEnabled: boolean,
    appConfig: TAppConfig,
    agentSdkCreator: AgentSdkProviderCreator<TAppConfig>,
): UseAgentSdkHookReturnValue {
    const [isReady, setIsReady] = useState(false);

    const agentSdk = useMemo(() => {
        if (!token) {
            return null;
        }

        return agentSdkCreator(token, employeeNum, hdxEnabled, appConfig, (isSdkReady: boolean) => {
            setIsReady(isSdkReady);
        });
    }, [token, employeeNum, hdxEnabled, appConfig, agentSdkCreator]);

    return { agentSdk, isReady };
}
