import { useQuery } from "@tanstack/react-query";
import { getStatistics } from "../api";
import type { StatisticsPayload } from "../types";

export const useStatistics = () => {
    const { data, error, isLoading } = useQuery<StatisticsPayload>({
        queryKey: ["statistics"],
        queryFn: async () => await getStatistics(),
    });

    return { statisticsData: data, error, isLoading };
};
