import { getLogger } from "@expert/logging";
import { Notification, Stack } from "@mantine/core";
import { createContext, useContext } from "react";
import classes from "./Toast.module.css";
import { useIcon, useCss as useToastCss } from "./hooks";
import { useToastStore } from "./store";
import { type Toast } from "./types";

const logger = getLogger({
    module: "ToastProvider",
});

interface ToastContextValue {
    info: (message: string) => void;
    success: (message: string) => void;
    error: (message: string) => void;
    show: (props: Omit<Toast, "id">) => void;
}

function Toast({ config }: { config: Toast }): JSX.Element {
    const Icon = useIcon(config.type);
    const css = useToastCss(config.type);

    // auto close toast after delay
    setTimeout(config.done, config.delay);

    return (
        <Notification
            classNames={{
                root: css,
                icon: classes.icon,
                description: classes.description,
                title: classes.title,
                closeButton: classes.closeButton,
            }}
            icon={<Icon />}
            onClose={config.done}
            title={config.title}
        >
            {config.message}
        </Notification>
    );
}

function ToastContainer({ toasts }: { toasts: Toast[] }): JSX.Element {
    return (
        <Stack classNames={{ root: classes.toastContainer }}>
            {toasts.map((toast) => (
                <Toast config={toast} key={toast.id} />
            ))}
        </Stack>
    );
}

function notImplemented() {
    logger.warn("Toast cannot be used outside of ToastProvider!");
}

const ToastContext = createContext<ToastContextValue>({
    show: notImplemented,
    info: notImplemented,
    success: notImplemented,
    error: notImplemented,
});

export function ToastProvider({ children }: { children: React.ReactNode }): JSX.Element {
    const { toasts, addToast } = useToastStore();

    const context = {
        show: addToast,
        info: (message: string) => {
            addToast({
                message,
                type: "info",
            });
        },
        success: (message: string) => {
            addToast({
                message,
                type: "success",
            });
        },
        error: (message: string) => {
            addToast({
                message,
                type: "error",
            });
        },
    };

    return (
        <ToastContext.Provider value={context}>
            {children}
            <ToastContainer toasts={toasts} />
        </ToastContext.Provider>
    );
}

export const useToast = () => useContext(ToastContext);
