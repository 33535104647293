import { sdkEventBus, useActiveVoiceTask, useAgentSdk, useCallbackState } from "@expert/sdk";
import { classNames } from "@expert/shared-utils";
import { Flex, Group, Stack } from "@mantine/core";
import PQueue from "p-queue";
import { useEffect, useMemo } from "react";
import { useGlobalNotification } from "@expert/common-ui";
import { CallbackInfoBanner, ScheduleCallbackForm } from "../ScheduleCallback";
import { TransferCall } from "../TransferCall/TransferCall";
import { clearCallbacksState, updateExistingCallback } from "../eventHandlers";
import classes from "./CallControls.module.css";
import { CallControlsSwitch } from "./CallControlsSwitch";
import { CallDetailsSelector } from "./CallDetailsSelector";
import { ConferencePanel } from "./ConferencePanel";
import { Dialpad } from "./Dialpad/Dialpad";
import { PendingCallDuration } from "./PendingCallDuration";
import { useControlsStore } from "./controls.store";

const BUFFER_INTERVAL = 300;
const dialQueue = new PQueue({ concurrency: 1, intervalCap: 1, interval: BUFFER_INTERVAL });

export function CallControls(): JSX.Element {
    const task = useActiveVoiceTask();
    const agentSdk = useAgentSdk();
    const callbackState = useCallbackState();
    const globalNotification = useGlobalNotification();

    const {
        clearPanelsState,
        scheduleCallbackPanelOpen,
        isConference,
        conferencePanelOpen,
        dialpadOpen,
        dialpadInput,
        transferCallActive,
        dialpadNotification,
        setIsConference,
        setLoadingExistingCallback,
        setDialpadNotification,
        resetState: resetControlsStoreState,
    } = useControlsStore();

    const isPendingOutbound = useMemo(() => {
        return (
            (task.callDirection === "outbound" && task.status === "pending") ||
            (task.callDirection === "outbound" && !task.conferenceStarted)
        );
    }, [task.callDirection, task.status, task.conferenceStarted]);

    useEffect(() => {
        if (["wrapping", "completed", "cancelled"].includes(task.status)) {
            clearPanelsState();
            setIsConference(false);

            if (dialpadNotification) {
                globalNotification.clearNotification(dialpadNotification);
                setDialpadNotification(undefined);
            }
        }

        // Whenever the main task is in a pending state, that means we're accepting a call
        // and it has to be the first call in this session (since a conference can't have been started yet).
        // If that's the case, then we reset the call controls state here so the call controls always
        // start in the default state at the beginning of a call
        if (task.status === "pending") {
            resetControlsStoreState();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [task.status]);

    useEffect(() => {
        if (dialpadInput.length > 0) {
            const dial = dialpadInput[dialpadInput.length - 1];
            void dialQueue.add(() => agentSdk.sendDtmfDigits(dial));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dialpadInput]);

    useEffect(() => {
        const unsubCallAccepted = sdkEventBus.on("call_accepted", async (voiceTask) => {
            clearCallbacksState();
            setLoadingExistingCallback(true);
            await updateExistingCallback(voiceTask);
            setLoadingExistingCallback(false);
        });

        return () => {
            unsubCallAccepted();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (task.conferenceParticipants.length || task.pendingConferenceParticipants.length) {
            clearPanelsState();
            setIsConference(true);
        } else {
            setIsConference(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [task.conferenceParticipants, task.pendingConferenceParticipants]);

    return (
        <Stack className={classes.controlsContainer}>
            <Stack
                className={classNames(
                    classes.controlsWrapper,
                    isConference && conferencePanelOpen ? classes.noBottomLeftRadius : null,
                )}
                data-testid="active-task"
            >
                <Group className={classes.controlsRow}>
                    <CallDetailsSelector task={task} />
                    {isPendingOutbound ? (
                        <Flex align="center" justify="center" className={classes.pendingCallStyle}>
                            <PendingCallDuration task={task} />
                        </Flex>
                    ) : null}

                    <Flex flex="1" justify="flex-end" miw="fit-content">
                        <Group align="center" data-testid={`call-controls-group-${task.status}`} justify="flex-end">
                            <CallControlsSwitch task={task} />
                        </Group>
                    </Flex>
                </Group>
                {callbackState && task.status === "assigned" ? <CallbackInfoBanner /> : null}
                {scheduleCallbackPanelOpen ? <ScheduleCallbackForm /> : null}
                {transferCallActive ? <TransferCall /> : null}
            </Stack>
            <Stack className={classes.dropdownControlsWrapper}>
                {dialpadOpen ? <Dialpad /> : null}
                {isConference && conferencePanelOpen ? <ConferencePanel /> : null}
            </Stack>
        </Stack>
    );
}
