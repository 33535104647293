import type { SVGProps } from "react";

interface ChallengesIconProps extends SVGProps<SVGSVGElement> {
    disabled: boolean;
}

export function ChallengesIcon(props: ChallengesIconProps) {
    const strokeColor = props.disabled ? "#333F48" : "#933ADC";
    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" height="100%" viewBox="0 0 25 25" width="100%" {...props}>
            <path
                stroke={strokeColor}
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4.5 10.072a1 1 0 0 1 1.555-.832l5.39 3.594a1 1 0 0 0 1.11 0l5.39-3.594a1 1 0 0 1 1.555.832v8.1a1 1 0 0 1-.445.832l-6.5 4.333a1 1 0 0 1-1.11 0L4.945 19a1 1 0 0 1-.445-.832v-8.096Z"
                clipRule="evenodd"
            />
            <path
                stroke={strokeColor}
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m4.5 11.7 6.945 4.631a1 1 0 0 0 1.11 0L19.5 11.7"
            />
            <path
                stroke={strokeColor}
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m4.5 15.2 6.945 4.631a1 1 0 0 0 1.11 0L19.5 15.2"
            />
            <path
                stroke={strokeColor}
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12.47.787 13.5 3h2a.489.489 0 0 1 .345.855l-1.736 1.711.96 2.208a.524.524 0 0 1-.745.655L12 7.122 9.676 8.429a.524.524 0 0 1-.746-.655l.96-2.208-1.734-1.709A.489.489 0 0 1 8.5 3h2L11.529.787a.53.53 0 0 1 .942 0Z"
                clipRule="evenodd"
            />
        </svg>
    );
}
