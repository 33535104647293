import { getActiveSession } from "../getters";
import { useSession } from "./useSession";

/** Retrieves the current callback state or null for the current session & call */
export function useCallbackState() {
    const session = useSession();

    return session.callbackState;
}

export function getCallbackState() {
    const session = getActiveSession();

    return session.callbackState;
}
