import type { TrainingAttachment, TrainingTask } from "@expert/sdk";
import { type Logger } from "@expert/logging";
import { TwilioTask, type TwilioTaskConfig } from "./twilioTask";

interface TrainingDetails {
    created: Date;
    createdBy: string;
    /** string "1" or "0" */
    isActive: string;
    /** string "1" or "0" */
    isArchived: string;
    lastUpdatedBy: string;
    lastUpdatedDateTime: Date;
    maxAttempts: number;
    maxDispatchLimit: {
        unitOfMeasurement: string;
        value: number; // Should this be `any` or `unknown` instead?
    };
    myAbsorbId: string;
    priority: number;
    tags: string[];
    trainingAttachments: TrainingAttachment[];
    // This is a UUID, should we do some regex validation or not?
    trainingId: string;
    /** In minutes */
    trainingLength: number;
    trainingLink: string;
    trainingName: string;
    trainingNotes: string;
    // What does this represent, can we get an enumeration value for this instead?
    monitorType: number;
}

interface TrainingTaskAttributes {
    training: TrainingDetails;
    assignToWorker: string;
    workerSid: string;
    empId: string;
    /** This is a username ex "Joe.Schmo" */
    supervisor: string;
    // Can we get an enumeration value here, or is this always "Training"?
    type: string;
}

// This is the Raw Event that we'll receive from Mission Control
export class TwilioTrainingTask extends TwilioTask implements TrainingTask {
    attributes: TrainingTaskAttributes;
    // TODO: This may not be needed
    // workflowSid: string;

    createdDate: Date;
    isActive: boolean;
    client: string | undefined;
    clientFriendlyName: string | undefined;

    tags: string[];
    attachments: TrainingAttachment[];
    lengthMinutes: number;
    link: string;
    name: string;
    notes: string;

    public override readonly hasCustomer = false;

    public override readonly partner = "asurion";

    public override readonly sessionId = undefined;

    public override readonly previousTaskId = undefined;

    constructor(name: string, config: TwilioTaskConfig) {
        super(name, config);

        this.attributes = config.reservation.task.attributes as TrainingTaskAttributes;

        this.createdDate = this.attributes.training.created;
        this.isActive = this.attributes.training.isActive === "1";

        this.tags = this.attributes.training.tags;
        this.attachments = this.attributes.training.trainingAttachments;
        this.lengthMinutes = this.attributes.training.trainingLength;
        this.link = this.attributes.training.trainingLink;
        this.name = this.attributes.training.trainingName;
        this.notes = this.attributes.training.trainingNotes;
    }

    // TODO: Remove this function if we don't end up adding any implementation details by the time the
    //       training feature is complete.
    public accept(logger: Logger) {
        logger.info("Accept Training Task | Accepting");
    }
}
