import { Stack } from "@mantine/core";
import { useMemo } from "react";
import { useCallbackState } from "@expert/sdk";
import { isNullish } from "@expert/shared-utils";
import { CallbackCallBar } from "../CallDetails";
import noCallControlsClasses from "./NoCallControls.module.css";
import { IdleNoCallControls } from "./IdleNoCallControls";

export function NoCallControls(): JSX.Element {
    const callbackState = useCallbackState();
    const isAwaitingCallback = useMemo(() => {
        return (
            !isNullish(callbackState?.callbackDelay) &&
            callbackState.scheduledFor &&
            callbackState.callbackType === "CallbackNow"
        );
    }, [callbackState]);

    return (
        <>
            {isAwaitingCallback ? (
                <Stack
                    justify="center"
                    dir=""
                    className={noCallControlsClasses.callbackNoControlsWrapper}
                    data-testid="no-active-task"
                >
                    <CallbackCallBar />
                </Stack>
            ) : (
                <Stack
                    justify="center"
                    dir=""
                    className={noCallControlsClasses.noControlsWrapper}
                    data-testid="no-active-task"
                >
                    <IdleNoCallControls />
                </Stack>
            )}
        </>
    );
}
