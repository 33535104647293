import { createContext, useContext, useEffect } from "react";
import { getLogger } from "@expert/logging";
import { useExpertWorkspaceWebsocketInternal } from "../hooks/useExpertWorkspaceWebsocket";
import type { SendJsonMessage } from "../types";

export const ExpertWorkspaceWebSocketContext = createContext<{ sendWebSocketMessage: SendJsonMessage } | null>(null);

interface ExpertWorkspaceWebSocketProviderProps {
    token: string;
    identity: string;
    children?: React.ReactNode;
}

const logger = getLogger({ module: "WebSocketProvider" });

export function ExpertWorkspaceWebSocketProvider({ token, identity, children }: ExpertWorkspaceWebSocketProviderProps) {
    const { sendWebSocketMessage, loading, readyState } = useExpertWorkspaceWebsocketInternal(token, identity);
    // TODO: Decide how do we want to handle WS reconnect/disconnect
    // if (loading) return <GlobalLoader msg="Creating websocket connection..." />;
    useEffect(() => {
        logger.trace(`==== WEB SOCKET STATE IS ${readyState}, LOADING: ${loading} ====`);
    }, [loading, readyState]);
    return (
        <ExpertWorkspaceWebSocketContext.Provider value={{ sendWebSocketMessage }}>
            {children}
        </ExpertWorkspaceWebSocketContext.Provider>
    );
}

export function useExpertWorkspaceWebSocket() {
    const context = useContext(ExpertWorkspaceWebSocketContext);
    return context;
}
