import { useEffect, useState } from "react";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { getLogger } from "@expert/logging";
import { env } from "../environment";
import { WS_EMITTER_NAME, expertWorkspaceWebSocketEventBus } from "../eventBus";
import type { ExpertWorkspaceWebSocketMessagePayload } from "../types";

const heartbeatInterval = 60000;
const url = env.webSocketUrl;

const logger = getLogger({
    module: "useExpertWorkspaceWebSocket",
});

export function useExpertWorkspaceWebsocketInternal(token: string, identity: string) {
    const [loading, setLoading] = useState(true);
    const { sendJsonMessage: sendWebSocketMessage, readyState } = useWebSocket(
        url,
        {
            queryParams: { token },
            heartbeat: {
                interval: heartbeatInterval,
                message: JSON.stringify({
                    action: "heartbeat",
                    correlationId: identity,
                }),
            },
            shouldReconnect: (_closeEvent) => true,
            reconnectAttempts: 10,
            reconnectInterval: (attemptNumber) => Math.min(Math.pow(2, attemptNumber) * 1000, 10000),
            onMessage,
            onError,
        },
        true,
    );

    useEffect(() => {
        setLoading(readyState !== ReadyState.OPEN);
    }, [loading, readyState]);

    const result = {
        sendWebSocketMessage,
        loading,
        readyState,
    };
    return result;
}

const onMessage = (message: MessageEvent) => {
    if (message.type !== "message") {
        logger.warn({ message }, `Unsupported message type: ${message.type}`);
        return;
    }
    if (message.origin !== url) {
        logger.warn({ message }, `Message from unexpected origin: ${message.origin}`);
        return;
    }
    let event: ExpertWorkspaceWebSocketMessagePayload;
    try {
        event = JSON.parse(message.data as string) as ExpertWorkspaceWebSocketMessagePayload;
    } catch (err: unknown) {
        logger.error({ err, message }, "Failed to parse message body");
        return;
    }
    expertWorkspaceWebSocketEventBus.emit(`${WS_EMITTER_NAME}_${event.messageType}`, event.payload);
};

const onError = (event: Event) => {
    console.log("==== WEBSOCKET Websocket error ====", event);
};
