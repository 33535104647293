import { reactAnalytics } from "@soluto-private/eventualize-react";
import {
    type ExpertAssistMessage,
    type FailureReason,
    addFailureMessage,
    addMessage,
} from "@soluto-private/expert-workspace-timeline";
import { getLogger } from "@expert/logging";
import { type ReactiveMessagePayload, processMessage, setDeviceInfo } from "@expert/messages";
import { trackAppcuesEvent, trackFullStoryEvent } from "@expert/monitoring";
import type { GaiaBotMessage } from "../types";
import { features } from "../../utils";

const mockMultiModal = {
    multiModal: {
        title: "reset-network-settings-ios",
        collage:
            "https://images.ctfassets.net/x1fwbjhscixw/30KY12OO0yKjhJAz4GNKXS/1aaa47de247d890323fb3886e236a7cf/MultimodalContent.jpg",
        //"https://images.ctfassets.net/x1fwbjhscixw/30KY12OO0yKjhJAz4GNKXS/1aaa47de247d890323fb3886e236a7cf/no-such-image.jpg",
        collection: {
            1: "https://images.ctfassets.net/x1fwbjhscixw/3UtQ5WyB8tonJ85MYawj3f/ac3dc778208ba71b15ee3018fdf698ce/page_3.jpg",
            2: "https://images.ctfassets.net/x1fwbjhscixw/RkEc90istB58hevRuid28/c5e861410d65d759e4d22eb3021e1999/page_4.jpg",
            3: "https://images.ctfassets.net/x1fwbjhscixw/6rYcmqRNYcFQFUrHBUzpJS/21ae6da58d865c2119d58147bfd8cab9/page_5.jpg",
            4: "https://images.ctfassets.net/x1fwbjhscixw/7nTHyZpkpXnvwTNfsbEpof/4f181581c9239b3389bc2a55f434fc55/page_6.jpg",
            5: "https://images.ctfassets.net/x1fwbjhscixw/2ON3revuME2sohNmMDR2n4/571df6a2ed93ad93cabf50a0d212f42c/page_7.jpg",
            6: "https://images.ctfassets.net/x1fwbjhscixw/4LwlKclwpnV790DQwBJkiP/eee2bde0443e2c4962d4967f63f67e6a/page_8.jpg",
        },
    },
};

export const addErrorMessage = (reason: FailureReason) => {
    const message = addFailureMessage(reason);
    void reactAnalytics.dispatcher.dispatchBusinessEvent("ErrorMessageReceived", {
        senderType: "system",
        messageType: message.type,
        messageId: message.id,
        message: message.text,
    });
};

export const handleIncomingMessage = (msg: GaiaBotMessage) => {
    const logger = getLogger({ module: "messaging", sessionId: msg.sessionId, tag: "solve" });
    const { sessionId, callSid, formattedMessage, analytics } = parseExpertAssistMessage(msg);
    if (sessionId && formattedMessage) {
        addMessage(formattedMessage);
        const reactiveMessage = msg as ReactiveMessagePayload;
        if (reactiveMessage.externalDevices) setDeviceInfo(reactiveMessage.externalDevices, logger);
        void reactAnalytics.dispatcher
            .withIdentities({
                ...(callSid && { CallSid: callSid }),
                ...(sessionId && { SessionId: sessionId }),
            })
            .dispatchBusinessEvent("MessageReceived", {
                senderType: "bot",
                messageType: formattedMessage.type,
                messageId: formattedMessage.id,
                ...analytics,
            });
        trackFullStoryEvent("ExpertAssistMessageReceived", {
            sessionId,
            callSid,
            messageType: formattedMessage.type,
            ...analytics,
        });
        trackAppcuesEvent("ExpertAssistMessageReceived", { messageType: formattedMessage.type, ...analytics });
    } else {
        addErrorMessage("botFailure");
    }
};

const parseExpertAssistMessage = (msg: GaiaBotMessage) => {
    let formattedMessage: ExpertAssistMessage | undefined;
    let analytics: Record<string, unknown> | undefined;

    const sessionId = msg.sessionGroupId;
    const callSid = msg.sessionId.startsWith("C") ? msg.sessionId : undefined;

    if ("response" in msg) {
        let sourceMsg = msg;

        const lowercased = msg.response.toLowerCase();
        if (
            lowercased.includes("reset") &&
            lowercased.includes("network") &&
            lowercased.includes("setting") &&
            (lowercased.includes("iphone") || lowercased.includes("ios"))
        ) {
            sourceMsg = { ...msg, ...mockMultiModal };
        }

        const { response, analytics: updatedAnalytics } = processMessage(sourceMsg);
        analytics = updatedAnalytics;
        formattedMessage = {
            id: crypto.randomUUID(),
            text: response,
            type: "Search",
            timestamp: msg.responseTimestamp,
        };
    } else if (msg.suggestion.messageType === "Proactive") {
        formattedMessage = {
            id: msg.suggestion.messageId,
            text: msg.suggestion.message,
            type: msg.suggestion.messageType,
            timestamp: msg.suggestion.outputTimestamp,
        };
    }

    return { formattedMessage, sessionId, callSid, analytics };
};
