import { customCleanEnv, json, url } from "envalid";

interface Env {
    prefixUrl: string;
    salesWhitelistedMdns: string[];
}

export const env = customCleanEnv(
    import.meta.env,
    {
        VITE_API_BASE_URL: url(),
        VITE_SALES_WHITELISTED_MDNS: json<string[]>({ default: [] }),
    },
    (inputEnv): Env => ({
        prefixUrl: inputEnv.VITE_API_BASE_URL,
        salesWhitelistedMdns: inputEnv.VITE_SALES_WHITELISTED_MDNS,
    }),
);
