import { useHomeProductSessionStore } from "@expert/sales-tools";
import { Flex, Text, Title } from "@mantine/core";
import { useEffect, useState } from "react";
import { useStatisticsStore } from "../stores";
import { BADGE_BREAKPOINT, BADGE_TYPES } from "../types";
import type { BadgeType, ConfirmedProduct } from "../types";
import classes from "./PostCallInsights.module.css";
import { DailyChallenge } from "./currentCall/DailyChallenge";
import { RevealScreen } from "./reveal/revealScreen";
import { getTextByScenario } from "./currentCall/textOptions";

export function PostCallInsights(): JSX.Element {
    const [reveal, setReveal] = useState(false);
    const [titleText, setTitleText] = useState("");
    const [secondaryText, setSecondaryText] = useState("");
    const [newOffer, statisticState] = useStatisticsStore((state) => [state.newOffer, state.statisticState]);
    const homeProducts = useHomeProductSessionStore.getState().products as unknown as ConfirmedProduct[];
    const homeProduct = homeProducts[0];

    useEffect(() => {
        const { title, subtitle } = getTextByScenario(homeProduct, newOffer, statisticState.dailyChallenge.offerCount);
        setTitleText(title);
        setSecondaryText(subtitle);
    }, [homeProduct, newOffer, statisticState.dailyChallenge.offerCount]);

    let targetBadge: BadgeType = BADGE_TYPES[0];

    if (statisticState.dailyChallenge.offerCount === BADGE_BREAKPOINT * 3) {
        targetBadge = BADGE_TYPES[2];
    } else if (statisticState.dailyChallenge.offerCount === BADGE_BREAKPOINT * 2) {
        targetBadge = BADGE_TYPES[1];
    } else {
        targetBadge = BADGE_TYPES[0];
    }

    useEffect(() => {
        // We have reached a new threshold of offers
        if (
            newOffer &&
            statisticState.dailyChallenge.offerCount > 0 &&
            statisticState.dailyChallenge.offerCount % BADGE_BREAKPOINT === 0 &&
            statisticState.dailyChallenge.offerCount <= BADGE_BREAKPOINT * 3
        ) {
            setTimeout(() => {
                setReveal(true);
            }, 2000);
        }
    }, [statisticState.dailyChallenge.offerCount, newOffer]);

    return (
        <Flex className={classes.insightsContainer} w="100%" direction="column">
            {reveal ? (
                <RevealScreen badgeType={targetBadge} />
            ) : (
                <>
                    <Flex gap="0.5rem" direction="column">
                        <Title fz="2.5rem" c="success.4">
                            {titleText}
                        </Title>
                        <Text size="lg"> {secondaryText} </Text>
                    </Flex>
                    <DailyChallenge offerCount={statisticState.dailyChallenge.offerCount} newOffer={newOffer} />
                </>
            )}
        </Flex>
    );
}
