import { useAnalytics } from "@expert/analytics";
import { useGlobalNotification, useToast } from "@expert/common-ui";
import { getLogger } from "@expert/logging";
import { isVoiceTask, useAgentSdk, useCallbackState, useSession, useSetCallbackState } from "@expert/sdk";
import { useMemo } from "react";
import { useControlsStore } from "../CallControls/controls.store";
import type { CancelCallbackRequest, ScheduleCallbackRequest } from "../api";
import { cancelCallback as cancelCallbackRequest, scheduleCallback as scheduleCallbackRequest } from "../api";
import { clearCallbacksState } from "../eventHandlers";
import { useCallbacksFormStore } from "./callbacks.store";
import { handleCallbackError } from "./utilities";

const logger = getLogger({ module: "useScheduleCallback" });

/**
 * Hook for scheduling callback logic to separate from UI components
 * TODO: This can use more refactoring in the future to separate SDK concerns better than the first pass
 */
export const useScheduleCallback = () => {
    const session = useSession();
    const task = session.currentTask;
    const activeVoiceTask = useMemo(() => {
        return isVoiceTask(task) ? task : null;
    }, [task]);
    const callbackState = useCallbackState();
    const agentSDK = useAgentSdk();
    const { dispatcher } = useAnalytics();
    const setCallbackState = useSetCallbackState();
    const { closeCallbackPanel } = useControlsStore();
    const {
        callbackType,
        selectedCallbackTime,
        selectedCallbackNowDelay,
        selectedCallbackMdn,
        setCallbackScheduled,
        selectedCallbackOption,
    } = useCallbacksFormStore();
    const globalNotification = useGlobalNotification();
    const toast = useToast();

    const handleError = (errorMsg: string, log?: string) => {
        globalNotification.error(errorMsg);
        logger.error(log ?? errorMsg);
    };

    const setCallbackScheduledSuccess = () => {
        setCallbackScheduled(true);

        void dispatcher
            .withMeta({ Client: "Verizon" }) //FIXME: resolve the hardcoded partner someday
            .withExtra({ isExternal: callbackType === "CallbackLater" })
            .dispatchBusinessEvent("CallbackScheduled");
        closeCallbackPanel();
    };

    const scheduleOrChange = async (request: ScheduleCallbackRequest) => {
        if (callbackState?.callbackMDN) {
            const requestData: CancelCallbackRequest = {
                ...request,
                phoneNumber: callbackState.callbackMDN || request.phoneNumber,
            };
            const cancelResponse = await cancelCallbackRequest(requestData);
            if (!cancelResponse.success) {
                // Correct when mindful and the UI are out of sync and we try to cancel a callback that does not exist.
                if (cancelResponse.errorJson.status === "InteractionNotFound") {
                    clearCallbacksState();
                }
                return cancelResponse;
            }

            // Mindful can send a success before the callback is actually cancelled.  Wait to ensure it is.
            await new Promise((resolve) => {
                setTimeout(resolve, 500);
            });

            const scheduleResponse = await scheduleCallbackRequest(request);
            if (!scheduleResponse.success) clearCallbacksState();
            return scheduleResponse;
        }
        return await scheduleCallbackRequest(request);
    };

    const scheduleCallbackLater = async () => {
        // This should never happen as a callback later should only be schedulable during an active call
        if (!activeVoiceTask) {
            logger.error("Cannot schedule CallbackLater without an active voice task");
            return;
        }

        if (!selectedCallbackTime) {
            logger.error("Cannot schedule callback without a selected time");
            return;
        }

        const { result, success, errorJson } = await scheduleOrChange({
            time: selectedCallbackTime,
            phoneNumber: selectedCallbackMdn,
        });

        if (errorJson && errorJson.status !== "Requested" && errorJson.status !== "ContactRequired") {
            handleCallbackError(errorJson.status, handleError);
            return;
        }

        if (!success) {
            logger.error(
                {
                    result,
                    success,
                    errorJson,
                },
                `An unexpected error occurred while ${callbackState ? "changing" : "scheduling"} the callback: `,
            );
            globalNotification.error(
                `An unexpected error occurred while ${callbackState ? "changing" : "scheduling"} the callback.`,
            );
            return;
        }

        // Ensure that result is a ScheduleCallbackResponse instead of a CancelCallbackResponse
        if (!("requestedAt" in result && "appointmentTime" in result)) {
            logger.error(`An unexpected error occurred while cancelling the existing callback: `, {
                result,
                success,
                errorJson,
            });
            globalNotification.error(
                `An unexpected error occurred while cancelling the existing callback.  Wait a few minutes and try again.`,
            );
            return;
        }

        setCallbackState(activeVoiceTask.id, {
            callbackType: "CallbackLater",
            scheduledAt: result.requestedAt,
            scheduledFor: result.appointmentTime,
            callbackDelay: null,
            callbackMDN: selectedCallbackMdn,
        });

        setCallbackScheduledSuccess();
    };

    const cancelScheduledCallback = async () => {
        if (callbackState?.callbackType === "CallbackLater") {
            // This should never happen as a callback later should only be manageable during an active call
            if (!activeVoiceTask) {
                logger.error("Cannot cancel a CallbackLater without an active voice task");
                return;
            }

            const result = await cancelCallbackRequest({ phoneNumber: activeVoiceTask.mdn });

            if (!result.success) {
                toast.error("Could not cancel callback to queue. Please wait a few minutes, then try again.");
                return;
            }
        }
        clearCallbacksState();
    };

    const canScheduleOrUpdateCallback = useMemo(() => {
        if (!callbackType || !selectedCallbackMdn) return false; // if missing info
        if (
            // if valid callback now
            callbackType === "CallbackNow" &&
            (selectedCallbackNowDelay || selectedCallbackOption === "callback-user-now")
        )
            return true;
        if (callbackType === "CallbackLater" && selectedCallbackTime) return true; // if valid callback later
        return false;
    }, [callbackType, selectedCallbackOption, selectedCallbackTime, selectedCallbackMdn, selectedCallbackNowDelay]);

    const scheduleCallback = async () => {
        if (callbackType === "CallbackNow") {
            const success = await agentSDK.scheduleCallbackNow(selectedCallbackNowDelay, selectedCallbackMdn);
            if (success) setCallbackScheduledSuccess();
        } else if (callbackType === "CallbackLater") {
            await scheduleCallbackLater();
        } else {
            logger.error("Unhandled callback type");
        }
    };

    const cancelCallback = async () => await cancelScheduledCallback();
    return {
        scheduleCallback,

        cancelCallback,
        /** whether a callback can currently be submitted or updated */
        canScheduleOrUpdateCallback,
    };
};
