import { Flex, Text } from "@mantine/core";
import type { BadgeType } from "@expert/post-call-insights";
import { BronzeBadge, GoldBadge, SilverBadge } from "@expert/post-call-insights";

interface BadgeTypeProps {
    badgeType: BadgeType;
    badgeCount: number;
}

export function Badge({ badgeType, badgeCount }: BadgeTypeProps): JSX.Element {
    function getIconComponent(type: BadgeType) {
        switch (type) {
            case "gold":
                return GoldBadge;
            case "silver":
                return SilverBadge;
            case "bronze":
                return BronzeBadge;
        }
    }

    const BadgeIcon = getIconComponent(badgeType);
    return (
        <Flex direction="column" gap="xs">
            <BadgeIcon height={48} width={48} />

            <Flex direction="row">
                <Text fw={700} size="sm">
                    {`${badgeCount}`}{" "}
                    <Text size="sm" span>
                        {badgeType}
                    </Text>
                </Text>
            </Flex>
        </Flex>
    );
}
