import { Button } from "@mantine/core";

export function QuickActionButton({ value, ...rest }: React.ButtonHTMLAttributes<HTMLButtonElement>): JSX.Element {
    return (
        <Button
            value={value}
            aria-label={value as string}
            c="var(--mantine-color-white)"
            data-testid={`QuickAction-${value as string}`}
            variant="default"
            {...rest}
        >
            {value}
        </Button>
    );
}
