import { useSession } from "@expert/sdk";
import { formatTime } from "@expert/shared-utils";
import type { TextProps } from "@mantine/core";
import { Text } from "@mantine/core";
import { useTimer } from "react-timer-hook";
import styles from "../sharedStyles.module.css";

type CallControlProps = TextProps & {
    timeExpired?: () => void;
    format?: string;
    appendUnit?: boolean;
};

/** Simple reuseable countdown text for the wrapping time */
export function WrappingCountdownText({
    timeExpired,
    format,
    appendUnit,
    size,
    ...other
}: CallControlProps): JSX.Element {
    const { wrappingState } = useSession();
    // if (!wrappingState) throw new Error("Wrapping countdown button should not be rendered without a wrapping state");

    const { totalSeconds } = useTimer({
        expiryTimestamp: new Date(wrappingState?.expirationTimestamp ?? Date.now()),
        autoStart: !!wrappingState,
        onExpire: () => {
            timeExpired && timeExpired();
        },
    });

    return (
        <Text className={styles.noWrapWhiteSpace} size={size ?? "sm"} span {...other}>
            {formatTime(totalSeconds, appendUnit, format)}
        </Text>
    );
}
