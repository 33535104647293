import { Container, Flex } from "@mantine/core";
import React, { useEffect, useState } from "react";
import { useAnalytics } from "@expert/analytics";
import { useFeatureFlag } from "@expert/shared-utils";
import { useCurrentStepStore } from "../stores";
import { StepOneContent } from "./StepSpecific/StepOneContent";
import { StepTwoContent } from "./StepSpecific/StepTwoContent";
import styles from "./GuidedFlow.module.css";
import { StepThreeContent } from "./StepSpecific/StepThreeContent";
import { UnlockAnimation } from "./StepSpecific/UnlockAnimation";
import type { SalesSteps } from "./types";
import { EnhancedStepOneContent } from "./StepSpecific/EnhancedStepOneContent";

export function GuidedFlow(): JSX.Element {
    const step: SalesSteps = useCurrentStepStore((state) => state.step);
    const [showAnimation, setShowAnimation] = useState(true);
    const { dispatcher } = useAnalytics();
    const { data: isDeviceDetectionEnhanced, isLoading: isFeatureFlagLoading } = useFeatureFlag(
        "EWP-isDeviceDetectionEnhanced",
    );
    useEffect(() => {
        void dispatcher.dispatchBusinessEvent(`${step}_GuidedFlow`);
    }, [dispatcher, step]);
    const StepOne = isDeviceDetectionEnhanced && !isFeatureFlagLoading ? EnhancedStepOneContent : StepOneContent;
    return (
        <Flex className={styles.stepsContainer}>
            <>
                {step === "DeviceCollectionStep" && !isFeatureFlagLoading && <StepOne />}
                {step === "SuggestedOfferStep" && (
                    <>
                        {showAnimation ? (
                            <Container mt="2rem" display="flex" w="30%" h="30%">
                                <UnlockAnimation
                                    onComplete={() => {
                                        setShowAnimation(false);
                                    }}
                                />
                            </Container>
                        ) : (
                            <StepTwoContent />
                        )}
                    </>
                )}
                {step === "LegalDisclosureStep" && <StepThreeContent />}
            </>
        </Flex>
    );
}
