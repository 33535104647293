import { Card, Flex, Text } from "@mantine/core";
import type { EligibleProduct } from "../../home-product";
import { SaleProgress } from "../../home-product/types";
import { getDisplayName, getIcon } from "../../partner-config/utils";
import styles from "./BaseOfferCard.module.css";
import { DeclinedIcon, EnrolledIcon, VerizonIcon } from "./icons";

interface OfferCardProps {
    product: EligibleProduct;
    onClick: (product: EligibleProduct) => void;
}

function determineCardStyle(saleProgess: SaleProgress | undefined) {
    if (saleProgess === SaleProgress.Success) {
        return styles.enrolled;
    } else if (saleProgess === SaleProgress.Declined) {
        return styles.declined;
    }

    return "";
}
export function OfferBaseCard({ product, onClick }: OfferCardProps): JSX.Element {
    const { productSku, saleProgress } = product;
    const handleClick = () => {
        if (saleProgress === SaleProgress.InProgress) {
            onClick(product);
        }
    };

    return (
        <Card
            classNames={{
                root: `${styles.cardContainer} ${determineCardStyle(saleProgress)}`,
            }}
            onClick={handleClick}
            radius="sm"
            shadow="sm"
        >
            <div>
                <VerizonIcon />
            </div>
            <Text classNames={{ root: styles.cardText }} size="sm">
                {getDisplayName("verizon", productSku)}
            </Text>
            <Flex
                className={saleProgress === SaleProgress.InProgress ? "" : styles.hidden}
                classNames={{ root: styles.planImageSection }}
            >
                {getIcon("verizon", productSku)}
            </Flex>
            {saleProgress !== SaleProgress.InProgress ? (
                <Flex classNames={{ root: styles.offerStatusSection }}>
                    {saleProgress === SaleProgress.Success ? <EnrolledIcon /> : <DeclinedIcon />}
                    <Text classNames={{ root: styles.planDispositionText }} size="sm">
                        {saleProgress === SaleProgress.Success ? "Enrolled" : "Declined"}
                    </Text>
                </Flex>
            ) : null}
        </Card>
    );
}
