import { Container, Flex, Menu, Text } from "@mantine/core";
import { forwardRef, useRef } from "react";
import classes from "./MessageInput.module.css";
import type { MenuItemProps } from "./types";

export function MessageInputContainer({ children }: { children: React.ReactNode }) {
    return (
        <Flex align="center" className={classes.inputContainer} justify="center">
            {children}
        </Flex>
    );
}

export function MessageInputBackground() {
    return <Container className={classes.inputBackground} />;
}

// TODO: Make message input more responsive (width)
export function MessageInputWrapper({ children }: { children: React.ReactNode }) {
    const inputRef = useRef(null);

    return (
        <Flex ref={inputRef} className={classes.inputWrapper} justify="center">
            {children}
        </Flex>
    );
}

export const ToolboxMenuItem = forwardRef<HTMLDivElement, MenuItemProps>(function ToolboxMenuItem(
    { id, item, focusedMenuItem, onClick }: MenuItemProps,
    ref,
) {
    return (
        <Menu.Item
            leftSection={item.icon}
            rightSection={
                item.quickActionLabel && (
                    <Text size="sm" c="gray.6">
                        {item.quickActionLabel}
                    </Text>
                )
            }
            key={item.label}
            disabled={item.disabled}
            id={`toolbox-menu-item-${item.quickActionLabel}`}
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus={focusedMenuItem === id}
            onClick={() => {
                onClick(item);
            }}
            style={{ pointerEvents: "unset" }}
            {...item.additionalProps}
        >
            <Text size="sm" ref={ref}>
                {item.label}
            </Text>
        </Menu.Item>
    );
});
