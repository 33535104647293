import { useActiveTask } from "./useActiveTask";

/** Provides the canonical partner for the active task/session, if no task exists returns null */
export function usePartner() {
    const activeTask = useActiveTask();
    return activeTask?.partner ?? null;
}

/* TODO: Handle fallback partner as expected as we launch to multiple partners */
export const fallbackPartner = "verizon";
