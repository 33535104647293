import { getRootDispatcher } from "@expert/analytics";
import type { Customer } from "@expert/customer-info";
import { useCallDetailsStore } from "../../../../call-details";

/*
 * To be considered preverified, a customer
 * Must not have an AccountType of BE
 * Must have a validation flag set to T in CSD
 * Must be attempting to auth an accountMdn that matches their callerID
 * Must have a name that matches either the account owner or authorized user on the agreement file
 */
export const determineCustomerVerificationStatus = (
    inputName: string,
    accountMdn: string,
    authorizedUsers: Customer[] | undefined,
) => {
    const { ACCOUNT_TYPE, VALIDATION_FLAG, ANI } = useCallDetailsStore.getState().callDetails;

    const isCorrectAccountType = ACCOUNT_TYPE !== undefined && ACCOUNT_TYPE !== "BE";
    const isIVRValidated = VALIDATION_FLAG === "T";
    const callerIdMatchesANI = ANI === accountMdn;
    const isAuthorizedUserFound = !!authorizedUsers?.find((customer) => customer.fullName === inputName);

    const dispatcher = getRootDispatcher().withExtra({
        isCorrectAccountType,
        isIVRValidated,
        callerIdMatchesANI,
        isAuthorizedUserFound,
    });

    if (isCorrectAccountType && isIVRValidated && callerIdMatchesANI && isAuthorizedUserFound) {
        void dispatcher.dispatchBusinessEvent("CustomerPrevalidationAccepted");
        return 1;
    }

    void dispatcher.dispatchBusinessEvent("CustomerPrevalidationRejected");
    return 0;
};
