import { Button, Flex, Modal, Text } from "@mantine/core";
import { BadgeBar, useStatistics, useStatisticsStore } from "@expert/post-call-insights";
import { useEffect } from "react";
import { DotsLoading } from "@expert/common-ui";
import { Badges } from "./Badges";
import classes from "./StatsModal.module.css";

interface StatsModalProps {
    opened: boolean;
    onClose: () => void;
}

export function StatsModal({ opened, onClose }: StatsModalProps): JSX.Element | null {
    const { statisticsData, isLoading } = useStatistics();
    const [statisticState, initialized, setState, setInitialized] = useStatisticsStore((state) => [
        state.statisticState,
        state.initialized,
        state.setState,
        state.setInitialized,
    ]);

    // Initialize the state with the statistics data
    useEffect(() => {
        if (!initialized && statisticsData) {
            setState(statisticsData);
            setInitialized(true);
        }
    }, [initialized, statisticsData, setInitialized, setState]);

    return (
        <Modal
            opened={opened}
            onClose={onClose}
            withCloseButton={false}
            classNames={{
                title: classes.modalTitle,
            }}
        >
            <Flex direction="column">
                {isLoading ? (
                    <Flex direction="column" justify="center" align="center" mt="lg">
                        <Text>Loading your daily challenge stats...</Text>
                        <Flex maw="160px">
                            <DotsLoading />
                        </Flex>
                    </Flex>
                ) : (
                    <>
                        <Text mb="xl" mt="lg" size="md" fw={700}>
                            Daily offer challenge
                        </Text>
                        <Flex className={classes.dailyChallenge} direction="column">
                            <BadgeBar badgeType="bronze" offerCount={statisticState.dailyChallenge.offerCount} />
                            <BadgeBar badgeType="silver" offerCount={statisticState.dailyChallenge.offerCount} />
                            <BadgeBar badgeType="gold" offerCount={statisticState.dailyChallenge.offerCount} />
                        </Flex>

                        <Badges {...statisticState.badges} />
                    </>
                )}

                <Button onClick={onClose} className={classes.rightAligned}>
                    Close
                </Button>
            </Flex>
        </Modal>
    );
}
