import { Flex, Title } from "@mantine/core";
import { BadgeBar } from "../badgeBar";

interface DailyChallengeProps {
    offerCount: number;
    newOffer: boolean;
}

export function DailyChallenge({ offerCount, newOffer }: DailyChallengeProps): JSX.Element {
    return (
        <Flex direction="column">
            <Title size="1.25rem" mb="1rem">
                Daily offer challenge
            </Title>
            <BadgeBar badgeType="bronze" offerCount={offerCount} newOffer={newOffer} />
            <BadgeBar badgeType="silver" offerCount={offerCount} newOffer={newOffer} />
            <BadgeBar badgeType="gold" offerCount={offerCount} newOffer={newOffer} />
        </Flex>
    );
}
