import { withPolymorphicClickAnalytics } from "@expert/analytics";
import { ScheduleCallbackForm, WrappingCountdownText } from "@expert/call-controls/";
import { CheckMark, CopyIcon, RadioCard, useToast } from "@expert/common-ui";
import { useVerifiedCustomerStore } from "@expert/customer-info";
import type { VoiceTask } from "@expert/sdk";
import { isVoiceTask, useAgentSdk, useMostRecentTask } from "@expert/sdk";
import {
    ActionIcon,
    Button,
    CopyButton,
    Flex,
    Group,
    Modal,
    Notification,
    Pill,
    Text,
    Textarea,
    rem,
} from "@mantine/core";
import { useCallback, useEffect, useState } from "react";
import Player from "react-lottie-player";
import type { SummaryResolution } from "../store/useSessionSummaryStore";
import { useSessionSummaryStore } from "../store/useSessionSummaryStore";
import { ACSSNotificationWrapper } from "./ACSSNotificationWrapper";
import { CustomerConfirmationStatusCard } from "./CustomerConfirmationStatusCard";
import classes from "./EditSessionSummaryModal.module.css";
import { summaryLoadingLottie } from "./summaryLoadingLottie";

interface EditSessionSummaryModalProps {
    onSend: (update?: string, skipSend?: boolean) => Promise<void>;
    onDismiss: () => void;
    loading: boolean;
    opened: boolean;
}
const CallCustomerButton = withPolymorphicClickAnalytics(Button, "Click");
const SendSummaryButton = withPolymorphicClickAnalytics(Button, "Click");

// This is the max length of the summary that we can send to ACSS per Verizon's guidelines.
const MAX_SUMMARY_LENGTH = 930;

export function EditSessionSummaryModal({
    onSend,
    onDismiss,
    loading,
    opened,
}: EditSessionSummaryModalProps): JSX.Element | null {
    const mostRecentTask = useMostRecentTask();
    const agentSdk = useAgentSdk();
    const accountNumber = useVerifiedCustomerStore((state) => state.customer?.accountNumber);
    const validAccountNumber = accountNumber?.length === 15;
    const [view, setView] = useState<"edit-summary" | "schedule-callback">("edit-summary");
    const toast = useToast();
    useEffect(() => setView("edit-summary"), [opened]);

    const [setSessionSummary, setResolved, summary, wasIssueResolved] = useSessionSummaryStore((state) => [
        state.setSessionSummary,
        state.setResolved,
        state.summary,
        state.wasIssueResolved,
    ]);

    const handleResolution = useCallback(
        (resolution: SummaryResolution) => {
            setResolved(resolution);
        },
        [setResolved],
    );

    const handleChange = useCallback(
        (event: React.ChangeEvent<HTMLTextAreaElement>) => {
            setSessionSummary(event.target.value);
        },
        [setSessionSummary],
    );

    const handleSend = () => void onSend(summary);

    const handleCopyAndSendSummary = () => {
        void navigator.clipboard.writeText(summary);
        void onSend(summary, true);
    };

    async function handleWrappingExpired() {
        await handleEndSession("WrappingTimeExpired");
    }

    async function handleEndSessionClick() {
        await handleEndSession("AgentTerminatedWrapping");
    }

    async function handleEndSession(reason: "AgentTerminatedWrapping" | "WrappingTimeExpired") {
        validAccountNumber ? handleSend() : handleCopyAndSendSummary();
        await agentSdk.endWrapup(reason);
    }

    const endSessionLabel = validAccountNumber ? "Send session summary" : "Copy summary";

    const notificationText = validAccountNumber
        ? "Summary will be sent to ACSS"
        : "Copy and paste to ACSS—notes won’t auto-send";

    /** Prevent mantine's attempt to close the modal when the outside overlay is clicked */
    const interceptClose = () => null;

    function renderAcssNotification() {
        return (
            <ACSSNotificationWrapper align="center" justify="space-between" sendable={validAccountNumber}>
                {validAccountNumber ? (
                    <Notification
                        color="dark.0"
                        classNames={{
                            root: classes.notificationRoot,
                        }}
                        radius="sm"
                        withCloseButton={false}
                    >
                        <Text fz="xs" lh="1.125rem">
                            {notificationText}
                        </Text>
                    </Notification>
                ) : (
                    <CopyButton value={summary}>
                        {({ copied, copy }) => {
                            const handleCopyClick = () => {
                                copy();
                                toast.success("Session summary copied to clipboard");
                            };
                            return (
                                <>
                                    <Notification
                                        onClick={handleCopyClick}
                                        color="dark.0"
                                        classNames={{
                                            root: classes.notificationRoot,
                                        }}
                                        radius="sm"
                                        withCloseButton={false}
                                    >
                                        <Text fz="xs" lh="1.125rem">
                                            {notificationText}
                                        </Text>
                                    </Notification>
                                    <ActionIcon mr="0.6rem" onClick={handleCopyClick} variant="subtle">
                                        {copied ? (
                                            <CheckMark fill="var(--mantine-color-dark-1)" width={18} />
                                        ) : (
                                            <CopyIcon stroke="var(--mantine-color-dark-1)" width={20} height={21} />
                                        )}
                                    </ActionIcon>
                                </>
                            );
                        }}
                    </CopyButton>
                )}
            </ACSSNotificationWrapper>
        );
    }

    return (
        <Modal
            centered
            classNames={{
                inner: classes.modalInner,
                overlay: classes.modalOverlay,
                content: classes.modalContent,
                body: classes.modalBody,
            }}
            onClose={interceptClose}
            opened={opened}
            overlayProps={{
                backgroundOpacity: 0.1,
            }}
            size="28rem"
            withCloseButton={false}
        >
            <Flex id="modal-heading" align="center" justify="space-between" mb="1rem">
                <Text className={classes.modalTitle} fw="var(--mantine-font-weight-normal)" fz="md">
                    {view === "schedule-callback" ? "Schedule Callback" : "Session Summary"}
                </Text>
                <Pill classNames={{ root: classes.pillRoot, label: classes.pillLabel }} fz="xxs" size="xs">
                    {/* FIXME: we should not depend on a UI component to handle the countdown timer expiration actions */}
                    <WrappingCountdownText
                        appendUnit={false}
                        format="m:ss"
                        ff="monospace"
                        fw="var(--mantine-font-weight-heavy)"
                        size="xxs"
                        timeExpired={handleWrappingExpired}
                    />
                    {" sec"}
                </Pill>
            </Flex>
            {view === "schedule-callback" && (
                <ScheduleCallbackForm onBackClick={() => setView("edit-summary")} afterSubmitClickDefault={onDismiss} />
            )}
            {/* TODO: split off into separate component file if the modal isn't depreciated*/}
            {view === "edit-summary" && (
                <>
                    {isVoiceTask(mostRecentTask) ? (
                        <CustomerConfirmationStatusCard
                            asurionCallId={mostRecentTask.asurionCallId}
                            mdn={mostRecentTask.mdn}
                            reservationSid={
                                (
                                    mostRecentTask as VoiceTask & {
                                        config?: { reservation?: { sid: string } };
                                    }
                                ).config?.reservation?.sid
                            }
                        />
                    ) : null}
                    <Text size="sm" mb=".5rem">
                        Was the customer’s issue resolved?
                    </Text>
                    <Group mb="1.6rem">
                        <RadioCard
                            analytics={{
                                actionId: "SessionSummaryModal_ProblemSolvedYes",
                            }}
                            color="var(--mantine-color-dark-5)"
                            label="Yes"
                            onClick={() => handleResolution(true)}
                            p={`0 ${wasIssueResolved ? rem(11) : rem(12)}`}
                            selected={wasIssueResolved === true}
                            data-testid="session-summary-problem-solved-yes"
                        />
                        <RadioCard
                            analytics={{
                                actionId: "SessionSummaryModal_ProblemSolvedNo",
                            }}
                            color="var(--mantine-color-dark-5)"
                            label="No"
                            p={`0 ${wasIssueResolved ? rem(12) : rem(11)}`}
                            onClick={() => handleResolution(false)}
                            selected={wasIssueResolved === false}
                            data-testid="session-summary-problem-solved-no"
                        />
                    </Group>
                    <Text size="sm" mb=".5rem">
                        Notes
                    </Text>
                    <Flex className={loading ? undefined : classes.modalTextareaContainer} direction="column">
                        {!loading ? (
                            <>
                                <Textarea
                                    className="fs-mask"
                                    classNames={{
                                        input: classes.modalTextarea,
                                    }}
                                    maxLength={MAX_SUMMARY_LENGTH}
                                    mr="0.125rem"
                                    mt="0.25rem"
                                    mb="0.3rem"
                                    onChange={handleChange}
                                    placeholder="Enter session summary here..."
                                    rows={7}
                                    value={summary}
                                    data-testid="session-summary-text-input"
                                />
                                {renderAcssNotification()}
                            </>
                        ) : (
                            <Flex direction="unset" justify="center" align="center" h="237">
                                <Player
                                    className={classes.loadingAnimation}
                                    animationData={summaryLoadingLottie}
                                    play
                                    speed={1}
                                    loop
                                />
                            </Flex>
                        )}
                    </Flex>
                    <Flex
                        classNames={{ root: classes.buttonContainer }}
                        id="modal-actions"
                        justify="flex-end"
                        pt="md"
                        gap="xs"
                    >
                        <CallCustomerButton
                            analytics={{
                                actionId: "SessionSummaryModal_CallCustomerButton",
                            }}
                            color="primary.3"
                            onClick={() => setView("schedule-callback")}
                            variant="outline"
                        >
                            <Text fw="lighter" fz="sm">
                                Schedule callback
                            </Text>
                        </CallCustomerButton>
                        <SendSummaryButton
                            analytics={{
                                actionId: "SessionSummaryModal_SendButton",
                            }}
                            className={classes.sendSummaryButton}
                            aria-label={endSessionLabel}
                            disabled={loading || !summary || wasIssueResolved === null}
                            fz="sm"
                            onClick={handleEndSessionClick}
                            data-testid="call-controls-end-session"
                        >
                            End session
                        </SendSummaryButton>
                    </Flex>
                </>
            )}
        </Modal>
    );
}
