import { type UserManagerSettings } from "oidc-client-ts";
import { env } from "../environment";

export const userManagerSettings: UserManagerSettings = {
    authority: env.oidcSsoUrl,
    client_id: env.oidcSsoClientId,
    response_type: `code`,
    redirect_uri: env.oidcSsoReturnUri,
    scope: "openid email profile GAIA:aigateway:invoke",
};
