import { useEffect, useMemo, useRef } from "react";
import { useExpertAssistStore } from "../../state";
import { List, TimelineListWrapper } from "./elements";
import { generateTimelineItems } from "./generateTimelineItems";
import type { TimelineListProps } from "./types";

export function TimelineList({ sessionId }: TimelineListProps) {
    const scrollRef = useRef<HTMLDivElement>(null);
    const { messages, features } = useExpertAssistStore();

    const timelineItems = useMemo(
        () =>
            generateTimelineItems({
                messages,
                scrollRef,
                ...features,
            }),
        [messages, scrollRef, features],
    );

    // scroll to bottom of timeline for each new message
    useEffect(() => {
        const div = scrollRef.current;
        div?.scrollTo({ top: div.scrollHeight, behavior: "smooth" });
    }, [messages.length]);

    // reset the animation for session resets
    useEffect(() => {
        const animatedElement = document.getElementById("timeline-list-wrapper");
        if (animatedElement) {
            animatedElement.style.animation = "none";
            animatedElement.offsetHeight;
            animatedElement.style.animation = "ease-out 1s slide-in 1";
        }
    }, [sessionId]);

    return (
        <TimelineListWrapper data-testid="timeline-list-wrapper" scrollRef={scrollRef}>
            <List data-testid="timeline-list">{timelineItems}</List>
        </TimelineListWrapper>
    );
}
