import { ActionIcon, Container, Flex, Text } from "@mantine/core";
import React from "react";
import { usePlanInfo } from "@expert/contentful";
import { CloseIcon } from "../Icons";
import styles from "./PlanInfoGuidedFlow.module.css";

interface PlanInfoGuidedFlowProps {
    productSku: string;
    closeDrawer: () => void;
    opened: boolean;
}

export function PlanInfoGuidedFlow({ productSku, closeDrawer, opened }: PlanInfoGuidedFlowProps): JSX.Element {
    const { planInfo, error } = usePlanInfo(productSku);
    return (
        <Container h={opened ? "37rem" : "0"} className={styles.drawerOpen}>
            <Container>
                <Flex mt="1.5rem" justify="flex-end">
                    <ActionIcon onClick={closeDrawer} variant="transparent">
                        <CloseIcon />
                    </ActionIcon>
                </Flex>
                {error ? (
                    <Text mt="4rem" ta="center">
                        We ran into an issue. Verizon Home Device Protect plan details aren’t available right now.{" "}
                        <a
                            target="_blank"
                            href="https://www.asurion.com/verizon/home-device-protect/whats-covered/"
                            rel="noopener"
                        >
                            View plan coverage here.
                        </a>
                    </Text>
                ) : (
                    <Flex direction="column">
                        <img src={planInfo?.logoUrl} alt="plan logo" />
                        <Text className={styles.planHeader}>{planInfo?.price}</Text>
                        <Text className={styles.planCoverage}>Coverage details</Text>
                        {planInfo?.coverageBenefitsList.map((benefit) => {
                            return (
                                <Flex key={benefit.description} className={styles.planInfoPointer}>
                                    <img src={benefit.iconUrl} alt="benefit icon" />
                                    <Text className={styles.planInfo}>{benefit.description}</Text>
                                </Flex>
                            );
                        })}
                    </Flex>
                )}
            </Container>
        </Container>
    );
}
