import { type Logger, getLogger } from "@expert/logging";
import ky, { type BeforeErrorHook, type BeforeRequestHook } from "ky";
import { env } from "../environment";
import { userCache } from "./userCache";

export const apiBaseUrl = env.prefixUrl;

function createAuthorizationBearerHook(tokenProvider: () => string | undefined): BeforeRequestHook {
    return ({ headers }) => {
        if (headers.has("Authorization")) return;
        const accessToken = tokenProvider();
        if (!accessToken) {
            throw new Error("Access token doesn’t exist");
        }
        headers.set("Authorization", `Bearer ${accessToken}`);
    };
}

function createErrorLoggingHook(logger: Logger): BeforeErrorHook {
    return (error) => {
        const {
            request: { method, url },
            response: { status, statusText },
        } = error;
        logger.warn({ method, url, status, statusText }, "received an HTTP Error");
        return error;
    };
}

export const api = ky.create({
    prefixUrl: env.prefixUrl,
    timeout: 30_000,
    hooks: {
        beforeRequest: [createAuthorizationBearerHook(() => userCache.ssoAccessToken)],
        beforeError: [createErrorLoggingHook(getLogger({ module: "api" }))],
    },
});
