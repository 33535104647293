import { useAnalytics } from "@expert/analytics";
import { useToast } from "@expert/common-ui";
import { getSessionId, isVoiceTask, useMostRecentTask } from "@expert/sdk";
import { useFeatureFlag } from "@expert/shared-utils";
import { Box, Button, CloseButton, Group, Input, Modal, Text, Title } from "@mantine/core";
import { useEffect, useState } from "react";
import { IMaskInput } from "react-imask";
import type { EligibleProduct } from "../../home-product";
import { createEnrollment, postDeclination, useOtherProductSessionStore } from "../../home-product";
import { EnrollmentStatus, SaleProgress } from "../../home-product/types";
import { getDisplayName } from "../../partner-config/utils";
import { isValidConfirmationCode } from "../services";
import classes from "./SaleTrackingModal.module.css";

interface SaleTrackingModalProps {
    opened: boolean;
    onClose: () => void;
    selectedProduct: EligibleProduct;
}

export function SaleTrackingModal({ opened, onClose, selectedProduct }: SaleTrackingModalProps): JSX.Element | null {
    const { data: isConfirmationCodeEnabled, isLoading: isConfirmationCodeLoading } = useFeatureFlag(
        "EWP-isConfirmationCodeEnabled",
    );
    const { dispatcher } = useAnalytics();
    const [isLoadingEnroll, setIsLoadingEnroll] = useState(false);
    const [isLoadingDecline, setIsLoadingDecline] = useState(false);
    const [codeValue, setCodeValue] = useState("");
    const mostRecentTask = useMostRecentTask();
    if (!isVoiceTask(mostRecentTask)) {
        throw new Error("Active task is not voice task");
    }

    useEffect(() => {
        void dispatcher.dispatchAction("DialogShown", "SaleTrackingModal", { productSku: selectedProduct.productSku });
    }, [dispatcher, selectedProduct.productSku]);

    const toast = useToast();

    const closeAndClear = () => {
        setCodeValue("");
        onClose();
    };
    const onAccept = async () => {
        setIsLoadingEnroll(true);
        void dispatcher.dispatchAction("ButtonClick", "Enroll", {
            productSku: selectedProduct.productSku,
            confirmationCode: codeValue,
        });

        const response = await createEnrollment({
            callId: mostRecentTask.agentCallId ?? getSessionId(),
            sessionId: getSessionId(),
            partner: "verizon",
            productOfferId: selectedProduct.id,
            confirmationCode: codeValue,
        });
        if (response.enrollmentStatus === EnrollmentStatus.Success) {
            useOtherProductSessionStore
                .getState()
                .updateProduct({ ...selectedProduct, ...{ saleProgress: SaleProgress.Success } });
        } else {
            toast.error("There was an error processing the enrollment");
        }
        setIsLoadingEnroll(false);
        closeAndClear();
    };

    const onDecline = async () => {
        setIsLoadingDecline(true);
        void dispatcher.dispatchAction("ButtonClick", "Decline", { productSku: selectedProduct.productSku });

        const response = await postDeclination({
            callId: mostRecentTask.agentCallId ?? getSessionId(),
            sessionId: getSessionId(),
            partner: "verizon",
            productOfferId: selectedProduct.id,
        });
        if (response) {
            useOtherProductSessionStore
                .getState()
                .updateProduct({ ...selectedProduct, ...{ saleProgress: SaleProgress.Declined } });
        } else {
            toast.error("There was an error processing the declination");
        }
        setIsLoadingDecline(false);
        closeAndClear();
    };

    //if this is true, disable the enroll button
    const confirmationCodeBlocker =
        selectedProduct.isConfirmationCodeRequired &&
        (isConfirmationCodeLoading || (isConfirmationCodeEnabled && !isValidConfirmationCode(codeValue)));

    return (
        <Modal
            centered
            classNames={{
                root: classes.modalRoot,
                content: classes.modalContent,
                title: classes.modalTitle,
                header: classes.modalHeader,
                overlay: classes.modalOverlay,
                inner: classes.modalInner,
            }}
            onClose={closeAndClear}
            opened={opened}
            withCloseButton={false}
            size="lg"
        >
            <CloseButton onClick={closeAndClear} className={classes.closeButton} />
            <Title className={classes.modalTitle}>Track Sale </Title>
            <Text className={classes.descriptionText}>
                By marking ‘I enrolled the customer’ you verify that you’ve completed the{" "}
                {getDisplayName("verizon", selectedProduct.productSku)} enrollment in Verizon’s ACSS.
            </Text>
            {selectedProduct.isConfirmationCodeRequired && !isConfirmationCodeLoading && isConfirmationCodeEnabled ? (
                <Box className={classes.inputWrapper}>
                    <Text className={classes.orderNumberText}>Enter the order number from ACSS</Text>
                    <Input
                        onChange={(event) => {
                            setCodeValue(event.currentTarget.value);
                        }}
                        component={IMaskInput}
                        defaultValue={codeValue}
                        placeholder="Order number"
                        size="lg"
                        mask={/^[a-zA-Z0-9]*$/}
                        classNames={{ wrapper: classes.styledInputWrapper, input: classes.styledInput }}
                    />
                </Box>
            ) : null}
            <Group className={classes.buttonGroup}>
                <Button
                    loading={isLoadingDecline}
                    disabled={isLoadingEnroll || isLoadingDecline}
                    className={classes.declineButton}
                    onClick={onDecline}
                    variant="subtle"
                    color="white"
                >
                    They declined
                </Button>
                <Button
                    loading={isLoadingEnroll}
                    disabled={isLoadingEnroll || isLoadingDecline || confirmationCodeBlocker}
                    className={classes.enrollButton}
                    onClick={onAccept}
                    radius="xl"
                    variant="default"
                >
                    I enrolled the customer
                </Button>
            </Group>
        </Modal>
    );
}
