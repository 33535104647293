import { type SVGProps } from "react";

export function Plus(props: SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg fill="none" height="100%" viewBox="0 0 29 28" width="100%" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M13.8228 1.1911V27.0396"
                stroke={props.stroke ?? "white"}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
            <path
                d="M27.3625 14.7307H1.51404"
                stroke={props.stroke ?? "white"}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
        </svg>
    );
}
