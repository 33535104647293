import { getRootDispatcher } from "@expert/analytics";
import type { Conference, UpdateParticipantOptions } from "@expert/sdk";
import { api, apiBaseUrl } from "@expert/shared-utils";
import { env } from "../environment";

const callsApi = api.extend({ prefixUrl: `${apiBaseUrl}/${env.serviceBasePath}/calls` });
const conferencesApi = api.extend({ prefixUrl: `${apiBaseUrl}/${env.serviceBasePath}/conferences` });

async function redirectCustomerCall(conferenceId: string, customerCallId: string, body: Record<string, unknown>) {
    await conferencesApi.post(`${conferenceId}/redirect-call/${customerCallId}`, { json: body });
}

export async function redirectCallToTfn(conferenceId: string, customerCallId: string, tfn: string) {
    await redirectCustomerCall(conferenceId, customerCallId, { tfn });
}

export async function redirectCallToSip(
    conferenceId: string,
    customerCallId: string,
    sip: string,
    sipParams?: Record<string, string>,
) {
    await redirectCustomerCall(conferenceId, customerCallId, { sip, sipParams });
}

export async function redirectCallToCCTS(
    conferenceId: string,
    customerCallId: string,
    taskAttributes: Record<string, unknown>,
) {
    await redirectCustomerCall(conferenceId, customerCallId, { taskAttributes });
}

export async function endCall(taskId: string, callId: string) {
    await callsApi.delete(callId, { searchParams: { conferenceName: taskId } });
}

export async function endConferenceByTaskId(taskId: string) {
    await conferencesApi.delete("", { searchParams: { conferenceName: taskId } });
}

export async function endConference(conferenceId: string) {
    await conferencesApi.delete(conferenceId);
}

export async function getConference(conferenceId: string) {
    return await conferencesApi.get(conferenceId).json<Conference>();
}

export async function addTfnParticipant(
    partner: string,
    sessionId: string,
    taskSid: string,
    conferenceId: string,
    name: string,
    tfn: string,
) {
    void getRootDispatcher()
        .withExtra({
            partner,
        })
        .withIdentities({
            ConferenceId: conferenceId,
            TaskSid: taskSid,
        })
        .dispatchBusinessEvent("OutboundCallInitiated", {
            destination: tfn,
            outboundCallType: "TollFreeNumber",
        });

    await conferencesApi.put(`${conferenceId}/participants`, {
        searchParams: { sessionId },
        json: { name, tfn, statusCallbackUrl: env.callCallbackUrl },
    });
}

export async function addSipParticipant(
    partner: string,
    sessionId: string,
    taskSid: string,
    conferenceId: string,
    name: string,
    sip: string,
    sipParams?: Record<string, string>,
) {
    void getRootDispatcher()
        .withExtra({
            partner,
        })
        .withIdentities({
            ConferenceId: conferenceId,
            TaskSid: taskSid,
        })
        .dispatchBusinessEvent("OutboundCallInitiated", {
            destination: sip,
            sipParams,
            outboundCallType: "SessionInitiationProtocol",
        });

    await conferencesApi.put(`${conferenceId}/participants`, {
        searchParams: { sessionId },
        json: { name, sip, sipParams, statusCallbackUrl: env.callCallbackUrl },
    });
}

export async function updateParticipant(
    conferenceId: string,
    participantId: string,
    options: UpdateParticipantOptions,
) {
    // TODO: Support hold and maybe mute
    await conferencesApi.post(`${conferenceId}/participants/${participantId}`, { json: options });
}

export async function removeParticipant(conferenceId: string, participantId: string) {
    await conferencesApi.delete(`${conferenceId}/participants/${participantId}`);
}
