import { useCustomerAvatar } from "@expert/contentful";
import { useAgentSdk, useSession } from "@expert/sdk";
import { useAsyncLoading } from "@expert/shared-utils";
import { Button, Group } from "@mantine/core";
import { useState } from "react";
import { useTimer } from "react-timer-hook";
import { clearCallbacksState } from "../../../eventHandlers";
import { CancelCallbackButton } from "../../Buttons";
import classes from "./CallbackCallBar.module.css";
import { CallbackCallBarBody } from "./CallbackCallBarBody";

export function CallbackCallBar(): JSX.Element {
    const session = useSession();
    const agentSdk = useAgentSdk();
    const callbackState = session.callbackState;

    const [errorCalling, setErrorCalling] = useState(false);
    const [isCalling, handleCallCustomerBack] = useAsyncLoading(async () => {
        setErrorCalling(false);
        let tries = 0;

        for (tries = 0; tries < 3; tries++) {
            try {
                await agentSdk.callCustomerBack(session);
                return;
            } catch (error) {
                console.error("Error calling customer back", error);
                await new Promise((resolve) => {
                    setTimeout(resolve, 1000);
                });
            }
        }

        setErrorCalling(true);
    });

    // TODO: Since this was moved out of the CancelCallbackButton this is now WET
    // This should be in the button component, or in the SDK
    const [loading, cancelCallback] = useAsyncLoading(async () => {
        await agentSdk.startWrapup("CallbackCancelled");
        clearCallbacksState();
    });

    const customerAvatarUrl = useCustomerAvatar(session.id);

    if (!callbackState) {
        throw new Error("CallbackCallBar should not be rendered without a callback state");
    }

    const expiryTimestamp = new Date(callbackState.scheduledFor);
    const { totalSeconds, isRunning, start } = useTimer({
        expiryTimestamp,
        autoStart: false,
        onExpire: () => {
            void handleCallCustomerBack();
        },
    });

    if (callbackState.scheduledFor && !isRunning) {
        start();
    }

    const mdnText = callbackState.callbackMDN;

    function retry() {
        void handleCallCustomerBack();
    }

    return (
        <Group p="xs">
            <Group align="center" flex="1">
                {customerAvatarUrl ? (
                    <img className={classes.profilePicture} src={customerAvatarUrl} alt="customer profile avatar" />
                ) : null}

                <CallbackCallBarBody
                    mdnText={mdnText}
                    secondsTillCall={totalSeconds}
                    isCalling={isCalling}
                    errorCalling={errorCalling}
                />
            </Group>
            <Group gap="sm" justify="flex-end" grow={false} flex="0">
                {errorCalling ? (
                    <Button color="primary" onClick={retry}>
                        Retry
                    </Button>
                ) : (
                    <CancelCallbackButton onClick={cancelCallback} loading={loading} />
                )}
            </Group>
        </Group>
    );
}
