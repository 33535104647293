import { getSessionId } from "@expert/sdk";
import { api, apiBaseUrl } from "@expert/shared-utils";

export const salesApi = api.extend({
    prefixUrl: `${apiBaseUrl}/api/sales`,
    hooks: {
        beforeRequest: [
            (request) => {
                request.headers.set("x-ewp-correlation-id", getSessionId());
            },
        ],
    },
});
