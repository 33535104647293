import { BADGE_BREAKPOINT, type BadgeType } from "../types";

const OFFER_BADGE_MAX = BADGE_BREAKPOINT * 3;

export const determineNewBadge = (updatedOfferCount: number): BadgeType | null => {
    if (updatedOfferCount % BADGE_BREAKPOINT === 0 && updatedOfferCount <= OFFER_BADGE_MAX) {
        if (updatedOfferCount === OFFER_BADGE_MAX) {
            return "gold";
        } else if (updatedOfferCount === BADGE_BREAKPOINT * 2) {
            return "silver";
        }

        return "bronze";
    }

    return null;
};
