import type { SVGProps } from "react";

export function EnrolledIcon(props: SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg fill="none" height={24} width={24} xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M12.042 2c-5.523 0-10 4.477-10 10s4.477 10 10 10 10-4.477 10-10-4.478-10-10-10Zm-.75 15.75-5-3.75 1.5-2 3 2.25 5.25-7 2 1.5-6.75 9Z"
                fill="#00B574"
            />
        </svg>
    );
}
