import type { LevelOrString } from "pino";
import { customCleanEnv, str, url } from "envalid";

interface Env {
    logCollectorUrl: `${string}/log`;
    logLevel: {
        api: LevelOrString;
        console: LevelOrString;
    };
    appName: string;
}

export const env = customCleanEnv(
    import.meta.env,
    {
        VITE_API_BASE_URL: url(),
        VITE_LOG_LEVEL_CONSOLE: str({ default: "trace" }),
        VITE_LOG_LEVEL_API: str({ default: "warn" }),
        VITE_APP_NAME: str(),
    },
    (inputEnv): Env => ({
        logCollectorUrl: `${inputEnv.VITE_API_BASE_URL}/log`,
        logLevel: {
            api: inputEnv.VITE_LOG_LEVEL_API,
            console: inputEnv.VITE_LOG_LEVEL_CONSOLE,
        },
        appName: inputEnv.VITE_APP_NAME,
    }),
);
