import type { Logger } from "@expert/logging";
import { Anchor } from "@mantine/core";
import { useReactAnalytics } from "@soluto-private/eventualize-react";

export interface OzmoLinkProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
    logger?: Logger;
    actionId: string;
    ozmo?: boolean;
}

export function OzmoLink({ href, title, actionId, ozmo, children, logger }: OzmoLinkProps) {
    const { dispatcher } = useReactAnalytics();
    const onClick = () => {
        logger?.child({ module: "OzmoLink" }).info({ url: href, title }, "Ozmo link clicked");
        const eventName = ozmo ? `Ozmo_${actionId}` : `Click_${actionId || "Link"}`;
        void dispatcher.dispatchBusinessEvent(eventName, {
            source: "Message",
            url: href,
            actionId: actionId || title,
            title,
        });
    };

    return (
        <Anchor href={href} target="_blank" onClick={onClick} size="sm" underline="always" data-testid="OzmoLink">
            {children}
        </Anchor>
    );
}
