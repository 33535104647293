import { customCleanEnv, str, url } from "envalid";

interface Env {
    serviceBasePath: string;
    conferenceUrl: string;
    callCallbackUrl: string;
    viteUcsdkFilepath: string;
    viteRequireJsUrl: string;
}

const SERVICE_PROVIDER = "twilio";

export const env = customCleanEnv(
    import.meta.env,
    {
        VITE_EVENTS_LISTENER_URL: url(),
        VITE_UCSDK_FILEPATH: str(),
        VITE_REQUIRE_JS_URL: url(),
    },
    (inputEnv): Env => ({
        serviceBasePath: `api/call-controls/${SERVICE_PROVIDER}`,
        conferenceUrl: `${inputEnv.VITE_EVENTS_LISTENER_URL}/api/${SERVICE_PROVIDER}/conference`,
        callCallbackUrl: `${inputEnv.VITE_EVENTS_LISTENER_URL}/api/${SERVICE_PROVIDER}/call`,
        viteUcsdkFilepath: inputEnv.VITE_UCSDK_FILEPATH,
        viteRequireJsUrl: inputEnv.VITE_REQUIRE_JS_URL,
    }),
);
