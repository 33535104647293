import type { SVGProps } from "react";

export function CaretDown(props: SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg fill="none" height={8} width={12} {...props}>
            <path
                clipRule="evenodd"
                d="m6 7.414 5.707-5.707L10.293.293 6 4.586 1.707.293.293 1.707 6 7.414Z"
                fill="#A839F3"
                fillRule="evenodd"
            />
        </svg>
    );
}
