import Markdown from "markdown-to-jsx";
import { Fragment } from "react";
import { hyperMediaOverride } from "./hyperMediaOverride";
import { listOverride } from "./listOverride";

interface MarkdownViewerProps {
    content: string;
}

export function MarkdownViewer({ content }: MarkdownViewerProps): JSX.Element {
    return (
        <Markdown
            options={{
                wrapper: Fragment,
                forceBlock: true,
                overrides: {
                    ...hyperMediaOverride,
                    ...listOverride,
                },
            }}
        >
            {content}
        </Markdown>
    );
}
