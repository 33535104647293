export const summaryLoadingLottie = {
    v: "5.0.1",
    fr: 100,
    ip: 0,
    op: 197,
    w: 100,
    h: 100,
    ddd: 0,
    assets: [],
    layers: [
        {
            ind: 5,
            nm: "Layer 5",
            ks: {
                p: { a: 0, k: [12.486, 40.747] },
                a: { a: 0, k: [10.154, 10.154, 0] },
                s: { a: 0, k: [57.408, 57.408, 100] },
                r: { a: 0, k: 0 },
                o: { a: 0, k: 100 },
            },
            ao: 0,
            ip: 0,
            op: 197,
            st: 0,
            bm: 0,
            sr: 1,
            ty: 4,
            shapes: [
                {
                    ty: "gr",
                    it: [
                        {
                            ty: "gr",
                            it: [
                                {
                                    ty: "sh",
                                    d: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [-5.404, 3.904],
                                                [-3.539, -6.861],
                                                [5.257, -3.299],
                                                [4.59, 5.672],
                                            ],
                                            o: [
                                                [5.426, 3.214],
                                                [-3.415, 3.995],
                                                [-5.576, -3.927],
                                                [3.566, -5.192],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 0],
                                                [16.207, 0],
                                                [16.207, 16.207],
                                                [0, 16.207],
                                                [0, 0],
                                            ],
                                            c: true,
                                        },
                                        hd: false,
                                    },
                                },
                                {
                                    ty: "st",
                                    c: { a: 0, k: [0.38, 0.42, 0.478, 1] },
                                    o: { a: 0, k: 100 },
                                    w: { a: 0, k: 5 },
                                    lc: 2,
                                    lj: 2,
                                    ml: 4,
                                },
                                {
                                    ty: "tr",
                                    p: { a: 0, k: [11.46, 11.46] },
                                    a: { a: 0, k: [8.104, 8.104] },
                                    s: { a: 0, k: [100, 100] },
                                    r: { a: 0, k: -45 },
                                    o: { a: 0, k: 100 },
                                    sk: { a: 0, k: 0 },
                                    sa: { a: 0, k: 0 },
                                },
                            ],
                            nm: "spark-7",
                            ln: "spark-7",
                            hd: false,
                        },
                        {
                            ty: "tr",
                            p: {
                                a: 1,
                                k: [
                                    {
                                        t: 107.7,
                                        s: [52.14, 85.496],
                                        i: { x: [0.102, 0.102], y: [2.254, 0.405] },
                                        o: { x: [0.621, 0.621], y: [-1.246, 0.238] },
                                        e: [25.394, -31.112],
                                    },
                                    { t: 176.3, s: [25.394, -31.112] },
                                ],
                            },
                            a: { a: 0, k: [11.46, 11.46] },
                            s: {
                                a: 1,
                                k: [
                                    {
                                        t: 107.6,
                                        s: [0, 0],
                                        i: { x: [1], y: [1] },
                                        o: { x: [0.42], y: [0] },
                                        e: [100, 100],
                                    },
                                    { t: 142.3, s: [100, 100] },
                                ],
                            },
                            r: {
                                a: 1,
                                k: [
                                    { t: 107, s: [0], i: { x: [0.58], y: [1] }, o: { x: [0.42], y: [0] }, e: [180] },
                                    { t: 176.3, s: [180] },
                                ],
                            },
                            o: {
                                a: 1,
                                k: [
                                    {
                                        t: 153.3,
                                        s: [100],
                                        i: { x: [0.833], y: [0.833] },
                                        o: { x: [0.167], y: [0.167] },
                                        e: [0],
                                    },
                                    { t: 174.7, s: [0] },
                                ],
                            },
                            sk: { a: 0, k: 0 },
                            sa: { a: 0, k: 0 },
                        },
                    ],
                    nm: "spark-6",
                    ln: "spark-6",
                    hd: false,
                },
            ],
        },
        {
            ind: 4,
            nm: "Layer 4",
            ks: {
                p: { a: 0, k: [34.493, 56.708] },
                a: { a: 0, k: [10.154, 10.154, 0] },
                s: { a: 0, k: [74.994, 74.994, 100] },
                r: { a: 0, k: 0 },
                o: { a: 0, k: 100 },
            },
            ao: 0,
            ip: 0,
            op: 197,
            st: 0,
            bm: 0,
            sr: 1,
            ty: 4,
            shapes: [
                {
                    ty: "gr",
                    it: [
                        {
                            ty: "gr",
                            it: [
                                {
                                    ty: "sh",
                                    d: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [-5.404, 3.904],
                                                [-3.539, -6.861],
                                                [5.257, -3.299],
                                                [4.59, 5.672],
                                            ],
                                            o: [
                                                [5.426, 3.214],
                                                [-3.415, 3.995],
                                                [-5.576, -3.927],
                                                [3.566, -5.192],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 0],
                                                [16.207, 0],
                                                [16.207, 16.207],
                                                [0, 16.207],
                                                [0, 0],
                                            ],
                                            c: true,
                                        },
                                        hd: false,
                                    },
                                },
                                {
                                    ty: "st",
                                    c: { a: 0, k: [0.38, 0.42, 0.478, 1] },
                                    o: { a: 0, k: 100 },
                                    w: { a: 0, k: 5 },
                                    lc: 2,
                                    lj: 2,
                                    ml: 4,
                                },
                                {
                                    ty: "tr",
                                    p: { a: 0, k: [11.46, 11.46] },
                                    a: { a: 0, k: [8.104, 8.104] },
                                    s: { a: 0, k: [100, 100] },
                                    r: { a: 0, k: -45 },
                                    o: { a: 0, k: 100 },
                                    sk: { a: 0, k: 0 },
                                    sa: { a: 0, k: 0 },
                                },
                            ],
                            nm: "spark-5",
                            ln: "spark-5",
                            hd: false,
                        },
                        {
                            ty: "tr",
                            p: {
                                a: 1,
                                k: [
                                    {
                                        t: 70.2,
                                        s: [9.47, 50.542],
                                        i: { x: [0.323, 0.323], y: [-0.234, 0.77] },
                                        o: { x: [0.648, 0.648], y: [0.745, 0.26] },
                                        e: [33.74, -43.853],
                                    },
                                    { t: 176.2, s: [33.74, -43.853] },
                                ],
                            },
                            a: { a: 0, k: [11.46, 11.46] },
                            s: {
                                a: 1,
                                k: [
                                    {
                                        t: 70.2,
                                        s: [0, 0],
                                        i: { x: [1], y: [1] },
                                        o: { x: [0.42], y: [0] },
                                        e: [100, 100],
                                    },
                                    { t: 108.2, s: [100, 100] },
                                ],
                            },
                            r: {
                                a: 1,
                                k: [
                                    { t: 69, s: [0], i: { x: [0.58], y: [1] }, o: { x: [0.42], y: [0] }, e: [180] },
                                    { t: 173.9, s: [180] },
                                ],
                            },
                            o: {
                                a: 1,
                                k: [
                                    {
                                        t: 129.1,
                                        s: [100],
                                        i: { x: [0.833], y: [0.833] },
                                        o: { x: [0.167], y: [0.167] },
                                        e: [0],
                                    },
                                    { t: 144, s: [0] },
                                ],
                            },
                            sk: { a: 0, k: 0 },
                            sa: { a: 0, k: 0 },
                        },
                    ],
                    nm: "spark-4",
                    ln: "spark-4",
                    hd: false,
                },
            ],
        },
        {
            ind: 3,
            nm: "Layer 3",
            ks: {
                p: { a: 0, k: [33.197, 42.985] },
                a: { a: 0, k: [10.154, 10.154, 0] },
                s: { a: 0, k: [46.677, 46.677, 100] },
                r: { a: 0, k: 0 },
                o: { a: 0, k: 100 },
            },
            ao: 0,
            ip: 0,
            op: 197,
            st: 0,
            bm: 0,
            sr: 1,
            ty: 4,
            shapes: [
                {
                    ty: "gr",
                    it: [
                        {
                            ty: "gr",
                            it: [
                                {
                                    ty: "sh",
                                    d: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [-5.404, 3.904],
                                                [-3.539, -6.861],
                                                [5.257, -3.299],
                                                [4.59, 5.672],
                                            ],
                                            o: [
                                                [5.426, 3.214],
                                                [-3.415, 3.995],
                                                [-5.576, -3.927],
                                                [3.566, -5.192],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 0],
                                                [16.207, 0],
                                                [16.207, 16.207],
                                                [0, 16.207],
                                                [0, 0],
                                            ],
                                            c: true,
                                        },
                                        hd: false,
                                    },
                                },
                                {
                                    ty: "st",
                                    c: { a: 0, k: [0.38, 0.42, 0.478, 1] },
                                    o: { a: 0, k: 100 },
                                    w: { a: 0, k: 5 },
                                    lc: 2,
                                    lj: 2,
                                    ml: 4,
                                },
                                {
                                    ty: "tr",
                                    p: { a: 0, k: [11.46, 11.46] },
                                    a: { a: 0, k: [8.104, 8.104] },
                                    s: { a: 0, k: [100, 100] },
                                    r: { a: 0, k: -45 },
                                    o: { a: 0, k: 100 },
                                    sk: { a: 0, k: 0 },
                                    sa: { a: 0, k: 0 },
                                },
                            ],
                            nm: "spark-3",
                            ln: "spark-3",
                            hd: false,
                        },
                        {
                            ty: "tr",
                            p: {
                                a: 1,
                                k: [
                                    {
                                        t: 42.6,
                                        s: [-1.282, 98.024],
                                        i: { x: [0.403, 0.403], y: [0.67, 0.815] },
                                        o: { x: [0.781, 0.781], y: [0.33, 0.4] },
                                        e: [-1.282, -17.171],
                                    },
                                    { t: 116.2, s: [-1.282, -17.171] },
                                ],
                            },
                            a: { a: 0, k: [11.46, 11.46] },
                            s: {
                                a: 1,
                                k: [
                                    {
                                        t: 42.6,
                                        s: [0, 0],
                                        i: { x: [1], y: [1] },
                                        o: { x: [0.42], y: [0] },
                                        e: [100, 100],
                                    },
                                    { t: 80.6, s: [100, 100] },
                                ],
                            },
                            r: {
                                a: 1,
                                k: [
                                    { t: 41.4, s: [0], i: { x: [0.58], y: [1] }, o: { x: [0.42], y: [0] }, e: [180] },
                                    { t: 117.5, s: [180] },
                                ],
                            },
                            o: {
                                a: 1,
                                k: [
                                    {
                                        t: 101.5,
                                        s: [100],
                                        i: { x: [0.833], y: [0.833] },
                                        o: { x: [0.167], y: [0.167] },
                                        e: [0],
                                    },
                                    { t: 116.2, s: [0] },
                                ],
                            },
                            sk: { a: 0, k: 0 },
                            sa: { a: 0, k: 0 },
                        },
                    ],
                    nm: "spark-2",
                    ln: "spark-2",
                    hd: false,
                },
            ],
        },
        {
            ind: 2,
            nm: "Layer 2",
            ks: {
                p: { a: 0, k: [14.483, 33.005] },
                a: { a: 0, k: [10.154, 10.154, 0] },
                s: { a: 0, k: [89.004, 89.004, 100] },
                r: { a: 0, k: 0 },
                o: { a: 0, k: 100 },
            },
            ao: 0,
            ip: 0,
            op: 197,
            st: 0,
            bm: 0,
            sr: 1,
            ty: 4,
            shapes: [
                {
                    ty: "gr",
                    it: [
                        {
                            ty: "gr",
                            it: [
                                {
                                    ty: "sh",
                                    d: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [-5.404, 3.904],
                                                [-3.539, -6.861],
                                                [5.257, -3.299],
                                                [4.59, 5.672],
                                            ],
                                            o: [
                                                [5.426, 3.214],
                                                [-3.415, 3.995],
                                                [-5.576, -3.927],
                                                [3.566, -5.192],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 0],
                                                [16.207, 0],
                                                [16.207, 16.207],
                                                [0, 16.207],
                                                [0, 0],
                                            ],
                                            c: true,
                                        },
                                        hd: false,
                                    },
                                },
                                {
                                    ty: "st",
                                    c: { a: 0, k: [0.38, 0.42, 0.478, 1] },
                                    o: { a: 0, k: 100 },
                                    w: { a: 0, k: 5 },
                                    lc: 2,
                                    lj: 2,
                                    ml: 4,
                                },
                                {
                                    ty: "tr",
                                    p: { a: 0, k: [11.46, 11.46] },
                                    a: { a: 0, k: [8.104, 8.104] },
                                    s: { a: 0, k: [100, 100] },
                                    r: { a: 0, k: -45 },
                                    o: { a: 0, k: 100 },
                                    sk: { a: 0, k: 0 },
                                    sa: { a: 0, k: 0 },
                                },
                            ],
                            nm: "spark",
                            ln: "spark",
                            hd: false,
                        },
                        {
                            ty: "tr",
                            p: {
                                a: 1,
                                k: [
                                    {
                                        t: 18.3,
                                        s: [18.037, 67.449],
                                        i: { x: [0.25, 0.25], y: [-1.916, 0.617] },
                                        o: { x: [0.707, 0.707], y: [3.985, 0.287] },
                                        e: [11.693, -3.088],
                                    },
                                    { t: 92.1, s: [11.693, -3.088] },
                                ],
                            },
                            a: { a: 0, k: [11.46, 11.46] },
                            s: {
                                a: 1,
                                k: [
                                    {
                                        t: 18.3,
                                        s: [0, 0],
                                        i: { x: [1], y: [1] },
                                        o: { x: [0.42], y: [0] },
                                        e: [100, 100],
                                    },
                                    { t: 56.3, s: [100, 100] },
                                ],
                            },
                            r: {
                                a: 1,
                                k: [
                                    { t: 17.2, s: [0], i: { x: [0.58], y: [1] }, o: { x: [0.42], y: [0] }, e: [180] },
                                    { t: 93.2, s: [180] },
                                ],
                            },
                            o: {
                                a: 1,
                                k: [
                                    {
                                        t: 77.4,
                                        s: [100],
                                        i: { x: [0.833], y: [0.833] },
                                        o: { x: [0.167], y: [0.167] },
                                        e: [0],
                                    },
                                    { t: 92.1, s: [0] },
                                ],
                            },
                            sk: { a: 0, k: 0 },
                            sa: { a: 0, k: 0 },
                        },
                    ],
                    nm: "spark",
                    ln: "spark",
                    hd: false,
                },
            ],
        },
        {
            ind: 1,
            nm: "pen",
            ks: {
                p: {
                    a: 1,
                    k: [
                        {
                            t: 0,
                            s: [28, 84, 0],
                            to: [0, 0, 0],
                            ti: [0, 0, 0],
                            i: { x: [0.58], y: [1] },
                            o: { x: [0.42], y: [0] },
                            e: [58, 84, 0],
                        },
                        {
                            t: 138.1,
                            s: [58, 84, 0],
                            to: [0, 0, 0],
                            ti: [0, 0, 0],
                            i: { x: [0.58], y: [1] },
                            o: { x: [0.42], y: [0] },
                            e: [28, 84, 0],
                        },
                        { t: 197, s: [28, 84, 0] },
                    ],
                },
                a: { a: 0, k: [0, 54.232, 0] },
                s: { a: 0, k: [116.438, 116.438, 100] },
                r: {
                    a: 1,
                    k: [
                        {
                            t: 0,
                            s: [-21.093],
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            e: [-18.487],
                        },
                        {
                            t: 25.2,
                            s: [-18.487],
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            e: [-25.045],
                        },
                        {
                            t: 42.6,
                            s: [-25.045],
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            e: [-20.427],
                        },
                        {
                            t: 62.1,
                            s: [-20.427],
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            e: [-21.74],
                        },
                        {
                            t: 78.2,
                            s: [-21.74],
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            e: [-19.9],
                        },
                        {
                            t: 95.5,
                            s: [-19.9],
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            e: [-24.26],
                        },
                        {
                            t: 112.8,
                            s: [-24.26],
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            e: [-16.916],
                        },
                        {
                            t: 135.8,
                            s: [-16.916],
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            e: [-21.093],
                        },
                        { t: 197, s: [-21.093] },
                    ],
                },
                o: { a: 0, k: 100 },
            },
            ao: 0,
            ip: 0,
            op: 197,
            st: 0,
            bm: 0,
            sr: 1,
            ty: 4,
            shapes: [
                {
                    ty: "gr",
                    it: [
                        {
                            ty: "sh",
                            d: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [-2.659, 3.423],
                                        [1.959, -2.707],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [2.841, -3.077],
                                        [-12.261, 14.597],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [-0.248, 1.242],
                                        [35.659, -43.423],
                                        [43.541, -37.293],
                                        [6.758, 6.497],
                                        [-2, 10],
                                    ],
                                    c: true,
                                },
                                hd: false,
                            },
                        },
                        {
                            ty: "st",
                            c: { a: 0, k: [0.51, 0.137, 0.824, 1] },
                            o: { a: 0, k: 100 },
                            w: { a: 0, k: 5 },
                            lc: 2,
                            lj: 2,
                            ml: 4,
                        },
                        {
                            ty: "tr",
                            p: { a: 0, k: [2, 44.232] },
                            a: { a: 0, k: [0, 0] },
                            s: { a: 0, k: [100, 100] },
                            r: { a: 0, k: 0 },
                            o: { a: 0, k: 100 },
                            sk: { a: 0, k: 0 },
                            sa: { a: 0, k: 0 },
                        },
                    ],
                    nm: "Object",
                    hd: false,
                },
            ],
            ln: "pen",
        },
    ],
    markers: [],
};
