import { Flex, ScrollArea } from "@mantine/core";
import { useAnalytics } from "@expert/analytics";
import { useEffect, useState } from "react";
import { type EligibleProduct } from "../stores";
import { LegalDisclosure } from "./StepSpecific/LegalDisclosure";
import classes from "./UnguidedFlow.module.css";
import { EnrollSection } from "./StepSpecific/EnrollSection";
import { StandaloneCard, UnguidedFooter } from "./StepSpecific/StandaloneCard";

interface UnguidedFlowProps {
    homeProduct: EligibleProduct;
}

export function UnguidedFlow({ homeProduct }: UnguidedFlowProps): JSX.Element {
    const [stepAdvance, setStepAdvance] = useState(false);
    const { dispatcher } = useAnalytics();
    const [disclosureChecked, setDisclosureChecked] = useState(false);
    useEffect(() => {
        void dispatcher.dispatchAction("PageShown", "UnguidedFlow");
    }, [dispatcher]);
    return stepAdvance ? (
        <Flex className={classes.stepsBox}>
            <ScrollArea>
                <LegalDisclosure
                    productSku={homeProduct.productSku}
                    isDisclosureChecked={disclosureChecked}
                    onDisclosureCheckedUpdated={(newStatus) => {
                        setDisclosureChecked(newStatus);
                    }}
                />
            </ScrollArea>
            <EnrollSection disclosureChecked={disclosureChecked} product={homeProduct} />
        </Flex>
    ) : (
        <Flex className={classes.stepsBox}>
            <StandaloneCard product={homeProduct} stepAdvance={setStepAdvance} />
            <UnguidedFooter product={homeProduct} />
        </Flex>
    );
}
