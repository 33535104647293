import { useQuery } from "@tanstack/react-query";
import { getAllQueueVolumes } from "../api";

export const useTrafficControl = () => {
    const { data, isFetching, isError } = useQuery({
        queryKey: [`traffic-control-all-queues`],
        queryFn: async () => getAllQueueVolumes(),
        retry: 1,
        refetchInterval: 60000,
    });
    return { data, isFetching, isError };
};
