import { Text } from "@mantine/core";
import React from "react";
import { LaptopFailure } from "../assets";
import { ErrorAsset, ErrorContent, ErrorWrapper } from "./elements";

interface GlobalErrorProps {
    msg: string;
    children?: React.ReactNode;
}
export function GlobalError({ msg, children }: GlobalErrorProps) {
    return (
        <ErrorWrapper>
            <ErrorAsset>
                <LaptopFailure />
            </ErrorAsset>
            <ErrorContent>
                <Text c="dark.3" size="lg">
                    {msg}
                </Text>
            </ErrorContent>
            {children ? <ErrorContent>{children}</ErrorContent> : null}
        </ErrorWrapper>
    );
}
