import type { EntryFieldTypes, EntrySkeletonType } from "contentful";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import type { ReactNode } from "react";
import { getOrCreateContentfulClient } from "../getOrCreateContentfulClient";

export interface PlanInfo {
    productSku: string;
    logoUrl: string | undefined;
    price: string;
    coverageBenefitsList: {
        iconUrl: string | undefined;
        description: string | undefined;
    }[];
    legalDisclosure: ReactNode;
}

interface CoverageBenefitFields {
    icon: EntryFieldTypes.AssetLink;
    description: EntryFieldTypes.Text;
}

interface PlanInfoFields {
    productSku: EntryFieldTypes.Symbol;
    logo: EntryFieldTypes.AssetLink;
    price: EntryFieldTypes.Symbol;
    coverageBenefitsList: EntryFieldTypes.Array<
        EntryFieldTypes.EntryLink<EntrySkeletonType<CoverageBenefitFields, "coverageBenefit">>
    >;
    legalDisclosure: EntryFieldTypes.RichText;
}

export const getPlanInfo = async (productSku: string): Promise<PlanInfo> => {
    const contentfulClient = getOrCreateContentfulClient();
    const planInfo = await contentfulClient.withoutUnresolvableLinks.getEntries<
        EntrySkeletonType<PlanInfoFields, "planInfo">
    >({
        content_type: "planInfo",
        "fields.productSku": productSku,
    });
    const fields = planInfo.items[0].fields;

    return {
        productSku: fields.productSku,
        price: fields.price,
        logoUrl: fields.logo?.fields.file?.url,
        coverageBenefitsList: fields.coverageBenefitsList.map((a) => {
            return { iconUrl: a?.fields.icon?.fields.file?.url, description: a?.fields.description };
        }),
        legalDisclosure: documentToReactComponents(fields.legalDisclosure),
    };
};
