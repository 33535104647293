import type { VoiceTask } from "@expert/sdk";
import { useTransfersStateStore } from "../TransferCall/transfers.store";
import { MAX_CONFERENCE_PARTICIPANTS } from "../types";

/**
 * the total count of call participants (excluding the expert and including participants that have not yet picked up)
 * The primary customer is added separately, since they are not included in task.conferenceParticipants
 */
export const getConferenceCallersCount = (task: VoiceTask): number => {
    const { conferenceParticipants, pendingConferenceParticipants, customerLeftTheConference } = task;

    //? build a set of ids to account for duplicates
    const participantCallIds = new Set<string>();
    for (const { participantCallId } of conferenceParticipants) participantCallIds.add(participantCallId);
    for (const { participantCallId } of pendingConferenceParticipants) participantCallIds.add(participantCallId);

    return participantCallIds.size + (customerLeftTheConference ? 0 : 1);
};

/** Returns true if the conference limit has been reached */
export const isConferenceLimitReached = (task: VoiceTask): boolean =>
    getConferenceCallersCount(task) >= MAX_CONFERENCE_PARTICIPANTS;

export function useTransferNumberToDisplay() {
    const { transferVdn, transferTfn } = useTransfersStateStore();

    if (transferVdn) {
        return transferVdn.name;
    }
    if (transferTfn) {
        return transferTfn;
    }
    return "Participant";
}
