import type { CaseDetails } from "@expert/shared-types";
import { immerable } from "immer";
import type { ChannelKind, TaskStatus } from "./types";

export abstract class Task {
    [immerable] = true;
    public status: TaskStatus = "pending";

    public readonly autoAccept: boolean = true;

    abstract get assignedAt(): Date | undefined;

    abstract get partner(): string;

    abstract get caseDetails(): CaseDetails | undefined;

    abstract updateCaseDetails(caseDetailsUpdates: Partial<CaseDetails>): Promise<void>;

    abstract get sessionId(): string | undefined;

    // TODO: Nasty way to have session from the very beginning
    abstract get sessionIdFallback(): string;

    public abstract get hasCustomer(): boolean;

    public abstract get previousTaskId(): string | undefined;

    /**
     * This function extracts from task simple details for logging.
     * It uses explicit properties name so we can destructure the object in the root level of the log record
     * @returns Task details for logging
     */
    public toLog(): Record<string, unknown> {
        return {
            taskId: this.id,
            taskName: this.name,
            sessionId: this.sessionId,
            taskStatus: this.status,
            taskAssignedAt: this.assignedAt,
        };
    }

    /**
     * @param mdn - The contact (chat || voice) MDN
     * @param previousTaskId - The taskId for the task preceding this one.
     * For instance, an outbound call started from an existing task will contain a previousTaskId
     * Or for transfers in messaging
     */
    constructor(
        public readonly type: ChannelKind,
        public readonly id: string,
        public readonly name: string,
        public readonly dateCreated: Date,
    ) {}
}
