import { EligibilityStatus } from "../../types";

interface AutoConfirmView {
    statusHeader: string;
    statusBody: string;
}

const statusMap: Record<string, AutoConfirmView | undefined> = {
    [EligibilityStatus.Eligible]: {
        statusHeader: "It’s too late to sell, but it counts as an opportunity",
        statusBody: "Confirm customers at the beginning of calls so it doesn’t affect your offer rate.",
    },
    [EligibilityStatus.Enrolled]: {
        statusHeader: "Confirm customers at the beginning of calls.",
        statusBody: "This is important so it doesn’t affect your offer rate.",
    },
    [EligibilityStatus.Ineligible]: {
        statusHeader: "Confirm customers at the beginning of calls",
        statusBody: "This is important so it doesn’t affect your offer rate.",
    },
    [EligibilityStatus.Pending]: {
        statusHeader: "Confirm customers at the beginning of calls.",
        statusBody: "This is important so it doesn’t affect your offer rate.",
    },
    [EligibilityStatus.Unknown]: {
        statusHeader: "Confirm customers at the beginning of calls.",
        statusBody: "This is important so it doesn’t affect your offer rate.",
    },
};

export const getAutoConfirmConfig = (status: EligibilityStatus) => {
    return (
        statusMap[status] ?? {
            statusHeader: "Something went wrong",
            statusBody: "Please reach out to Expert Workspace support.",
        }
    );
};
