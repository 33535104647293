import * as React from "react";
import type { SVGProps } from "react";

export function SilverBadge(props: SVGProps<SVGSVGElement>) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" height="100%" viewBox="0 0 25 25" width="100%" {...props}>
            <path
                fill="#AAA1A1"
                d="M10.194 24c2.485 0 6.176-2.787 7.506-3.713 1.377-.96 2.89-2.192 2.89-3.957V3.906A3.906 3.906 0 0 0 16.686 0H3.905A3.906 3.906 0 0 0 0 3.906V16.33c0 1.56 1.035 2.694 2.234 3.53C3.5 20.743 7.607 24 10.194 24Z"
            />
            <path
                fill="url(#a)"
                d="M10.194 24c2.485 0 6.176-2.787 7.506-3.713 1.377-.96 2.89-2.192 2.89-3.957V3.906A3.906 3.906 0 0 0 16.686 0H3.905A3.906 3.906 0 0 0 0 3.906V16.33c0 1.56 1.035 2.694 2.234 3.53C3.5 20.743 7.607 24 10.194 24Z"
            />
            <path fill="#93BCFC" d="m.77 1.577 5.722 8.11h7.747L7.938 0H3.906A3.9 3.9 0 0 0 .77 1.577Z" />
            <path
                fill="url(#b)"
                fillOpacity={0.15}
                d="m.77 1.577 5.722 8.11h7.747L7.938 0H3.906A3.9 3.9 0 0 0 .77 1.577Z"
            />
            <path fill="#6DA0EE" d="M19.999 1.839A3.902 3.902 0 0 0 16.685 0h-3.53L6.783 9.687l7.57.253 5.646-8.101Z" />
            <path
                fill="url(#c)"
                fillOpacity={0.15}
                d="M19.999 1.839A3.902 3.902 0 0 0 16.685 0h-3.53L6.783 9.687l7.57.253 5.646-8.101Z"
            />
            <path fill="#848B8C" d="M10.295 19.576a5.68 5.68 0 1 0 0-11.36 5.68 5.68 0 0 0 0 11.36Z" />
            <path
                fill="url(#d)"
                fillOpacity={0.5}
                d="M10.295 19.576a5.68 5.68 0 1 0 0-11.36 5.68 5.68 0 0 0 0 11.36Z"
            />
            <path
                stroke="#838383"
                strokeWidth={0.25}
                d="M10.295 19.576a5.68 5.68 0 1 0 0-11.36 5.68 5.68 0 0 0 0 11.36Z"
            />
            <path
                fill="#FFFCF6"
                stroke="#838383"
                strokeWidth={0.25}
                d="M9.912 10.586a.406.406 0 0 1 .766 0l.562 1.616c.056.16.205.269.375.272l1.71.035a.406.406 0 0 1 .237.729l-1.363 1.033a.406.406 0 0 0-.143.44l.495 1.638a.406.406 0 0 1-.62.45l-1.404-.976a.406.406 0 0 0-.463 0l-1.404.976a.406.406 0 0 1-.62-.45l.495-1.637a.406.406 0 0 0-.143-.44l-1.363-1.034a.406.406 0 0 1 .237-.729l1.71-.035a.406.406 0 0 0 .375-.272l.561-1.616Z"
            />
            <defs>
                <linearGradient id="a" x1={0} x2={18.917} y1={1.319} y2={21.149} gradientUnits="userSpaceOnUse">
                    <stop stopColor="#fff" stopOpacity={0.6} />
                    <stop offset={1} stopColor="#fff" stopOpacity={0} />
                </linearGradient>
                <linearGradient id="b" x1={8.673} x2={4.083} y1={3.575} y2={7.531} gradientUnits="userSpaceOnUse">
                    <stop stopOpacity={0} />
                    <stop offset={1} />
                </linearGradient>
                <linearGradient id="c" x1={11.284} x2={16.863} y1={0.735} y2={6.466} gradientUnits="userSpaceOnUse">
                    <stop offset={0.01} stopOpacity={0} />
                    <stop offset={1} />
                </linearGradient>
                <radialGradient
                    id="d"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientTransform="rotate(58.401 -4.28 11.145) scale(11.7601)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#fff" stopOpacity={0.7} />
                    <stop offset={1} stopColor="#fff" stopOpacity={0} />
                </radialGradient>
            </defs>
        </svg>
    );
}
