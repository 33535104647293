import { useGlobalNotification } from "@expert/common-ui";
import { getLogger } from "@expert/logging";
import type { ConferenceParticipant, ConferenceParticipantBase } from "@expert/sdk";
import { isPendingConferenceParticipant, useActiveVoiceTask, useAgentSdk } from "@expert/sdk";
import { classNames } from "@expert/shared-utils";
import { Group, Stack, Text } from "@mantine/core";
import { useEffect, useMemo } from "react";
import { useTransferNumberToDisplay } from "../../../utils";
import { HoldButton, RemoveParticipantButton } from "../../Buttons";
import sharedStyles from "../../sharedStyles.module.css";
import styles from "./ParticipantControls.module.css";
import { ParticipantLabel } from "./ParticipantLabel";

interface ConferenceParticipantControlsProps {
    participant: ConferenceParticipantBase;
    isPrimary?: boolean;
}

export function ConferenceParticipantControls({ participant, isPrimary = false }: ConferenceParticipantControlsProps) {
    const logger = getLogger({ module: "participantControls" });
    const globalNotification = useGlobalNotification();
    const agentSdk = useAgentSdk();
    const task = useActiveVoiceTask();
    const participantDisplayName = useTransferNumberToDisplay();
    const participantError = !participant.participantName;

    const isPending = useMemo(() => {
        return isPendingConferenceParticipant(participant);
    }, [participant]);

    const removeParticipant = () =>
        isPending
            ? agentSdk.removePendingParticipant(task, participant.participantCallId)
            : agentSdk.removeParticipant(task, participant.participantCallId);

    useEffect(() => {
        if (participantError) {
            globalNotification.error(
                `We had trouble adding ${participantDisplayName} to the call.  Please check the number and try again.`,
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [participant.participantName]);

    if (participantError) {
        if (participant.participantCallId) {
            logger.error(
                { task },
                `Could not find participant with call ID ${participant.participantCallId}... removing from conference.`,
            );
            void removeParticipant();
        } else {
            logger.error(
                { task },
                `Could not find identifiers for the given participant... unable to remove from conference.`,
            );
        }
        return null;
    }

    const onHoldClick = (hold: boolean) =>
        agentSdk.updateParticipant(task, participant.participantName, {
            endConferenceOnExit: false,
            hold,
        });

    return (
        <Group
            classNames={{
                root: classNames(styles.participantContainer, isPrimary ? styles.primaryBackground : null),
            }}
            data-testid={isPending ? "pending-participant-panel" : "active-participant-controls"}
        >
            <Stack gap="1px">
                {isPrimary /* TODO: display participant/vdn name when available */ ? (
                    <Text size="xs" c="white">
                        Primary caller
                    </Text>
                ) : null}
                <Group gap="xs">
                    <Text className="fs-mask" fw={700} size="md" c="white">
                        <ParticipantLabel mdn={participant.participantName} />
                    </Text>
                    {isPending ? (
                        <Text size="xs" fw={300} className={sharedStyles.animatedEllipses}>
                            Calling
                        </Text>
                    ) : null}
                </Group>
            </Stack>
            <span className={sharedStyles.stretch} />
            <>
                {isPending ? null : (
                    <HoldButton
                        task={task}
                        outlined
                        isOnHold={!!participant.hold}
                        onPauseCallButtonClick={() => onHoldClick(true)}
                        onResumeCallButtonClick={() => onHoldClick(false)}
                        participant={participant as ConferenceParticipant}
                    />
                )}
                <RemoveParticipantButton onClick={removeParticipant} disabled={!!participant.hold} />
            </>
        </Group>
    );
}
