import { useAnalytics } from "@expert/analytics";
import { useToast } from "@expert/common-ui";
import { useVerifiedCustomerStore } from "@expert/customer-info";
import { getLogger } from "@expert/logging";
import { isVoiceTask, useAgentStore, useSession } from "@expert/sdk";
import { getHashedValue, stripCountryCode } from "@expert/shared-utils";
import { Group } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { useCallback, useEffect, useState } from "react";
import { useShallow } from "zustand/react/shallow";
import { useSessionSummaryStore } from "../store/useSessionSummaryStore";
import { validateSummary } from "../validateSummary";
import { EditSessionSummaryModal } from "./EditSessionSummaryModal";
import styles from "./EditSessionSummaryModal.module.css";

const STREAMSHUB_EVENT_DELAY_IN_MS = 6 * 1000; // 6 second delay request by ML/Sales

const logger = getLogger({ module: "sessionSummary" });

// TODO: Replace with Dynamic Component in the timeline,
// with sort of Edit/Copy buttons and toText() to extract content only for ACSS.
// So hopefully all of the code below will be merged with solve team's code 🤔
export function SessionSummary(): JSX.Element | null {
    const { dispatcher } = useAnalytics();
    const [mdn, accountNumber] = useVerifiedCustomerStore((state) => [
        state.customer?.mdn,
        state.customer?.accountNumber,
    ]);

    const [setCanAppend, setSubmitted, autogeneratedSummary] = useSessionSummaryStore((state) => [
        state.setCanAppend,
        state.setSubmitted,
        state.autogeneratedSummary,
    ]);

    const [summaryLoaded, setSummaryLoaded] = useState(false);

    const toast = useToast();
    const [opened, { open: openModal, close: closeModal }] = useDisclosure(false);

    const agentActivity = useAgentStore(useShallow((state) => state.activity));
    const activeSession = useSession();
    const activeTask = activeSession.currentTask;

    if (activeTask && !isVoiceTask(activeTask)) {
        throw new Error("Unsupported task channel. SessionSummary should not be rendered without a voice task");
    }

    /** the agentSid is always used for streamsHub so we use it here for the analytics identity*/
    const agentCallSid = activeTask?.agentCallId;

    // TODO: Evidently?
    const shouldSendSummary = true;

    // A wrapping task or a newly wrapping agent means we are entering session summary
    const inWrappingState = activeTask?.status === "wrapping" || agentActivity === "Wrapping";

    const taskIsActive = activeTask?.status === "assigned";
    const hasImmediateCallBack = activeSession.callbackState?.callbackType === "CallbackNow";

    useEffect(() => {
        if (hasImmediateCallBack) return;

        // TODO: this is stupid but it's our only option right now with streamshub
        if (inWrappingState) {
            openModal();
            setTimeout(() => {
                setCanAppend(false);
                setSummaryLoaded(true);
            }, STREAMSHUB_EVENT_DELAY_IN_MS);
        } else if (taskIsActive) {
            setCanAppend(true);
        }
    }, [hasImmediateCallBack, inWrappingState, taskIsActive, setCanAppend, openModal]);

    const onDismiss = () => {
        closeModal();
    };

    const onSessionSummaryCompleted = useCallback(
        async (update?: string, skipSend?: boolean) => {
            if (skipSend) {
                // Stats -> Mixpanel
                void dispatcher
                    .withIdentities({ CallSid: agentCallSid })
                    .withMeta({ Client: "Verizon", Channel: "voice", SkipEDP: true })
                    .dispatchBusinessEvent("SessionSummary_Stats", {
                        summaryWasAutoGenerated: autogeneratedSummary !== "",
                        summaryWasEdited: update !== autogeneratedSummary,
                        summaryWasSubmittedViaACSS: false,
                        summaryWasCopiedToClipboard: true,
                        summaryLength: update?.length ?? 0,
                        timeRanOut: false,
                    });

                toast.success("Copied session summary");
                setSubmitted(true);
                closeModal();
                return;
            }

            const { isError, response } = validateSummary({ mdn, accountNumber, summary: update });

            if (!mdn || isError) {
                logger.error(response ?? "Something went wrong");
                toast.error("Something went wrong");
                closeModal();
                return;
            }

            const mdnFormatted = stripCountryCode(mdn);
            const mdnHashed = await getHashedValue(mdnFormatted);

            const sessionSummaryPayload = {
                accountNo: accountNumber,
                remarkText: update,

                /**
                 * These values are hard-coded verizon flags
                 * that will remain this way for the foreseeable future
                 *
                 * 👇🤓👇
                 */

                billAccountType: "C",
                remarkLevel: "A",
                remarkType: "EM",
                contactMethod: "EM",
            };

            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            if (shouldSendSummary) {
                // Summary -> ACSS
                void dispatcher
                    .withIdentities({ CallSid: agentCallSid, Mdn: mdnFormatted, HashedMdn: mdnHashed })
                    .withMeta({ Client: "Verizon", Channel: "voice", SkipMixpanel: true, SkipEDP: true })
                    .dispatchBusinessEvent("SessionSummary_Submit", sessionSummaryPayload);

                // Stats -> Mixpanel
                void dispatcher
                    .withIdentities({ CallSid: agentCallSid })
                    .withMeta({ Client: "Verizon", Channel: "voice", SkipEDP: true })
                    .dispatchBusinessEvent("SessionSummary_Stats", {
                        summaryWasAutoGenerated: autogeneratedSummary !== "",
                        summaryWasEdited: update !== autogeneratedSummary,
                        summaryWasSubmittedViaACSS: true,
                        summaryWasCopiedToClipboard: false,
                        summaryLength: update?.length ?? 0,
                        timeRanOut: false,
                    });
            } else {
                logger.debug({ sessionSummaryPayload }, "Send session summary skipped (toggled off)");
            }

            setSubmitted(true);
            closeModal();
            toast.success("Summary sent");
        },
        [
            mdn,
            accountNumber,
            shouldSendSummary,
            setSubmitted,
            closeModal,
            toast,
            dispatcher,
            agentCallSid,
            autogeneratedSummary,
        ],
    );

    // cleanup function on unmount
    useEffect(() => {
        return () => {
            const { summary, submitted } = useSessionSummaryStore.getState();

            if (!submitted && summary) {
                // Stats -> Mixpanel
                void dispatcher
                    .withIdentities({ CallSid: agentCallSid })
                    .withMeta({ Client: "Verizon", Channel: "voice", SkipEDP: true })
                    .dispatchBusinessEvent("SessionSummary_Stats", {
                        summaryWasAutoGenerated: autogeneratedSummary !== "",
                        summaryWasEdited: summary !== autogeneratedSummary,
                        summaryWasSubmittedViaACSS: false,
                        summaryWasCopiedToClipboard: true,
                        summaryLength: summary.length,
                        timeRanOut: true,
                    });
                toast.success("Session summary copied to clipboard");
                void navigator.clipboard.writeText(summary);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Group className={styles.tempTimeline}>
            <EditSessionSummaryModal
                loading={!summaryLoaded}
                onDismiss={onDismiss}
                onSend={onSessionSummaryCompleted}
                opened={opened}
            />
        </Group>
    );
}
