import { InitialIcon, InitialText } from "./elements";

interface UserInitialsProps {
    name: string;
}

export function UserInitial({ name }: UserInitialsProps): JSX.Element {
    return (
        <InitialIcon>
            <InitialText>{name.charAt(0).toUpperCase()}</InitialText>
        </InitialIcon>
    );
}
