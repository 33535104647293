import type { VoiceTask } from "@expert/sdk";
import { environment, userCache } from "@expert/shared-utils";
import { getFeature } from "@soluto-private/expert-workspace-feature-flagging";
import { type TwilioAppConfig } from "./types";

export function buildSipParameters(task: VoiceTask, sipParams: Record<string, string> = {}) {
    return task.userToUser ? { "User-to-User": task.userToUser, ...sipParams } : sipParams;
}

export function createUcid() {
    const now = new Date();

    const node = "00301";
    const hexNode = parseInt(node).toString(16).padStart(4, "0");
    const seq = Math.floor(Math.random() * 65535)
        .toString(10)
        .padStart(5, "0");
    const hexSeq = parseInt(seq).toString(16).padStart(4, "0");
    const epochTime = Math.round(now.getTime() / 1000);
    const hexTime = epochTime.toString(16).padStart(8, "0");
    return { hexUcid: `00FA08${hexNode}${hexSeq}${hexTime}`, ucid: `${node}${seq}${epochTime}` };
}

export async function calculateToAndFrom(toPhoneNumber: string, appConfig: TwilioAppConfig) {
    const isSipOutboundCallEnabled = await getFeature<boolean>("EWP-isSipOutboundCallEnabled", {
        environment,
        employeeId: userCache.employeeId,
    });

    if (isSipOutboundCallEnabled && appConfig.outboundCallFromSipVerizon) {
        return {
            // TODO: Implement failover functionality
            to: `sip:${toPhoneNumber}@${appConfig.sip.Ips.Outbound.NDC};tnx=${appConfig.sip.Interconnect.Ashburn.NDC}`,
            from: appConfig.outboundCallFromSipVerizon,
        };
    }

    // Fallback
    return {
        to: toPhoneNumber,
        from: appConfig.outboundCallFromTwilio,
    };
}
