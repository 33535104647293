import { immer } from "zustand/middleware/immer";
import { shallow } from "zustand/shallow";
import { createWithEqualityFn } from "zustand/traditional";
import { getRootDispatcher } from "@expert/analytics";
import { persist } from "zustand/middleware";
import type { SalesAssistDevicesEventPayload, SalesAssistEventPayload, SalesAssistOfferEventPayload } from "../models";

interface GuidedFlowStreamHubState {
    salesAssistOfferEvents: SalesAssistOfferEventPayload[];
    salesAssistDevicesEvents: SalesAssistDevicesEventPayload[];
    manualDevicesEvents: SalesAssistOfferEventPayload[];
    processGuidedFlowEvent: (event: SalesAssistEventPayload) => void;
    resetEvents: () => void;
}

export const useGuidedFlowStreamhubStore = createWithEqualityFn<GuidedFlowStreamHubState>()(
    persist(
        immer((set) => ({
            salesAssistOfferEvents: [],
            salesAssistDevicesEvents: [],
            manualDevicesEvents: [],
            processGuidedFlowEvent: (payload) => {
                set((state) => {
                    let offerText = "";
                    let devices = "";

                    switch (payload.context) {
                        case "guidedFlow-offers":
                            offerText = payload.body.map(({ message }) => message).join("");
                            void getRootDispatcher().dispatchAction("OfferGenerated", "GuidedFlow", { offerText });
                            return { salesAssistOfferEvents: [payload] };

                        case "guidedFlow-cues":
                            payload.body.forEach((item) => {
                                const { message } = item;
                                devices = message ? `${message}, ` : "";
                            });

                            void getRootDispatcher().dispatchAction("DeviceDetected", "GuidedFlow", { devices });
                            return { salesAssistDevicesEvents: [payload] };
                        case "manual-devices":
                            offerText = payload.body.map(({ message }) => message).join("");
                            return { manualDevicesEvents: [payload] };

                        default:
                            return state;
                    }
                });
            },
            resetEvents: () => {
                set((state) => {
                    state.salesAssistDevicesEvents = [];
                    state.salesAssistOfferEvents = [];
                    state.manualDevicesEvents = [];
                });
            },
        })),
        { name: "guided-flow-streamhub-store", skipHydration: true },
    ),
    shallow,
);
