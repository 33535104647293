import { customCleanEnv, str } from "envalid";

interface Env {
    appName: string;
}

export const env = customCleanEnv(
    import.meta.env,
    {
        VITE_APP_NAME: str(),
    },
    (inputEnv): Env => ({
        appName: inputEnv.VITE_APP_NAME,
    }),
);
