import { useActiveVoiceTask } from "@expert/sdk";
import { useEffect } from "react";
import { PhoneNumberInput, TransferButtonGroup } from "./components";
import { useTransfersStateStore } from "./transfers.store";

export function TransferCall() {
    const activeTask = useActiveVoiceTask();
    const { setTransferType } = useTransfersStateStore();

    useEffect(() => {
        // TODO: Currently supporting redirects only
        setTransferType("REDIRECT");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <PhoneNumberInput />
            <TransferButtonGroup activeTask={activeTask} />
        </>
    );
}
