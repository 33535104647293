import { immer } from "zustand/middleware/immer";
import { shallow } from "zustand/shallow";
import { createWithEqualityFn } from "zustand/traditional";
import type { StatisticsPayload } from "../types";

interface StatisticsState {
    initialized: boolean;
    offerStatusReceived: boolean;
    newOffer: boolean;
    statisticState: StatisticsPayload;
    setInitialized: (value: boolean) => void;
    setNewOffer: (value: boolean) => void;
    setOfferStatusReceived: (value: boolean) => void;
    setState: (payload: StatisticsPayload) => void;
    setPartialState: (payload: Partial<StatisticsPayload>) => void;
}

export const useStatisticsStore = createWithEqualityFn<StatisticsState>()(
    immer((set) => ({
        initialized: false,
        newOffer: false,
        offerStatusReceived: false,
        statisticState: {
            dailyChallenge: {
                offerCount: 0,
            },
            badges: {
                bronze: 0,
                silver: 0,
                gold: 0,
            },
        },
        setInitialized: (value) => {
            set((state) => {
                state.initialized = value;
            });
        },
        setNewOffer: (value) => {
            set((state) => {
                state.newOffer = value;
            });
        },
        setOfferStatusReceived: (value) => {
            set((state) => {
                state.offerStatusReceived = value;
            });
        },
        setState: (payload) => {
            set((state) => {
                state.statisticState = payload;
            });
        },
        setPartialState: (payload) => {
            set((state) => {
                state.statisticState = {
                    ...state.statisticState,
                    ...payload,
                };
            });
        },
    })),
    shallow,
);
