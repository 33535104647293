import { parsePhoneNumber } from "awesome-phonenumber";
import { env } from "../environment";
import { getVdn } from "../transfer/transferUtils";

function isTransferOption(phoneNumber?: string): boolean {
    if (!phoneNumber) {
        return false;
    }
    const results = getVdn(phoneNumber);
    return !!results; // Returns true if there's any value in results
}

export const validatePhoneNumber = (mdn: string | undefined): { valid: boolean; number?: string } => {
    if (!mdn) {
        return { valid: false };
    }

    const { valid, number } = parsePhoneNumber(mdn, { regionCode: "US" });
    const isValid =
        valid ||
        env.salesWhitelistedMdns.includes(mdn.replace(/-|\s|\(|\)/g, "")) ||
        isTransferOption(number?.significant);
    return { valid: isValid, number: number?.e164 };
};
