import { createContext, useContext } from "react";
import { useGaiaWebsocketInternal } from "../hooks/useGaiaWebsocket";
import type { GaiaWebsocketContext } from "../types";

export const GaiaContext = createContext<GaiaWebsocketContext | null>(null);

interface GaiaProviderProps {
    url: string;
    token: string;
    client: string;
    identity: string;
    children?: React.ReactNode;
}

export function GaiaProvider({ url, token, client, identity, children }: GaiaProviderProps) {
    const webSocketObject = useGaiaWebsocketInternal(url, token, client, identity);
    return <GaiaContext.Provider value={webSocketObject}>{children}</GaiaContext.Provider>;
}

export function useGaiaWebsocket() {
    const context = useContext(GaiaContext);
    return context;
}
