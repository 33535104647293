import { FullStory, init } from "@fullstory/browser";
import { getLogger } from "@expert/logging";

const logger = getLogger({
    module: "fullstory",
});

interface FullStoryConfig {
    orgId: string;
    isDevMode: boolean;
    readyCallback?: Parameters<typeof init>[1];
}

let devMode = false;

export const initFullStory = ({ orgId, isDevMode, readyCallback }: FullStoryConfig) => {
    devMode = isDevMode;
    init(
        {
            orgId,
            debug: true,
            devMode: isDevMode,
        },
        ({ sessionUrl, ...rest }) => {
            logger.debug({ sessionUrl }, "FullStory session initialized");
            readyCallback?.({ sessionUrl, ...rest });
        },
    );
};

interface FullStoryIdentifier {
    identity: string;
    displayName: string;
    properties?: object;
}

export const setFullStoryIdentifier = ({ identity, displayName, properties }: FullStoryIdentifier) => {
    if (!identity) return;
    if (devMode) return;

    FullStory("setIdentity", {
        uid: identity,
        properties: {
            displayName,
            ...properties,
        },
    });
};

export const trackFullStoryEvent = (name: string, properties: object) => {
    if (devMode) return;

    FullStory("trackEvent", {
        name,
        properties,
    });
};
