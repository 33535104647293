import { useAnalytics } from "@expert/analytics";
import { Flex, SimpleGrid, Tabs, Text } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { useState } from "react";
import { SalesTag, useHomeProductSessionStore, useOtherProductSessionStore } from "../home-product";
import type { EligibleProduct } from "../home-product";
import { HomeProductFlow } from "../home-product/components/HomeProductFlow";
import { EligibilityStatus, SaleProgress } from "../home-product/types";
import { getSortedProductSKUs } from "../partner-config/utils";
import { SaleView } from "../home-product/components/StepSpecific/SaleView";
import classes from "./MultiProduct.module.css";
import { SaleTrackingModal } from "./components";
import { OfferBaseCard } from "./components/BaseOfferCard";

export function MultiProduct(): JSX.Element {
    const [opened, { open, close }] = useDisclosure(false);
    const { dispatcher } = useAnalytics();
    const [selectedProduct, setSelectedProduct] = useState<EligibleProduct | undefined>(undefined);
    const eligibleProducts = useOtherProductSessionStore((state) => state.products);
    const sortedEligibleProducts = getSortedProductSKUs("verizon", eligibleProducts);
    const homeProducts = useHomeProductSessionStore((state) => state.products);

    return (
        <Tabs
            classNames={{ root: classes.root }}
            radius={0}
            data-testid="multi-product-tab-container"
            defaultValue="homePlan"
        >
            <Tabs.List grow justify="center">
                <Tabs.Tab
                    className={classes.tab}
                    onClick={() => void dispatcher.dispatchAction("ButtonClick", "HomeTab")}
                    value="homePlan"
                >
                    <Flex className={classes.tabHeader}>
                        <Text size="sm">Home plan</Text>{" "}
                        {homeProducts?.at(0) && homeProducts[0].status === EligibilityStatus.Eligible ? (
                            <SalesTag eligibility={homeProducts[0].status} />
                        ) : null}
                    </Flex>
                </Tabs.Tab>
                <Tabs.Tab
                    className={classes.tab}
                    onClick={() => void dispatcher.dispatchAction("ButtonClick", "OtherPlansTab")}
                    value="otherPlans"
                >
                    Other plans
                </Tabs.Tab>
            </Tabs.List>
            <Tabs.Panel className={classes.tabPanel} value="homePlan">
                <HomeProductFlow />
            </Tabs.Panel>
            <Tabs.Panel className={classes.tabPanel} value="otherPlans">
                {sortedEligibleProducts.length === 0 ? (
                    <SaleView status={EligibilityStatus.Ineligible} />
                ) : (
                    <SimpleGrid className={classes.cardGrid} cols={2} spacing="lg">
                        {sortedEligibleProducts.map((product) => {
                            return product.productSku !== "VZN_VMPMD" ||
                                sortedEligibleProducts.find((p) => p.productSku === "VZN_AAL")?.saleProgress ===
                                    SaleProgress.Success ? (
                                <OfferBaseCard
                                    product={product}
                                    key={product.productSku}
                                    onClick={(selected) => {
                                        void dispatcher.dispatchAction("ButtonClick", "OfferCardClicked", {
                                            productSku: selected.productSku,
                                        });
                                        setSelectedProduct(selected);
                                        open();
                                    }}
                                />
                            ) : null;
                        })}
                    </SimpleGrid>
                )}
            </Tabs.Panel>
            {selectedProduct ? (
                <SaleTrackingModal onClose={close} opened={opened} selectedProduct={selectedProduct} />
            ) : null}
        </Tabs>
    );
}
