import type { ExpertAssistMessage } from "../shared-types";

export const defaultMessages = {
    firstOpen: {
        text: `Hey there 👋 I'm here to help you solve your customers’ issues. Think of me as your assistant—you can chat with me anytime!

Ask or tell me anything, like "how to reset network settings" or "can’t send texts on iPhone 14.”`,
        type: "Default",
        id: `firstOpen_${crypto.randomUUID()}`,
    },
    inCallDeviceInfo: {
        text: "What kind of device do they need help with? Not listed? Type it in!",
        type: "DeviceInfo",
        id: `deviceInfo_${crypto.randomUUID()}`,
    },
    manualReset: {
        text: "The chat was reset. I’m ready to help!",
        type: "Default",
        id: `manualReset_${crypto.randomUUID()}`,
    },
} satisfies Record<string, ExpertAssistMessage>;
export type ResetReason = keyof typeof defaultMessages;

const defaultMessagesForProactive = {
    ...defaultMessages,
    firstOpen: {
        text: `Hey there 👋 I'm here to help you solve your customers’ issues. Think of me as
your assistant—I’ll be listening to the call to share tips. You can also chat
with me anytime with questions!`,
        type: "Default",
        id: `firstOpen_${crypto.randomUUID()}`,
    },
} satisfies Record<string, ExpertAssistMessage>;

export const getDefaultMessages = (isProactiveEnabled: boolean) =>
    isProactiveEnabled ? defaultMessagesForProactive : defaultMessages;
