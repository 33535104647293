import { Container, Flex, ScrollArea, Text } from "@mantine/core";
import { useGuidedFlowStreamhubStore, useHomeProductSessionStore } from "../../stores";
import { SaleProgress } from "../../types";
import type { SalesAssistEventPayload, SalesAssistOfferEventPayload } from "../../models";
import classes from "./StepTwoContent.module.css";
import { StepTwoFooter } from "./StepTwoFooter";

export function StepTwoContent(): JSX.Element | null {
    const products = useHomeProductSessionStore((state) => state.products);
    const offerEvents = useGuidedFlowStreamhubStore((state) => state.salesAssistOfferEvents);
    const manualDevicesList = useGuidedFlowStreamhubStore((state) => state.manualDevicesEvents);

    if (!products) return null;
    const homeProduct = products[0];
    const defaultOffer: SalesAssistOfferEventPayload = {
        body: [
            {
                message:
                    "Looking at your account, I noticed you are not taking advantage of our protection plan that covers things like ",
            },
            {
                message: "TVs, iPads, and gaming systems.",
                format: "highlight",
            },
            {
                message:
                    "If anything breaks, we’ll repair, replace, or reimburse you for it. I highly recommend this coverage!",
                newLine: true,
            },
            {
                message: "Can I get you signed up today?",
                format: "bold",
                newLine: true,
            },
        ],
        context: "guidedFlow-offers",
        heading: "Try this Offer!",
        template: "text",
    };
    let latestEvent: SalesAssistOfferEventPayload = defaultOffer;
    if (offerEvents.length > 0) {
        //TODO: Should we stop render after displaying one offer?
        latestEvent = offerEvents[0];
    }
    let manualDevices: SalesAssistEventPayload = {
        body: [],
        context: "manual-devices",
    };
    if (manualDevicesList.length > 0) {
        manualDevices = manualDevicesList[0];
    }

    return (
        <>
            {homeProduct.saleProgress === SaleProgress.InProgress ? (
                <Flex className={classes.offerRoot}>
                    <ScrollArea className={classes.scrollArea}>
                        <Container className={classes.offerBox}>
                            <Flex direction="row" justify="center">
                                <Text className={classes.stepTitle}>
                                    Use this{" "}
                                    <Text className={classes.boldGreenText} span>
                                        offer
                                    </Text>
                                </Text>
                            </Flex>
                            <Container className={classes.offerText}>
                                {latestEvent.body.map((item) => {
                                    const { message, format, newLine } = item;
                                    const classNames = [
                                        classes.offerTextSpan,
                                        newLine ? classes.newLine : "",
                                        format === "highlight" ? classes.highlightedText : "",
                                        format === "bold" ? classes.boldText : "",
                                    ]
                                        .join(" ")
                                        .trim();

                                    return (
                                        <Text span key={message} className={classNames}>
                                            {message}
                                        </Text>
                                    );
                                })}
                                {manualDevices.body.map((device) => {
                                    const { message, format, newLine } = device;
                                    if (!message) return null;
                                    const classNames = [
                                        classes.offerTextSpan,
                                        newLine ? classes.newLine : "",
                                        format === "bold" ? classes.boldText : "",
                                    ]
                                        .join(" ")
                                        .trim();
                                    return (
                                        <Text span key={message} className={classNames}>
                                            {message}
                                        </Text>
                                    );
                                })}
                            </Container>
                        </Container>
                    </ScrollArea>
                    <StepTwoFooter product={homeProduct} />
                </Flex>
            ) : null}
        </>
    );
}
