import { ActionIcon, Button, Card, Flex, Text } from "@mantine/core";
import { useAnalytics, withPolymorphicClickAnalytics } from "@expert/analytics";
import { useState } from "react";
import { getSessionId } from "@expert/sdk";
import type { EligibleProduct } from "../../stores";
import { useCurrentStepStore, useHomeProductSessionStore } from "../../stores";
import { SaleProgress } from "../../types";
import { InfoIcon } from "../Icons";
import { postDeclination } from "../../api";
import { VerizonIcon } from "../../../multi-product/components/icons";
import { PlanInfoGuidedFlow } from "./PlanInfoGuidedFlow";
import styles from "./StandaloneCard.module.css";

interface StandaloneProps {
    product: EligibleProduct;
    stepAdvance: React.Dispatch<React.SetStateAction<boolean>>;
}

export function StandaloneCard({ product, stepAdvance }: StandaloneProps): JSX.Element {
    const AnalyticsButton = withPolymorphicClickAnalytics(Button, "ButtonClick");
    const [declining, setDeclining] = useState(false);
    const [updateProduct] = useHomeProductSessionStore((state) => [state.updateProduct]);
    const [step, updateStep] = useCurrentStepStore((state) => [state.step, state.updateStep]);
    const { dispatcher } = useAnalytics();

    const decline = async () => {
        try {
            const sessionId = getSessionId();
            await postDeclination({
                callId: sessionId,
                sessionId,
                partner: "verizon",
                productOfferId: product.id,
            });
            updateProduct({ ...product, ...{ saleProgress: SaleProgress.Declined } });
            setDeclining(false);
        } catch (e) {
            setDeclining(false);
        }
    };
    return (
        <Card className={styles.card}>
            <Flex direction="column" gap="0.5rem">
                <VerizonIcon />
                <Text size="md">Verizon Home Device Protect</Text>
            </Flex>
            <Flex className={styles.lower}>
                <AnalyticsButton
                    analytics={{
                        actionId: "EnrollmentDeclineButton",
                        eventData: {
                            step,
                        },
                    }}
                    color="white"
                    data-testid="enrollment-decline-button"
                    loading={declining}
                    onClick={() => {
                        setDeclining(true);
                        void decline();
                    }}
                    size="md"
                    variant="transparent"
                >
                    <Text td="underline" size="sm">
                        Decline
                    </Text>
                </AnalyticsButton>
                <Button
                    size="md"
                    disabled={declining}
                    className={styles.actionText}
                    data-testid="predisclosure-advance-button"
                    color="white"
                    variant="outline"
                    onClick={() => {
                        void dispatcher
                            .withExtra({ step: "unguided-predisclosures" })
                            .dispatchBusinessEvent("ButtonClicked_ContinueToDisclosures");
                        updateStep("LegalDisclosureStep");
                        stepAdvance(true);
                    }}
                >
                    Continue to disclosures
                </Button>
            </Flex>
        </Card>
    );
}

interface FooterProps {
    product: EligibleProduct;
}
export function UnguidedFooter({ product }: FooterProps) {
    const { dispatcher } = useAnalytics();
    const [opened, setOpened] = useState(false);
    const openDrawer = () => {
        setOpened(true);
        void dispatcher.withExtra({ step: "unguided-predisclosures" }).dispatchBusinessEvent("ButtonClick_PlanInfo");
    };
    const closeDrawer = () => {
        setOpened(false);
    };
    return (
        <Flex className={styles.footerBox}>
            <PlanInfoGuidedFlow productSku={product.productSku} opened={opened} closeDrawer={closeDrawer} />
            <ActionIcon className={styles.infoIcon} variant="transparent" onClick={openDrawer}>
                <InfoIcon />
            </ActionIcon>
        </Flex>
    );
}
