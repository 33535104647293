import { getSessionId } from "@expert/sdk";
import { api } from "@expert/shared-utils";
import type { QueueTrafficInfo } from "../models";

export const getAllQueueVolumes = async (): Promise<QueueTrafficInfo[]> => {
    const response = await api.get(`api/traffic-control/v1/queues`, {
        headers: {
            "x-ewp-correlation-id": getSessionId(),
        },
    });

    return response.json<QueueTrafficInfo[]>();
};
