import { type SVGProps } from "react";

export function Mute(props: SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg fill="none" height="100%" viewBox="0 0 20 31" width="100%" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M10.0608 23.5007V29.6551"
                stroke="#F0F0F5"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.38474"
            />
            <path
                clipRule="evenodd"
                d="M10.0608 1.34485C7.3416 1.34485 5.13727 3.54918 5.13727 6.26837V14.8845C5.13727 17.6037 7.3416 19.8081 10.0608 19.8081C12.78 19.8081 14.9843 17.6037 14.9843 14.8845V6.26837C14.9843 3.54918 12.78 1.34485 10.0608 1.34485Z"
                fillRule="evenodd"
                stroke="#F0F0F5"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.38474"
            />
            <path
                d="M18.677 11.1919V14.8845C18.677 19.6431 14.8194 23.5007 10.0608 23.5007C5.30223 23.5007 1.44464 19.6431 1.44464 14.8845V11.1919"
                stroke="#F0F0F5"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.38474"
            />
        </svg>
    );
}
