import { getLogger } from "@expert/logging";
import { clearCallbackState, getActiveSession } from "../sessions/getters";
import { getCallbackState } from "../sessions/hooks";
import { useSessionStore } from "../sessions/session.store";
import type { AgentSdkBase } from "./agentSdkBase";
import { sdkEventBus } from "./eventBus";
import { useAgentStore } from "./store";
import type { Task } from "./task";
import { isVoiceTask } from "./voice";

const logger = getLogger({ module: "callbacks" });

/** @deprecated this doesn't seem to be called anywhere and may not work as intended */
export async function cancelActiveCallbackNow<TTask extends Task>(agentSdk: AgentSdkBase<TTask>) {
    await agentSdk.startWrapup("CallbackCancelled");
    clearCallbackState();

    // If you came here wondering how we end the session when we cancel a callback:
    // At the time of writing this happens when the user clicks "End Session" on the SessionSummaryModal
}

export async function scheduleCallbackNow<TTask extends Task>(
    agentSdk: AgentSdkBase<TTask>,
    callbackDelay: number,
    callbackMdn: string,
) {
    function handleSetCallbackNowState(voiceTask: Task) {
        const scheduledFor = Date.now() + callbackDelay * 1000;

        setCallbackState(voiceTask.id, {
            callbackType: "CallbackNow",
            scheduledAt,
            scheduledFor,
            callbackDelay,
            callbackMDN: callbackMdn,
        });
    }

    function handleWithinActiveTask(task: Task) {
        const cachedTaskId = task.id;

        const callbackState = {
            callbackType: "CallbackNow",
            scheduledAt,
            // We don't schedule it yet since we are waiting for the task to wrap
            // THis time should reflect the end of call time  + delay
            scheduledFor: 0,
            callbackDelay,
            callbackMDN: callbackMdn,
        } as const;

        setCallbackState(task.id, callbackState);
        subTaskCompletedCallbackNow(cachedTaskId);
    }

    async function handleWithinWrappingTask(task: Task) {
        // Order is important here, since task completion has side effects in the agentSdk
        // 1. We set our callback state
        // 2. We complete the task
        // 3. onTaskCompleted() will set our activity to "Callback Pending" if a callback state is set
        logger.debug("Scheduling callback now within wrapping task", { task });
        handleSetCallbackNowState(task);

        await agentSdk.endWrapup("CallbackScheduled");

        // No need to set agent activity, SDK should do this as a side effect of task completing.
        // TODO: Future: This is a fragility point
    }

    /**
     * We are scheduling a callback when we no longer have a task
     * This may happen when the agent cancels a callback and schedules it again
     */
    async function handleWithoutTask() {
        const session = getActiveSession();

        const previousTask = session.tasks.at(-1);
        if (!previousTask)
            throw new Error(
                "Cannot schedule a callback now without a previous task, no previous task found on session",
            );

        if (session.wrappingState) {
            await agentSdk.endWrapup("CallbackScheduled");
        }

        await agentSdk.setAgentActivity("Callback Pending");
        handleSetCallbackNowState(previousTask);
    }

    const agentActivity = useAgentStore.getState().activity;
    const setCallbackState = useSessionStore.getState().setCallbackState;
    const session = getActiveSession();

    const currentTask = session.currentTask;

    const scheduledAt = Date.now();

    try {
        if (currentTask) {
            if (currentTask.status === "wrapping" || agentActivity === "Wrapping" || session.wrappingState) {
                await handleWithinWrappingTask(currentTask);
            } else {
                handleWithinActiveTask(currentTask);
            }
        } else {
            await handleWithoutTask();
        }

        return true;
    } catch (err) {
        logger.error("Failed to schedule callback now", err);
        return false;
    }
}

let existingCallbackNowSubscription: (() => void) | null = null;
export function subTaskCompletedCallbackNow(taskId: string) {
    logger.debug({ taskId }, "Subscribing to task wrapping event for callback now");

    // If a callback if scheduled again, make sure we are not piling up subscriptions
    if (existingCallbackNowSubscription) existingCallbackNowSubscription();

    existingCallbackNowSubscription = sdkEventBus.once(
        "task_wrapping",
        (eventTask) => {
            existingCallbackNowSubscription = null;

            //TODO: Remove when we have chat support, this will no longer be needed and more serves as a touch-point error
            if (!isVoiceTask(eventTask)) throw new Error("Unsupported task type in callback handler");

            logger.debug({ taskId, eventTask }, "Task wrapping event received for callback now");

            // TODO: Ensure that cancelling a callback will clear our callback state
            //    - I changed the behavior here to rely on the session callback state, and not the UI store "dirty" state

            const existingCallbackState = getCallbackState();
            logger.debug({ taskId, existingCallbackState }, "Checking callback state for callback now");

            // If the callback was cancelled or changed we don't want to set a bad callbackNow state
            if (existingCallbackState?.callbackType === "CallbackNow") {
                const setCallbackState = useSessionStore.getState().setCallbackState;

                const scheduledFor = Date.now() + (existingCallbackState.callbackDelay ?? 0) * 1000;

                logger.debug(
                    {
                        taskId,
                        scheduledFor,
                        newState: {
                            ...existingCallbackState,
                            scheduledFor,
                        },
                    },
                    "Setting callback now state",
                );

                setCallbackState(taskId, {
                    ...existingCallbackState,
                    scheduledFor,
                });
            }
        },
        (voiceTask) => voiceTask.id === taskId,
    );
}
