import type { GaiaWebsocketContext } from "@expert/gaia";
import { useGaiaWebsocket } from "@expert/gaia";
import { getLogger } from "@expert/logging";
import { type VoiceTask, useActiveTask, useSession } from "@expert/sdk";
import { usePrevious } from "@mantine/hooks";
import { solveSessionClosed, solveSessionStarted } from "@soluto-private/expert-workspace-timeline";
import { useEffect } from "react";
import { retryAsync } from "ts-retry";

const subscriptionTimeoutDuration = 2000;
const subscriptionRetryCount = 3;

const logger = getLogger({
    module: "useSolveSession",
    tag: "solve",
});

interface SessionLifecycleProps {
    sessionId: string;
    websocketObj: GaiaWebsocketContext;
    withCustomer: boolean;
    callSid?: string;
}

export const useSolveSession = () => {
    const { id: sessionId, kind: sessionKind } = useSession();
    const websocketObj = useGaiaWebsocket();
    const task = useActiveTask();
    // TODO(afd): Revisit this to properly handle nonvoice tasks and not unsafely cast this
    // eslint-disable-next-line
    const callSid = (task as VoiceTask)?.callSid;
    const previousSessionId = usePrevious(sessionId);
    const previousCallSid = usePrevious(callSid);

    const previousWSLoading = usePrevious(websocketObj?.loading);

    useEffect(() => {
        if (!sessionId || !websocketObj || websocketObj.loading) return;

        const callSidChanged = callSid && previousCallSid !== callSid;
        const sessionIdChanged = previousSessionId !== sessionId;
        const websocketObjJustLoaded = previousWSLoading === true;

        if (sessionIdChanged && previousSessionId) {
            switchSession({
                sessionId,
                websocketObj,
                callSid,
                withCustomer: sessionKind === "with-customer",
                previousSessionId,
            });
            return;
        }

        /** When a call comes, we subscribe to GAIA with a sessionId (callSid is undefined).
         * Once the callSid arrives, we need to resubscribe to GAIA with the existing sessionId and new callSid **/
        if (callSidChanged) return void resubscribeWithCallSid(sessionId, websocketObj, callSid);

        if (websocketObjJustLoaded)
            return void startSession({
                sessionId,
                websocketObj,
                callSid,
                withCustomer: sessionKind === "with-customer",
            });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [callSid, sessionId, websocketObj?.loading]);
};

const startSession = async ({ sessionId, websocketObj, withCustomer, callSid }: SessionLifecycleProps) => {
    solveSessionStarted({ sessionId, callSid, withCustomer });
    await subscribeSessionToGaia(sessionId, websocketObj, callSid);
};

const subscribeSessionToGaia = async (sessionId: string, websocketObj: GaiaWebsocketContext, callSid?: string) => {
    const loggerWithContext = logger.child({
        sessionId,
        callSid,
    });
    try {
        await retryAsync(
            async () => {
                await websocketObj.subscribeSessionToGaia({
                    sessionId,
                    sendJsonMessage: websocketObj.sendJsonMessage,
                    callSid,
                    timeout: subscriptionTimeoutDuration,
                });
            },
            { delay: subscriptionTimeoutDuration, maxTry: subscriptionRetryCount },
        );
    } catch (e) {
        loggerWithContext.error(
            `Error subscribing session ${sessionId} to gaia with max retries (${subscriptionRetryCount})`,
            e,
        );
    }
};

const resubscribeWithCallSid = async (sessionId: string, websocketObj: GaiaWebsocketContext, callSid: string) => {
    await subscribeSessionToGaia(sessionId, websocketObj, callSid);
};

const switchSession = ({
    sessionId,
    websocketObj,
    withCustomer,
    callSid,
    previousSessionId,
}: SessionLifecycleProps & { previousSessionId: string }) => {
    closeSession({ sessionId: previousSessionId, websocketObj, callSid });
    void startSession({
        sessionId,
        websocketObj,
        callSid,
        withCustomer,
    });
};

const closeSession = ({ sessionId, websocketObj, callSid }: Omit<SessionLifecycleProps, "withCustomer">) => {
    solveSessionClosed({ sessionId, callSid });
    websocketObj.unsubscribeSessionFromGaia(sessionId, websocketObj.sendJsonMessage);
};
