import { IconButton } from "@expert/common-ui";
import { Mute } from "@expert/common-ui/assets/image/Mute";
import { Unmute } from "@expert/common-ui/assets/image/Unmute";
import { useAgentSdk } from "@expert/sdk";
import type { VoiceTaskProps } from "../../types";
import { useControlsStore } from "../controls.store";

export function MuteButton({ task }: VoiceTaskProps): JSX.Element {
    const agentSdk = useAgentSdk();
    const { isMuted, toggleMute } = useControlsStore();

    const muteToggleIcon = isMuted ? <Unmute height={19} /> : <Mute height={19} />;
    const muteToggleText = isMuted ? "Unmute" : "Mute";

    const handleClick = () => {
        void agentSdk.muteCall(task, !task.isMuted);
        toggleMute(!isMuted);
    };

    return (
        <IconButton
            active={isMuted}
            analytics={{
                actionId: "MuteButton",
            }}
            aria-label={muteToggleText}
            data-testid={`call-controls-${isMuted ? "unmute" : "mute"}`}
            onClick={handleClick}
            radius="xl"
            size="35"
            tooltipLabel={muteToggleText}
        >
            {muteToggleIcon}
        </IconButton>
    );
}
