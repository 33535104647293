import { Text } from "@mantine/core";
import Player from "react-lottie-player";
import classes from "./BotListeningLoader.module.css";
import listeningLottie from "./listeningLottie.json";

function BotListeningLoader() {
    return (
        <>
            <Player animationData={listeningLottie} className={classes.player} loop play speed={1} />
            <Text c="dark.2" className={classes.text} fs="italic" fz={16}>
                Listening...
            </Text>
        </>
    );
}
export default BotListeningLoader;
