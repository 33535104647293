import { Button, Chip, Container, Flex, Group, ScrollArea, Stack, Text } from "@mantine/core";
import { useEffect, useState } from "react";
import { useAnalytics } from "@expert/analytics";
import { trackAppcuesEvent } from "@expert/monitoring";
import { IneligibleIcon, ListeningAnimation } from "../Icons";
import { useCurrentStepStore, useGuidedFlowStreamhubStore, useHomeProductSessionStore } from "../../stores";
import classes from "./EnhancedStepOneContent.module.css";
import { EnhancedStepOneFooter } from "./EnhancedStepOneFooter";

type DetectionType = "Manual" | "Automatic" | null;

export interface ProductData {
    productType: string;
    detectionType: DetectionType;
}

export function EnhancedStepOneContent() {
    const [itemLimit, setItemLimit] = useState<number | undefined>(7);
    const deviceEvents = useGuidedFlowStreamhubStore((state) => state.salesAssistDevicesEvents);
    const products = useHomeProductSessionStore((state) => state.products);
    const { dispatcher } = useAnalytics();
    const updateStep = useCurrentStepStore((state) => state.updateStep);
    const [deviceDetectionData, setDeviceDetectionData] = useState<ProductData[]>([
        { productType: "Tablet", detectionType: null },
        { productType: "Smart watch", detectionType: null },
        { productType: "Television", detectionType: null },
        { productType: "Gaming system", detectionType: null },
        { productType: "Router", detectionType: null },
        { productType: "Bluetooth speaker", detectionType: null },
        { productType: "Headphones", detectionType: null },
        { productType: "Computer keyboard", detectionType: null },
        { productType: "Security camera", detectionType: null },
        { productType: "Modem", detectionType: null },
        { productType: "Printer", detectionType: null },
        { productType: "Streaming device", detectionType: null },
        { productType: "Computer monitor", detectionType: null },
        { productType: "Computer", detectionType: null },
    ]);

    const manualDevices = deviceDetectionData.filter((item) => item.detectionType === "Manual");
    const automaticDevices = deviceDetectionData.filter((item) => item.detectionType === "Automatic");

    useEffect(() => {
        trackAppcuesEvent("EnhanceDeviceTagging shown", {});
    }, []);

    useEffect(() => {
        if (deviceEvents.length <= 0) return;
        const updatedDetectionData = deviceDetectionData.map((device) => {
            const located = deviceEvents[0].body.find(
                (item) =>
                    item.message?.toLowerCase() === device.productType ||
                    item.message?.toLowerCase().includes(device.productType.toLowerCase()),
            );
            if (located) {
                return { productType: device.productType, detectionType: "Automatic" } as ProductData;
            }
            return device;
        });
        setDeviceDetectionData(updatedDetectionData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deviceEvents]);

    useEffect(() => {
        if (manualDevices.length === 0 && automaticDevices.length >= 3) {
            void dispatcher.dispatchBusinessEvent("DeviceDetectionAutoAdvance_GuidedFlow");
            const timer1 = setTimeout(() => {
                updateStep("SuggestedOfferStep");
            }, 2000);
            return () => {
                clearTimeout(timer1);
            };
        }
    }, [automaticDevices.length, dispatcher, manualDevices.length, updateStep]);

    if (!products) {
        return (
            <Flex className={classes.loadingContainer} data-testid="failure">
                <Container>{IneligibleIcon}</Container>
                <Text className={classes.statusBodyHeader}>We had an issue checking eligibility.</Text>
                <Text className={classes.statusBodyText} data-testid="sale-view-status-body">
                    Try refreshing the page.
                </Text>
            </Flex>
        );
    }
    return (
        <Stack gap={0} className={classes.devicesSection}>
            <ScrollArea h="87%" mb="3rem">
                <Flex className={classes.deviceList}>
                    <Text fw="300" c="dark.0" size="24px">
                        Ask about their{" "}
                        <Text fw="700" c="success.5" size="24px" span>
                            home tech
                        </Text>
                    </Text>
                    <Flex mt="24px" gap={12} className={classes.listening}>
                        <ListeningAnimation />
                        <Text c="dark.2" fw="400" size="sm">
                            Listening for at least 3 devices...
                        </Text>
                    </Flex>
                    <Flex mt="24px" mb="2rem" className={classes.chipContainer}>
                        <Chip.Group
                            value={deviceDetectionData
                                .filter((d) => d.detectionType !== null)
                                .map((device) => device.productType)}
                            multiple
                        >
                            <Group gap={10}>
                                {deviceDetectionData.slice(0, itemLimit).map((entry) => (
                                    <Chip
                                        id={`DeviceChip-${entry.productType}`}
                                        onClick={() => {
                                            if (entry.detectionType !== "Automatic") {
                                                void dispatcher.dispatchBusinessEvent("DeviceChipClicked", {
                                                    device: entry.productType,
                                                    selectionAction:
                                                        entry.detectionType === "Manual" ? "deselected" : "selected",
                                                });
                                            }
                                            const updatedDeviceDetection = [...deviceDetectionData].map((item) => {
                                                if (item.productType === entry.productType) {
                                                    if (item.detectionType === "Manual") {
                                                        return {
                                                            productType: item.productType,
                                                            detectionType: null,
                                                        } as ProductData;
                                                    } else if (item.detectionType === null) {
                                                        return {
                                                            productType: item.productType,
                                                            detectionType: "Manual",
                                                        } as ProductData;
                                                    }
                                                }
                                                return item;
                                            });
                                            setDeviceDetectionData(updatedDeviceDetection);
                                        }}
                                        icon={<svg />}
                                        variant="transparent"
                                        size="xl"
                                        key={entry.productType}
                                        value={entry.productType}
                                        classNames={{ label: classes.chip, iconWrapper: classes.chipIcon }}
                                    >
                                        {entry.productType}
                                    </Chip>
                                ))}
                            </Group>
                        </Chip.Group>

                        {itemLimit !== undefined ? (
                            <Button
                                mt="16px"
                                variant="filled"
                                color="dark.4"
                                className={classes.showMore}
                                onClick={() => {
                                    void dispatcher.dispatchBusinessEvent("ShowMoreDeviceChipsClicked");
                                    setItemLimit(undefined);
                                }}
                            >
                                Show more
                            </Button>
                        ) : null}
                    </Flex>
                </Flex>
            </ScrollArea>
            <EnhancedStepOneFooter productSku={products[0].productSku} manualDevices={manualDevices} />
        </Stack>
    );
}
