import AccessoriesIcon from "../multi-product/components/icons/AccessoriesIcon.png";
import AddALineIcon from "../multi-product/components/icons/AddALine.png";
import FiosIcon from "../multi-product/components/icons/FiosIcon.png";
import FiveGIcon from "../multi-product/components/icons/FiveGIcon.png";
import MobileProtectIcon from "../multi-product/components/icons/MobileProtect.png";
import type { SalesConfiguration } from "./SalesConfiguration";

export const verizonSalesConfig: SalesConfiguration = {
    partner: "verizon",
    plans: [
        {
            sku: "VZN_AAL",
            name: "Add-a-Line",
            icon: <img alt="Fios Icon" height="72px" src={AddALineIcon} width="50px" />,
        },
        {
            sku: "VZN_VMPMD",
            name: "VMP",
            icon: <img alt="Fios Icon" height="60px" src={MobileProtectIcon} width="75px" />,
        },
        { sku: "FIOS", name: "Fios", icon: <img alt="Fios Icon" height="72px" src={FiosIcon} width="50px" /> },
        {
            sku: "FWA_5G_UWB",
            name: "5G",
            icon: <img alt="5G Icon" height="70px" src={FiveGIcon} width="100px" />,
        },
        {
            sku: "FWA_5G_CBAND",
            name: "5G",
            icon: <img alt="5G Icon" height="70px" src={FiveGIcon} width="100px" />,
        },
        {
            sku: "VZN_ACC",
            name: "Accessories",
            icon: <img alt="Accessories Icon" height="55px" src={AccessoriesIcon} width="72px" />,
        },
    ],
};

export const verizonSkuOrder: Record<string, number> = {
    VZN_AAL: 0,
    VZN_VMPMD: 1,
    FIOS: 2,
    FWA_5G_CBAND: 3,
    FWA_5G_UWB: 4,
    FWA_4G: 5,
    VZN_ACC: 6,
};
