import { transformDispatcher, useAnalytics } from "@expert/analytics";
import { useVerifiedCustomerStore } from "@expert/customer-info";
import type { CustomerDetailsPayload } from "@expert/customer-info/";
import { useActiveVoiceTask } from "@expert/sdk";
import { formatTime, stripCountryCode, validatePhoneNumber } from "@expert/shared-utils";
import { Flex, RingProgress, Text } from "@mantine/core";
import { useEffect, useState } from "react";
import { useTimer } from "react-timer-hook";
import { useCallDetailsStore } from "../../../call-details";
import classes from "./AutoConfirm.module.css";
import { useVerificationFlowStore } from "./store";

interface AutoConfirmProps {
    mdnFromTask: string;
    customerData?: CustomerDetailsPayload | null;
}

export function AutoConfirmCountDown({ mdnFromTask, customerData }: AutoConfirmProps): JSX.Element {
    const { setIsIvrVerified } = useVerifiedCustomerStore.getState();
    const { setCustomer } = useVerifiedCustomerStore.getState();
    const { pushStep } = useVerificationFlowStore.getState();
    const { dispatcher } = useAnalytics();
    const activeVoiceTask = useActiveVoiceTask();
    const [isExpired, setIsExpired] = useState(false);
    const [VALIDATION_FLAG, ANI] = useCallDetailsStore((state) => [
        state.callDetails.VALIDATION_FLAG,
        state.callDetails.ANI,
    ]);
    const { totalSeconds, pause } = useTimer({
        //Todo: move 5mins to contentful
        expiryTimestamp: new Date((activeVoiceTask.assignedAt?.getTime() ?? new Date().getTime()) + 1000 * 60 * 5),
        autoStart: true,
        onExpire: () => {
            if (!validatePhoneNumber(mdnFromTask).valid) {
                void dispatcher.dispatchBusinessEvent("AutoConfirm_Timer_Ended_WithInvalidMdn");
                setIsExpired(true);
                return;
            }
            setCustomer({
                ...customerData?.primaryAccountHolder,
                ...{ fullName: customerData?.primaryAccountHolder?.fullName, mdn: mdnFromTask },
            });
            transformDispatcher((newDispatcher) => {
                return newDispatcher.withExtra({ eligibilityCheckType: "TimerExpiredAutomaticCheck" });
            });
            if (VALIDATION_FLAG === "T" && ANI === stripCountryCode(mdnFromTask)) {
                void dispatcher.dispatchBusinessEvent("AutoConfirm_Timer_Ended", { IvrVerified: true });
                setIsIvrVerified(true);
            } else {
                void dispatcher.dispatchBusinessEvent("AutoConfirm_Timer_Ended", { IvrVerified: false });
                setIsIvrVerified(false);
            }
            pushStep("VerificationComplete");
            setIsExpired(true);
        },
    });

    useEffect(() => {
        if (activeVoiceTask.status === "wrapping") {
            pause();
        }
    }, [activeVoiceTask, pause]);

    useEffect(() => {
        void dispatcher.dispatchBusinessEvent("AutoConfirm_Timer_Initiated");
    }, [dispatcher]);
    return (
        <>
            {!isExpired ? (
                <Flex className={classes.footerAuto}>
                    <Flex>
                        <Text fw="400" size="sm" component="span">
                            We’ll auto-confirm them if you don’t. Heads up, this’ll
                            <Text component="span" fw="700">
                                {" "}
                                impact opportunity count.
                            </Text>
                        </Text>
                    </Flex>
                    <Flex>
                        <RingProgress
                            roundCaps
                            size={100}
                            rootColor="dark.9"
                            thickness={7}
                            aria-label="Auto Confirm progress"
                            sections={[{ value: totalSeconds / 3, color: "primary.5" }]}
                            label={
                                <Text c="dark.1" ff="mono" fw={400} ta="center" size="sm">
                                    {formatTime(totalSeconds, false, "m:ss")}
                                </Text>
                            }
                        />
                    </Flex>
                </Flex>
            ) : null}
        </>
    );
}
