import { useAnalytics, withPolymorphicClickAnalytics } from "@expert/analytics";
import { getSessionId } from "@expert/sdk";
import { ActionIcon, Button, Flex, Text } from "@mantine/core";
import { useState } from "react";
import { createEnrollment, postDeclination } from "../../api";
import type { EligibleProduct } from "../../stores";
import { useCurrentStepStore, useHomeProductSessionStore } from "../../stores";
import type { Enrollment } from "../../types";
import { EnrollmentStatus, SaleProgress } from "../../types";
import { InfoIcon } from "../Icons";
import classes from "./EnrollSection.module.css";
import { PlanInfoGuidedFlow } from "./PlanInfoGuidedFlow";

interface EnrollSectionProps {
    product: EligibleProduct;
    disclosureChecked: boolean;
    isGuidedFlow?: boolean;
}
export function EnrollSection({ product, disclosureChecked, isGuidedFlow }: EnrollSectionProps): JSX.Element {
    const [opened, setOpened] = useState(false);
    const step = useCurrentStepStore((state) => state.step);
    const { dispatcher } = useAnalytics();

    const openDrawer = () => {
        void dispatcher.withExtra({ step, isGuidedFlow }).dispatchAction("ButtonClick", "PlanInfo");
        setOpened(true);
    };
    const closeDrawer = () => {
        setOpened(false);
    };
    const [enrolling, setEnrolling] = useState(false);
    const [declining, setDeclining] = useState(false);
    const [updateProduct] = useHomeProductSessionStore((state) => [state.updateProduct]);
    const sessionId = getSessionId();
    const enrollmentDetails: Enrollment = {
        callId: sessionId,
        sessionId,
        partner: "verizon",
        productOfferId: product.id,
    };
    const enroll = async () => {
        try {
            const { enrollmentStatus } = await createEnrollment(enrollmentDetails);
            enrollmentStatus === EnrollmentStatus.Success
                ? updateProduct({ ...product, ...{ saleProgress: SaleProgress.Success } })
                : updateProduct({ ...product, ...{ saleProgress: SaleProgress.Failure } });
            setEnrolling(false);
        } catch (e) {
            setEnrolling(false);
        }
    };
    const decline = async () => {
        try {
            await postDeclination(enrollmentDetails);
            updateProduct({ ...product, ...{ saleProgress: SaleProgress.Declined } });
            setDeclining(false);
        } catch (e) {
            setDeclining(false);
        }
    };
    const AnalyticsButton = withPolymorphicClickAnalytics(Button, "ButtonClick");
    const updateStep = useCurrentStepStore((state) => state.updateStep);

    return (
        <Flex className={classes.bottomControlContainer} justify="space-between">
            <PlanInfoGuidedFlow productSku={product.productSku} opened={opened} closeDrawer={closeDrawer} />
            {isGuidedFlow ? (
                <Flex align="center" direction="row" gap="8px">
                    <ActionIcon onClick={openDrawer} variant="transparent">
                        <InfoIcon />
                    </ActionIcon>
                    <AnalyticsButton
                        analytics={{
                            actionId: "GuidedFlowBackButton",
                            eventData: {
                                ProductOfferId: enrollmentDetails.productOfferId,
                                step,
                            },
                        }}
                        onClick={() => {
                            updateStep("SuggestedOfferStep");
                        }}
                        disabled={enrolling || declining}
                        color="white"
                        data-testid="Guided-flow-back-button"
                        variant="transparent"
                    >
                        <Text td="underline" size="sm">
                            Back
                        </Text>
                    </AnalyticsButton>
                </Flex>
            ) : null}

            <Flex className={classes.rightAligned} gap="8px">
                <AnalyticsButton
                    analytics={{
                        actionId: "EnrollmentDeclineButton",
                        eventData: {
                            ProductOfferId: enrollmentDetails.productOfferId,
                            step,
                            isGuidedFlow,
                        },
                    }}
                    color="white"
                    data-testid="enrollment-decline-button"
                    disabled={enrolling}
                    loading={declining}
                    onClick={() => {
                        setDeclining(true);
                        void decline();
                    }}
                    size="md"
                    variant="subtle"
                >
                    <Text td="underline" size="sm">
                        Decline
                    </Text>
                </AnalyticsButton>
                <AnalyticsButton
                    analytics={{
                        actionId: "EnrollmentAcceptButton",
                        eventData: {
                            ProductOfferId: enrollmentDetails.productOfferId,
                            step,
                            isGuidedFlow,
                        },
                    }}
                    color="asurion.5"
                    data-testid="enrollment-accept-button"
                    disabled={!disclosureChecked || declining}
                    loading={enrolling}
                    onClick={() => {
                        setEnrolling(true);
                        void enroll();
                    }}
                    radius="xl"
                    size="md"
                    variant="filled"
                >
                    Enroll
                </AnalyticsButton>
            </Flex>
        </Flex>
    );
}
