import { useEffect, useState } from "react";
import { type BadgeType } from "../../types";
import { badgeMap } from "./badgeMap";
import { ActiveBar } from "./activeBar";
import { LockedBar } from "./lockedBar";
import { FilledBar } from "./filledBar";

interface BadgeBarProps {
    badgeType: BadgeType;
    offerCount: number;
    newOffer?: boolean;
}

export function BadgeBar({ badgeType, offerCount, newOffer }: BadgeBarProps) {
    const [showFilledAfterTransition, setShowFilledAfterTransition] = useState(false);
    const [showLockedAfterTransition, setShowLockedAfterTransition] = useState(true);

    const { upperLimit, lowerLimit } = badgeMap[badgeType];

    const locked = offerCount < lowerLimit;
    const active = !locked && !showFilledAfterTransition;
    const filled = offerCount >= upperLimit;

    const recentlyFilled = newOffer && offerCount === upperLimit;
    const recentlyActivated = newOffer && offerCount === lowerLimit;

    const fillDelay = recentlyFilled ? 2000 : 0;
    const activateDelay = recentlyActivated ? 2000 : 0;

    useEffect(() => {
        setTimeout(() => {
            setShowFilledAfterTransition(filled);
        }, fillDelay);

        setTimeout(() => {
            setShowLockedAfterTransition(false);
        }, activateDelay);
    });

    // The currently active bar
    if (active && !recentlyActivated) {
        return <ActiveBar badgeType={badgeType} offerCount={offerCount} newOffer={newOffer} />;
    }

    // Will be activated soon...
    if (recentlyActivated) {
        return showLockedAfterTransition ? (
            <LockedBar badgeType={badgeType} />
        ) : (
            <ActiveBar badgeType={badgeType} offerCount={offerCount} newOffer={newOffer} />
        );
    }

    // Just locked
    if (locked) {
        return <LockedBar badgeType={badgeType} />;
    }

    // Just filled
    return showFilledAfterTransition ? <FilledBar badgeType={badgeType} /> : null;
}
