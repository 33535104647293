import { immer } from "zustand/middleware/immer";
import { shallow } from "zustand/shallow";
import { createWithEqualityFn } from "zustand/traditional";
import { persist } from "zustand/middleware";
import type { ChannelShareDatabasePayload } from "../api/types";

interface CallDetailsState {
    callDetails: ChannelShareDatabasePayload;
    setCallDetails: (details: ChannelShareDatabasePayload) => void;
    resetCallDetails: () => void;
}

export const useCallDetailsStore = createWithEqualityFn<CallDetailsState>()(
    persist(
        immer((set) => ({
            callDetails: {},
            setCallDetails: (details) => {
                set((state) => {
                    state.callDetails = details;
                });
            },
            resetCallDetails: () => {
                set((state) => {
                    state.callDetails = {};
                });
            },
        })),
        { name: "call-details-store", skipHydration: true },
    ),
    shallow,
);
