import type { MessageType } from "../../shared-types";
import { useExpertAssistStore } from "../../state";
import { useCreateActions } from "./useCreateActions";
import type { MultipleActions, QuickAction } from "./types";
import { getQuickActions } from "./getQuickActions";

interface QuickActionsManagerProps {
    currentMessageType: MessageType;
    isOverflowing: boolean;
    isExpanded: boolean;
    isLastMessage: boolean;
    isLastProactiveMessage: boolean;
    setIsExpanded: React.Dispatch<React.SetStateAction<boolean>>;
}

/*
Based on design, only a single quick actions group is showing on the screen at any time.
*/
export function useQuickActionsManager({
    currentMessageType,
    isOverflowing,
    isExpanded,
    isLastMessage,
    isLastProactiveMessage,
    setIsExpanded,
}: QuickActionsManagerProps): QuickAction[] {
    const { partner } = useExpertAssistStore();

    const allActions = useCreateActions({ setIsExpanded });
    const currentActions: MultipleActions[] = [];

    if (isLastMessage || isLastProactiveMessage) {
        switch (currentMessageType) {
            case "Search": {
                if (partner === "att") {
                    currentActions.push(allActions.neutralOrPositiveFeedback, allActions.tsnr);
                }
                break;
            }
            case "Proactive": {
                if (isOverflowing && !isExpanded) {
                    currentActions.push(allActions.expandMessage, allActions.negativeFeedback);
                } else {
                    currentActions.push(allActions.neutralOrPositiveFeedback, allActions.negativeFeedback);
                }
                break;
            }
            case "DeviceInfo": {
                currentActions.push(allActions.devices);
                break;
            }
            default:
                break;
        }
    }

    const quickActions = getQuickActions(currentActions);
    return quickActions;
}
