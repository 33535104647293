import { Flex } from "@mantine/core";
import Player from "react-lottie-player";
import classes from "../../styles.module.css";
import { successLottie } from "./successLottie";

export function FeedbackSuccessAnimation() {
    return (
        <Flex direction="unset" justify="center" align="center">
            <Player className={classes.successAnimation} animationData={successLottie} play speed={1} loop={false} />
        </Flex>
    );
}
