import type { ContentfulClientApi } from "contentful";
import { createClient } from "contentful";
import { env } from "./environment";

let contentfulClient: ContentfulClientApi<undefined> | undefined;
export const getOrCreateContentfulClient = () => {
    if (contentfulClient) {
        return contentfulClient;
    }

    contentfulClient = createClient({
        space: env.space,
        accessToken: env.accessToken,
    });

    return contentfulClient;
};
