import type { SVGProps } from "react";

export function Edit(props: SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.1391 1.93815C11.5267 1.55088 12.0521 1.33333 12.6 1.33333C13.1481 1.33333 13.6738 1.55108 14.0614 1.93867C14.449 2.32625 14.6667 2.85194 14.6667 3.40007C14.6667 3.94805 14.4491 4.47359 14.0617 4.86115C14.0616 4.86126 14.0618 4.86104 14.0617 4.86115L13.219 5.70693C13.1956 5.74141 13.1686 5.77419 13.1381 5.80474C13.1081 5.8347 13.076 5.86122 13.0422 5.88428L8.47225 10.4706C8.34715 10.5962 8.17722 10.6667 8 10.6667H6C5.63181 10.6667 5.33333 10.3683 5.33333 10.0001V8.00007C5.33333 7.82285 5.4039 7.65292 5.52944 7.52782L10.1154 2.9582C10.1385 2.92428 10.1652 2.89202 10.1953 2.86193C10.2259 2.83125 10.2589 2.80418 10.2935 2.78073L11.1386 1.93867C11.1388 1.93849 11.1389 1.93832 11.1391 1.93815ZM10.6742 4.28367L6.66667 8.27691V9.3334H7.72316L11.7164 5.32588L10.6742 4.28367ZM12.6575 4.38138L11.6187 3.34254L12.0814 2.88147C12.2189 2.74393 12.4055 2.66667 12.6 2.66667C12.7945 2.66667 12.9811 2.74393 13.1186 2.88147C13.2561 3.01901 13.3334 3.20556 13.3334 3.40007C13.3334 3.59458 13.2561 3.78113 13.1186 3.91867L12.6575 4.38138ZM2.58579 4.58578C2.96086 4.21071 3.46957 4 4 4H4.66667C5.03486 4 5.33333 4.29848 5.33333 4.66667C5.33333 5.03486 5.03486 5.33333 4.66667 5.33333H4C3.82319 5.33333 3.65362 5.40357 3.5286 5.52859C3.40357 5.65362 3.33333 5.82319 3.33333 6V12C3.33333 12.1768 3.40357 12.3464 3.5286 12.4714C3.65362 12.5964 3.82319 12.6667 4 12.6667H10C10.1768 12.6667 10.3464 12.5964 10.4714 12.4714C10.5964 12.3464 10.6667 12.1768 10.6667 12V11.3333C10.6667 10.9651 10.9651 10.6667 11.3333 10.6667C11.7015 10.6667 12 10.9651 12 11.3333V12C12 12.5304 11.7893 13.0391 11.4142 13.4142C11.0391 13.7893 10.5304 14 10 14H4C3.46957 14 2.96086 13.7893 2.58579 13.4142C2.21071 13.0391 2 12.5304 2 12V6C2 5.46957 2.21071 4.96086 2.58579 4.58578Z"
                fill="var(--mantine-color-gray-1)"
            />
        </svg>
    );
}
