import { useVerifiedCustomerStore } from "@expert/customer-info";
import type { VoiceTask } from "@expert/sdk";
import { getSessionId, isVoiceTask, useMostRecentTask } from "@expert/sdk";
import { Container, Flex, Text } from "@mantine/core";
import { useEffect } from "react";
import classes from "./SalesFlow.module.css";
import { useEligibilityV2, useHomeProductSessionStore, useOtherProductSessionStore } from "./home-product";
import { IneligibleIcon, LoadingAnimation } from "./home-product/components/Icons";
import { EligibilityStatus, SaleProgress } from "./home-product/types";
import { MultiProduct } from "./multi-product/MultiProduct";
import { AutoConfirmFlow } from "./home-product/components/AutoConfirm/AutoConfirmFlow";

export function SalesFlow(): JSX.Element {
    const mostRecentTask = useMostRecentTask();

    if (!isVoiceTask(mostRecentTask)) {
        throw new Error("Active task is not voice task");
    }
    const mdn = useVerifiedCustomerStore((state) => state.customer?.mdn);
    const sessionId = getSessionId();
    const [homeProduct] = useHomeProductSessionStore((state) => [state.products]);
    const isIvrVerified = useVerifiedCustomerStore((state) => state.isIvrVerified);
    const {
        data: eligibilityV2,
        isError: eligibilityV2Error,
        isLoading: isLoadingEligibilityV2,
    } = useEligibilityV2(
        {
            asurionCallId: mostRecentTask.asurionCallId,
            partner: "verizon",
            reservationSid: (mostRecentTask as VoiceTask & { config?: { reservation?: { sid: string } } }).config
                ?.reservation?.sid,
            sessionId,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            customerMdn: mdn!.slice(2),
        },
        useHomeProductSessionStore.persist.hasHydrated() && (!homeProduct || homeProduct.length === 0),
    );

    useEffect(() => {
        if (eligibilityV2) {
            useOtherProductSessionStore.getState().updateProducts(
                eligibilityV2
                    .filter((p, _index, eligibleArray) => {
                        if (p.status !== EligibilityStatus.Eligible) return false;
                        if (p.productSku !== "3002") {
                            if (
                                // when 5G_uwb and 5G_cband are both eligible, we should not show 5G_uwb
                                p.productSku === "FWA_5G_UWB" &&
                                eligibleArray.some((q) => q.productSku === "FWA_5G_CBAND")
                            ) {
                                return false;
                            }
                            return true;
                        }
                        return false;
                    })
                    .map((p) => {
                        return { ...{ saleProgress: SaleProgress.InProgress }, ...p };
                    }),
            );

            const foundHomeProduct = eligibilityV2.find((p) => p.productSku === "3002");

            // If the backend returns no home product, we need to add a dummy product to the store
            useHomeProductSessionStore.getState().updateProducts(
                foundHomeProduct
                    ? [{ saleProgress: SaleProgress.InProgress, ...foundHomeProduct }]
                    : [
                          {
                              id: "",
                              productSku: "3002",
                              saleProgress: SaleProgress.InProgress,
                              status: EligibilityStatus.Unknown,
                          },
                      ],
            );
        }
    }, [eligibilityV2]);

    if (eligibilityV2Error) {
        return (
            <Flex className={classes.loadingContainer} data-testid="failure">
                <Container>{IneligibleIcon}</Container>
                <Text className={classes.statusBodyHeader}>We had an issue checking eligibility.</Text>
                <Text className={classes.statusBodyText} data-testid="sale-view-status-body">
                    Try refreshing the page.
                </Text>
            </Flex>
        );
    }

    if (isLoadingEligibilityV2) {
        return (
            <Flex className={classes.loadingContainer}>
                <Text>Confirming customer information...</Text>
                <Container maw="160px">
                    <LoadingAnimation />
                </Container>
            </Flex>
        );
    }
    if (!isIvrVerified) {
        return <AutoConfirmFlow />;
    }

    return <MultiProduct />;
}
