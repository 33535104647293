import type { ExtractProps, WithClickAnalyticsProps } from "@soluto-private/eventualize-react";
import type { AnalyticsAction } from "@soluto-private/eventualize";
import { clickAnalyticsHocBody } from "@soluto-private/eventualize-react";
import type { ComponentPropsWithRef, ElementType } from "react";
import type { MantineComponentProps, MantineDefaultElementType, MantineDefaultRef } from "./types";

type Props<TComponentProps, TAction extends AnalyticsAction, TElementType extends ElementType, TRef> = TComponentProps &
    Omit<ComponentPropsWithRef<TElementType>, keyof TComponentProps> &
    WithClickAnalyticsProps<TAction, TComponentProps, TRef>;

/** Augments the provided component with click analytics */
export function withPolymorphicClickAnalytics<
    TComponent,
    TAction extends AnalyticsAction,
    TComponentProps = ExtractProps<TComponent>,
    TElementType extends ElementType = MantineDefaultElementType<TComponentProps>,
    TRefType = MantineDefaultRef<TComponentProps>,
>(Component: TComponent, action: TAction) {
    return clickAnalyticsHocBody<
        TComponent,
        TAction,
        TRefType,
        MantineComponentProps<TComponentProps>,
        Props<MantineComponentProps<TComponentProps>, TAction, TElementType, TRefType>
    >(Component, action);
}
