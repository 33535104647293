import { type VoiceTask, sdkEventBus } from "@expert/sdk";
import { useEffect, useRef } from "react";
import { StreamhubWebClient } from "../client";
import type { StreamhubEventPayload } from "../models";

export interface ConnectionProps {
    activeVoiceTask: VoiceTask | null;
    onMessage: (event: StreamhubEventPayload) => void;
}

export const useStreamhubConnection = ({ activeVoiceTask, onMessage }: ConnectionProps) => {
    const wsClient = useRef<StreamhubWebClient | null>(null);

    if (activeVoiceTask?.agentCallId && !wsClient.current) {
        wsClient.current = new StreamhubWebClient({
            callSid: activeVoiceTask.agentCallId,
            onMessage,
        });
    }

    useEffect(() => {
        const unsubscribeToTaskCompleted = sdkEventBus.on("task_completed", () => {
            if (!wsClient.current) return;

            wsClient.current.disconnect();
            wsClient.current = null;
        });

        return () => {
            unsubscribeToTaskCompleted();
        };
    }, []);
};
