import { IconButton, Schedule } from "@expert/common-ui";
import { useCallbackState } from "@expert/sdk";
import { useMemo } from "react";
import { useControlsStore } from "../controls.store";

export function ToggleCallbackPanelButton(): JSX.Element {
    const callbackState = useCallbackState();
    const hasCallback = !!callbackState;
    const { scheduleCallbackPanelOpen, openCallbackPanel, closeCallbackPanel, loadingExistingCallback } =
        useControlsStore();

    const canScheduleCallback: boolean = useMemo(
        () => scheduleCallbackPanelOpen && !hasCallback,
        [hasCallback, scheduleCallbackPanelOpen],
    );
    const isDisabled = hasCallback && !scheduleCallbackPanelOpen;
    const handleClick = scheduleCallbackPanelOpen ? closeCallbackPanel : openCallbackPanel;

    return (
        <IconButton
            active={canScheduleCallback}
            analytics={{
                actionId: "ScheduleCallbackButton",
            }}
            aria-label="Schedule callback"
            data-testid="call-controls-callback-schedule-begin"
            disabled={isDisabled}
            loading={loadingExistingCallback}
            onClick={handleClick}
            radius="xl"
            size="35"
            tooltipLabel="Schedule callback"
        >
            <Schedule
                height={18}
                stroke={canScheduleCallback ? "var(--mantine-color-black)" : "var(--mantine-color-white)"}
            />
        </IconButton>
    );
}
