export const MIN_CALLBACK_NOW_DELAY = 1; // Seconds
export const DEFAULT_CALLBACK_NOW_DELAY = 30; // Seconds
export const MAX_CALLBACK_NOW_DELAY = 120; // Seconds
export const MIN_CALLBACK_STEP = 5; // Seconds

export const MAX_SCHEDULE_AHEAD_DAYS = 90; // Days
export const FIRST_DAY_OF_WEEK = 0; // Sunday

export const TIME_ZONES = [
    { name: "Eastern", value: "America/New_York" },
    { name: "Central", value: "America/Chicago" },
    { name: "Mountain", value: "America/Denver" },
    //! Aside from a ~300k population Navajo territory, Arizona does recognize DST
    { name: "Mountain (Arizona)", value: "America/Phoenix" },
    { name: "Pacific", value: "America/Los_Angeles" },
    { name: "Alaska", value: "America/Anchorage" },
    { name: "Hawaii-Aleutian", value: "Pacific/Honolulu" },
] as const;
export type TimeZoneValue = (typeof TIME_ZONES)[number]["value"];

export const DEFAULT_TIME_ZONE: TimeZoneValue = "America/New_York";

export type CallbackRadioOption = "callback-user-now" | "callback-user-with-delay" | "callback-to-queue-later";
export type CallbackFormVariant = "active-call" | "post-call";
