import { Text } from "@mantine/core";
import { useHomeProductSessionStore } from "../../stores";
import { AutoConfirmSharedStep } from "./AutoConfirmSharedStep";

export function AutoConfirmFlow(): JSX.Element {
    const products = useHomeProductSessionStore((state) => state.products);
    if (!products || products.length === 0) {
        return <Text data-testid="autoconfirm-flow-error">Something went wrong</Text>;
    }
    const homeProduct = products[0];

    return <AutoConfirmSharedStep status={homeProduct.status} />;
}
