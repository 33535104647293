import { getLogger } from "@expert/logging";
import { api, getHashedValue, stripCountryCode } from "@expert/shared-utils";
import { HTTPError } from "ky";
import { type CustomerDetailsPayload } from "./types";

const logger = getLogger({
    module: "customerDetails",
});

export const getCustomerDetails = async (mdn: string): Promise<CustomerDetailsPayload | null> => {
    const formattedMdn = stripCountryCode(mdn);
    try {
        const result = await api
            .post("api/v1/customer/details", {
                json: {
                    mdn: formattedMdn,
                    hashedMdn: await getHashedValue(formattedMdn),
                    partner: "verizon", // TODO: support more partners in the future
                },
                retry: 0, // retries already done via @tanstack/react-query
            })
            .json<CustomerDetailsPayload>();

        if (result.primaryAccountHolder?.mdn) {
            result.primaryAccountHolder.mdn = mdn;
        }
        return result;
    } catch (err: unknown) {
        if (err instanceof HTTPError) {
            logger.error(`getCustomerDetails API call failed with status: ${err.response.status}`);
            return null;
        }
        logger.error({ err }, `getCustomerDetails API call failed with exception`);
        return null;
    }
};
