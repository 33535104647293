import { api, apiBaseUrl } from "@expert/shared-utils";
import { HTTPError } from "ky";
import type {
    CallbackStatusRequest,
    CallbackStatusResponse,
    CancelCallbackRequest,
    CancelCallbackResponse,
    MindfulApiResponse,
    ScheduleCallbackRequest,
    ScheduleCallbackResponse,
    WidgetStatusRequest,
    WidgetStatusResponse,
} from "./models";

const callbacksApi = api.extend({ prefixUrl: `${apiBaseUrl}/api/callbacks/v1` });

// todo: rework `useScheduleCallback` to catch thrown `HTTPError` instances instead of returning inside of an object
async function toResultOrErrorJson<T>(promise: Promise<T>) {
    try {
        const result = await promise;
        return { success: true as const, result };
    } catch (e) {
        if (!(e instanceof HTTPError)) throw e;
        const errorJson = (await e.response.json()) as MindfulApiResponse;
        return { success: false as const, errorJson };
    }
}

export async function scheduleCallback(request: ScheduleCallbackRequest) {
    return await toResultOrErrorJson(
        callbacksApi.post("schedule-callback", { json: request }).json<ScheduleCallbackResponse>(),
    );
}

export async function cancelCallback(request: CancelCallbackRequest) {
    return await toResultOrErrorJson(
        callbacksApi.post("cancel-callback", { json: request }).json<CancelCallbackResponse>(),
    );
}

export async function getCallbackStatus(request: CallbackStatusRequest) {
    return await callbacksApi.post("callback-status", { json: request }).json<CallbackStatusResponse>();
}

export async function getWidgetStatus(request: WidgetStatusRequest) {
    return await callbacksApi.post("widget-status", { json: request }).json<WidgetStatusResponse>();
}
