export const IneligibleIcon = (
    <svg
        data-testid="ineligible-icon"
        fill="none"
        height="150"
        viewBox="0 0 150 150"
        width="150"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M116.248 84.8071C114.382 66.1589 112.36 41.5592 109.584 20.883C109.555 20.7526 109.5 20.6292 109.423 20.5198C109.346 20.4103 109.248 20.3171 109.135 20.2454C109.021 20.1738 108.894 20.125 108.762 20.102C108.629 20.0791 108.494 20.0823 108.362 20.1115L17.4929 29.0168C19.9807 53.7929 22.2019 71.5153 24.8451 93.1834"
            stroke="white"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.99515"
        />
        <path
            d="M16.6491 95.0997C13.5395 70.8526 12.2067 57.2302 9.18591 27.3843C8.96513 26.0822 9.25372 24.7456 9.9927 23.6476C10.7317 22.5496 11.8653 21.7731 13.1619 21.4768L112.938 11.5796C113.968 11.6075 114.96 11.9698 115.762 12.6108C116.565 13.2518 117.133 14.1362 117.381 15.1285C121.468 42.4175 122.534 62.0135 124.777 84.3869L16.6491 95.0997Z"
            stroke="white"
            strokeLinejoin="round"
            strokeWidth="2.66019"
        />
        <path
            d="M16.9824 95.1011H125.266C129.708 108.547 130.019 109.715 134.662 122.081C135.439 123.764 136.085 125.503 136.594 127.284C137.038 129.708 137.038 132.794 128.82 132.794H21.6469C13.6505 132.794 10.9851 132.353 10.9851 126.843L16.6492 95.1011"
            stroke="white"
            strokeLinejoin="round"
            strokeWidth="2.66019"
        />
        <path
            d="M121.445 118.687L116.337 101.273H26.5557L23.3127 118.687H121.445Z"
            stroke="white"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.99515"
        />
        <path
            d="M26.0669 109.209H118.313"
            stroke="white"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.99515"
        />
        <path
            d="M100.721 118.201L95.6125 101.669"
            stroke="white"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.99515"
        />
        <path
            d="M43.5701 117.299L45.0805 101.714"
            stroke="white"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.99515"
        />
        <path
            d="M72.6454 118.202L72.179 102.155"
            stroke="white"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.99515"
        />
        <path
            d="M55.1868 133.235L56.7416 118.687"
            stroke="white"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.99515"
        />
        <path
            d="M93.8355 133.014L90.7258 118.687"
            stroke="white"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.99515"
        />
        <path
            d="M10.541 126.623C10.0967 129.047 9.45259 134.338 13.3841 135.44C15.783 136.101 16.8936 135.881 22.2689 136.101C59.052 136.321 96.2571 137.203 129.975 136.101C131.162 136.173 132.353 136.024 133.484 135.66C135.883 134.778 138.749 130.811 136.994 127.725"
            stroke="white"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.99515"
        />
        <path
            d="M132.152 80.774L137.283 77.0708"
            stroke="white"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.99515"
        />
        <path
            d="M135.994 88.4429H141.303"
            stroke="white"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.99515"
        />
        <path
            d="M132.152 96.0938L137.283 99.7969"
            stroke="white"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.99515"
        />
        <path
            d="M67.4037 62.1468L69.3361 61.9484C69.9657 52.8702 69.9657 43.7597 69.3361 34.6815C67.7794 34.2758 66.166 34.1268 64.5605 34.2406C62.9678 34.4591 61.422 34.9357 59.9849 35.6514C61.2103 44.6645 63.0582 53.5835 65.5156 62.3452L67.4037 62.1468Z"
            fill="white"
            stroke="white"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2.66019"
        />
        <path
            d="M68.2584 75.9213C70.153 75.7314 71.5337 74.0532 71.3424 72.1731C71.151 70.293 69.46 68.9228 67.5654 69.1127C65.6709 69.3026 64.2901 70.9807 64.4815 72.8608C64.6729 74.741 66.3639 76.1112 68.2584 75.9213Z"
            stroke="white"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2.64699"
        />
        <path
            d="M68.6833 74.7837C69.8913 74.6589 70.7686 73.5861 70.6429 72.3873C70.5173 71.1886 69.4361 70.3179 68.2282 70.4426C67.0202 70.5673 66.1429 71.6402 66.2686 72.839C66.3943 74.0377 67.4754 74.9084 68.6833 74.7837Z"
            fill="white"
            stroke="white"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2.66019"
        />
    </svg>
);
