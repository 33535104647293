import { customCleanEnv, url } from "envalid";

interface Env {
    expertFeedbackUrl: string;
}

export const env = customCleanEnv(
    import.meta.env,
    {
        VITE_API_BASE_URL: url(),
    },
    (inputEnv): Env => ({
        expertFeedbackUrl: `${inputEnv.VITE_API_BASE_URL}/api/v1/feedback/expert`,
    }),
);
