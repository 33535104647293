import { Edit, FlexSpacer } from "@expert/common-ui";
import { useActiveVoiceTask, useCallbackState } from "@expert/sdk";
import { Alert, Button, Group, Text, ThemeIcon } from "@mantine/core";
import { withClickAnalytics } from "@soluto-private/eventualize-react";
import { useMemo } from "react";
import { useControlsStore } from "../../../CallControls/controls.store";
import styles from "./CallbackInfoBanner.module.css";

const AnalyticsAlertBanner = withClickAnalytics(Alert, "Click");

const AnalyticsChangeButton = withClickAnalytics(Button, "Click");

export function CallbackInfoBanner() {
    const activeTask = useActiveVoiceTask();
    const { scheduleCallbackPanelOpen, openCallbackPanel } = useControlsStore();
    const callbackState = useCallbackState();

    // If the call is not in an active-state we cannot change the callback info
    const disableChange = useMemo(() => {
        return scheduleCallbackPanelOpen || ["cancelled", "completed", "wrapping"].includes(activeTask.status);
    }, [activeTask.status, scheduleCallbackPanelOpen]);
    const scheduledForText: string | null = useMemo(() => {
        if (!callbackState) return null;

        if (callbackState.callbackType === "CallbackNow" && callbackState.callbackDelay !== null) {
            if (callbackState.callbackDelay <= 1) return "immediately after the call ends";
            return `${callbackState.callbackDelay} seconds after the call ends`;
        }

        const scheduledTime = new Date(callbackState.scheduledFor);
        const formattedDate = new Intl.DateTimeFormat(navigator.languages[0], {
            dateStyle: "short",
            timeStyle: "short",
        }).format(scheduledTime);
        //TODO: format dates in a more readable way, ie. dayjs(scheduledTime).format("llll");
        return `for ${formattedDate}`;
    }, [callbackState]);

    if (!callbackState) {
        console.error("CallbackInfoBanner should not be rendered if there is no callback state");
        <Alert color="red.9" variant="filled">
            CallbackInfoBanner should not be rendered if there is no callback state
        </Alert>;
    }

    return (
        <AnalyticsAlertBanner
            analytics={{
                actionId: "CallbackInfoBanner",
            }}
            classNames={{
                root: styles.alertRoot,
            }}
            p="xs"
        >
            <Group justify="flex-end">
                <Text data-testid="call-controls-callback-delay-text" size="sm" mt="sm" mb="sm" fw="lighter">
                    A callback is scheduled <b>{scheduledForText}</b>
                </Text>
                <FlexSpacer />
                {!scheduleCallbackPanelOpen || !disableChange ? (
                    <AnalyticsChangeButton
                        analytics={{
                            actionId: "CallbackChangeButton",
                        }}
                        color="gray.1"
                        classNames={{ section: styles.section }}
                        leftSection={
                            <ThemeIcon bottom={1} variant="transparent">
                                <Edit />
                            </ThemeIcon>
                        }
                        data-testid="call-controls-callback-delay-edit"
                        disabled={disableChange}
                        size="md"
                        onClick={openCallbackPanel}
                        variant="subtle"
                    >
                        Edit
                    </AnalyticsChangeButton>
                ) : null}
            </Group>
        </AnalyticsAlertBanner>
    );
}
