export function RightArrowIcon() {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.244 4.41058C10.5694 4.08514 11.0971 4.08514 11.4225 4.41058L16.4225 9.41058C16.7479 9.73602 16.7479 10.2637 16.4225 10.5891L11.4225 15.5891C11.0971 15.9145 10.5694 15.9145 10.244 15.5891C9.91856 15.2637 9.91856 14.736 10.244 14.4106L13.8214 10.8332H4.16659C3.70635 10.8332 3.33325 10.4601 3.33325 9.99984C3.33325 9.5396 3.70635 9.1665 4.16659 9.1665H13.8214L10.244 5.58909C9.91856 5.26366 9.91856 4.73602 10.244 4.41058Z"
                fill="#E6E6EB"
            />
        </svg>
    );
}
