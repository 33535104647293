import { Flex, ScrollArea } from "@mantine/core";
import { useState } from "react";
import { useHomeProductSessionStore } from "../../stores";
import styles from "./StepThreeContent.module.css";
import { LegalDisclosure } from "./LegalDisclosure";
import { EnrollSection } from "./EnrollSection";

export function StepThreeContent(): JSX.Element | null {
    const [disclosureChecked, setDisclosureChecked] = useState(false);
    const products = useHomeProductSessionStore((state) => state.products);
    if (!products) return null;
    const homeProduct = products[0];

    return (
        <Flex w="100%" direction="column">
            <ScrollArea className={styles.scrollArea}>
                <LegalDisclosure
                    productSku={homeProduct.productSku}
                    isDisclosureChecked={disclosureChecked}
                    onDisclosureCheckedUpdated={(newStatus) => {
                        setDisclosureChecked(newStatus);
                    }}
                />
            </ScrollArea>

            <EnrollSection disclosureChecked={disclosureChecked} product={homeProduct} isGuidedFlow />
        </Flex>
    );
}
