import * as React from "react";
import type { SVGProps } from "react";

export function TextInfoPage(props: SVGProps<SVGSVGElement>) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={64} height={64} fill="none" {...props}>
            <path stroke="#6E767D" strokeLinecap="round" strokeLinejoin="round" strokeWidth={3} d="M12 22.668h25.334" />
            <path
                stroke="#6E767D"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2.667}
                d="M12 30.668h16M12 14.668h18.667M12 38.667h10.667M12 46.667h10.667M28 62.667H4a2.667 2.667 0 0 1-2.667-2.666V4A2.667 2.667 0 0 1 4 1.334h35.448c.707 0 1.386.281 1.886.781l9.885 9.886c.5.5.781 1.178.781 1.885v8.781"
            />
            <path
                stroke="#6E767D"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2.667}
                d="M46.667 62.667c8.836 0 16-7.163 16-16 0-8.836-7.164-16-16-16-8.837 0-16 7.164-16 16 0 8.837 7.163 16 16 16Z"
                clipRule="evenodd"
            />
            <path
                stroke="#6E767D"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2.667}
                d="M46.667 54.667v-9.333c0-.737-.597-1.334-1.334-1.334h-2.666M44.667 37.334a.667.667 0 1 1 0 1.333.667.667 0 0 1 0-1.333M42.667 54.667h8"
            />
        </svg>
    );
}
