import type { ScheduleCallbackType } from "@expert/shared-types";
import { parsePhoneNumber } from "awesome-phonenumber";
import dayjs from "dayjs";
import { create } from "zustand";
import { devtools } from "zustand/middleware";
import type { CallbackRadioOption, TimeZoneValue } from "./types";
import { DEFAULT_CALLBACK_NOW_DELAY, DEFAULT_TIME_ZONE } from "./types";
import { callbackTypeFromRadio } from "./utilities";

interface CallbacksFormState {
    callbackType: ScheduleCallbackType | null;
    callbackScheduled: boolean; //TODO: this should be a selector based on the sessions store and not a form state
    selectedCallbackOption: CallbackRadioOption;
    selectedCallbackTime: number | null;
    selectedCallbackNowDelay: number;
    selectedCallbackMdn: string;

    scheduledDate: Date;
    scheduledTimeZone: TimeZoneValue;
    unixTimeslots: number[];
    fetchingTimeslots: boolean;
}

interface CallbacksFormActions {
    resetState: () => void;
    clearDateTimeState: () => void;

    setCallbackType: (selectedCallbackSpecificType: CallbackRadioOption) => void;
    setCallbackScheduled: (callbackScheduled: boolean) => void;
    setSelectedCallbackTime: (selectedCallbackTime: number | null) => void;
    setSelectedCallbackNowDelay: (selectedCallbackNowDelay: number) => void;
    setSelectedCallbackMdn: (selectedCallbackMdn: string) => void;

    setScheduledDate: (date: Date) => void;
    setScheduledTimeZone: (zone: TimeZoneValue) => void;
    setUnixTimeslots: (zone: number[]) => void;
    setFetchingTimeslots: (isFetching: boolean) => void; // TODO: move this to a useAsync or react-query
}

type CallbacksFormStore = CallbacksFormState & CallbacksFormActions;

const defaultDateTimeState: Pick<
    CallbacksFormState,
    "scheduledDate" | "selectedCallbackTime" | "scheduledTimeZone" | "unixTimeslots" | "fetchingTimeslots"
> = {
    scheduledDate: dayjs().hour(12).minute(0).toDate(),
    selectedCallbackTime: null,
    //TODO: find the agents time zone and set it as the default
    scheduledTimeZone: DEFAULT_TIME_ZONE,
    unixTimeslots: [],
    fetchingTimeslots: false,
};

const defaultCallbackFormState: CallbacksFormState = {
    ...defaultDateTimeState,
    callbackType: "CallbackNow",
    callbackScheduled: false,
    selectedCallbackOption: "callback-user-with-delay",
    selectedCallbackTime: null,
    selectedCallbackNowDelay: DEFAULT_CALLBACK_NOW_DELAY,
    selectedCallbackMdn: "",
};

const getCallbackNowDelay = (state: CallbacksFormState, selectedCallbackOption: CallbackRadioOption) => {
    if (selectedCallbackOption === "callback-user-now") {
        return 0;
    } else if (selectedCallbackOption === "callback-user-with-delay" && state.selectedCallbackNowDelay === 0) {
        return DEFAULT_CALLBACK_NOW_DELAY;
    }
    return state.selectedCallbackNowDelay;
};

/** Only used for managing the callback UI state, the tasks actual callback state is handled in the SDK task store */
export const useCallbacksFormStore = create<CallbacksFormStore>()(
    devtools(
        (set) => ({
            ...defaultCallbackFormState,

            resetState: () => set(defaultCallbackFormState, false, "resetState"),
            clearDateTimeState: () => {
                set(defaultDateTimeState, false, "clearDateTimeState");
            },

            setCallbackType: (selectedCallbackSpecificType) =>
                set(
                    (state) => ({
                        callbackType: callbackTypeFromRadio(selectedCallbackSpecificType),
                        selectedCallbackOption: selectedCallbackSpecificType,
                        selectedCallbackNowDelay: getCallbackNowDelay(state, selectedCallbackSpecificType),
                    }),
                    false,
                    "setCallbackType",
                ),

            setCallbackScheduled: (callbackScheduled) => set({ callbackScheduled }, false, "setCallbackScheduled"),

            setSelectedCallbackTime: (selectedCallbackTime) =>
                set({ selectedCallbackTime }, false, "setSelectedCallbackTime"),

            setSelectedCallbackNowDelay: (selectedCallbackNowDelay) =>
                set({ selectedCallbackNowDelay }, false, "setSelectedCallbackNowDelay"),

            setSelectedCallbackMdn: (selectedCallbackMdn) =>
                set(
                    () => {
                        if (selectedCallbackMdn) {
                            const { number, valid } = parsePhoneNumber(selectedCallbackMdn);
                            if (valid) return { selectedCallbackMdn: number.e164 };
                        } else return { selectedCallbackMdn: "" };

                        return {};
                    },
                    false,
                    "setSelectedCallbackMdn",
                ),

            setScheduledDate: (scheduledDate) => set({ scheduledDate }, false, "setScheduledDate"),
            setScheduledTimeZone: (scheduledTimeZone) => set({ scheduledTimeZone }, false, "setScheduledTimeZone"),
            setUnixTimeslots: (unixTimeslots) =>
                set({ unixTimeslots, fetchingTimeslots: false }, false, "setUnixTimeslots"),
            setFetchingTimeslots: (isFetching) => set({ fetchingTimeslots: isFetching }, false, "setFetchingTimeslots"),
        }),
        { enabled: import.meta.env.MODE !== "production", store: "callbacks-form", name: "workspace/call-controls" },
    ),
);
