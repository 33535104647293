import type { Task } from "../task";
import type { VoiceTask } from "./voiceTask";

export function isVoiceTask(task?: Task): task is VoiceTask {
    return !!task && task.type === "voice";
}

export function isOutboundVoiceTask(task?: Task): task is VoiceTask {
    if (!isVoiceTask(task)) return false;

    return task.callDirection === "outbound";
}
