import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import type { GlobalNotification } from "./types";
import { type ToastContext } from "./GlobalNotificationProvider";

interface GlobalNotificationStore {
    notifications: GlobalNotification[];
    addNotification: (notification: Omit<GlobalNotification, "id" | "done">) => ToastContext;
    removeNotification: (id: string) => void;
}

const DEFAULT_DELAY = 10_000;

export const useGlobalNotificationStore = create<GlobalNotificationStore>()(
    immer(
        devtools(
            (set) => ({
                notifications: [],
                addNotification: ({
                    message,
                    type,
                    title = "",
                    delay = DEFAULT_DELAY,
                    persistent = false,
                    fadeOutAnim = false,
                }: Omit<GlobalNotification, "id" | "done">) => {
                    const id = Date.now().toString();

                    set(
                        (state) => {
                            state.notifications.push({
                                persistent,
                                id,
                                message,
                                title,
                                type,
                                delay,
                                fadeOutAnim,
                                done: state.removeNotification.bind(null, id) as unknown as () => void,
                            });
                        },
                        false,
                        "addNotification",
                    );

                    return { id };
                },
                removeNotification: (id: string) => {
                    set(
                        (state) => {
                            const notification = state.notifications.find((t) => t.id === id);
                            if (notification) {
                                notification.fadeOutAnim = true;
                            }
                        },
                        false,
                        "removeNotification",
                    );
                    setTimeout(() => {
                        set((state) => {
                            state.notifications = state.notifications.filter((t) => t.id !== id);
                        });
                    }, 200);
                },
            }),
            {
                enabled: import.meta.env.MODE !== "production",
                store: "globalNotification",
                name: "workspace/common-ui",
            },
        ),
    ),
);
