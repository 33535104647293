import { SERVICE_PROVIDER } from "@expert/shared-types";
import { getServiceProviderAccessToken } from "../auth";
import { TwilioAgentSDK, type TwilioAppConfig } from "../twilio";
import { useAppConfig } from "../config";

export function createAgentSDK(
    token: string,
    employeeNum: string | null,
    hdxEnabled: boolean,
    // When we have more provider we need to add more types to the union
    appConfig: TwilioAppConfig | undefined,
    onReady: (isReady: boolean) => void,
) {
    if (!token) {
        throw new Error("No token provided");
    }

    if (!appConfig) {
        throw new Error("No app config provider");
    }

    if (SERVICE_PROVIDER === "TWILIO") {
        return new TwilioAgentSDK(token, getServiceProviderAccessToken, employeeNum, hdxEnabled, appConfig, onReady);
    }
    throw new Error("Unknown provider");
}

export function useSdkAppConfig() {
    if (SERVICE_PROVIDER === "TWILIO") {
        return useAppConfig<TwilioAppConfig>();
    }

    throw new Error("Unknown provider");
}
