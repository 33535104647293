import { withPolymorphicClickAnalytics } from "@expert/analytics";
import { Button, Flex, Text } from "@mantine/core";
import { useControlsStore } from "../controls.store";
import styles from "./Dialpad.module.css";

interface DialButtonProps {
    subtitle: string;
    title: string;
}

export function DialButton({ subtitle, title }: DialButtonProps): JSX.Element {
    const { setDialpadInput, dialpadInput, isMuted } = useControlsStore();

    const handleClick = (input: string) => {
        setDialpadInput(dialpadInput + input);
    };

    const DialpadOptionButton = withPolymorphicClickAnalytics(Button, "Click");

    return (
        <DialpadOptionButton
            analytics={{
                actionId: `DialOptionButton-${title}`,
            }}
            classNames={{ label: styles.buttonLabel }}
            color="gray.3"
            bg={isMuted ? "dark.9" : undefined}
            data-testid="call-controls-dial-option-input"
            disabled={isMuted}
            mih="3.25rem"
            onClick={() => {
                handleClick(title);
            }}
            p="0rem"
            radius="md"
            variant="subtle"
        >
            <Flex direction="column">
                <Text c="gray.3" fw={700}>
                    {title}
                </Text>
                <Text c="gray.5" fw={400} size="xs">
                    {subtitle}
                </Text>
            </Flex>
        </DialpadOptionButton>
    );
}
