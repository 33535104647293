import type { ScheduleCallbackType } from "@expert/shared-types";
import dayjs, { extend } from "dayjs";
import "dayjs/locale/zh-cn";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { type MindfulResponseType } from "../api";
import type { CallbackRadioOption, TimeZoneValue } from "./types";

extend(utc);
extend(timezone);

/**
 * @param value - The radio option selected by the expert
 * @returns The SDK callback type for the selected radio option
 */
export const callbackTypeFromRadio = (value: CallbackRadioOption): ScheduleCallbackType => {
    switch (value) {
        case "callback-user-now":
            return "CallbackNow";
        case "callback-user-with-delay":
            return "CallbackNow";
        case "callback-to-queue-later":
            return "CallbackLater";
    }
};

/**
 * @param time - Unix time in milliseconds
 * @param timeZone - Timezone string to format the time in
 * @returns a dayjs formatted hour string in `h:mm a` format. ie `9:55 pm`
 */
export const getFormattedHourString = (time: number, timeZone: TimeZoneValue): string =>
    dayjs(time).tz(timeZone).format("h:mm a");

/**
 * @param selectedDay - The day the expert has selected to schedule a callback
 * @param timeZone - The timezone selected for the callback
 * @returns An array of 15 minute interval unix timeslots in milliseconds
 */
export const getDefaultTimeslots = (selectedDay: Date, timeZone: TimeZoneValue): number[] => {
    const beginningOfDefaultTimeslots = dayjs(selectedDay)
        .tz(timeZone)
        .hour(0) //? if we limit callbacks to reasonable hours we should update the min hour allowed
        .minute(0)
        .second(0)
        .millisecond(0);

    const timeslots: number[] = [];

    const maxIntervals = 15 * 4 * 24; //? redundant fallback to prevent infinite loop
    for (let minute = 0; minute < maxIntervals; minute += 15) {
        const timeslotOption = beginningOfDefaultTimeslots.add(minute, "minutes");
        timeslots.push(timeslotOption.unix() * 1000);

        //? if we limit callbacks to reasonable hours we should update the max hour allowed
        if (timeslotOption.hour() === 23 && timeslotOption.minute() === 45) break;
    }
    return timeslots;
};

/** reduces timeslots to only the ones that are on the selected day/timezone and have not passed already */
export const filterTimeslotsForDay = (date: Date, timeZone: TimeZoneValue, unixTimeslots: number[]): number[] => {
    const beginningOfScheduledDay = dayjs().isSame(dayjs(date).tz(timeZone), "day")
        ? dayjs().tz(timeZone).unix() * 1000
        : dayjs(date).tz(timeZone).hour(0).minute(0).second(0).millisecond(0).unix() * 1000;
    const endOfScheduledDay = dayjs(date).tz(timeZone).hour(23).minute(59).second(59).millisecond(999).unix() * 1000;

    const filteredTimeslots = unixTimeslots.filter(
        (timeslot) => timeslot >= beginningOfScheduledDay && timeslot < endOfScheduledDay,
    );

    // If filtering the timeslots available from Mindful ends up with 0 results, we'll result to displaying
    // all 15 minute increments and if an error occurs when the expert tries to schedule the new time it should
    // display a toast notification for them.
    //! if there really are no available times should we do this to the expert though? it should fail every time
    return filteredTimeslots.length ? filteredTimeslots : getDefaultTimeslots(date, timeZone);
};

/** helper to handle the different error responses from Mindful */
export const handleCallbackError = (
    mindfulResponseStatus: MindfulResponseType,
    handleError: (errorMsg: string, log?: string) => void,
) => {
    switch (mindfulResponseStatus) {
        case "ContactNumberRequestLimit":
            handleError("The phone number entered is unavailable for callback. Please try another number.");
            break;
        case "DuplicateRequest":
            handleError("A callback is still being processed. Please wait 10 seconds, then try again.");
            break;
        case "AppointmentTimeFull":
            handleError("Sorry, the callback time was already taken. Please select another time.");
            break;
        case "AppointmentSlotNotFound":
            handleError("Sorry, we’re having trouble scheduling for the selected time. Please select another time.");
            break;
        // NOTE: If the IP is being rate limited this is a pretty big problem because it indicates that our
        //       server making the requests is being rate limited. I'll just let the expert know to wait a few minutes
        //       though before trying again.
        case "IPRequestLimit":
        case "RequestTimeout":
        case "RequestRefusedDueToOperationMode":
            handleError("We’re having trouble scheduling this callback. Please wait a few minutes then try again.");
            break;
        // NOTE: This case should never be hit because we should filter all past times out, but if it does get triggered
        //       we'll leave a message for the expert.
        case "AppointmentTimeInThePast":
            handleError(
                "The selected callback time has already passed. Please select a callback time that occurs later than the current time.",
            );
            break;
        // NOTE: This case should never be hit because we should validate all phone numbers before sending the request,
        //       but if it does get triggered we'll leave a message for the expert.
        case "InvalidContact":
            handleError("The callback phone number is invalid. Please enter a valid callback phone number.");
            break;
        case "Unauthorized":
            handleError(
                "An unexpected server error occurred. Please wait a few minutes, then try again.",
                "Mindful returned an unauthorized response. Cannot schedule callback.",
            );
            break;
        // NOTE: This should never happen, but if it does we'll log an error and show a toast so the expert
        //       knows there's a problem.
        case "InteractionNotFound":
            handleError(
                "An unexpected server error occurred. We’re not able to schedule the callback at this time.",
                "An expert tried to re-schedule a callback that has already been cancelled. This indicates we have a state issue on the frontend allowing this to happen.",
            );
            break;
        // NOTE: We should never get InvalidParameters, InvalidRequest, InvalidTimeZone, or WidgetNotFound
        //       because all requests are formatted by the us in the code. This message is for additional safety.
        case "InvalidParameters":
        case "InvalidRequest":
        case "InvalidTimeZone":
        case "ServiceUnavailable":
        case "WidgetNotFound":
        case "InternalServerError":
            handleError("An unexpected server error occurred. Please wait a few minutes, then try again.");
            break;
        default:
    }
};

/** find the equivalent hour minute combination when the days can be different */
export const findEquivalentTimeslot = (
    time: number | null,
    timeslots: number[],
    timeZone: TimeZoneValue,
): number | null => {
    if (!time) return null;
    const equivalentTimeslot = timeslots.find(
        (timeslot) => getFormattedHourString(timeslot, timeZone) === getFormattedHourString(time, timeZone),
    );
    return equivalentTimeslot ? equivalentTimeslot : null;
};
