import { Flex } from "@mantine/core";
import { LottieAnimator } from "../animations/LottieAnimator";
import classes from "./styles.module.css";

export function WorkspaceLoading() {
    return (
        <Flex direction="unset" justify="center" align="center">
            <LottieAnimator
                className={classes.workspaceLoadingAnimation}
                src="/assets/lottie-animations/WorkspaceLoader.lottie"
            />
        </Flex>
    );
}
