import type { SVGProps } from "react";

const defaultColor = "white";

export function Hangup({ stroke = defaultColor, ...props }: SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg fill="none" height="100%" viewBox="0 0 31 25" width="100%" {...props}>
            <path
                clipRule="evenodd"
                d="M26.3768 15.5772C28.1251 15.9615 29.3913 17.4809 29.454 19.2698V21.1161C29.444 22.4715 28.3477 23.5678 26.9923 23.5779H23.2996C21.9445 23.5672 20.8486 22.4712 20.8379 21.1161V19.8852C17.1541 19.5234 13.4437 19.5234 9.75996 19.8852V21.1161C9.74991 22.4715 8.65361 23.5678 7.2982 23.5779H3.60556C2.25015 23.5678 1.15385 22.4715 1.1438 21.1161V19.2698C1.20656 17.4809 2.47272 15.9615 4.221 15.5772C11.5308 14.0843 19.067 14.0843 26.3768 15.5772V15.5772Z"
                fillRule="evenodd"
                stroke={stroke}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.38474"
            />
            <path
                d="M15.2989 1.42206V8.80734"
                stroke={stroke}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.38474"
            />
            <path
                d="M3.60553 5.11472L9.14449 10.6537"
                stroke={stroke}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.38474"
            />
            <path
                d="M26.9923 5.11472L21.4533 10.6537"
                stroke={stroke}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.38474"
            />
        </svg>
    );
}
