import { classNames } from "@expert/shared-utils";
import type { FlexProps } from "@mantine/core";
import { Flex } from "@mantine/core";
import { forwardRef } from "react";
import styles from "./EditSessionSummaryModal.module.css";

type NotificationProps = FlexProps & { sendable?: boolean };

export const ACSSNotificationWrapper = forwardRef<HTMLDivElement, NotificationProps>(function NotificationWrapper(
    { sendable, ...rest }: NotificationProps,
    ref,
) {
    return (
        <Flex
            className={classNames(styles.notificationWrapper, sendable ? styles.acssSendable : null)}
            {...rest}
            ref={ref}
        />
    );
});
