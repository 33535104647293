import { Button, Input, Text } from "@mantine/core";
import { useAnalytics, withPolymorphicClickAnalytics } from "@expert/analytics";
import { IMaskInput } from "react-imask";
import { useState } from "react";
import { useFeatureFlag, validatePhoneNumber } from "@expert/shared-utils";
import classes from "./CustomerVerificationUpdated.module.css";

interface MdnSectionProps {
    mdn: string;
    onComplete: (input: string) => void;
    onSave: (input: boolean) => void;
}

export function MdnSection({ mdn, onComplete, onSave }: MdnSectionProps): JSX.Element {
    const ButtonWithAnalytics = withPolymorphicClickAnalytics(Button, "Click");
    const { dispatcher } = useAnalytics();
    const [isChangeMdn, setIsChangeMdn] = useState(mdn === "");
    const [inputMdn, setInputMdn] = useState<string>(mdn);
    const [error, setError] = useState<string | null>(null);
    const { data: blacklistedNumbers } = useFeatureFlag<string>("EWP-isBlacklistedNumber");
    const { data: blacklistedPrefixes } = useFeatureFlag<string>("EWP-blacklistedPrefixes");

    function onClickSave() {
        const strippedInputMdn = inputMdn.replace(/[^\d+]/g, "");
        if (
            isChangeMdn &&
            (!!blacklistedPrefixes?.includes(strippedInputMdn.slice(0, 5)) ||
                blacklistedNumbers?.includes(strippedInputMdn))
        ) {
            void dispatcher.dispatchBusinessEvent("BlacklistedMdnSubmitted");

            setError("This isn’t the customer’s phone number. Please ask for the right one.");
            return;
        }

        isChangeMdn ? setIsChangeMdn(false) : setIsChangeMdn(true);
        void dispatcher.dispatchBusinessEvent("MDN_Submitted");
        if (isChangeMdn) {
            setError(null);
            onComplete(strippedInputMdn);
            onSave(false);
        } else {
            onSave(true);
        }
    }

    const onInputChange = ({ currentTarget: { value } }: React.ChangeEvent<HTMLInputElement>) => {
        setInputMdn(value);
    };
    const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter" && validatePhoneNumber(inputMdn).valid) {
            void dispatcher.dispatchUserAction("EnterKey", "MdnContinue");
            onClickSave();
        }
    };

    return (
        <>
            <Text c="dark.1" size="18px" mb="1rem" mt="1rem">
                What’s the phone number they’re calling about?
            </Text>
            <Input.Wrapper
                data-testid="mdn-edit"
                error={error}
                className="fs-mask"
                classNames={{
                    label: classes.label,
                    root: classes.textAreaRoot,
                }}
            >
                <Input
                    /* eslint-disable-next-line jsx-a11y/no-autofocus */
                    autoFocus={mdn === ""}
                    size="lg"
                    disabled={!isChangeMdn}
                    value={inputMdn}
                    rightSectionPointerEvents="all"
                    rightSectionWidth={100}
                    component={IMaskInput}
                    onKeyDown={onKeyDown}
                    onInput={onInputChange}
                    mask="+1 (000) 000-0000"
                    onChange={(event) => {
                        setInputMdn(event.currentTarget.value);
                    }}
                    rightSection={
                        <ButtonWithAnalytics
                            /* eslint-disable-next-line no-nested-ternary */
                            disabled={!isChangeMdn ? false : inputMdn ? !validatePhoneNumber(inputMdn).valid : true}
                            data-testid="change-phone-number-button"
                            td={isChangeMdn ? "" : "underline"}
                            variant={isChangeMdn ? "" : "subtle"}
                            color={isChangeMdn ? "" : "dark.1"}
                            size="xs"
                            onClick={onClickSave}
                            analytics={{ actionId: "change phone number" }}
                        >
                            {isChangeMdn ? "Save" : "Change"}
                        </ButtonWithAnalytics>
                    }
                    classNames={{ wrapper: classes.styledInputWrapper, input: classes.styledInput }}
                />
            </Input.Wrapper>
        </>
    );
}
