import { Caller, PhoneNumber } from "@expert/common-ui";
import type { VoiceTask } from "@expert/sdk";
import { Group, Stack, Text } from "@mantine/core";
import sharedStyles from "../sharedStyles.module.css";

interface CallBarProps {
    task: VoiceTask;
}

export function PendingCallBar({ task }: CallBarProps): JSX.Element {
    const pendingStatusText = task.callDirection === "outbound" ? "Calling" : "Incoming call";

    return (
        <Group gap="0">
            <Group h="35" mr="sm" p="0">
                <Caller fill="var(--mantine-color-asurion-5)" />
            </Group>
            <Stack gap="0">
                <Text fw={300} size="xs" className={sharedStyles.animatedEllipses}>
                    {pendingStatusText}
                </Text>
                <Text className="fs-mask" fw={700} mr="sm" size="md">
                    <PhoneNumber mdn={task.mdn} />
                </Text>
            </Stack>
        </Group>
    );
}
