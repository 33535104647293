import { useState } from "react";
import { errorEventBus } from "./errorEventBus";

type HandlerFunctionVariadic = (...args: never[]) => Promise<void>;

export function useAsyncLoading<TAction extends HandlerFunctionVariadic>(
    action: TAction,
    onError?: () => void,
): [boolean, TAction] {
    //TODO: We shouldn't have shared-utils depending on common-UI. We need a better way to do this
    // This probably should go out to a logger or eventbus that shared-utils exposes that the app then sets up for handling/toasting
    // This hook should not couple itself directly to toasts

    const [loading, setLoading] = useState(false);

    async function runAction(...args: never[]) {
        try {
            setLoading(true);
            await action(...args);
        } catch (error) {
            // TODO: temporary event bus to remove circular dependency
            // Remove this along with the addition of a more elegant solution.
            errorEventBus.emit("error-occurred", error);
            onError?.();
        } finally {
            setLoading(false);
        }
    }

    return [loading, runAction as TAction];
}
