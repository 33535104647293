import { getRootDispatcher } from "@expert/analytics";
import { useVerifiedCustomerStore } from "@expert/customer-info";
import {
    useCurrentStepStore,
    useGuidedFlowStreamhubStore,
    useHomeProductSessionStore,
    useOtherProductSessionStore,
} from "@expert/sales-tools";
import { isVoiceTask, sdkEventBus } from "@expert/sdk";
import { useExpertNoteStore } from "@soluto-private/expert-workspace-notepad";
import { removePii } from "@soluto-private/expert-workspace-pii";
import { getCallDetails, useCallDetailsStore } from "../call-details";
import { useVerificationFlowStore } from "../customer-panel/components/customer-verification";
import { useSessionSummaryStore } from "../session-summary";

const emitSessionEndEvent = async (previousSessionId: string) => {
    const { summary, wasIssueResolved } = useSessionSummaryStore.getState();

    // TODO:// client, channel & program name are hard-coded for the pilot as requested. Change it!
    void getRootDispatcher()
        .withMeta({ Client: "Verizon", Channel: "voice", SkipEDP: false, SkipMixpanel: false })
        .withIdentity("SessionId", previousSessionId)
        .withIdentity("SupportSessionId", previousSessionId)
        .dispatchBusinessEvent("SupportSessionClosed", {
            EndCallReason: wasIssueResolved ? "resolved" : "not resolved",
            EndCallSubReason: "",
            SupportSessionNotes: summary,
            ProgramName: "VMP",
        });

    const { note } = useExpertNoteStore.getState();
    if (note) {
        const piiScrubbedNote = await removePii(note);
        void getRootDispatcher()
            .withMeta({ Client: "Verizon", Channel: "voice", SkipEDP: false, SkipMixpanel: true })
            .withIdentity("SessionId", previousSessionId)
            .withIdentity("SupportSessionId", previousSessionId)
            .dispatchBusinessEvent("SaveExpertNote", {
                note: piiScrubbedNote,
            });
    }
};

export const setupSessionLifecycleManager = () => {
    /*
     * We need to reset a few stores when a new session comes in
     * Reset customer verification status
     * Reset sale session
     * Reset CSD Call Details
     */
    sdkEventBus.on("session_changed", async ({ newSession, previousSession }) => {
        // end of session cleanup
        if (newSession.kind === "without-customer") {
            // Legacy SupportSessionClosed event
            void emitSessionEndEvent(previousSession.id);
            useVerificationFlowStore.getState().resetSteps();
            useCallDetailsStore.getState().resetCallDetails();
            useHomeProductSessionStore.getState().resetSaleSession();
            useOtherProductSessionStore.getState().resetSaleSession();
            useVerifiedCustomerStore.getState().reset();
            useGuidedFlowStreamhubStore.getState().resetEvents();
            useCurrentStepStore.getState().resetCurrentStepStore();
        }
        // beginning of session
        else {
            if (!isVoiceTask(newSession.currentTask)) return;

            const task = newSession.currentTask;

            useSessionSummaryStore.getState().resetSessionSummary();
            const data = await getCallDetails(task.asurionCallId);
            useCallDetailsStore.getState().setCallDetails(data ?? {});

            const voiceTaskConvProp = task.conversations;
            const tfnDnis = voiceTaskConvProp ? voiceTaskConvProp.conversation_attribute_8 : null;

            await getRootDispatcher()
                .withExtra({
                    UUI_ANI: data?.ANI,
                    CallDirection: task.callDirection,
                    CVPCallId: task.asurionCallId,
                    TfnDnis: tfnDnis,
                    ContactSource: data?.ClientCode,
                    ICMRouterKey: data?.RouterCallKey,
                    ICMRouterDay: data?.RouterCallDay,
                })
                .withMeta({ Client: "Verizon", Channel: "Call" })
                .dispatchBusinessEvent("SupportSessionStarted");
        }
    });
};
