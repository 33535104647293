import { bool, customCleanEnv, str, url } from "envalid";

interface Env {
    mode: string;
    isProdMode: boolean;
    commitHash: string;
    gaiaWebsocketUrl: string;
    fullstoryOrgId: string;
    dynatraceEnabled: boolean;
    dynatraceAppMonitoringUrl: string;
    appcuesEnabled: boolean;
    appcuesUrl: string;
    viteUcsdkFilepath?: string;
    apiBaseUrl: string;
}

export const env = customCleanEnv(
    import.meta.env,
    {
        MODE: str(),
        PROD: bool(),
        COMMIT_HASH: str(),
        VITE_GAIA_WEBSOCKET_URL: url(),
        VITE_FULLSTORY_ORG_ID: str(),
        VITE_DYNATRACE_ENABLED: bool(),
        VITE_DYNATRACE_APP_MONITORING_URL: str(),
        VITE_UCSDK_FILEPATH: str(),
        VITE_APPCUES_ENABLED: bool(),
        VITE_APPCUES_URL: str(),
        VITE_API_BASE_URL: url(),
    },
    (inputEnv): Env => ({
        mode: inputEnv.MODE,
        isProdMode: inputEnv.MODE === "production" && inputEnv.PROD,
        commitHash: inputEnv.COMMIT_HASH,
        gaiaWebsocketUrl: inputEnv.VITE_GAIA_WEBSOCKET_URL,
        fullstoryOrgId: inputEnv.VITE_FULLSTORY_ORG_ID,
        dynatraceEnabled: inputEnv.VITE_DYNATRACE_ENABLED,
        dynatraceAppMonitoringUrl: inputEnv.VITE_DYNATRACE_APP_MONITORING_URL,
        viteUcsdkFilepath: inputEnv.VITE_UCSDK_FILEPATH,
        appcuesEnabled: inputEnv.VITE_APPCUES_ENABLED,
        appcuesUrl: inputEnv.VITE_APPCUES_URL,
        apiBaseUrl: inputEnv.VITE_API_BASE_URL,
    }),
);
