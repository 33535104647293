import { Container, Flex, Text } from "@mantine/core";
import { NoVerifiedMdnIcon } from "../Icons/NoVerifiedMdnIcon";
import type { EligibilityStatus } from "../../types";
import classes from "./AutoConfirmFlow.module.css";
import { getAutoConfirmConfig } from "./AutoConfirmMap";

export function AutoConfirmSharedStep({ status }: { status: EligibilityStatus }): JSX.Element {
    const { statusHeader, statusBody } = getAutoConfirmConfig(status);
    return (
        <Flex className={classes.loadingContainer} data-testid="no-verified-mdn">
            <Container>{NoVerifiedMdnIcon}</Container>
            <Text fw="300" size="xl" className={classes.statusBodyHeader}>
                {statusHeader}
            </Text>
            <Text fw="400" size="sm" className={classes.statusBodyText} data-testid="no-sale-view-status-body">
                {statusBody}
            </Text>
        </Flex>
    );
}
