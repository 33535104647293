import { getRootDispatcher } from "@expert/analytics";
import { getLogger } from "@expert/logging";
import { HTTPError } from "ky";
import type { Enrollment, EnrollmentResponse } from "../types";
import { EnrollmentStatus } from "../types";
import { salesApi } from "./api";

const logger = getLogger({
    module: "enrollment",
});

export const createEnrollment = async (enrollment: Enrollment) => {
    try {
        const result = await salesApi.post("v1/enrollment", { json: enrollment }).json<EnrollmentResponse>();

        void getRootDispatcher().dispatchBusinessEvent("GetEnrollmentStatus", {
            ProductOfferId: enrollment.productOfferId,
            enrollmentStatus: result.enrollmentStatus,
            StatusOrigin: "ASP",
            productSku: result.productSku,
            confirmationCode: enrollment.confirmationCode,
        });
        return result;
    } catch (err: unknown) {
        if (err instanceof HTTPError) {
            void getRootDispatcher().dispatchBusinessEvent("EnrollmentError", {
                Status: "LAMBDA_ERROR",
                ProductOfferId: enrollment.productOfferId,
                Error: err,
            });
        }

        logger.error({ err });
        return { enrollmentStatus: EnrollmentStatus.Error };
    }
};
