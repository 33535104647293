import type { SVGProps } from "react";

export function CloseIcon(props: SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
            <g fill="#E6E6EB" fillRule="evenodd" clipRule="evenodd">
                <path d="m5.707 4.293 14 14-1.414 1.414-14-14 1.414-1.414Z" />
                <path d="m19.707 5.707-14 14-1.414-1.414 14-14 1.414 1.414Z" />
            </g>
        </svg>
    );
}
