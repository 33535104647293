import type { ExpertAssistMessage, Features } from "../../shared-types";
import { MarkdownViewer } from "../formatted-content";
import { generateSessionSummaryItem } from "../message-bubble/custom-messages/SessionSummary";

export function generateTimelineItemFromType(
    message: ExpertAssistMessage,
    { isOzmoMessagingEnabled, isFormattingEnabled, isMultiModalEnabled }: Features,
) {
    switch (message.type) {
        case "Connection":
        case "Expert":
        case "Default":
        case "DeviceInfo":
        case "QuickAction":
        case "Proactive":
        case "Search":
            return isFormattingEnabled || isOzmoMessagingEnabled || isMultiModalEnabled ? (
                <MarkdownViewer content={message.text} />
            ) : (
                message.text
            );
        case "SessionSummary": {
            const sessionSummary = message.metaData?.sessionSummary;
            return sessionSummary && generateSessionSummaryItem(sessionSummary, isFormattingEnabled);
        }
    }
}
