import { getFeaturesCache } from "@expert/platform-features";
import type { VoiceTask } from "@expert/sdk";
import { Flex, Group } from "@mantine/core";
import { AddParticipantButton } from "../../CallControls/Buttons";
import { useControlsStore } from "../../CallControls/controls.store";
import { useTransfersStateStore } from "../transfers.store";
import { TransferCallButton } from "./TransferCallButton";

export function TransferButtonGroup({ activeTask }: { activeTask: VoiceTask }) {
    const { transferVdn, transferTfn } = useTransfersStateStore();
    const { controlLock } = useControlsStore();
    const isAddButtonDisabled = (!transferTfn && !transferVdn) || controlLock;

    //TODO: should we disable adding primary when customer is still on call?
    return (
        <Flex align="center" justify="end">
            <Group>
                <TransferCallButton task={activeTask} />
                {getFeaturesCache().expertworkspace_conference_is_enabled ? ( //TODO: do we need this check anymore?
                    <AddParticipantButton
                        task={activeTask}
                        transferVdn={transferVdn}
                        transferTfn={transferTfn}
                        disabled={isAddButtonDisabled}
                        isPrimary={
                            !!activeTask.customerLeftTheConference &&
                            !!transferTfn &&
                            activeTask.mdn.includes(transferTfn)
                        }
                    />
                ) : null}
            </Group>
        </Flex>
    );
}
