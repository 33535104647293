import type { VoiceTask } from "@expert/sdk";
import { useStopwatch } from "@expert/shared-utils";
import { Text } from "@mantine/core";

interface PendingCallDurationProps {
    task: VoiceTask;
}

export function PendingCallDuration({ task }: PendingCallDurationProps): JSX.Element | null {
    const formattedHoldTime = useStopwatch(task.dateCreated.getTime(), true);

    if (task.status !== "pending") return null;

    return (
        <Text fw={500} size="sm" ta="center">
            Calling {formattedHoldTime}
        </Text>
    );
}
