import type { Task } from "@expert/sdk";
import type { TwilioOutboundTaskAttributes } from "./models";
import type { TwilioTask } from "./twilioTask";

export function isOutboundAttributes(attributes: unknown): attributes is TwilioOutboundTaskAttributes {
    return !!(attributes as TwilioOutboundTaskAttributes).outbound_to;
}

export function isTwilioTask(task: Task): task is TwilioTask {
    return !!(task as TwilioTask).config;
}
