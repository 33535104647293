import { ActionIcon, Button, Stack } from "@mantine/core";
import { useState } from "react";
import { useAnalytics } from "@expert/analytics";
import { InfoIcon, RightArrowIcon } from "../Icons";
import { useCurrentStepStore, useGuidedFlowStreamhubStore } from "../../stores";
import { type SalesAssistEventPayload } from "../../models";
import classes from "./EnhancedStepOneContent.module.css";
import { PlanInfoGuidedFlow } from "./PlanInfoGuidedFlow";
import { type ProductData } from "./EnhancedStepOneContent";

interface EnhancedStepOneFooterProps {
    productSku: string;
    manualDevices: ProductData[];
}

export function EnhancedStepOneFooter({ productSku, manualDevices }: EnhancedStepOneFooterProps): JSX.Element | null {
    const [opened, setOpened] = useState(false);
    const [step, updateStep] = useCurrentStepStore((state) => [state.step, state.updateStep]);
    const { dispatcher } = useAnalytics();
    const openDrawer = () => {
        setOpened(true);
    };
    const closeDrawer = () => {
        setOpened(false);
    };
    const manualDeviceAddedPayload: SalesAssistEventPayload = {
        body: [
            {
                message: "Devices you added: ",
                format: "text",
            },
            {
                message: manualDevices
                    .map((device) => device.productType)
                    .join(", ")
                    .toString(),
                format: "bold",
            },
            {
                message: " ",
                newLine: true,
            },
        ],
        context: "manual-devices",
    };

    return (
        <Stack className={classes.footerBox}>
            <PlanInfoGuidedFlow productSku={productSku} opened={opened} closeDrawer={closeDrawer} />
            <ActionIcon
                className={classes.infoIcon}
                variant="transparent"
                onClick={() => {
                    openDrawer();
                    void dispatcher.withExtra({ step }).dispatchBusinessEvent("ButtonClick_PlanInfo");
                }}
            >
                <InfoIcon />
            </ActionIcon>
            <Button
                className={classes.stepAdvance}
                variant="subtle"
                color="white"
                rightSection={<RightArrowIcon />}
                onClick={() => {
                    if (manualDevices.length > 0) {
                        useGuidedFlowStreamhubStore.getState().processGuidedFlowEvent(manualDeviceAddedPayload);
                        void dispatcher.dispatchBusinessEvent("DevicesDetectedManually", { manualDevices });
                    }
                    updateStep("SuggestedOfferStep");
                    void dispatcher.withExtra({ step }).dispatchBusinessEvent("ButtonClick_DevicesAsked");
                }}
            >
                Show Offer
            </Button>
        </Stack>
    );
}
