import { type Logger, type WriteFn, pino } from "pino";
import { write } from "./utils/pretty";
import { send } from "./utils/sendToBackend";
import { env } from "./environment";

let rootLogger: Logger | undefined;

interface LoggerContextBase {
    module?: string;
}

export function getLogger<TContext extends LoggerContextBase = LoggerContextBase>(
    context: TContext & Record<string, string>,
) {
    if (!rootLogger) {
        rootLogger = pino({
            browser: {
                write: write as WriteFn,
                transmit: {
                    level: env.logLevel.api.toLowerCase(),
                    send,
                },
            },
        });
    }
    const loggerContext = {
        ...context,
        appName: env.appName,
    };
    return rootLogger.child(loggerContext);
}
