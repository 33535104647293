import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import type { Toast } from "./types";

interface ToastStore {
    toasts: Toast[];
    addToast: (toast: Omit<Toast, "id" | "done">) => void;
    removeToast: (id: string) => void;
}

const DEFAULT_DELAY = 10000;

export const useToastStore = create<ToastStore>()(
    immer(
        devtools(
            (set) => ({
                toasts: [],
                addToast: ({ message, type, title = "", delay = DEFAULT_DELAY }: Omit<Toast, "id" | "done">) => {
                    set(
                        (state) => {
                            const id = Date.now().toString();

                            state.toasts.push({
                                id,
                                message,
                                title,
                                type,
                                delay,
                                done: state.removeToast.bind(null, id) as unknown as () => void,
                            });
                        },
                        false,
                        "addToast",
                    );
                },
                removeToast: (id: string) => {
                    set(
                        (state) => {
                            state.toasts = state.toasts.filter((toast) => toast.id !== id);
                        },
                        false,
                        "removeToast",
                    );
                },
            }),
            { enabled: import.meta.env.MODE !== "production", store: "toast", name: "workspace/common-ui" },
        ),
    ),
);
