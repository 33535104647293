const FeedbackIssues = ["General feedback", "Something’s broken"] as const;
export type FeedbackIssueTypes = (typeof FeedbackIssues)[number];

export interface FeedbackPayload {
    appName: string;
    caller: FeedbackCaller;
    callSid?: string;
    category: FeedbackIssueTypes;
    extraData?: Record<string, unknown>;
    sessionId: string;
    ssoAccessToken: string;
    text: string;
}
export type PartialFeedbackPayload = Omit<FeedbackPayload, "category" | "text">;

export type FeedbackCaller = "StandaloneHeader" | "StandaloneTimeline" | "PlatformHeader" | "PlatformTimeline";

export const feedbackUIMapping = {
    default: {
        title: "We’re all ears!",
        subtitle: "Share issues, things you want, and any other feedback",
        textAreaPlaceholder: {
            "General feedback":
                "Example: I want to be able to text customers a picture of where to find a  setting on their phone",
            "Something’s broken": "Example: I can’t type in the chat",
        },
        bottomText: "We’ll save your progress in case you need to come back.",
        radioGroup: FeedbackIssues,
    },
    success: {
        title: "Thanks for sharing!",
        subtitle: "We read every response and appreciate all of it",
    },
};
