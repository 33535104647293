import type { VoiceTask } from "@expert/sdk";
import { useAgentSdk } from "@expert/sdk";
import { Space } from "@mantine/core";
import type { TaskProps } from "../../types";
import { isConferenceLimitReached } from "../../utils";
import {
    HangupButton,
    HoldButton,
    LeaveCallButton,
    MuteButton,
    ToggleCallbackPanelButton,
    ToggleDialpadButton,
    ToggleTransferPanelButton,
} from "../Buttons";
import { useControlsStore } from "../controls.store";

export function AssignedCallControls({ task }: TaskProps<VoiceTask>): JSX.Element {
    const { isConference } = useControlsStore();
    const agentSdk = useAgentSdk();

    /** Disable the transfer button if the conference limit has been reached. Always show when not in a conference. */
    const transferButtonDisabled = isConference && isConferenceLimitReached(task);

    return (
        <>
            <ToggleTransferPanelButton disabled={transferButtonDisabled} />
            <ToggleDialpadButton />
            <ToggleCallbackPanelButton />
            {isConference ? null : (
                <HoldButton
                    task={task}
                    isOnHold={task.holdState.onHold}
                    onPauseCallButtonClick={() => agentSdk.holdCall(task)}
                    onResumeCallButtonClick={() => agentSdk.resumeCall(task)}
                />
            )}
            <MuteButton task={task} />
            <Space />
            {isConference ? <LeaveCallButton /> : <HangupButton task={task} />}
        </>
    );
}
