export function PhoneTextIcon() {
    return (
        <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10.5846 12.5833V18.4167C10.5846 19.3371 9.83844 20.0833 8.91797 20.0833H3.08464C2.16416 20.0833 1.41797 19.3371 1.41797 18.4167V5.91667C1.41797 4.99619 2.16416 4.25 3.08464 4.25H6.41797"
                stroke="var(--mantine-color-dark-3)"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20.5859 7.1665C20.5859 7.85686 20.0263 8.4165 19.3359 8.4165H14.7526L12.2526 10.9165V8.4165H9.33594C8.64558 8.4165 8.08594 7.85686 8.08594 7.1665V2.1665C8.08594 1.47615 8.64558 0.916504 9.33594 0.916504H19.3359C20.0263 0.916504 20.5859 1.47615 20.5859 2.1665V7.1665Z"
                stroke="var(--mantine-color-dark-3)"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M14.5443 4.25C14.4292 4.25 14.3359 4.34327 14.3359 4.45833C14.3359 4.57339 14.4292 4.66667 14.5443 4.66667C14.6593 4.66667 14.7526 4.57339 14.7526 4.45833C14.7526 4.34327 14.6593 4.25 14.5443 4.25"
                stroke="var(--mantine-color-dark-3)"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M17.4583 4.25C17.3433 4.25 17.25 4.34327 17.25 4.45833C17.25 4.57339 17.3433 4.66667 17.4583 4.66667C17.5734 4.66667 17.6667 4.57339 17.6667 4.45833C17.6667 4.34327 17.5734 4.25 17.4583 4.25"
                stroke="var(--mantine-color-dark-3)"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M11.6263 4.25C11.5112 4.25 11.418 4.34327 11.418 4.45833C11.418 4.57339 11.5112 4.66667 11.6263 4.66667C11.7414 4.66667 11.8346 4.57339 11.8346 4.45833C11.8346 4.34327 11.7414 4.25 11.6263 4.25"
                stroke="var(--mantine-color-dark-3)"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
