import { getLogger } from "@expert/logging";
import { api } from "@expert/shared-utils";

const contextLogger = getLogger({ module: "getAppConfig" });

interface AppConfigResponse<TAppConfig> {
    appConfig: TAppConfig;
}

export async function getAppConfig<TAppConfig>(): Promise<TAppConfig> {
    try {
        const { appConfig } = await api.get("api/app-config").json<AppConfigResponse<TAppConfig>>();
        return appConfig;
    } catch (err: unknown) {
        contextLogger.error({ err });
        throw err;
    }
}
