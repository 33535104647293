import { useGuidedFlowStreamhubStore } from "@expert/sales-tools";
import type { SalesAssistEventPayload } from "@expert/sales-tools";
import type { GenerativeSummaryMessage } from "@expert/shared-types";
import type { OfferRateEventPayload } from "@expert/post-call-insights";
import { processOfferRate } from "@expert/post-call-insights";
import { getLogger } from "@expert/logging";
import { useSessionSummaryStore } from "../session-summary";
import type { StreamhubEventPayload } from "../streamhub-client";

const logger = getLogger({ module: "Streamhub" });

// manual cast since event.eventPayload.body[0].message returns a stringified object
function convertToGenerativeSummaryMessage(
    data: string | GenerativeSummaryMessage | undefined,
): GenerativeSummaryMessage | null {
    // data is already in the correct format
    if (typeof data === "object") {
        return data;
    }

    const requiredProperties = ["title", "summary", "troubleshootingSteps", "deviceType", "offerHistory"];

    // data received is a string that needs to be parsed
    if (typeof data === "string") {
        const parsed = JSON.parse(data) as GenerativeSummaryMessage;
        const allPropertiesExist = requiredProperties.every((prop) => prop in parsed);

        if (allPropertiesExist) {
            return parsed;
        }
        logger.warn(
            {
                parsed,
            },
            "MISSING REQUIRED PROPERTIES FOR GenerativeSummaryMessage",
        );
    }

    return null;
}

export const STREAMSHUB_EVENT_CONTEXTS = ["guidedFlow-cues", "guidedFlow-offers", "summaries", "offer-rate"] as const;
export type StreamhubEventContext = (typeof STREAMSHUB_EVENT_CONTEXTS)[number];

function handleGuidedFlowEvent(event: StreamhubEventPayload) {
    useGuidedFlowStreamhubStore.getState().processGuidedFlowEvent(event.eventPayload as SalesAssistEventPayload);
}

function handleSummaryEvent(event: StreamhubEventPayload) {
    if (event.eventPayload.body.length > 0) {
        const data = event.eventPayload.body[0].message;
        const generativeSummaryMessage = convertToGenerativeSummaryMessage(data);
        useSessionSummaryStore.getState().appendSessionSummary(generativeSummaryMessage?.summary ?? "");
    }
}

function handleOfferRateEvent(event: StreamhubEventPayload) {
    const data = event.eventPayload as OfferRateEventPayload;
    processOfferRate(data);
}

export const storeStreamhubMessages = (event: StreamhubEventPayload) => {
    const context = event.eventPayload.context;

    switch (context) {
        case "guidedFlow-cues":
        case "guidedFlow-offers":
            handleGuidedFlowEvent(event);
            break;
        case "summaries":
            handleSummaryEvent(event);
            break;
        case "offer-rate":
            handleOfferRateEvent(event);
            break;
        default:
            logger.warn({ context }, "Unknown Streamhub event context");
    }
};
