import { getLogger } from "@expert/logging";
import type { StreamhubEventPayload } from "../models";

const logger = getLogger({
    module: "processStreamhubEvent",
});

export const processStreamhubEvent = (event: MessageEvent<string>): StreamhubEventPayload | undefined => {
    try {
        return JSON.parse(event.data) as StreamhubEventPayload;
    } catch (err: unknown) {
        logger.error({ err }, "Error processing streamshub event");
    }
};
