import { getLogger } from "@expert/logging";
import { getRootDispatcher } from "@expert/analytics";
import { useHomeProductSessionStore } from "@expert/sales-tools/src/home-product";
import { EligibilityStatus } from "@expert/sales-tools/src/home-product/types";
import { saveOfferBehavior } from "../api";
import type { ConfirmedProduct, OfferRateEventPayload, OfferRateMessage } from "../types";
import { useStatisticsStore } from "../stores";

const logger = getLogger({ module: "PostCallInsights" });

export const processOfferRate = (payload: OfferRateEventPayload) => {
    const homeProducts = useHomeProductSessionStore.getState().products as unknown as ConfirmedProduct[];
    const homeProduct = homeProducts[0];
    if (payload.body.length <= 0) {
        logger.error("No data found for offer rate");
        void getRootDispatcher().dispatchAction("ResponseMissing", "OfferRate");
        return;
    }

    const { format, message } = payload.body[0] as { format: string; message: string };

    try {
        const offerData = (
            format === "json" ? JSON.parse(message) : { offered: message === "true" }
        ) as OfferRateMessage;

        if (homeProduct.status === EligibilityStatus.Eligible) {
            void saveOfferBehavior(offerData.offered);
        } else {
            // if the customer is not eligible, we still display the post call insights, but we don't save the offer behavior
            useStatisticsStore.getState().setNewOffer(false);
            useStatisticsStore.getState().setOfferStatusReceived(true);
        }
    } catch (error) {
        logger.error(error, "Error parsing JSON data for offer rate");
    }
};
