import { type SVGProps } from "react";

export function Calendar(props: SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg fill="none" height="100%" viewBox="0 0 26 26" width="100%" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                clipRule="evenodd"
                d="M1.5 4.50098C1.5 3.94869 1.94772 3.50098 2.5 3.50098H23.5C24.0523 3.50098 24.5 3.94869 24.5 4.50098V23.501C24.5 24.0533 24.0523 24.501 23.5 24.501H2.5C1.94772 24.501 1.5 24.0533 1.5 23.501V4.50098Z"
                fillRule="evenodd"
                id="Rectangle-path"
                stroke={props.stroke ?? "white"}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
            <path
                d="M6.5 1.50098V6.50098"
                id="Shape"
                stroke={props.stroke ?? "white"}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
            <path
                d="M19.5 1.50098V6.50098"
                id="Shape_2"
                stroke={props.stroke ?? "white"}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
            <path
                d="M1.5 8.50098H24.5"
                id="Shape_3"
                stroke={props.stroke ?? "white"}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
        </svg>
    );
}
