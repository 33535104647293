import { withPolymorphicClickAnalytics } from "@expert/analytics";
import { Caller, Plus } from "@expert/common-ui";
import { Button, Group, Text, ThemeIcon } from "@mantine/core";
import noCallControlsClasses from "./NoCallControls.module.css";

interface NoActiveCallProps {
    onAddCall: () => void;
}

const NoCallControlsButton = withPolymorphicClickAnalytics(Button, "Click");

export function NoActiveCallControl({ onAddCall }: NoActiveCallProps) {
    return (
        <Group gap="xs">
            <Group h="35" p="0">
                <Caller fill="var(--mantine-color-gray-8)" />
            </Group>
            <Text ml="0" size="sm" c="gray.6" onClick={onAddCall}>
                No active call
            </Text>
            <Group gap="sm" justify="flex-end" flex={1}>
                <NoCallControlsButton
                    analytics={{
                        actionId: "NoCallControls_NewCallButton",
                    }}
                    classNames={{
                        section: noCallControlsClasses.newCallIcon,
                    }}
                    leftSection={
                        <ThemeIcon bottom={1} variant="transparent">
                            <Plus stroke="var(--mantine-color-gray-0)" width={15} />
                        </ThemeIcon>
                    }
                    onClick={onAddCall}
                    size="sm"
                    variant="filled"
                >
                    New call
                </NoCallControlsButton>
            </Group>
        </Group>
    );
}
