import { getLogger } from "@expert/logging";
import type { Draft } from "immer";
import { configure } from "safe-stable-stringify";
import { create } from "zustand";
import type { StorageValue } from "zustand/middleware";
import { devtools, persist } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import type { Task } from "../agent/task";
import type { CallbackState, TaskStatus, WrappingState } from "../agent/types";
import type {
    ConferenceParticipant,
    InactiveConferenceParticipant,
    PendingConferenceParticipant,
    VoiceTask,
} from "../agent/voice";
import { isVoiceTask } from "../agent/voice";
import { SessionInstance } from "./session";
import type { Session, SessionChangeReason } from "./types";

const safeStringify = configure({
    deterministic: false,
    maximumDepth: 10,
    strict: false,
});
const logger = getLogger({
    module: "SessionStore",
});

interface SessionStoreState {
    initialized: boolean;
    sessions: SessionInstance[]; // TODO: Rename "sessions". Remove inactive sessions as they are ended (flag). Hook onto  this somewhere else.
}

export interface SessionStore extends SessionStoreState {
    /** Transactionally mutate a store with the immer WriteableDraft running the callers logic */
    mutateStore: (mutation: (state: Draft<SessionStore>) => void) => void;

    /** Ends the provided session and inserts the new session */
    endSession: (sessionId: string, reason: SessionChangeReason, newSession: SessionInstance) => void;
    addTaskToSession: (sessionId: string, task: Task) => void;
    updateTaskStatus: (taskId: string, status: TaskStatus) => void;
    muteTask: (taskId: string, shouldMute: boolean) => void;
    setHold: (taskId: string, onHold: boolean) => void;
    setCallbackState: (taskId: string, callbackState?: Omit<CallbackState, "originTaskId">) => void;
    clearCallbackState: () => void;
    setWrappingState: (wrappingState: WrappingState) => void;
    clearWrappingState: () => void;
    setConferenceStarted: (taskId: string, isStarted: boolean) => void;
    setCustomerLeftTheCall: (taskId: string) => void;
    addConferenceParticipant: (taskId: string, participant: ConferenceParticipant) => void;
    addPendingConferenceParticipant: (taskId: string, participant: PendingConferenceParticipant) => void;
    updatePendingConferenceParticipant: (
        taskId: string,
        participantCallId: string,
        options: Pick<PendingConferenceParticipant, "status">,
    ) => void;
    removePendingConferenceParticipant: (taskId: string, participantCallId: string) => void;
    addInactiveConferenceParticipant: (taskId: string, participant: InactiveConferenceParticipant) => void;
    updateConferenceParticipant: (taskId: string, participantCallId: string, options: { hold: boolean }) => void;
    removeConferenceParticipant: (taskId: string, participantCallId: string) => void;

    /** Will clear the session history, preserving the current session */
    resetHistory: () => void;

    /** Will completely reset the store, clearing all sessions, and putting the store in an un-initialized state.
     * This is handy for unit tests, but should not be used in production.
     */
    resetStore: () => void;

    getSession: (sessionId: string) => Session | null;
    getSessionByTaskId: (sessionId: string) => Session | null;
    getTask: (taskId: string) => Task | null;
    findTask: (predicate: (task: Task) => boolean) => Task | null;
    computed: {
        activeTasks: () => Task[]; // TODO: Temp backwards compat
        currentSession: () => SessionInstance;
        previousSession: () => SessionInstance | undefined;
    };
}

/** Prevent action exceptions from bubbling up to the rest of the application
 * Without having a try/catch inside every action
 */
function safeAction<T extends unknown[], R>(action: (...args: T) => R): (...args: T) => void {
    return (...args: T): void => {
        try {
            action(...args);
        } catch (err: unknown) {
            logger.error({ err }, "Error in action");
        }
    };
}

// TODO: Handle multiple active assigned tasks? Like chat. Does each active task sget a sessionId if we don't already have sessions?
// Create sessions if they don't exist with existing sessionIds for those tasks?

export const useSessionStore = create<SessionStore>()(
    immer(
        persist(
            devtools(
                (set, get) => ({
                    initialized: false,
                    sessions: [],

                    mutateStore: safeAction((mutation: (state: Draft<SessionStore>) => void) =>
                        set(
                            (state) => {
                                mutation(state);
                            },
                            false,
                            "mutateStore",
                        ),
                    ),
                    //TEMP NOTE: Really don't like having a dumb store that doesn't guard it's state, this shouldn't even be exposed to the workspace so other don't use it
                    addTaskToSession: safeAction((sessionId: string, task: Task) =>
                        set(
                            (state) => {
                                const session = state.sessions.find((s) => s.id === sessionId);
                                if (!session) throw new Error(`Session ${sessionId} not found`);

                                session.addTask(task);

                                return state;
                            },
                            false,
                            "addTaskToSession",
                        ),
                    ),
                    endSession: safeAction(
                        (sessionId: string, reason: SessionChangeReason, newSession: SessionInstance) =>
                            set(
                                (state) => {
                                    logger.trace({ sessionId, newSession }, "endSession()");
                                    const session = state.sessions.find((s) => s.id === sessionId);
                                    if (!session) throw new Error(`Session ${sessionId} not found`);

                                    session.endSession(reason);

                                    state.sessions.push(newSession);
                                    return state;
                                },
                                false,
                                "addSession",
                            ),
                    ),
                    updateTaskStatus: safeAction((taskId: string, status: TaskStatus) =>
                        set(
                            (state) => {
                                logger.trace({ taskId, status }, "updateTaskStatus()");
                                return updateTaskStatusInternal(state, taskId, status);
                            },
                            false,
                            "updateTaskStatus",
                        ),
                    ),
                    muteTask: safeAction((taskId: string, shouldMute: boolean) =>
                        set(
                            (state) => {
                                logger.trace({ taskId, shouldMute }, "muteTask()");
                                return mutateVoiceTask(state, taskId, (task) => {
                                    task.isMuted = shouldMute;
                                });
                            },
                            false,
                            "muteTask",
                        ),
                    ),
                    setHold: safeAction((taskId: string, onHold: boolean) =>
                        set(
                            (state) => {
                                logger.trace({ taskId, onHold }, "setHold()");
                                return mutateVoiceTask(state, taskId, (task) => {
                                    if (onHold && task.holdState.onHold) {
                                        throw new Error("Hold is already true");
                                    }

                                    task.holdState = {
                                        onHold,
                                        onHoldSince: onHold ? Date.now() : null,
                                    };
                                });
                            },
                            false,
                            "setHold",
                        ),
                    ),
                    setCallbackState: safeAction(
                        (taskId: string, callbackState?: Omit<CallbackState, "originTaskId">) =>
                            set(
                                (state) => {
                                    logger.trace({ taskId, callbackState }, "setCallbackState()");

                                    const session = findSession(state, (s) => s.tasks.some((t) => t.id === taskId));
                                    if (!session) throw new Error("Session not found");

                                    if (session.metadata.status !== "active") {
                                        logger.error("Cannot set CallbackState on an inactive session.", {
                                            session,
                                            taskId,
                                        });
                                        throw new Error("Cannot set CallbackState on an inactive session.");
                                    }

                                    session.callbackState = callbackState
                                        ? { ...callbackState, originTaskId: taskId }
                                        : null;

                                    return state;
                                },
                                false,
                                "setCallbackState",
                            ),
                    ),
                    clearCallbackState: safeAction(() =>
                        set(
                            (state) => {
                                console.trace("clearCallbackState()");
                                logger.trace("clearCallbackState()");
                                const session = state.sessions[state.sessions.length - 1];

                                session.callbackState = null;

                                return state;
                            },
                            false,
                            "clearCallbackState",
                        ),
                    ),
                    setWrappingState: safeAction((wrappingState: WrappingState) =>
                        set(
                            (state) => {
                                logger.trace({ wrappingState }, "setWrappingState()");

                                const session = state.sessions[state.sessions.length - 1];

                                if (session.metadata.status !== "active") {
                                    logger.error("Cannot set WrappingState on an inactive session.", {
                                        session,
                                        wrappingState,
                                    });
                                    throw new Error("Cannot set WrappingState on an inactive session.");
                                }

                                session.wrappingState = wrappingState;

                                return state;
                            },
                            false,
                            "setWrappingState",
                        ),
                    ),
                    clearWrappingState: safeAction(() =>
                        set(
                            (state) => {
                                logger.trace("clearWrappingState()");
                                const session = state.sessions[state.sessions.length - 1];

                                session.wrappingState = null;

                                return state;
                            },
                            false,
                            "clearWrappingState",
                        ),
                    ),
                    setConferenceStarted: safeAction((taskId: string, isStarted: boolean) =>
                        set(
                            (state) => {
                                logger.trace({ taskId, isStarted }, "setConferenceStarted()");
                                return mutateVoiceTask(state, taskId, (task) => {
                                    task.conferenceStarted = isStarted;
                                });
                            },
                            false,
                            "setConferenceStarted",
                        ),
                    ),
                    setCustomerLeftTheCall: safeAction((taskId: string) =>
                        set(
                            (state) => {
                                logger.trace({ taskId }, "setCustomerLeftTheCall()");
                                return mutateVoiceTask(state, taskId, (task) => {
                                    task.isMuted = false;
                                    task.holdState = {
                                        onHold: false,
                                        onHoldSince: null,
                                    };
                                    task.customerLeftTheConference = true;
                                });
                            },
                            false,
                            "setCustomerLeftTheCall",
                        ),
                    ),
                    addPendingConferenceParticipant: safeAction(
                        (taskId: string, participant: PendingConferenceParticipant) =>
                            set(
                                (state) => {
                                    logger.trace({ taskId, participant }, "addPendingConferenceParticipant()");
                                    return mutateVoiceTask(state, taskId, (task) => {
                                        task.pendingConferenceParticipants.push(participant);
                                    });
                                },
                                false,
                                "addPendingConferenceParticipant",
                            ),
                    ),
                    updatePendingConferenceParticipant: safeAction(
                        (taskId: string, participantCallId: string, options) =>
                            set(
                                (state) => {
                                    logger.trace(
                                        { taskId, participantCallId, options },
                                        "updatePendingConferenceParticipant()",
                                    );
                                    return mutateVoiceTask(state, taskId, (task) => {
                                        const participantIndex = task.pendingConferenceParticipants.findIndex(
                                            (p) => p.participantCallId === participantCallId,
                                        );
                                        if (participantIndex === -1) throw new Error("Participant not found");

                                        task.pendingConferenceParticipants[participantIndex] = {
                                            ...task.pendingConferenceParticipants[participantIndex],
                                            ...options,
                                        };
                                    });
                                },
                                false,
                                "updatePendingConferenceParticipant",
                            ),
                    ),
                    removePendingConferenceParticipant: safeAction((taskId: string, participantCallId: string) =>
                        set(
                            (state) => {
                                logger.trace({ taskId, participantCallId }, "removePendingConferenceParticipant()");
                                return mutateVoiceTask(state, taskId, (task) => {
                                    task.pendingConferenceParticipants = task.pendingConferenceParticipants.filter(
                                        (p) => p.participantCallId !== participantCallId,
                                    );
                                });
                            },
                            false,
                            "removePendingConferenceParticipant",
                        ),
                    ),
                    addInactiveConferenceParticipant: safeAction(
                        (taskId: string, participant: InactiveConferenceParticipant) =>
                            set(
                                (state) => {
                                    logger.trace({ taskId, participant }, "addInactiveConferenceParticipant()");
                                    return mutateVoiceTask(state, taskId, (task) => {
                                        // Remove the participant from pending
                                        task.pendingConferenceParticipants = task.pendingConferenceParticipants.filter(
                                            (p) => p.participantCallId !== participant.participantCallId,
                                        );
                                        task.inactiveConferenceParticipants.push(participant);
                                    });
                                },
                                false,
                                "addInactiveConferenceParticipant",
                            ),
                    ),
                    addConferenceParticipant: safeAction((taskId: string, participant: ConferenceParticipant) =>
                        set(
                            (state) => {
                                logger.trace({ taskId, participant }, "addConferenceParticipant()");
                                return mutateVoiceTask(state, taskId, (task) => {
                                    // Remove the participant from pending
                                    task.pendingConferenceParticipants = task.pendingConferenceParticipants.filter(
                                        (p) => p.participantCallId !== participant.participantCallId,
                                    );
                                    task.conferenceParticipants.push(participant);
                                });
                            },
                            false,
                            "addConferenceParticipant",
                        ),
                    ),
                    updateConferenceParticipant: safeAction(
                        (taskId: string, participantCallId: string, options: { hold: boolean }) =>
                            set(
                                (state) => {
                                    logger.trace(
                                        { taskId, participantCallId, options },
                                        "updateConferenceParticipant()",
                                    );
                                    return mutateVoiceTask(state, taskId, (task) => {
                                        const participantIndex = task.conferenceParticipants.findIndex(
                                            (p) => p.participantCallId === participantCallId,
                                        );
                                        if (participantIndex === -1) throw new Error("Participant not found");

                                        task.conferenceParticipants[participantIndex] = {
                                            ...task.conferenceParticipants[participantIndex],
                                            ...options,
                                            onHoldSince:
                                                options.hold &&
                                                !task.conferenceParticipants[participantIndex].onHoldSince
                                                    ? Date.now()
                                                    : undefined,
                                        };
                                    });
                                },
                                false,
                                "updateConferenceParticipant",
                            ),
                    ),
                    removeConferenceParticipant: safeAction((taskId: string, participantCallId: string) =>
                        set(
                            (state) => {
                                logger.trace({ taskId, participantCallId }, "removeConferenceParticipant()");
                                return mutateVoiceTask(state, taskId, (task) => {
                                    task.conferenceParticipants = task.conferenceParticipants.filter(
                                        (p) => p.participantCallId !== participantCallId,
                                    );
                                });
                            },
                            false,
                            "removeConferenceParticipant",
                        ),
                    ),

                    resetHistory: safeAction(() => {
                        if (import.meta.env.MODE === "production") {
                            throw new Error("resetHistory() should not be called in production");
                        }

                        set(
                            (state) => {
                                state.sessions = [state.sessions[state.sessions.length - 1]];
                                return state;
                            },
                            false,
                            "clearHistory",
                        );
                    }),
                    resetStore: safeAction(() => {
                        if (import.meta.env.MODE === "production") {
                            throw new Error("resetStore() should not be called in production");
                        }

                        set(
                            (state) => {
                                state.sessions = [];
                                state.initialized = false;
                                return state;
                            },
                            false,
                            "resetStore",
                        );
                    }),

                    getTask: (taskId: string) => findTask(get(), (task) => task.id === taskId),
                    findTask: (predicate: (task: Task) => boolean) => findTask(get(), predicate),
                    getSession: (sessionId: string) =>
                        findSession(get(), (session) => session.id === sessionId) as Session | null,
                    getSessionByTaskId: (taskId: string) =>
                        findSession(get(), (session) => session.tasks.some((t) => t.id === taskId)) as Session | null,
                    computed: {
                        //TODO: Future, actually support multi-sessions with an active session & task
                        activeTasks: () => {
                            const currentSession = get().computed.currentSession();

                            return currentSession.currentTask ? [currentSession.currentTask] : [];
                        },
                        currentSession: () => {
                            const sessions = get().sessions;
                            return sessions[sessions.length - 1];
                        },
                        previousSession: () => {
                            const sessions = get().sessions;

                            if (sessions.length < 2) return undefined;

                            return sessions[sessions.length - 2];
                        },
                    },
                }),
                { enabled: import.meta.env.MODE !== "production", store: "sessions", name: "workspace/sdk" },
            ),
            {
                name: "session-tasks-store",
                // @ts-expect-error types needs to be fixed to allow partial store return value
                partialize: (state) => ({
                    sessions: state.sessions.slice(-50),
                }),
                storage: {
                    getItem(name: string) {
                        const str = localStorage.getItem(name);
                        if (!str)
                            return {
                                state: {
                                    sessions: [createInitSession()],
                                },
                            } as StorageValue<SessionStore>;

                        const state = JSON.parse(str) as SessionStoreState;

                        if (state.sessions.length === 0) {
                            state.sessions = [createInitSession()];
                        } else {
                            state.sessions = state.sessions.map((session) => new SessionInstance(session));
                        }

                        // This guards a bug regression where the last session was not ended
                        if (state.sessions[state.sessions.length - 1].metadata.status === "ended") {
                            state.sessions.push(createInitSession());
                        }

                        // To achieve the requirement that we do not expose tasks till post SDK/Twilio init
                        // This is restored upon init
                        state.sessions.forEach((session) => {
                            session.hideRehydratedTasks();
                        });

                        return {
                            state,
                        } as StorageValue<SessionStore>;
                    },
                    setItem(name: string, value: StorageValue<SessionStore>) {
                        const str = safeStringify(value.state, serializeReplacer);
                        localStorage.setItem(name, str ?? "");
                    },
                    removeItem: (name: string) => {
                        localStorage.removeItem(name);
                    },
                },
            },
        ),
    ),
);

function mutateVoiceTask(state: Draft<SessionStore>, taskId: string, mutation: (task: Draft<VoiceTask>) => void) {
    const task = findTask(state, (x) => x.id === taskId);
    if (!task) throw new Error(`${taskId} not found in store`);
    if (!isVoiceTask(task)) throw new Error(`${taskId} isn't a voice task`);

    mutation(task);

    return state;
}

/** Should not be exposed */
export function updateTaskStatusInternal(state: Draft<SessionStore>, taskId: string, status: TaskStatus) {
    const existingTask = findTask(state, (x) => x.id === taskId);
    if (!existingTask) throw new Error(`${taskId} not found in store`);

    existingTask.status = status;

    //TODO: Pre-existing duplicated logic, perhaps the below can be removed for every state change?
    resetOnWrapping(existingTask);

    //TODO: Why do we do this 👇...?
    if (isVoiceTask(existingTask)) {
        existingTask.isMuted = false;
        existingTask.holdState = { onHold: false, onHoldSince: null };
    }

    return state;
}

function resetOnWrapping(task: Draft<Task>) {
    if (isVoiceTask(task)) {
        task.isMuted = false;
        task.holdState = { onHold: false, onHoldSince: null };
    }
}

/** produces an initialization session when no sessions exist during rehydration */
function createInitSession() {
    return new SessionInstance({
        id: crypto.randomUUID(),
        previousId: null,
        startedTimestamp: Date.now(),
        kind: "without-customer",
        tasks: [],
        callbackState: null,
        wrappingState: null,
        metadata: {
            startReason: "InitialSession",
            status: "active",
        },
    });
}

function findTask(state: Draft<SessionStore>, predicate: (task: Task) => boolean) {
    return (() => {
        // Reverse search for performance & correctness
        for (let i = state.sessions.length - 1; i >= 0; i--) {
            const session = state.sessions[i];
            const task = session.tasks.find(predicate);
            if (task) return task;
        }

        return null;
    })();
}

function findSession(state: Draft<SessionStore>, predicate: (session: Session) => boolean) {
    return (() => {
        // Reverse search for performance & correctness
        for (let i = state.sessions.length - 1; i >= 0; i--) {
            const session = state.sessions[i];
            if (predicate(session as Session)) return session;
        }

        return null;
    })();
}

function serializeReplacer(key: string, value: unknown) {
    if (key.startsWith("_")) return undefined;

    return value;
}
