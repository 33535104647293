import { GlobalError, GlobalLoader } from "@expert/common-ui";
import { getLogger } from "@expert/logging";
import React, { useEffect, useState } from "react";
import { AuthProvider, hasAuthParams, useAuth } from "react-oidc-context";
import { type User } from "oidc-client-ts";
import { environment } from "@expert/shared-utils";
import { getFeature } from "@soluto-private/expert-workspace-feature-flagging";
import { setIdentityAsTestUser } from "@expert/analytics";
import { ErrorText } from "./elements";
import { userManagerSettings } from "./userManagerSettings";

interface AuthenticationProviderProps {
    children?: React.ReactNode;
}

interface AuthHandlerProps {
    children?: React.ReactNode;
}

export function AuthenticationProvider({ children }: AuthenticationProviderProps) {
    // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
    const onSigninCallback = (user?: User | void): Promise<void> | void => {
        window.history.replaceState({}, document.title, window.location.pathname);
        if (user?.url_state) {
            window.location.replace(user.url_state);
        } else {
            window.history.replaceState({}, document.title, window.location.pathname);
        }
    };

    return (
        <AuthProvider {...userManagerSettings} onSigninCallback={onSigninCallback}>
            <AuthHandler>{children}</AuthHandler>
        </AuthProvider>
    );
}

const logger = getLogger({ module: "AuthenticationProvider" });

function AuthHandler({ children }: AuthHandlerProps) {
    const auth = useAuth();
    const [checkingIfTestUser, setCheckingIfTestUser] = useState<boolean>(true);

    useEffect(() => {
        if (!hasAuthParams() && !auth.isAuthenticated && !auth.isLoading && !auth.activeNavigator) {
            logger.debug({ auth }, "auth login redirect");
            void auth.signinRedirect({
                url_state: window.location.pathname === "/" ? undefined : window.location.href,
            });
        }
    }, [auth]);

    useEffect(() => {
        if (auth.isLoading) {
            return;
        }

        const checkIfUserIsTestUser = async () => {
            const isTestUser = await getFeature<boolean>("EWP-isTestUser", {
                environment,
                employeeId: auth.user?.profile.employeeID ?? auth.user?.profile.userPrincipalName ?? "",
            });

            logger.info(`(Worker): isTestUser: '${isTestUser}'`);
            if (isTestUser) {
                setIdentityAsTestUser();
            }

            setCheckingIfTestUser(false);
        };

        void checkIfUserIsTestUser();
    }, [auth.isLoading, auth.user]);

    switch (auth.activeNavigator) {
        case "signinSilent":
            return <GlobalLoader msg="Signing you in..." />;
        case "signoutRedirect":
            return <GlobalLoader msg="Signing you out..." />;
        case undefined:
        case "signinRedirect":
        case "signinPopup":
        case "signoutPopup":
        case "signoutSilent":
        case "signinResourceOwnerCredentials":
            break;
    }

    if (auth.error) return <GlobalError msg="Auth Error Occurred" />;
    if (auth.isLoading) return <GlobalLoader msg="Signing you in..." />;
    if (checkingIfTestUser) return <GlobalLoader msg="Checking identity..." />;
    if (auth.isAuthenticated) return <>{children} </>;
    return <ErrorText>Not Authenticated</ErrorText>;
}
