import { isVoiceTask } from "../voice";
import { useSession } from "../../sessions/hooks/useSession";

//TODO: How should this work in a multi-session/task scenario?
export function useActiveTask() {
    const { currentTask } = useSession();

    return currentTask;
}

/**
 * @deprecated Instead of using this hook, you should pass the voice task down the DOM chain:
 *               Ex. <MyComponent voiceTask={task} />
 */
export function useActiveVoiceTask() {
    const activeTask = useActiveTask();

    if (!activeTask) {
        throw new Error("No active task found");
    }

    if (!isVoiceTask(activeTask)) {
        throw new Error("Active task is not voice task");
    }

    return activeTask;
}

/**
 * @deprecated Instead of using this hook, you should pass the voice task down the DOM chain:
 *               Ex. <MyComponent voiceTask={task} />
 */
export function useOptionalActiveVoiceTask() {
    const activeTask = useActiveTask();

    if (!activeTask) {
        return undefined;
    }

    if (!isVoiceTask(activeTask)) {
        return undefined;
    }

    return activeTask;
}
