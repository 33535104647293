/* eslint-disable @typescript-eslint/no-empty-function */
import { useToast } from "@expert/common-ui";
import { useFeedbackDraftStore } from "@soluto-private/expert-workspace-feedback";

export const useFeedbackNotification = () => {
    const toast = useToast();
    const { draft } = useFeedbackDraftStore();

    return (type: "success" | "error") => {
        if (type === "success" && draft.text) {
            toast.show({
                title: " ",
                message: "Feedback saved",
                type,
                done: () => {},
            });
        } else if (type === "error") {
            toast.show({
                title: " ",
                message: "Oops, there was issue sending feedback. Try again—we saved your progress.",
                type,
                done: () => {},
            });
        }
    };
};
