import { getLogger } from "@expert/logging";
import { Workspace } from "twilio-taskrouter";

const logger = getLogger({ module: "twilioWorkspaceSdk" });

export class TwilioWorkspaceSdk {
    private readonly workspace: Workspace;
    private taskQueues: Record<string, string> | undefined;
    private agentTaskQueues: Record<string, string> | undefined;
    public outboundCallTaskQueueId: string | undefined;
    constructor(token: string) {
        this.workspace = new Workspace(token, {});
    }

    public async getAvailableAgents() {
        const localLogger = logger.child({ action: "getAvailableAgents" });
        localLogger.trace("Get Available Agents | Execution started");
        const workersMap = await this.workspace.fetchWorkers({ ActivityName: "Available" });
        const agents = Array.from(workersMap.values());
        localLogger.trace({ agents }, "Get Available Agents | Execution ended");
        return Object.fromEntries(agents.map((a) => [a.workerSid, a.friendlyName]));
    }

    private async initAllTaskQueues() {
        const localLogger = logger.child({ action: "getWorkerTaskQueues" });
        localLogger.trace("Init Task Queues | Execution started");
        const taskQueuesMap = await this.workspace.fetchTaskQueues();
        const taskQueues = Array.from(taskQueuesMap.values());
        this.taskQueues = Object.fromEntries(taskQueues.map((q) => [q.queueSid, q.queueName]));
        localLogger.debug({ taskQueues }, "Init Task Queues | Execution ended");
    }

    private async initWorkerTaskQueues(workerSid: string) {
        const localLogger = logger.child({ action: "getWorkerTaskQueues" });
        localLogger.trace("Init Agent Task Queues | Execution started");
        const taskQueuesMap = await this.workspace.fetchTaskQueues({ WorkerSid: workerSid });
        const taskQueues = Array.from(taskQueuesMap.values());
        this.agentTaskQueues = Object.fromEntries(taskQueues.map((q) => [q.queueSid, q.queueName]));
        localLogger.debug({ taskQueues }, "Init Agent Task Queues | Execution ended");
    }

    public async init(workerSid: string) {
        await Promise.all([this.initAllTaskQueues(), this.initWorkerTaskQueues(workerSid)]);
        const agentTaskQueueIds = Object.keys(this.agentTaskQueues ?? {});
        if (agentTaskQueueIds.length > 0) {
            this.outboundCallTaskQueueId = agentTaskQueueIds[0];
        }
    }

    public updateToken(token: string) {
        this.workspace.updateToken(token);
    }
}
