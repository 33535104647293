import { type SVGProps } from "react";

export function Pause(props: SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg fill="none" height="100%" viewBox="0 0 25 25" width="100%" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                clipRule="evenodd"
                d="M1.74481 1.8812C1.74481 1.62627 1.95147 1.41962 2.20639 1.41962H8.66851C8.92344 1.41962 9.13009 1.62627 9.13009 1.8812V23.1139C9.13009 23.3688 8.92344 23.5755 8.66851 23.5755H2.20639C1.95147 23.5755 1.74481 23.3688 1.74481 23.1139V1.8812Z"
                fillRule="evenodd"
                stroke="white"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
            <path
                clipRule="evenodd"
                d="M16.5154 1.8812C16.5154 1.62627 16.722 1.41962 16.977 1.41962H23.4391C23.694 1.41962 23.9007 1.62627 23.9007 1.8812V23.1139C23.9007 23.3688 23.694 23.5755 23.4391 23.5755H16.977C16.722 23.5755 16.5154 23.3688 16.5154 23.1139V1.8812Z"
                fillRule="evenodd"
                stroke="white"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
        </svg>
    );
}
