import { Textarea } from "@mantine/core";
import { useHotkeys } from "@mantine/hooks";
import { useReactAnalytics } from "@soluto-private/eventualize-react";
import type { Logger } from "@expert/logging";
import type { ChangeEvent, KeyboardEvent, SyntheticEvent } from "react";
import { useEffect, useRef, useState } from "react";
import { useExpertAssistStore } from "../../state";
import classes from "./MessageInput.module.css";
import { SendButton } from "./SendButton";
import { ToolboxButton } from "./ToolboxButton";
import { MessageInputBackground, MessageInputContainer, MessageInputWrapper } from "./elements";

const formatMessage = (msg: string) => {
    return msg.replace(/\n+$/, ""); // remove all trailing newline characters
};

interface MessageInputProps {
    logger: Logger;
}

export function MessageInput({ logger }: MessageInputProps): JSX.Element {
    /* 
        note: the reason for using useReactAnalytics directly from
        eventualize-react instead of using useAnalytics from the @expert/analytics
        package is because we need to be able to publish this @soluto-private/expert-workspace-timeline
        package to be used outside of this repository, so we can not use the
        unpublished @expert/analytics package in this instance.
    */
    const { dispatcher } = useReactAnalytics();
    const { sendMessage, shouldAutoOpenToolbox, setShouldAutoOpenToolbox } = useExpertAssistStore();

    const [input, setInput] = useState("");
    const inputRef = useRef<HTMLTextAreaElement>(null);
    const isInputDisabled = ["", "/"].includes(input.trimEnd());
    const toolboxInput = input.startsWith("/") ? input : undefined;
    // TODO: implement a more robust PII detection mechanism while still avoiding UI lag
    const containsPotentialPii = /(?:\d\D*){4,}/.test(input); // check if input contains 4 or more digits

    const [toolboxOpened, setToolboxOpened] = useState(false);
    const [toolboxTrapFocus, setToolboxTrapFocus] = useState(false);

    const handleInputChange = ({ target: { value: newInputValue } }: ChangeEvent<HTMLTextAreaElement>) => {
        setInput(newInputValue);
        const shouldOpenToolbox = newInputValue.startsWith("/");
        if (shouldOpenToolbox && !toolboxOpened) {
            void dispatcher.dispatchAction("Shortcut", "OpenToolbox", {});
        }
        setToolboxOpened(shouldOpenToolbox || false);
    };
    const handleSubmit = (event: SyntheticEvent) => {
        event.preventDefault();
        const formattedInput = formatMessage(input);
        const type = "Expert";
        const messageId = sendMessage({ text: formattedInput, type });
        void dispatcher.dispatchAction("Click", "SendMessage", { messageType: type, messageId });
        setInput("");
    };
    const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === "Enter" && !event.shiftKey && !isInputDisabled) handleSubmit(event);
        setToolboxTrapFocus((toolboxOpened && ["ArrowDown", "ArrowUp"].includes(event.key)) || false);
    };
    const handleToolboxClose = () => {
        if (toolboxInput) {
            setInput("");
        }
    };

    // auto-open toolbox on load for visibility to experts on new toolbox feature
    useEffect(() => {
        if (!shouldAutoOpenToolbox) return;
        setToolboxOpened(shouldAutoOpenToolbox);
        setShouldAutoOpenToolbox(false);
    }, [setToolboxOpened, shouldAutoOpenToolbox, setShouldAutoOpenToolbox]);

    useHotkeys(
        [
            [
                "/",
                () => {
                    if (!toolboxOpened) {
                        setToolboxOpened(true);
                        inputRef.current?.focus();
                        setInput(`${input}/`);
                        void dispatcher.dispatchAction("Shortcut", "OpenToolbox", {});
                    }
                },
            ],
        ],
        undefined,
        true,
    );

    return (
        <MessageInputContainer>
            <MessageInputBackground />
            <MessageInputWrapper>
                <Textarea
                    autosize
                    className={containsPotentialPii ? "fs-mask" : undefined}
                    classNames={{ input: classes.input }}
                    maxRows={5}
                    minRows={1}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyDown}
                    placeholder="Ask Assist or type / to open the toolbox"
                    radius="xl"
                    leftSection={
                        <ToolboxButton
                            opened={toolboxOpened}
                            setOpened={setToolboxOpened}
                            onClose={handleToolboxClose}
                            input={toolboxInput}
                            trapFocus={toolboxTrapFocus}
                            logger={logger}
                        />
                    }
                    leftSectionWidth="4.25rem"
                    rightSection={<SendButton isDisabled={isInputDisabled} onClick={handleSubmit} />}
                    rightSectionWidth={64}
                    size="xl"
                    style={{ width: "100%", input: { fz: "sm" } }}
                    value={input}
                    variant="filled"
                    data-testid="timeline-message-input"
                    id="timeline-message-input"
                    ref={inputRef}
                />
            </MessageInputWrapper>
        </MessageInputContainer>
    );
}
