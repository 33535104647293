import { type VoiceTask } from "@expert/sdk";
import { PendingCallBar, StandardCallBar } from "./CallDetails";

interface CallBarProps {
    task: VoiceTask;
}

export function CallDetailsSelector({ task }: CallBarProps): JSX.Element {
    if (task.status === "pending") {
        return <PendingCallBar task={task} />;
    }

    return <StandardCallBar task={task} />;
}
