import { Flex } from "@mantine/core";
import { AnalyticsProvider } from "@soluto-private/eventualize-react";
import { forwardRef, useEffect } from "react";
import type { TimelineProps } from "../../shared-types";
import { useExpertAssistStore } from "../../state";
import { MessageInput } from "../message-input";
import { TimelineList } from "../timeline-list";

export const Timeline = forwardRef<HTMLDivElement, TimelineProps>(function Timeline(
    { agentId, callSid, sendMessage, sessionId, features, partner, logger: loggerProp }: TimelineProps,
    ref,
): JSX.Element {
    const { setSessionId, setCallSid, setExpertId, setSendMessage, setFeatures, setPartner } = useExpertAssistStore();
    const logger = loggerProp.child({ module: "Timeline" });

    useEffect(() => {
        setSessionId(sessionId, logger);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setSessionId, sessionId]);

    useEffect(() => {
        setPartner(partner, logger);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setPartner, partner]);

    useEffect(() => {
        if (callSid) {
            setCallSid(callSid, logger);
        }

        return () => {
            setCallSid(undefined);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setCallSid, callSid]);

    useEffect(() => {
        setExpertId(agentId, logger);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setExpertId, agentId]);

    useEffect(() => {
        setSendMessage(sendMessage);
    }, [setSendMessage, sendMessage]);

    useEffect(() => {
        setFeatures(features);
    }, [setFeatures, features]);

    return (
        <AnalyticsProvider
            dispatcher={(analyticsDispatcher) =>
                analyticsDispatcher.withIdentities({ ExpertAssistSessionId: sessionId })
            }
        >
            <Flex
                align="center"
                data-testid="timeline-wrapper"
                direction="column"
                h="100%"
                justify="flex-end"
                style={{ overflow: "hidden" }}
                m="auto"
                ref={ref}
                w="100%"
            >
                <TimelineList sessionId={sessionId} />
                <MessageInput logger={logger} />
            </Flex>
        </AnalyticsProvider>
    );
});
