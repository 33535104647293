import { FlexSpacer, PhoneNumber } from "@expert/common-ui";
import { Group, Text } from "@mantine/core";
import { formatTime } from "@expert/shared-utils";

interface BodyProps {
    mdnText: string;
    secondsTillCall: number;
    isCalling: boolean;
    errorCalling: boolean;
}
export function CallbackCallBarBody({ mdnText, secondsTillCall, isCalling, errorCalling }: BodyProps) {
    //TODO: Remove/redo pending design
    if (errorCalling) {
        return (
            <Group flex={1}>
                <Text fw={700} size="md">
                    Call Failed{" "}
                    <Text fw={700} size="lg">
                        <PhoneNumber mdn={mdnText} />
                    </Text>
                </Text>
                <FlexSpacer />
            </Group>
        );
    }

    if (isCalling) {
        return (
            <Group align="center">
                <Text fw={400} size="md">
                    Calling{" "}
                    <Text fw={700} size="lg">
                        <PhoneNumber mdn={mdnText} />
                    </Text>
                </Text>
            </Group>
        );
    }

    return (
        <Group align="center">
            <Text fw={400} size="md">
                Calling{" "}
                <b className="fs-mask">
                    <PhoneNumber mdn={mdnText} />
                </b>{" "}
                <>
                    in
                    <Text fw={700} size="lg">
                        {formatTime(secondsTillCall, true, "m:ss")}
                    </Text>
                </>
            </Text>
        </Group>
    );
}
