import { getLogger } from "@expert/logging";
import type { StreamhubEventPayload } from "../models";
import { processStreamhubEvent } from "./processEvent";
import { getStreamhubUrl } from "./url";

const logger = getLogger({
    module: "streamhub-websocket",
});

const LOGGER_OPTIONS = "color: #FFC0CB";

const RECONNECT_DELAY_MS = 1000;
const MAX_RECONNECT_ATTEMPTS = 10;

interface ClientOptions {
    callSid: string;
    onMessage: (event: StreamhubEventPayload) => void;
}

export class StreamhubWebClient {
    private connection: WebSocket | undefined;

    private wsUrl: string;
    private onMessage: ClientOptions["onMessage"];
    private reconnectAttempts: number;
    private keepAlive: boolean;
    private heartbeatInterval: NodeJS.Timeout | undefined = undefined;

    constructor({ callSid, onMessage }: ClientOptions) {
        this.wsUrl = getStreamhubUrl(callSid);
        this.reconnectAttempts = 0;
        this.keepAlive = true;
        this.onMessage = onMessage;
        this.connect();
    }

    private connect() {
        logger.debug("=== Streamhub connect ===");
        const connection = new WebSocket(this.wsUrl);
        this.connection = connection;

        connection.onopen = (event: Event) => {
            logger.debug(event, `%c Connected to Streamhub websocket`, LOGGER_OPTIONS);
            this.heartbeatInterval = setInterval(() => {
                connection.send(JSON.stringify({ type: "flexlog", event: { nothing: true } }));
            }, 30000);
        };

        connection.onmessage = (event: MessageEvent<string>) => {
            this.reconnectAttempts = 0;

            //separates generative and sessionSummary events
            const result: StreamhubEventPayload | undefined = processStreamhubEvent(event);
            if (!result) return;
            logger.debug({ eventData: event.data }, `%c Streamhub processed message`, LOGGER_OPTIONS);
            this.onMessage(result);
        };

        connection.onerror = (event: Event) => {
            logger.debug(event, `%c Streamhub connection error`, LOGGER_OPTIONS);
            this.keepAlive = false;
        };

        connection.onclose = (event: CloseEvent) => {
            // close connection if there was a server error

            clearInterval(this.heartbeatInterval);
            if (!event.wasClean) {
                logger.debug(event, `%c Streamhub connection closed due to error`, LOGGER_OPTIONS);
                return;
            }

            // only reconnect if the server close the connection
            if (this.reconnectAttempts < MAX_RECONNECT_ATTEMPTS && this.keepAlive) {
                this.reconnectAttempts++;
                logger.debug(event, `%c Streamhub connection attempt ${this.reconnectAttempts}`, LOGGER_OPTIONS);

                setTimeout(() => {
                    this.connect();
                }, RECONNECT_DELAY_MS);
            } else if (this.reconnectAttempts === MAX_RECONNECT_ATTEMPTS) {
                logger.warn(event, `%c Max Streamhub reconnection attempts reached`, LOGGER_OPTIONS);
            }

            logger.debug(event, `%c Streamhub connection closed`, LOGGER_OPTIONS);
        };
    }

    public disconnect() {
        logger.debug("=== Streamhub disconnect ===");
        this.keepAlive = false;
        if (this.connection && this.connection.readyState === this.connection.OPEN) {
            this.connection.close();
        }
    }
}
