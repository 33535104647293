import * as React from "react";
import type { SVGProps } from "react";

export function LockIcon(props: SVGProps<SVGSVGElement>) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
            <path
                stroke="#A5AAAF"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M20.5 11.5a2 2 0 0 0-2-2h-13a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h13a2 2 0 0 0 2-2v-10ZM6.5 6a5.5 5.5 0 1 1 11 0v3.5h-11V6Z"
                clipRule="evenodd"
            />
            <path
                stroke="#A5AAAF"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 17.75a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5Z"
                clipRule="evenodd"
            />
        </svg>
    );
}
