import { DialpadIcon, IconButton } from "@expert/common-ui";
import { useControlsStore } from "../controls.store";

export function ToggleDialpadButton(): JSX.Element {
    const { dialpadOpen, isMuted, toggleDialpadActive } = useControlsStore();

    const isDisabled = isMuted;
    return (
        <IconButton
            active={dialpadOpen}
            analytics={{
                actionId: "ToggleDialpadButton",
            }}
            aria-label="Dialpad"
            data-testid="call-controls-dialpad-toggle"
            onClick={toggleDialpadActive}
            disabled={isDisabled}
            radius="xl"
            size="35"
            tooltipLabel={isDisabled ? "Disabled while muted" : "Dialpad"}
        >
            <DialpadIcon
                height={18}
                stroke={dialpadOpen ? "var(--mantine-color-black)" : "var(--mantine-color-white)"}
            />
        </IconButton>
    );
}
