import { Card, Flex, Loader, Text, Title } from "@mantine/core";
import { useEffect } from "react";
import { useEligibilityV2 } from "@expert/sales-tools";
import { formatPhoneNumber, validatePhoneNumber } from "@expert/shared-utils";
import { getSessionId } from "@expert/sdk";
import { transformDispatcher } from "@expert/analytics";
import { useVerifiedCustomerStore } from "@expert/customer-info";
import { CheckMarkCircle } from "../icons";

interface CustomerConfirmationStatusCardProps {
    mdn: string;
    reservationSid?: string;
    asurionCallId?: string;
}

export function CustomerConfirmationStatusCard({
    mdn,
    reservationSid,
    asurionCallId,
}: CustomerConfirmationStatusCardProps): JSX.Element | null {
    const customer = useVerifiedCustomerStore((state) => state.customer);
    const shouldCheckEligibility = !customer && validatePhoneNumber(mdn).valid;
    const { isLoading: isLoadingEligibility } = useEligibilityV2(
        {
            customerMdn: mdn.slice(2),
            reservationSid,
            sessionId: getSessionId(),
            partner: "verizon",
            asurionCallId,
        },
        shouldCheckEligibility,
    );

    useEffect(() => {
        transformDispatcher((newDispatcher) => {
            return newDispatcher.withExtra({ eligibilityCheckType: "EndOfSessionAutomaticCheck" });
        });
    }, []);

    // do not show card if we did not check eligibility in this component
    if (!shouldCheckEligibility) return null;

    return (
        <Card mb="24px" bg="primary.2">
            {isLoadingEligibility ? (
                <Flex>
                    <Loader size="xs" color="gray.9" />
                    <Flex direction="column" ml="1rem">
                        <Title c="gray.9" size="14px">
                            Checking eligibility for {formatPhoneNumber(mdn)}
                        </Title>
                        <Text mt="8px" c="gray.9" size="14px">
                            We automatically confirm if you don’t at the start of the call.
                        </Text>
                    </Flex>
                </Flex>
            ) : (
                <Flex>
                    <CheckMarkCircle />
                    <Flex direction="column" ml="1rem">
                        <Title c="gray.9" size="14px">
                            We checked eligibility for {formatPhoneNumber(mdn)}
                        </Title>
                        <Text mt="8px" c="gray.9" size="14px">
                            We automatically confirm if you don’t at the start of the call.
                        </Text>
                    </Flex>
                </Flex>
            )}
        </Card>
    );
}
