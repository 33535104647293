import { Indicator } from "@mantine/core";

interface ActivityBubbleProps {
    available: boolean;
    size?: number;
}

export function ActivityBubble({ available, size }: ActivityBubbleProps): JSX.Element {
    const color = available ? "var(--mantine-color-success-4)" : "var(--mantine-color-red-5)";
    return <Indicator color={color} inline mx="xs" offset={7} position="middle-center" size={size ?? 14} withBorder />;
}
