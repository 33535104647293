import { getLogger } from "@expert/logging";
import { Image, type MantineTransition, ScrollArea, Transition } from "@mantine/core";
import { useHover, useMergedRef, useMouse, useToggle } from "@mantine/hooks";
import { useReactAnalytics } from "@soluto-private/eventualize-react";
import { useEffect, useState } from "react";

const logger = getLogger({ module: "ImageZoomPan" });

export function ImageZoomPan({ src, title }: React.ImgHTMLAttributes<HTMLImageElement>) {
    const [isDisplayingImage, setIsDisplayingImage] = useState<boolean>(false);
    const [isZoomIn, toggleZoomIn] = useToggle([false, true] as const);

    const { dispatcher } = useReactAnalytics();
    const { ref: mouseRef, x, y } = useMouse();
    const { ref: hoverRef, hovered } = useHover();
    const ref = useMergedRef(hoverRef, mouseRef);

    const scaleImage: MantineTransition = {
        in: { transform: `scale(3)` },
        out: { transform: `unset` },
        common: { transformOrigin: `${x}px ${y}px` },
        transitionProperty: "transform",
    };

    const onImageClick = () => {
        logger.info({ url: src, title, isZoomIn }, "multi modal image clicked");
        void dispatcher.dispatchBusinessEvent("MultiModal_Click_CollageImage", {
            source: "Message",
            url: src,
            actionId: "CollageImage",
            title,
        });
        toggleZoomIn();
    };

    const onImageLoad = () => {
        logger.debug({ url: src, title }, "multi modal image loaded successfully");
        setIsDisplayingImage(true);
    };

    const onImageLoadError = () => {
        logger.warn({ url: src, title }, "multi modal image failed to load");
        setIsDisplayingImage(false);
    };

    const onImageZoomIn = () => {
        logger.debug({ url: src, title, isZoomIn }, "multi modal image zoomed in");
    };

    const onImageZoomOut = () => {
        logger.debug({ url: src, title }, "multi modal image zoomed out");
    };

    useEffect(() => {
        if (!hovered) {
            toggleZoomIn(false);
        }
    }, [hovered, toggleZoomIn]);

    return (
        <ScrollArea.Autosize
            display={isDisplayingImage ? "inline" : "none"}
            onClick={onImageClick}
            ref={ref}
            type="never"
            mt="md"
            style={{
                borderRadius: "var(--mantine-radius-md)",
                cursor: isZoomIn ? "zoom-out" : "zoom-in",
            }}
        >
            <Transition
                mounted={isZoomIn}
                transition={scaleImage}
                duration={300}
                exitDuration={300}
                timingFunction="ease-in-out"
                keepMounted
                onEntered={onImageZoomIn}
                onExited={onImageZoomOut}
            >
                {(transitionStyle) => (
                    <Image
                        src={src}
                        radius="md"
                        onLoad={onImageLoad}
                        onError={onImageLoadError}
                        fit="contain"
                        style={{
                            ...transitionStyle,
                            display: "inline-block",
                        }}
                    />
                )}
            </Transition>
        </ScrollArea.Autosize>
    );
}
