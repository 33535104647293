import type { SVGProps } from "react";

export function VerizonIcon(props: SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg fill="none" height="17" viewBox="0 0 75 17" width="75" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g id="Verizon - light bg">
                <path
                    d="M73.1619 0.978516L69.0752 9.39123L67.5385 6.2036H65.8765L68.4036 11.4177H69.7469L74.8125 0.978516H73.1619Z"
                    fill="#D52B1E"
                    id="mark"
                />
                <path
                    d="M55.6312 16.5998H58.9211V10.8051C58.9211 9.47968 59.7179 8.54859 60.9018 8.54859C62.0402 8.54859 62.6321 9.31537 62.6321 10.4217V16.5998H65.9219V9.81926C65.9219 7.51891 64.4876 5.89771 62.1654 5.89771C60.6855 5.89771 59.6496 6.51114 58.8756 7.62845H58.8072V6.20442H55.6199L55.6312 16.5998ZM49.1654 5.91962C45.7276 5.91962 43.4281 8.29665 43.4281 11.4295C43.4281 14.5405 45.7276 16.9394 49.1654 16.9394C52.6032 16.9394 54.9027 14.5405 54.9027 11.4295C54.9141 8.29665 52.6032 5.91962 49.1654 5.91962ZM49.1426 14.5952C47.5831 14.5952 46.7521 13.3355 46.7521 11.4295C46.7521 9.50159 47.5717 8.26378 49.1426 8.26378C50.7022 8.26378 51.5673 9.50159 51.5673 11.4295C51.5787 13.3355 50.7136 14.5952 49.1426 14.5952ZM34.0481 16.5998H43.2915V14.1023H38.0551V14.0366L43.0638 8.63622V6.19347H34.0481V8.70195H39.1138V8.76767L34.0481 14.2118V16.5998ZM29.8248 16.5998H33.1374V6.20442H29.8248V16.5998ZM22.1409 16.5998H25.4422V11.8239C25.4422 9.65495 26.7968 8.69099 28.8572 8.96484H28.9255V6.22633C28.7548 6.16061 28.5612 6.14965 28.2539 6.14965C26.9676 6.14965 26.1024 6.71926 25.3625 7.93516H25.2942V6.20442H22.1409V16.5998ZM16.0849 14.6062C14.6278 14.6062 13.7399 13.697 13.5692 12.2949H21.3555C21.3782 10.0602 20.7635 8.27474 19.4772 7.15742C18.5665 6.34683 17.3712 5.89771 15.8459 5.89771C12.5902 5.89771 10.3362 8.27474 10.3362 11.3857C10.3362 14.5185 12.4877 16.9065 16.0621 16.9065C17.4168 16.9065 18.4868 16.556 19.3747 15.9754C20.3196 15.3511 21.0026 14.4309 21.1847 13.5217H18.0201C17.7128 14.2009 17.0525 14.6062 16.0849 14.6062ZM15.9142 8.1652C17.0753 8.1652 17.8721 8.99771 18.0087 10.2136H13.5919C13.8537 8.98675 14.5481 8.1652 15.9142 8.1652ZM3.75656 16.5998H7.21715L10.9737 6.20442H7.66111L5.55516 12.8864H5.50962L3.40367 6.20442H0L3.75656 16.5998ZM29.8248 2.50195H33.1374V5.3281H29.8248V2.50195Z"
                    fill="#F0F0F5"
                    id="text"
                />
            </g>
        </svg>
    );
}
