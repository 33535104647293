import { getLogger } from "@expert/logging";
import { getActiveSession, getPreviousSessionId, getSessionHistory, getSessionId, useAgentStore } from "@expert/sdk";
import { userCache } from "@expert/shared-utils";
import type { AnalyticsContextModel, AnyData } from "@soluto-private/eventualize";
import { env } from "../../config";

const logger = getLogger({ module: "enhanceAnalytics" });

// TODO: Once this expands, move somewhere else, or create a simple enhancer registration/deregistration mechanism that works for our platform integrations
/** Enhances all dispatcher dispatches with fundamental, global, information */
export function enhanceAnalytics(_eventName: string, _eventData: AnyData, context: AnalyticsContextModel) {
    const sessionId = getSessionId();
    const previousSessionId = getPreviousSessionId();
    const { currentTask } = getActiveSession();

    // Debug aid to try and identify a bug where analytics have the same sessionId across what should be multiple sessions
    if (context.identities.SessionId) {
        const providedSessionId = context.identities.SessionId;
        if (providedSessionId !== sessionId && providedSessionId !== previousSessionId) {
            const history = getSessionHistory();
            const historyEntry = history.find((entry) => entry.id === providedSessionId);

            logger.error({ providedSessionId, historyEntry, sessionId, previousSessionId }, "SessionIdMismatch");
        }
    }

    context.app.commitHash = env.commitHash;
    context.identities.WorkerId = useAgentStore.getState().agentId;
    context.identities.Username = useAgentStore.getState().agentName;
    context.identities.EmpId = userCache.employeeId ?? undefined;

    if (currentTask && !context.identities.TaskId) {
        context.identities.TaskId = currentTask.id;
    }

    // mixpanel identity (enables uniques + funnels)
    context.identities.ExpertId = userCache.employeeId ?? undefined;

    // Guard to prevent overwriting the session id if it's already set
    if (!context.identities.SessionId) {
        context.identities.SessionId = sessionId;
    }

    // legacy identity for SoCo backwards compat
    context.identities.SupportSessionId = context.identities.SessionId;
}
